import { createTheme } from '@mui/material/styles'

const theme = createTheme({
	palette: {
		primary: {
			main: '#1B4DFF', // Primary color (default for buttons, links, etc.)
		},
		secondary: {
			main: '#1B4DFF1A', // Secondary color
		},
		error: {
			main: '#FF3E3E', // Error color
		},
	},
	typography: {
		fontFamily: 'Roboto, Arial, sans-serif', // Global font family
		button: {
			textTransform: 'none', // Disable uppercase for buttons
		},

		h1: {
			fontWeight: 600,
			fontSize: '44px',
		},

		h6: {
			fontWeight: 600,
		},

		customHeading: {
			fontWeight: 600,
			fontSize: '14px',
			color: '#50607A',
		},

		subtitle2: {
			fontWeight: 700,
		},

		customContent: {
			fontWeight: 500,
			fontSize: '14px',
			color: '#50607A',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 8, // Default border radius for all buttons
				},
			},
		},
	},
})

export default theme
