import { Box, Drawer, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { CircularLoader, SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useGetTeamByIDQuery } from "../../../store/teamsSlice";

const Content = ({ data, key, toggleDrawer }) => {
  // Destructuring the data
  // const { name, desc } = data.data;

  return (
    <Box key={key} sx={{ padding: "2rem" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography variant="p" sx={{ fontSize: "28px", fontWeight: "400" }}>
          Team Details
        </Typography>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        noValidate={true}
        autoComplete="off"
      >
        <Grid item={true} md={6} xs={12}>
          <Typography variant="h2" sx={{ fontSize: "15px" }}>
            Team Name:
          </Typography>
          <Typography
            sx={{
              wordWrap: "break-word",
              overflowWrap: "break-word",
              hyphens: "auto",
            }}
            variant="body1"
          >
            {" "}
            {data?.name}
          </Typography>
        </Grid>
        <Grid item={true} md={6} xs={12}>
          {/* <Divider /> */}
          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="h2" sx={{ fontSize: "15px" }}>
              Description:
            </Typography>
            <Typography variant="body1"> {data?.description}</Typography>
          </Box>
        </Grid>
        <Grid item={true} md={6} xs={12}>
          {/* <Divider /> */}
          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="h2" sx={{ fontSize: "15px" }}>
              Team Members:
            </Typography>
            <ul>
              {data?.members.map((CareTeamMember) => (
                <li key={CareTeamMember.id}>
                  {CareTeamMember.name} {`(${CareTeamMember.role})`}
                  {/* <ListItemText primary={CareTeamMember.name}>
                   
                  </ListItemText> */}
                </li>
              ))}
            </ul>
          </Box>
        </Grid>

        <Stack
          direction="row"
          justifyContent={"flex-end"}
          sx={{ marginTop: "3rem" }}
        >
          <SolidButton
            label="Close"
            onClick={(e) => {
              toggleDrawer(false);
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export function ViewTeamDetailDrawer({ state, toggleDrawer }) {
  const id = state.teamID;
  // Integrating the API
  const { isLoading, isError, error, data } = useGetTeamByIDQuery(id);

  const isMobile = useIsMobile();
  if (isLoading) return <CircularLoader />;
  if (isError) return <div>{error}</div>;
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        key={state.teamID}
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: isMobile ? "80%" : "45%",
          },
        }}
        anchor="right"
        open={state.detail}
        onClose={toggleDrawer(false)}
      >
        <Content
          key={state.teamID}
          data={data?.data || []}
          toggleDrawer={toggleDrawer(false)}
        />
      </Drawer>
    </React.Fragment>
  );
}
