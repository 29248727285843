import {
  FormControl,
  InputLabel,
  MenuItem,
  Portal,
  Select,
} from "@mui/material";
import { useRef } from "react";

export function CreateTrigger({ triggerData: data, trigger, setTrigger }) {
  const container = useRef(null);

  const handleChange = (event) => {
    const trigger = event.target.value;
    setTrigger(trigger);
  };

  return (
    // <Box>
    //   <FormControl fullWidth>
    //     <InputLabel id="demo-simple-trigger-label">Select Trigger</InputLabel>
    //     <Select
    //       labelId="demo-simple-task-label"
    //       id="demo-simple-trigger"
    //       value={trigger}
    //       label="Select Trigger"
    //       onChange={handleChange}
    //     >
    //       {data?.data?.map((item) => (
    //         <MenuItem key={item._id} value={item._id}>
    //           {console.log(item.title, "trigger")}
    //           {item.title}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //   </FormControl>
    // </Box>

    <FormControl
      fullWidth={true}
      ref={container}
      sx={{
        textAlign: "left",
      }}
    >
      <InputLabel id="demo-simple-form-label">Select Trigger</InputLabel>
      <Portal container={() => container.current}>
        <Select
          fullWidth={true}
          labelId="demo-simple-form-label"
          id="demo-simple-trigger"
          value={trigger}
          label="Select Trigger"
          onChange={handleChange}
        >
          {data?.data?.map((item) => (
            <MenuItem
              key={item._id}
              value={item?._id}
              sx={{
                "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                  width: "100%",
                },
                width: "590px",
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Select>
      </Portal>
    </FormControl>
  );
}
