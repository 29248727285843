import CircleIcon from "@mui/icons-material/Circle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import { useSelector } from "react-redux";
import { useGetPatientsSurveyQuery } from "../../store/patientsSurveySlice";
import { CircularLoader } from "../Loader";

const renderResult = (item) => {
  if (item?.type === "signaturepad" && item?.result) {
    return <img src={item.result} alt="Signature" />;
  } else if (
    item?.type === "multipletext" &&
    typeof item?.result === "object"
  ) {
    return (
      <Box>
        {Object.entries(item.result).map(([key, value], index) => (
          <Typography key={index} sx={{ marginTop: "10px", fontSize: "14px" }}>
            {key}: {value}
          </Typography>
        ))}
      </Box>
    );
  } else {
    return item?.result;
  }
};

const ContentAccordion = ({ data, defaultExpanded }) => {
  return (
    <Accordion
      sx={{ border: "none", boxShadow: "none", width: "100%" }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Box sx={{ width: "100%" }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Survey Form
          </Typography>
          <Typography variant="subtitle2">{data?.title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} sm={12}>
            {data?.status !== "pending" &&
              data?.formResult &&
              data?.formResult
                .filter((items) => items?.type != "panel")
                .map((item, index) => (
                  <Grid key={index} item md={12} xs={12}>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: "20px",
                          marginBottom: "12px",
                          padding: "6px",
                        }}
                      >
                        <Typography variant="h2" sx={{ fontSize: "16px" }}>
                          Q{index + 1}
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: "20px" }}>
                        <Typography variant="h2" sx={{ fontSize: "14px" }}>
                          {item?.title}
                        </Typography>
                        <Typography
                          sx={{ marginTop: "10px", fontSize: "14px" }}
                        >
                          {Array.isArray(item?.result)
                            ? item?.result.map((val, i) =>
                                item.type == "checkbox" ? (
                                  <Grid item md={12} xs={12}>
                                    <Divider />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          marginRight: "20px",
                                          marginBottom: "12px",
                                          padding: "6px",
                                        }}
                                      ></Box>
                                      <Box sx={{ marginTop: "20px" }}>
                                        <Typography
                                          sx={{
                                            marginTop: "10px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {i + 1}. {val}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                ) : (
                                  Object.keys(val).map((key, indexes) => (
                                    <Grid item md={12} xs={12}>
                                      <Divider />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            marginRight: "20px",
                                            marginBottom: "12px",
                                            padding: "6px",
                                          }}
                                        >
                                          <Typography
                                            variant="h2"
                                            sx={{ fontSize: "16px" }}
                                          >
                                            Q{indexes + 1}
                                          </Typography>
                                        </Box>
                                        <Box sx={{ marginTop: "20px" }}>
                                          <Typography
                                            variant="h2"
                                            sx={{ fontSize: "14px" }}
                                          >
                                            {key}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              marginTop: "10px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {val[key]}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  ))
                                ),
                              )
                            : renderResult(item)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}

            {data?.status === "pending" && (
              <Typography>
                Survey is currently pending. Results will be available once the
                survey is completed
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const customContent = (dummyCallData) => {
  return dummyCallData.map((data, index) => (
    <Box key={index} sx={{ width: "-webkit-fill-available" }}>
      <ContentAccordion data={data} defaultExpanded={index === 0} />
    </Box>
  ));
};

const CustomTitle = ({ dateTime }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle" color="textSecondary" fontSize={"14px"}>
        {moment(dateTime).format("DD-MMM-YY")}
      </Typography>
      <Typography variant="body2" color="textSecondary" fontSize={"12px"}>
        {moment(dateTime).format("h:mm A")}
      </Typography>
    </Box>
  );
};

export default function FormTab({
  id,
  queueIds,
  isShowForms,
  setIsShowForms,
  isFormType,
  setIsFormLoading,
}) {
  const { user } = useSelector((state) => state.user);

  const [filteredData, setFilteredData] = useState([]);
  const [timelineItems, setTimelineItems] = useState([]);

  const { isLoading, data, refetch } = useGetPatientsSurveyQuery({
    userID: user.id,
    id,
    page: 1,
    limit: 1000,
  });

  useEffect(() => {
    if (filteredData && !isShowForms) {
      const timelineDataReturn = filteredData?.map((item) => {
        return { title: <CustomTitle dateTime={item?.createdAt} /> };
      });
      setTimelineItems(timelineDataReturn);
    } else if (isShowForms) {
      const timelineDataReturn = data?.data?.map((item) => {
        return { title: <CustomTitle dateTime={item?.createdAt} /> };
      });
      setTimelineItems(timelineDataReturn);
    }
  }, [filteredData, isShowForms]);

  useEffect(() => {
    if (data?.data && isFormType) {
      const dataToShow = data?.data?.filter((item) =>
        queueIds.includes(item?._id),
      );
      setFilteredData(dataToShow);
    } else if (data?.data && !isFormType) {
      const timelineDataReturn = data?.data?.map((item) => {
        return { title: <CustomTitle dateTime={item?.createdAt} /> };
      });
      setTimelineItems(timelineDataReturn);
    }
  }, [data]);

  useEffect(() => {
    refetch();
    setIsShowForms(false);
  }, []);

  useEffect(() => {
    setIsFormLoading(isLoading);
  }, [isLoading]);

  if (isLoading) return <CircularLoader />;

  return (
    <Box
      sx={{
        height: "70vh",
        overflowY: "hidden",
        overflowX: "hidden",
        marginBottom: "40px",
      }}
      className="DialogContent"
    >
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          size="small"
          startIcon={<FeedOutlinedIcon />}
          sx={{
            mr: "10px",
            background: "#E9E9E9",
            color: "black",
            "&:hover": {
              backgroundColor: "#bdbdbd",
              color: "white",
            },
          }}
        >
          Upload Form
        </Button>
      </Box> */}
      {(!isShowForms &&
        filteredData &&
        filteredData?.length === 0 &&
        isFormType) ||
      ((isShowForms || !isFormType) &&
        data?.data &&
        data?.data?.length === 0) ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography variant="h6">
            {!isShowForms && isFormType
              ? "No Queue Forms Record Found"
              : "No Forms Record Found"}
          </Typography>
        </Box>
      ) : (
        <Chrono
          key={timelineItems?.length}
          items={timelineItems}
          hideControls={true}
          disableClickOnCircle={true}
          mode="VERTICAL"
          disableAutoScrollOnClick={true}
          scrollable={false}
          cardHeight={100}
          theme={{ primary: "#0049C6", secondary: "#f5f5f5" }}
          cardPosition="top"
        >
          {customContent(
            !isShowForms && isFormType ? filteredData : data?.data || [],
          )}

          <Box className="chrono-icons">
            {timelineItems.map((item, index) => (
              <CircleIcon
                key={index}
                sx={{ color: "#0049C6", fontSize: "20px" }}
              />
            ))}
          </Box>
        </Chrono>
      )}
    </Box>
  );
}
