import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DateField, SolidButton } from "../../../../Components";
import TimeFieldPicker from "../../../../Components/TimeField";
import { useChildNode } from "../../../../contexts/addChildNode";
import { useIsMobile } from "../../../../contexts/isMobile";
import { useGetAllJourneysQuery } from "../../../../store/journeysSlice";
import { useGetPhysiciansOptionsQuery } from "../../../../store/physiciansSlice";
import {
  useGetProceduresOptionsQuery,
  useGetProceduresStatusQuery,
} from "../../../../store/proceduresSlice";
import {
  useAddNewSurgeryMutation,
  useEditSurgeryMutation,
} from "../../../../store/surgeriesSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 500,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius: "10px",
};

const JourneyDropdown = ({
  triggerJourney,
  setTriggerJourney,
  journeyListData,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Store the _id instead of title
    setTriggerJourney(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl variant="standard" fullWidth>
        <Select
          required
          id="demo-multiple-checkbox"
          multiple
          value={triggerJourney}
          onChange={handleChange}
          renderValue={(selected) =>
            selected
              .map(
                (id) =>
                  journeyListData?.data?.find((src) => src._id === id)?.title,
              )
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {journeyListData?.data?.map((src) => (
            <MenuItem key={src._id} value={src._id}>
              <Checkbox checked={triggerJourney.indexOf(src._id) > -1} />
              <ListItemText primary={src.title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export function SurgeryModal({
  open,
  setOpen,
  fetechAgain,
  setEditData,
  editData,
  status,
  patientId,
}) {
  const { procedureReferesh, setProcedureReferesh } = useChildNode();
  const { user } = useSelector((state) => state.user);
  const { id } = useParams();
  const isMobile = useIsMobile();
  const [surgeryDate, setSurgeryDate] = useState("");
  const [time, setTime] = useState("");
  const [surgeryTime, setSurgeryTime] = useState({});
  const [surgeon, setSurgeon] = useState("");
  const [selectedSurgeon, setSelectedSurgeon] = useState(null);
  const [selectedSurgeryType, setSelectedSurgeryType] = useState(null);
  const [selectedSurgeryStatus, setSelectedSurgeryStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const { data: journeyListData, refetch: refetchJourneyList } =
    useGetAllJourneysQuery({
      id: user.id,
      params: "exclude=appointment",
      procedureId: editData?._id,
    });
  const { data: procedureTypeData, isLoading: isProcedureLoading } =
    useGetProceduresOptionsQuery();
  const { data: procedureStatusData, isLoading: isStatusLoading } =
    useGetProceduresStatusQuery();
  const { data: physicianOptions, isLoading: isPhysicianLoading } =
    useGetPhysiciansOptionsQuery();
  const [triggerJourney, setTriggerJourney] = useState([]);
  // const [delayValue, setDelayValue] = useState(5);
  // const [metric, setMetric] = useState("hours");
  const [form, setForm] = useState({
    // triggerAutomatedAction: false,
    description: "",
    location: "",
    surgeryType: "",
    patient: patientId ? patientId : id,
    careTeamMember: user.id,
  });
  const [addNewSurgery, { isLoading: isAddSurgeryLoading }] =
    useAddNewSurgeryMutation();
  const [editSurgery, { isLoading: isEditSurgeryLoading }] =
    useEditSurgeryMutation();

  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const resetAllValues = () => {
    setForm({
      // triggerAutomatedAction: false,
      description: "",
      location: "",
      surgeryType: "",
      patient: patientId ? patientId : id,
      careTeamMember: user.id,
    });
    setTriggerJourney([]);
    setSurgeryDate("");
    setSelectedSurgeryType(null);
    setEditData({});
    setSurgeon("");
    setTime();
    setSelectedSurgeon(null);
    setSurgeryTime({});
    setSelectedSurgeryStatus(null);
  };

  // const onCheckboxChange = (e) => {
  // setForm((form) => ({
  // ...form,
  // [e.target.name]: !form.triggerAutomatedAction,
  // }));
  // };

  const surgeryData = [
    {
      title: "Primary Total Hip Replacement",
      value: "primary_total_hip_replacement",
    },
    {
      title: "Primary Total Knee Replacement",
      value: "primary_total_knee_replacement",
    },
    {
      title: "Other Procedure",
      value: "other_procedure",
    },
  ];

  const handleSurgeryChange = (e, newValue) => {
    setSelectedSurgeryType(newValue);
    setForm((form) => ({ ...form, surgeryType: newValue?._id }));
  };

  const handleStatusChange = (e, newValue) => {
    setSelectedSurgeryStatus(newValue);
    setForm((form) => ({ ...form, surgeryStatus: newValue?.value }));
  };

  const createSurgery = async (e) => {
    if (status === "active") {
      if (
        surgeryDate === "" ||
        surgeon === "" ||
        form.surgeryType === "" ||
        !form.surgeryStatus
        // (form.triggerAutomatedAction && (delayValue === "" || metric === ""))
      ) {
        toast.error("Please fill out the required fields");
        return;
      }

      if (editData?._id) {
        const body = {
          ...form,
          surgeryType: form?.surgeryType,
          surgeon,
          surgeryDate,
          // delayValue,
          // delayMetric: metric,
          journeyIds: triggerJourney,
          surgeryTime,
        };

        await editSurgery({
          id: editData?._id,
          body,
        })
          .unwrap()
          .then(() => {
            fetechAgain();
            setProcedureReferesh(procedureReferesh + 1);
            resetAllValues();
            setOpen(false);
            toast.success("Successfully Edited!");
          })
          .catch((error) => {
            toast.error(error.data || error?.data?.error);
          });
      } else {
        await addNewSurgery({
          ...form,
          surgeryType: form?.surgeryType,
          surgeon,
          surgeryDate,
          // delayValue,
          // delayMetric: metric,
          journeyIds: triggerJourney,
          surgeryTime,
        })
          .unwrap()
          .then(() => {
            fetechAgain();
            resetAllValues();
            setOpen(false);
            if (triggerJourney?.length !== 0) {
              toast.success(
                "Procedure created and Journey assigned successfully!",
              );
              setProcedureReferesh(procedureReferesh + 1);
            } else {
              toast.success("Procedure created successfully!");
            }
          })
          .catch((error) => {
            if (error?.status === 400) {
              toast.error(error?.data?.error);
            } else {
              toast.error(error.data || error?.data?.error);
            }
          });
      }
    } else {
      toast.error("The patient status is currently inactive.");
      resetAllValues();
      setOpen(false);
    }
  };

  const handleClose = () => {
    resetAllValues();
    setOpen(false);
  };

  const handleSurgeonChange = (event, newValue) => {
    setSelectedSurgeon(newValue);
    setSurgeon(newValue?._id);
  };

  useEffect(() => {
    if (procedureStatusData?.data) {
      const transformedOptions = Object.entries(procedureStatusData.data).map(
        ([key, value]) => ({ name: value, value: key }),
      );
      setStatusOptions(transformedOptions);
    }
  }, [procedureStatusData]);

  useEffect(() => {
    if (editData) {
      // const filteredJourney = journeyListData?.data?.find(
      //   (item) => item?.title === editData?.assignedJourneyName
      // );
      console.log(editData, "Procedure Edit Data");
      const filteredSurgeryType = procedureTypeData?.data?.find(
        (item) => item?.name === editData?.surgeryType,
      );
      const filteredSurgeryStatus = statusOptions?.find(
        (item) => item?.name === editData?.surgeryStatus,
      );

      setForm({
        // triggerAutomatedAction: editData?.triggerAutomatedAction || false,
        description: editData?.description || "",
        location: editData?.location || "",
        patient: patientId ? patientId : id,
        careTeamMember: editData?.careTeamMember || user.id,
        surgeryType: filteredSurgeryType?._id || "",
        surgeryStatus: filteredSurgeryStatus?.name || null,
      });

      setSurgeon(editData?.surgeon?._id);
      setSelectedSurgeon(editData?.surgeon);
      setSelectedSurgeryType(filteredSurgeryType);
      setSurgeryDate(
        editData?.surgeryDate ? new Date(editData?.surgeryDate) : "",
      );
      setSelectedSurgeryStatus(filteredSurgeryStatus);

      if (editData?.surgeryTime?.hour) {
        const { hour, minute } = editData?.surgeryTime;

        // Create a new date object
        const newDate = new Date();

        // Set the hours and minutes
        newDate.setHours(
          Number.parseInt(hour, 10),
          Number.parseInt(minute, 10),
          0,
          0,
        );

        // Convert to string format like 'Thu Apr 04 2024 08:00:00 GMT+0500 (Pakistan Standard Time)'
        const formattedDate = newDate.toString();

        setTime(new Date(formattedDate));
      } else {
        setTime("");
      }

      setSurgeryTime(editData?.surgeryTime);

      // setTriggerJourney({
      //   id: filteredJourney?._id,
      //   name: filteredJourney?.title,
      // });
    }
  }, [editData]);

  useEffect(() => {
    refetchJourneyList();
  }, []);

  if (isProcedureLoading) return <>{console.log("Loading")}</>;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "80%" : 750,
          height: isMobile ? "60%" : "80%",
          bgcolor: "#F5F5F5",
          p: 4,
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Procedure
          </Typography>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              resetAllValues();
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Box>
        </Stack>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color={"#979797"} mt={2}>
                Surgeon Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedSurgeon}
                onChange={(e, newValue) => handleSurgeonChange(e, newValue)}
                options={
                  !isPhysicianLoading && Array.isArray(physicianOptions)
                    ? physicianOptions
                    : []
                }
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
              {/* <TextField
                variant="standard"
                fullWidth
                name="surgeonName"
                value={form.surgeonName}
                onChange={onChange}
              /> */}
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color={"#979797"} mt={2}>
                Procedure Type <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedSurgeryType}
                onChange={(e, newValue) => handleSurgeryChange(e, newValue)}
                options={procedureTypeData?.data}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
              {/* <TextField
                variant="standard"
                fullWidth
                name="surgeryType"
                value={form.surgeryType}
                onChange={onChange}
              /> */}
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color={"#979797"} mt={2}>
                Procedure Status <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedSurgeryStatus}
                onChange={(e, newValue) => handleStatusChange(e, newValue)}
                options={statusOptions}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>

            {/* ********************** START: COMMENTING THE PROCEDURE DATE AND TIME SEPARATE UI, AS PRECAUTIONS FROM ERRORS */}
            <Grid item md={6} xs={12}>
              <Typography fontSize={12} color={"#979797"}>
                Procedure Date <span style={{ color: "red" }}>*</span>
              </Typography>
              <DateField
                date={surgeryDate}
                setDate={setSurgeryDate}
                // {...{ label: "Surgery Time" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography fontSize={12} color={"#979797"}>
                Procedure Time
              </Typography>
              <TimeFieldPicker
                date={time}
                setDate={setTime}
                isSurgery={true}
                setHourToRunAt={setSurgeryTime}
                // {...{ label: "Surgery Time" }}
              />
            </Grid>
            {/* ********************** END: COMMENTING THE PROCEDURE DATE AND TIME SEPARATE UI, AS PRECAUTIONS FROM ERRORS */}
            <Grid item md={6} xs={12}>
              <Typography fontSize={12} color={"#979797"} mb={1}>
                Location
              </Typography>
              <TextField
                variant="standard"
                fullWidth
                name="location"
                value={form.location}
                onChange={onChange}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize={12} color={"#979797"} mb={1}>
                  Select Journey
                </Typography>
                <Tooltip
                  title="Select journey to link with this procedure"
                  placement="right-start"
                >
                  <Box
                    sx={{
                      color: "grey",
                      paddingLeft: "3px",
                      cursor: "pointer",
                    }}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Box>
                </Tooltip>
              </Box>
              <JourneyDropdown
                {...{ triggerJourney, setTriggerJourney, journeyListData }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography fontSize={12} color={"#979797"} mt={2}>
                Description
              </Typography>
              <TextField
                variant="standard"
                fullWidth
                name="description"
                multiline
                rows={4}
                value={form.description}
                onChange={onChange}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <Typography fontSize={12} mt={2}>
                Journey to Assign{" "}
              </Typography>
              <Autocomplete
                value={triggerJourney.name}
                onChange={(e, newvalue) =>{
                  setTriggerJourney({
                    id:  journeyListData?.data?.find(
                      (item) => item._id === newvalue
                    )?._id,
                    name: newvalue,
                  })}
                }
                options={journeyListData?.data?.map((item) => item?.title)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid> */}

            {/* <Grid item md={12} xs={12}> 
              <FormControlLabel
                control={
                  <Checkbox
                    name="triggerAutomatedAction"
                    checked={form.triggerAutomatedAction}
                    onChange={(e) => onCheckboxChange(e)}
                  />
                }
                label="Trigger automated actions on the surgery"
              />
             </Grid> */}
            {/* {form.triggerAutomatedAction && ( 
              <>
                <Grid item md={6} xs={12}>
                  <DelaySelect metric={metric} setMetric={setMetric} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography fontSize={12} color={"#979797"} mt={2}>
                    {`${metric}`}
                  </Typography>
                  <TextField
                    variant="standard"
                    fullWidth
                    name="title"
                    value={delayValue}
                    onChange={(e) => {
                      setDelayValue(e.target.value);
                    }}
                  />
                </Grid>
              </>
              )} */}
          </Grid>
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
          >
            <SolidButton
              label="Assign"
              onClick={createSurgery}
              disabled={isAddSurgeryLoading || isEditSurgeryLoading}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
