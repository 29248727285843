import { Box, Button, Grid, Typography } from "@mui/material";

const PatientCheck = ({ setActiveStep, setPatientCheck }) => {
  const handleResponse = (response) => {
    console.log("User response:", response);
    // Add your logic to handle Yes or No response
  };

  return (
    <Grid
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ height: "100%", overflowX: "hidden" }}
    >
      <img
        srcSet={require("../../assets/thank-you-doctor.png")}
        src={require("../../assets/thank-you-doctor.png")}
        alt="Thank You doctor"
        loading="lazy"
      />
      <Typography variant="h4" align="center" sx={{ marginY: "20px" }}>
        Are you the patient?
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button
          variant="outlined"
          onClick={() => {
            setActiveStep(1);
            setPatientCheck("no");
          }}
          sx={{
            color: "#242424",
            borderColor: "#E4E4E4",
            borderRadius: "24px",
            fontSize: "20px",
            fontWeight: "600",
            paddingY: "24px",
            paddingX: "32px",
            mr: 3,
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setActiveStep(1);
            setPatientCheck("yes");
          }}
          sx={{
            backgroundColor: "#1B4DFF",
            color: "#fff",
            borderRadius: "24px",
            fontSize: "20px",
            fontWeight: "600",
            paddingY: "24px",
            paddingX: "32px",
          }}
        >
          Yes
        </Button>
      </Box>
    </Grid>
  );
};

export default PatientCheck;
