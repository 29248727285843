import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useChildNode } from "../contexts/addChildNode";

export function NodeMenuStartJourney({
  anchorEl,
  setAnchorEl,
  setAddChildNode,
  setAnchorElButton,
}) {
  const open = Boolean(anchorEl);

  const { setIsChildNodeAdded } = useChildNode();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdChild = () => {
    setAddChildNode(true);
    setIsChildNodeAdded(true);
    setAnchorEl(null);
    setAnchorElButton(null);
  };

  return (
    <div>
      <div
        sx={{ cursor: "pointer", color: "black" }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleAdChild}>Add Child Node</MenuItem>
      </Menu>
    </div>
  );
}
