import * as React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { useState, useEffect } from 'react'
import ImageModal from '../MessageLayout/ImageModal'
import { useGetPatientImagesQuery } from '../../store/patientsSlice'
import { CircularLoader } from '../Loader'
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'

export default function GalleryTab({ id }) {
	const [groupedImages, setGroupedImages] = useState([])
	const [image, setImage] = useState('')
	const [title, settitle] = useState('')
	const [uploadBy, setUploadBy] = useState('')
	const [open, setOpen] = useState(false)
	const [date, setDate] = useState('')
	const { data, isLoading, isSuccess } = useGetPatientImagesQuery(id)

	const handleClick = (image, caption, date, uploadedBy) => {
		setImage(image)
		settitle(caption)
		setDate(date)
		setOpen(true)
		setUploadBy(uploadedBy)
	}

	useEffect(() => {
		const groupImagesByDate = () => {
			console.log(data)
			const grouped = data?.patientImages?.reduce((acc, curr) => {
				const date = new Date(curr.createdAt)
				const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
				console.log(formattedDate)
				if (!acc[formattedDate]) {
					acc[formattedDate] = { date: formattedDate, images: [curr] }
				} else {
					acc[formattedDate].images.push(curr)
				}
				return acc
			}, {})

			// Sort the grouped array by date in descending order
			const sortedGroupedArray = Object.values(grouped).sort((a, b) => {
				const dateA = new Date(a.date)
				const dateB = new Date(b.date)
				return dateB - dateA
			})

			setGroupedImages(sortedGroupedArray)
		}

		if (data) {
			groupImagesByDate()
		}
	}, [data])

	if (isLoading) return <CircularLoader />

	return (
		<>
			{/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          size="small"
          startIcon={<CollectionsOutlinedIcon />}
          sx={{
            mr: "10px",
            background: "#E9E9E9",
            color: "black",
            "&:hover": {
              backgroundColor: "#bdbdbd",
              color: "white",
            },
          }}
        >
          Upload Image
        </Button>
      </Box> */}
			<div style={{ overflowY: 'auto', maxHeight: '69vh' }} className="DialogContent">
				{groupedImages?.length > 0 ? (
					<>
						{groupedImages.map((itemsData, mainIndex) => (
							<div key={mainIndex}>
								<Typography sx={{ fontSize: 'large', fontWeight: '600', padding: '30px 0px', color: '#636363' }}>{itemsData?.date}</Typography>
								<Grid container spacing={1}>
									{itemsData?.images?.map((item, index) => (
										<Grid item md={4} xs={4}>
											<Card
												onClick={(e) => handleClick(item.imageUrl, item.reason, item.createdAt, item?.uploadedBy)}
												style={{ cursor: 'pointer' }}
												key={index}
											>
												<CardActionArea>
													<CardMedia height={140} component="img" image={`${item.imageUrl}`} alt={item.reason} />
													<CardContent>
														<Typography
															variant="body2"
															color="text.secondary"
															style={{
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																textOverflow: 'ellipsis',
																width: '150px',
															}}
														>
															{item.reason}
														</Typography>
													</CardContent>
												</CardActionArea>
											</Card>
										</Grid>
									))}
								</Grid>
							</div>
						))}
					</>
				) : (
					<Typography
						sx={{
							fontSize: '18px',
							fontWeight: 600,
							mt: '2rem',
							textAlign: 'center',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '50vh',
						}}
					>
						No Images Found.
					</Typography>
				)}

				<ImageModal {...{ open, setOpen, title, image, date, uploadBy }} />
			</div>
		</>
	)
}
