import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentSiteId, getTokenFromLocalStroage } from "../utils";

export const baseApiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("authorization", `Bearer ${getTokenFromLocalStroage()}`);
      headers.set("hospital", getCurrentSiteId());
      return headers;
    },
  }),
  tagTypes: ["User", "Servey", "PatientsSurvey", "Journeys", "Journey"],
  endpoints: (builder) => ({}),
});
