import { Button, Grid, Typography } from "@mui/material";

export default function EnrollScreen({
  isSmallScreen,
  setIsEnrolledClicked,
  setActiveStep,
  hospitalDetails,
  hospitalDetailsLoading,
}) {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="space-between"
      sx={{
        minHeight: "97vh", // Full height of the viewport
      }}
    >
      <Grid xs={12} sx={{ pl: "16px" }}>
        <img
          srcSet={hospitalDetails?.logoS3Url}
          src={hospitalDetails?.logoS3Url}
          alt="Hospital-title"
          loading="lazy"
          style={{
            width: "auto",
            height: "100px",
            maxWidth: "100%",
            objectFit: "contain",
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" sx={{ color: "#060606" }}>
          {hospitalDetails?.onBoardingPageTitle}{" "}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#242424", opacity: 0.7, marginY: "16px" }}
        >
          {hospitalDetails?.onBoardingPageDescription}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <video
          controls
          autoPlay
          muted
          style={{ width: "100%", borderRadius: "8px" }}
          src={hospitalDetails?.onBoardingVideoUrl}
        >
          Your browser does not support the video tag.
        </video>{" "}
      </Grid>
      {/* <Grid item xs={12}>
				<Card
					sx={{
						backgroundColor: 'rgba(27, 77, 255, 0.05)',
						padding: '10px',
						borderRadius: '8px',
					}}
				>
					<CardContent>
						<Typography variant="body1" sx={{ color: '#242424' }}>
							<span style={{ color: '#1B4DFF' }}>Sign up</span> for the Bone and Joint Health Text Line to get updates about your orthopedic care!
						</Typography>
					</CardContent>
				</Card>
			</Grid> */}
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#1B4DFF",
            borderRadius: "20px",
            width: isSmallScreen ? "90vw" : "initial",
            mb: 1,
          }}
          onClick={() => {
            if (hospitalDetails?.isPatientScreen) {
              setIsEnrolledClicked(true);
              setActiveStep(0);
            } else {
              setActiveStep(1);
            }
          }}
        >
          Enroll Now
        </Button>
      </Grid>
    </Grid>
  );
}
