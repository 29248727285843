import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

function MessageAccordion({ title, data }) {
  return (
    <Accordion
      defaultExpanded
      sx={{
        width: "90%", // Ensure the accordion does not overflow its parent
        overflow: "hidden", // Prevent content overflow
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel1-${title}`}
        id={`panel1-${title}`}
        sx={{ background: "#f1f3f4", wordWrap: "break-word", fontWeight: 600 }} // Handle word wrapping
      >
        View {title}
      </AccordionSummary>
      <AccordionDetails sx={{ background: "#f1f3f4" }}>
        <Typography
          sx={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            marginBottom: "10px",
          }}
        >
          {" "}
          {/* Word wrapping for long text */}
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              a: (props) => (
                <a
                  href={props.href}
                  style={{
                    color: "#000",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                  target="blank"
                >
                  {props.children}
                </a>
              ),
              p: (props) => (
                <p
                  style={{
                    margin: "0px",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {props.children}
                </p>
              ),
            }}
          >
            {data?.replace(/^```|```$/g, "")}
          </ReactMarkdown>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default MessageAccordion;
