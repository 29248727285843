import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgents: builder.query({
      query: () => {
        return `/agents`;
      },
    }),
    getAgentsFilter: builder.query({
      query: () => {
        return `/agents/getFilters`;
      },
    }),
    addNewAgent: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/agents",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Agents", id: "LIST" }],
    }),
    sendMessageByAgentId: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/agents/assistant",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Agents", id: "LIST" }],
    }),
    editAgents: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/agents/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // deletePhysician: builder.mutation({
    //   query: (id) => {
    //     return {
    //       url: `/v2/physician/${id}`,
    //       method: "DELETE",
    //     };
    //   },
    // }),
  }),
});

export const {
  useGetAgentsQuery,
  useGetAgentsFilterQuery,
  useAddNewAgentMutation,
  useSendMessageByAgentIdMutation,
  useEditAgentsMutation,
  //   useDeletePhysicianMutation
} = extendedApiSlice;
