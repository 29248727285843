import { Box } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import { useIsMobile } from "../../contexts/isMobile";

export function DoughnutChart({
  allMetric,
  setAllMetric,
  heading,
  labels,
  formData,
  colors,
  query,
  backendData,
}) {
  // For Responsiveness
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState([]);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isMobile = useIsMobile();

  // Data for doughnut
  const maxVisibleLabels = 3;

  // Prepare visible labels and "Others" group
  const prepareData = (labels, formData) => {
    if (labels.length <= maxVisibleLabels) {
      return {
        visibleLabels: labels,
        visibleData: formData,
        othersRemaining: [],
      };
    }

    const visibleLabels = labels.slice(0, maxVisibleLabels);
    const visibleData = formData.slice(0, maxVisibleLabels);

    const othersLabels = labels.slice(maxVisibleLabels);
    const othersData = formData.slice(maxVisibleLabels);

    // Calculate the total for "Others" and store details
    const othersTotal = othersData.reduce((sum, value) => sum + value, 0);
    const othersRemaining = othersLabels.map((label, index) => ({
      label,
      value: othersData[index],
    }));

    visibleLabels.push("Others");
    visibleData.push(othersTotal);

    return {
      visibleLabels,
      visibleData,
      othersRemaining,
    };
  };

  // Apply the transformation
  const { visibleLabels, visibleData, othersRemaining } = prepareData(
    labels,
    formData,
  );
  console.log("othersRemaining:", othersRemaining);

  const updatedColors = [...colors.slice(0, maxVisibleLabels), "#99ABBF"];

  const data = {
    labels: visibleLabels,
    datasets: [
      {
        label: heading,
        data: visibleData, // Use aggregated data
        borderColor: ["#FFFFFF"],
        backgroundColor: updatedColors,
        pointBackgroundColor: "rgba(255,206,86,0.2)",
      },
    ],
  };
  console.log("Prepared Chart Data:", data);
  // Options for doughnut
  const options = {
    plugins: {
      title: {
        display: true,
        text: heading,
        font: {
          size: 16,
          weight: "bold",
        },
        padding: {
          bottom: 20,
        },
        align: "start",
      },
      legend: {
        display: true,
        position: "right",
        align: "end",
        labels: {
          boxWidth: 10,
          padding: 10,
          usePointStyle: true,
        },
        onHover: (event, legend, item) => {
          if (legend.index == "3") {
            setAnchorEl(event.native.target);
            setPopoverData(othersRemaining); // Pass aggregated data for "Others"
          }
        },
        onLeave: () => {
          setAnchorEl(null);
        },
      },
      datalabels: {
        color: (ctx) => {
          const value = ctx.dataset.data[ctx.dataIndex];
          if (
            data.labels[ctx.dataIndex] === "Pending" ||
            data.labels[ctx.dataIndex] === "Total Procedures" ||
            data.labels[ctx.dataIndex] === "No Procedures" ||
            data.labels[ctx.dataIndex] === "No"
          ) {
            return "black"; // Black for Pending
          }
          return "white"; // White for Complete or others
        },
        formatter: (value, ctx) => {
          // Calculate the percentage value
          const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
          const total = dataset.data.reduce((sum, current) => sum + current, 0);
          const percentage = Math.round((value * 100) / total);
          if (percentage > 0) {
            return `${percentage}%`;
          } else {
            return ""; // Empty string if percentage is not greater than 0
          }
        },
        font: {
          size: 12,
          weight: "bold",
        },
        display: true, // Set the display property to true
      },
      afterDraw: (chart) => {
        console.log(chart);
        if (chart.data.datasets[0].data.length < 1) {
          const ctx = chart.ctx;
          const width = chart.width;
          const height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "30px Arial";
          ctx.fillText("No data to display", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
    maintainAspectRatio: false, // Disable aspect ratio to control size manually
    responsive: true,
    onClick: (evt, element) => {
      if (element.length > 0) {
        const chart = element[0].element.$context.chart;
        const datasetIndex = element[0].datasetIndex;
        const index = element[0].index;
        const label = chart.data.labels[index];
        const backendValue = backendData?.find((item) => item.label === label);
        if (
          !allMetric?.some(
            (obj) => obj.query === query && obj.values === backendValue?.value,
          )
        ) {
          const newMetric = [
            ...allMetric,
            { metric: heading, key: label, values: backendValue?.value, query },
          ];
          setAllMetric(newMetric);
          localStorage.setItem("allMetric", JSON.stringify(newMetric));
        }
      }
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement.length
        ? "pointer"
        : "default";
    },
  };

  return (
    <div style={{ width: "100%", height: "250px", position: "relative" }}>
      <Pie data={data} options={options} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          marginTop: "25px",
          marginRight: "8px",
        }}
      >
        <Box
          sx={{
            padding: "10px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {popoverData?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#99ABBF",
                  marginRight: "8px",
                  borderRadius: "50%",
                }}
              ></Box>
              <Typography>{item.label}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </div>
  );
}
