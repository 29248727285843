import { Buffer } from "buffer";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularLoader, SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useGetCarePathwayQuery } from "../../../store/carepathwaysSlice";
import {
  useAddNewContentMutation,
  useGenerateUploadUrlMutation,
} from "../../../store/contentsSlice";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

export const CreateContentModal = ({ open, setOpen, title }) => {
  const fileRef = useRef();
  const { user } = useSelector((state) => state.user);
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [key, setKey] = useState("");
  const [showLoader, setLoader] = useState(false);
  const isMobile = useIsMobile();
  const LoadingMessage =
    "File is uploading, please don’t close your window it might take couple of minutes";
  const [selectCarePathway, setSelectCarePathway] = useState([]);
  const [carePathways, setCarePathway] = useState([]);
  const [CarePathwaysList, setCarePathwaysList] = useState([]);
  const { data: carePathwayData } = useGetCarePathwayQuery();

  const [generateUploadUrl] = useGenerateUploadUrlMutation();

  useEffect(() => {
    if (carePathwayData?.data) {
      const newResult = Object.entries(carePathwayData?.data)?.map(
        ([key, value]) => {
          return {
            _id: value,
            title: key,
          };
        },
      );

      setCarePathwaysList(newResult);
    }
  }, [carePathwayData]);

  const handleClose = () => {
    setOpen(false);
    setName(""); // Clear the tag input field
    setFile("");
    setKey("");
    setType("");
    setCarePathway([]);
  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const uploadFile = e.target.files[0];
      const fileType = uploadFile.name.split(".").pop();
      setType(fileType);
      setFile(uploadFile);
      setKey(uploadFile.name);
    }
  };

  const [addNewContent, { isLoading: isAddContentLoading }] =
    useAddNewContentMutation();
  const handleCarePathwayChange = (event, newValue) => {
    // Extract values from newValue array of objects
    const values = newValue.map((item) => item._id);

    setSelectCarePathway(
      // On autofill we get a stringified value.
      typeof newValue === "string" ? newValue?.split(",") : newValue,
    );

    setCarePathway(values);
  };
  const onSave = async () => {
    setLoader(true);
    try {
      const params = {
        Bucket: "azra-content-manager",
        Key: file.name,
        Body: file,
        ContentType: file?.type,
      };
      const assignedUrl = await generateUploadUrl({
        fileName: file.name,
        contentType: file?.type,
      });

      const preSignedUrl = assignedUrl?.data?.pre_signed_url;

      // Step 2: Upload the file to S3 using the pre-signed URL
      const uploadResponse = await fetch(preSignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type, // Make sure to set the correct content type
        },
        body: file, // Upload the actual file content
      });

      if (!uploadResponse.ok) {
        toast.error("Failed to upload the file.");
        return;
      }

      const fileUrl = preSignedUrl.split("?")[0];

      await addNewContent({
        name,
        type,
        key,
        carePathways,
        url: fileUrl,
        creator: user.id,
        s3Uri: `s3://${params?.Bucket}/${file?.name}`,
      })
        .unwrap()
        .then((res) => {
          setLoader(false);
          toast.success("Successfully created!");
          handleClose();
        })
        .catch((e) => {
          setLoader(false);
          toast.error(e?.data?.error || e?.message || "Some error occurred!");
        });
    } catch (e) {
      setLoader(false);
      console.error("Error uploading file:", e);
      toast.error("Some error occurred while uploading the file.");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 600,
    bgcolor: "#F5F5F5",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          {showLoader ? (
            <div style={{ textAlign: "center" }}>
              <CircularLoader message={LoadingMessage} modalHeight="30vh" />
            </div>
          ) : (
            <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                required
                id="outlined-required"
                label="Name"
                name="name"
                placeholder="Enter Hospital Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                sx={{ width: "100%", mt: 2, marginBottom: "0.5em" }}
              />

              <Autocomplete
                multiple
                value={selectCarePathway}
                onChange={(e, newValue) => handleCarePathwayChange(e, newValue)}
                options={CarePathwaysList}
                getOptionLabel={(option) => option?.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Care Pathways"
                  />
                )}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#816179",
                    padding: "5px",
                    height: isMobile ? "30px" : "35px",
                    width: isMobile ? "100%" : "150px",
                    borderRadius: "0.5rem",
                    fontSize: "14px",
                  }}
                  onClick={() => fileRef.current.click()}
                >
                  Upload File
                </Button>
                {file && (
                  <Typography sx={{ marginLeft: "5px" }}>
                    {" "}
                    : {file.name ? file.name : "No File Selected"}{" "}
                  </Typography>
                )}
                <input
                  hidden
                  type="file"
                  name="myFile"
                  ref={fileRef}
                  onChange={onFileChange}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Box sx={{ ml: "auto" }}>
                  <SolidButton
                    label="Save"
                    onClick={onSave}
                    disabled={isAddContentLoading}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
