import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { useCreateChatByPhoneMutation } from "../../store/callSlice";

function validatePhoneNumber(number) {
  // Remove all spaces
  const cleanedNumber = number.replace(/\s+/g, "");

  // Check if it starts with "+1" and has exactly 12 characters
  if (!cleanedNumber.startsWith("+1") && cleanedNumber.length !== 12) {
    return true;
  } else {
    return false;
  }
}

export const SendUnknownMessageField = React.memo(
  ({
    setPage,
    scrollDown,
    phone,
    refetchAgain,
    setData,
    data,
    consentResponse,
  }) => {
    // States
    const [inputMessage, setInputMessage] = React.useState("");

    // Integrating API's
    const [createChatByPhone] = useCreateChatByPhoneMutation();

    // Functions

    const sentMessage = async () => {
      if (consentResponse !== "yes") {
        toast.error("SMS consent has not been provided by the user!");
      } else if (phone === "N/A") {
        toast.error("User phone number does not exist!");
      } else if (validatePhoneNumber(phone)) {
        toast.error("Phone Number not valid!");
      } else {
        createChatByPhone({
          body: inputMessage.replace(/\n/g, "\r\n"),
          senderId: "panal",
          sentBy: "panal",
          phoneNumber: phone?.replace(/\s+/g, ""),
        });

        const newData = [
          ...data,
          {
            body: inputMessage.replace(/\n/g, "\r\n"),
            senderId: "panal",
            sentBy: "panal",
            phoneNumber: phone?.replace(/\s+/g, ""),
          },
        ];

        setData(newData);

        // refetchAgain()
        setInputMessage("");
        setPage(1);
        scrollDown();
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        const cursorPosition = e.target.selectionStart;
        const text = inputMessage;
        const beforeCursor = text.substring(0, cursorPosition);
        const afterCursor = text.substring(cursorPosition, text.length);
        setInputMessage(beforeCursor + "\n" + afterCursor);
      }
    };

    const handleTextField = (e) => {
      setInputMessage(e.target.value);
    };

    return (
      <>
        <Grid alignItems="center">
          <Grid item xs={12} sm={12}>
            <TextField
              placeholder="Write your message..."
              value={inputMessage}
              onChange={(e) => handleTextField(e)}
              multiline
              maxRows={12}
              onKeyPress={handleKeyPress}
              InputProps={{
                disableUnderline: true,

                style: {
                  borderRadius: "10px",
                  padding: "10px",
                  minHeight: "6rem",
                },
                endAdornment: (
                  <>
                    <Button
                      onClick={() => {
                        sentMessage();
                        setInputMessage("");
                      }}
                      size="medium"
                      variant="contained"
                      sx={{
                        background: "0049C6",
                        borderRadius: "30px",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: 1,
                      }}
                    >
                      Send
                    </Button>
                  </>
                ),
              }}
              sx={{
                width: "100%",
                padding: "10px 0px",
                "& .MuiInputBase-inputMultiline": {
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar for WebKit browsers
                  },
                  scrollbarWidth: "none",
                },
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  },
);
