export const pathways = [
  {
    id: "1",
    label: "Total Shoulder Replacement",
    value: "total_shoulder_replacement",
  },
  { id: "2", label: "Total Knee Replacement", value: "total_knee_replacement" },
  {
    id: "3",
    label: "Total Hip Replacement Pathway",
    value: "total_hip_replacement_pathway",
  },
  {
    id: "4",
    label: "Nonoperative Hip and Knee",
    value: "nonoperative_hip_and_knee",
  },
  { id: "5", label: "Ankle Fracture", value: "ankle_fracture" },
  { id: "6", label: "Sports Medicine", value: "sports_medicine" },
  { id: "7", label: "Hip Fracture (non-thr)", value: "hip_fracture_non_thr" },
  { id: "8", label: "Hand Wrist Elbow", value: "hand_wrist_elbow" },
  {
    id: "9",
    label: "Long Bone Lower Extremity Fracture",
    value: "long_bone_lower_extremity",
  },
  { id: "10", label: "Spine", value: "spine" },
  {
    id: "11",
    label: "Reverse Total Shoulder Arthroplasty Pathway",
    value: "reverse_total_shoulder_artroplasty_pathway",
  },
  {
    id: "12",
    label: "Long Bone Lower Pathway",
    value: "long_bone_lower_pathway",
  },
  { id: "13", label: "Ob-Gyn", value: "ob_gyn" },
];

export const programs = [
  {
    id: "1",
    label: "Total Shoulder Replacement",
    value: "total_shoulder_replacement",
  },
  { id: "2", label: "Total Knee Replacement", value: "total_knee_replacement" },
  {
    id: "3",
    label: "Total Hip Replacement Pathway",
    value: "total_hip_replacement_pathway",
  },
  {
    id: "4",
    label: "Nonoperative Hip and Knee",
    value: "nonoperative_hip_and_knee",
  },
  { id: "5", label: "Ankle Fracture", value: "ankle_fracture" },
  { id: "6", label: "Sports Medicine", value: "sports_medicine" },
  { id: "7", label: "Hip Fracture (non-thr)", value: "hip_fracture_non_thr" },
  { id: "8", label: "Hand Wrist Elbow", value: "hand_wrist_elbow" },
  {
    id: "9",
    label: "Long Bone Lower Extremity Fracture",
    value: "long_bone_lower_extremity",
  },
  { id: "10", label: "Spine", value: "spine" },
  {
    id: "11",
    label: "Reverse Total Shoulder Arthroplasty Pathway",
    value: "reverse_total_shoulder_artroplasty_pathway",
  },
  {
    id: "12",
    label: "Long Bone Lower Pathway",
    value: "long_bone_lower_pathway",
  },
  { id: "13", label: "Ob-Gyn", value: "ob_gyn" },
];

export const languages = [
  { label: "English", id: "english" },
  { label: "Spanish", id: "spanish" },
];

export const convertToObjectArray = (obj) => {
  const result = [];
  let id = 1;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result.push({
        id: id++,
        title: key,
        value: obj[key],
      });
    }
  }
  return result;
};
