import { Drawer } from "@mui/material";
import React from "react";
import { AddUser } from "./AddUser";

export function SideBar({ state, toggleDrawer }) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "80%",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <AddUser toggleDrawer={toggleDrawer(false)} />
      </Drawer>
    </React.Fragment>
  );
}
