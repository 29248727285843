import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"; // Transportation icon
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices"; // Utilities icon
import FastfoodIcon from "@mui/icons-material/Fastfood"; // Food icon
import FavoriteIcon from "@mui/icons-material/Favorite"; // Health icon
import HomeIcon from "@mui/icons-material/Home"; // Housing icon
import LocalAtmIcon from "@mui/icons-material/LocalAtm"; // Financial icon
import SecurityIcon from "@mui/icons-material/Security"; // Safety icon
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms"; // Tobacco icon

const iconMapping = {
  housing: <HomeIcon />,
  financial: <LocalAtmIcon />,
  food: <FastfoodIcon />,
  transportation: <DirectionsCarIcon />,
  utility: <ElectricalServicesIcon />,
  safety: <SecurityIcon />,
  tobacco: <SmokingRoomsIcon />,
  health: <FavoriteIcon />,
  // Add other icons if needed
};

export const socialNeedsOptionData = [
  { value: "housing", label: "Housing", icon: <HomeIcon /> },
  { value: "financial", label: "Financial", icon: <LocalAtmIcon /> },
  { value: "food", label: "Food", icon: <FastfoodIcon /> },
  {
    value: "transportation",
    label: "Transportation",
    icon: <DirectionsCarIcon />,
  },
  { value: "utilities", label: "Utilities", icon: <ElectricalServicesIcon /> },
  { value: "safety", label: "Safety", icon: <SecurityIcon /> },
  { value: "tobacco", label: "Tobacco", icon: <SmokingRoomsIcon /> },
  { value: "health", label: "Health", icon: <FavoriteIcon /> },
];

export const loopingCloseStatus = [
  { value: "opened", label: "Opened" },
  { value: "closed", label: "Closed" },
];

export const socialCareConsent = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

function formatString(str) {
  if (str.includes("_")) {
    return str.replace(/_/g, " ");
  }
  return str;
}

export function convertObjectToArray(obj) {
  return Object.entries(obj).map(([label, value]) => ({
    label: formatString(label),
    value: value,
  }));
}

export function convertSocialNeedsToOptionData(obj) {
  const options = [];

  // Map over each key-value pair in the socialNeeds object
  for (const [label, value] of Object.entries(obj)) {
    options.push({
      value: value.toLowerCase(),
      label: label,
      icon: iconMapping[value.toLowerCase()] || null, // Use icon if available, else null
    });
  }

  return options;
}
