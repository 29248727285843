import { createContext, useContext, useState } from "react";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [userMessages, setUserMessages] = useState([]);
  const [messagesToSend, setMessagesToSend] = useState([]);
  const [isAILoading, setIsAILoading] = useState(false);

  function updateMessage(inputMessage) {
    const updatedChatMessages = [
      ...chatMessages,
      { content: inputMessage, role: "user" },
    ];
    const updatedUserMessages = [
      ...userMessages,
      { content: inputMessage, role: "user" },
    ];
    const messagesToSendTemp = [...updatedUserMessages];

    setMessagesToSend(messagesToSendTemp);
    setChatMessages(updatedChatMessages);
    setUserMessages(updatedUserMessages);

    //   const finalChatMessages = [...updatedChatMessages, { content: response.data.answer, reference: response.data?.context_docs, role: 'system' }];
    //   const finalUserMessages = [...updatedUserMessages, { content: response.data.answer, role: 'assistant' }]
    //   setChatMessages(finalChatMessages);
    //   setUserMessages(finalUserMessages)
    // Handle the response as needed, for example, updating chat messages with the response

    return updatedChatMessages;
  }

  return (
    <ChatContext.Provider
      value={{
        chatMessages,
        setChatMessages,
        updateMessage,
        messagesToSend,
        setMessagesToSend,
        isAILoading,
        setIsAILoading,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export function useChatValues() {
  return useContext(ChatContext);
}
