import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function MultiselectDropdown({
  data,
  setData,
  placeholder,
  metric,
  setAllMetric,
  allMetric,
  keyData,
}) {
  function toCamelCase(str) {
    return str
      .split(" ") // Split the string by spaces
      .map(
        (word, index) =>
          index === 0
            ? word?.toLowerCase() // Convert the first word to lowercase
            : word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase(), // Capitalize the first letter of the rest
      )
      .join(""); // Join the words back together
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleItemClick = (name) => {
    if (metric && metric !== "") {
      if (
        allMetric?.some(
          (obj) => obj.metric === metric && obj.key === Object.keys(name)[0],
        )
      ) {
        const newArray = allMetric.filter(
          (item) =>
            item?.metric !== metric || item?.key !== Object.keys(name)[0],
        );
        setAllMetric(newArray);
        localStorage.setItem("allMetric", JSON.stringify(newArray));
      } else {
        const newArray = [
          ...allMetric,
          {
            metric,
            key: Object.keys(name)[0],
            values: Object.values(name)[0],
            query: toCamelCase(metric),
          },
        ];
        setAllMetric(newArray);
        localStorage.setItem("allMetric", JSON.stringify(newArray));
      }
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={true}
          value={data}
          onChange={handleChange}
          input={<OutlinedInput label={placeholder} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {keyData?.length !== 0 &&
            keyData.map((name) => (
              <MenuItem
                key={name}
                value={Object.keys(name)[0]}
                onClick={() => handleItemClick(name)}
              >
                <Checkbox checked={data.indexOf(Object.keys(name)[0]) > -1} />
                <ListItemText primary={Object.keys(name)[0]} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
