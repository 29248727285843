import React from 'react'

export function Conversation({ color, size }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size || '16'} height={size || '16'} viewBox="0 0 16 16" fill="none">
			<path
				d="M12.0002 2C12.7074 2 13.3857 2.28095 13.8858 2.78105C14.3859 3.28115 14.6668 3.95942 14.6668 4.66667V10C14.6668 10.7072 14.3859 11.3855 13.8858 11.8856C13.3857 12.3857 12.7074 12.6667 12.0002 12.6667H8.85083L5.67616 14.5713C5.58056 14.6287 5.47208 14.6612 5.36068 14.6658C5.24927 14.6704 5.13849 14.647 5.03847 14.5977C4.93845 14.5484 4.85239 14.4749 4.78816 14.3837C4.72393 14.2926 4.68359 14.1868 4.67083 14.076L4.66683 14V12.6667H4.00016C3.316 12.6667 2.65801 12.4037 2.16229 11.9322C1.66657 11.4607 1.37104 10.8166 1.33683 10.1333L1.3335 10V4.66667C1.3335 3.95942 1.61445 3.28115 2.11454 2.78105C2.61464 2.28095 3.29292 2 4.00016 2H12.0002ZM9.3335 8H5.3335C5.15668 8 4.98712 8.07024 4.86209 8.19526C4.73707 8.32029 4.66683 8.48986 4.66683 8.66667C4.66683 8.84348 4.73707 9.01305 4.86209 9.13807C4.98712 9.2631 5.15668 9.33333 5.3335 9.33333H9.3335C9.51031 9.33333 9.67988 9.2631 9.8049 9.13807C9.92992 9.01305 10.0002 8.84348 10.0002 8.66667C10.0002 8.48986 9.92992 8.32029 9.8049 8.19526C9.67988 8.07024 9.51031 8 9.3335 8ZM10.6668 5.33333H5.3335C5.15668 5.33333 4.98712 5.40357 4.86209 5.5286C4.73707 5.65362 4.66683 5.82319 4.66683 6C4.66683 6.17681 4.73707 6.34638 4.86209 6.4714C4.98712 6.59643 5.15668 6.66667 5.3335 6.66667H10.6668C10.8436 6.66667 11.0132 6.59643 11.1382 6.4714C11.2633 6.34638 11.3335 6.17681 11.3335 6C11.3335 5.82319 11.2633 5.65362 11.1382 5.5286C11.0132 5.40357 10.8436 5.33333 10.6668 5.33333Z"
				fill={color}
			/>
		</svg>
	)
}
