import { Box, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Header from "./Header";
import Body from "./Body";

const Content = ({ name,
  user,
  path,
  patient,
  acuity,
  value,
  id,
  status,
  consent,
isJourney}) => {
  return(
    <>
    <Header {...{ name,
							user,
							path,
							patient,
							acuity,
							value,
							id,
							status,
							consent,}} />
              <Body patient={patient} isJourney={isJourney} />
    </>
  )
}

export function PatientProfileDrawer({
  state,
  toggleDrawer,
  name,
							user,
							path,
							patient,
							acuity,
							value,
							id,
							status,
							consent,
              isJourney
}) {

  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "70%",
            padding:'20px',
            maxHeight: '100vh',
            overflowY: 'hidden'
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
          <Typography sx={{fontSize:'22px', fontWeight:'bold'}}>Patient Management Detail</Typography>
          <CloseIcon onClick={toggleDrawer(false)} sx={{cursor:'pointer'}} />
        </Box>
       <Content {...{ name,
							user,
							path,
							patient,
							acuity,
							value,
							id,
							status,
							consent,
              isJourney}} />
      </Drawer>
    </React.Fragment>
  );
}
