import React from 'react'

export function Form({color, size}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size || '16'} height={size || '16'} viewBox="0 0 16 16" fill="none">
			<path
				d="M9.05717 1.33333H3.99984C3.2665 1.33333 2.6665 1.93333 2.6665 2.66666V13.3333C2.6665 14.0667 3.2665 14.6667 3.99984 14.6667H11.9998C12.7332 14.6667 13.3332 14.0667 13.3332 13.3333V5.60933C13.3332 5.43266 13.2632 5.26266 13.1378 5.13799L9.5285 1.52866C9.40317 1.40333 9.23384 1.33333 9.05717 1.33333ZM5.6665 12H4.99984C4.81584 12 4.6665 11.8507 4.6665 11.6667V11C4.6665 10.816 4.81584 10.6667 4.99984 10.6667H5.6665C5.8505 10.6667 5.99984 10.816 5.99984 11V11.6667C5.99984 11.8507 5.8505 12 5.6665 12ZM5.6665 9.33333H4.99984C4.81584 9.33333 4.6665 9.18399 4.6665 8.99999V8.33333C4.6665 8.14933 4.81584 7.99999 4.99984 7.99999H5.6665C5.8505 7.99999 5.99984 8.14933 5.99984 8.33333V8.99999C5.99984 9.18399 5.8505 9.33333 5.6665 9.33333ZM10.6665 12H7.99984C7.63184 12 7.33317 11.7013 7.33317 11.3333C7.33317 10.9653 7.63184 10.6667 7.99984 10.6667H10.6665C11.0345 10.6667 11.3332 10.9653 11.3332 11.3333C11.3332 11.7013 11.0345 12 10.6665 12ZM10.6665 9.33333H7.99984C7.63184 9.33333 7.33317 9.03466 7.33317 8.66666C7.33317 8.29866 7.63184 7.99999 7.99984 7.99999H10.6665C11.0345 7.99999 11.3332 8.29866 11.3332 8.66666C11.3332 9.03466 11.0345 9.33333 10.6665 9.33333ZM8.6665 5.99999V2.33333L12.3332 5.99999H8.6665Z"
				fill={color}
			/>
		</svg>
	)
}
