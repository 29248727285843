import { useReactFlow } from "reactflow";
import { randomLabel, uuid } from "../utils";

export function usePlaceholderClick(id) {
  const { getNode, setNodes, setEdges } = useReactFlow();

  const onClick = () => {
    const parentNode = getNode(id);

    if (!parentNode) {
      return;
    }

    const childPlaceholderId = uuid();

    const childPlaceholderNode = {
      id: childPlaceholderId,
      position: { x: parentNode.position.x, y: parentNode.position.y },
      type: "placeholder",
      data: { label: "+" },
    };

    const childPlaceholderEdge = {
      id: `${parentNode.id}=>${childPlaceholderId}`,
      source: parentNode.id,
      target: childPlaceholderId,
      type: "placeholder",
    };

    setNodes((nodes) =>
      nodes
        .map((node) => {
          if (node.id === id) {
            return {
              ...node,
              type: "workflow",
              data: { label: randomLabel() },
            };
          }
          return node;
        })
        .concat([childPlaceholderNode]),
    );

    setEdges((edges) =>
      edges
        .map((edge) => {
          if (edge.target === id) {
            return {
              ...edge,
              type: "workflow",
            };
          }
          return edge;
        })
        .concat([childPlaceholderEdge]),
    );
  };

  return onClick;
}

export default usePlaceholderClick;
