import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Chrono } from 'react-chrono'
import CircleIcon from '@mui/icons-material/Circle'
import { CircularLoader } from '../Loader'
import AddIcon from '@mui/icons-material/Add'
import { useDeleteNoteMutation, useGetNoteByIDQuery, useGetPatientNotesQuery } from '../../store/notesSlice'
import { ContentCopy } from '@mui/icons-material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import NoteAccordion from '../../Pages/Patient/PatientProfile/NoteAccordion'
import { parse } from 'marked'
import { toast } from 'react-toastify'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'
import NoteModal from '../../Pages/Patient/PatientProfile/ProfileModals/NotesModal'

function getArrayStatus(obj) {
	if (obj.forms.length === 0 && obj.calls.length === 0 && obj.chats.length === 0) {
		return 'Manually Entered'
	} else {
		let result = ''
		if (obj.forms.length > 0) {
			result += 'Form, '
		}
		if (obj.chats.length > 0) {
			result += 'SMS, '
		}
		if (obj.calls.length > 0) {
			result += 'Call, '
		}
		// Remove trailing comma at the end of result
		if (result.endsWith(', ')) {
			result = result.slice(0, -2)
		}
		return result
	}
}
const tagStatus = (status) => {
	if (status === 'published') {
		return <Chip label={status?.charAt(0)?.toUpperCase() + status?.slice(1)} size="small" color="success" />
	} else if (status === 'draft') {
		return <Chip label={status?.charAt(0)?.toUpperCase() + status?.slice(1)} size="small" color="warning" />
	} else {
		return 'N/A'
	}
}

const copyText = (description) => {
	// Create a temporary container to hold the HTML content
	const tempDiv = document.createElement('div')
	tempDiv.innerHTML = parse(description) // Convert markdown/HTML to actual DOM structure

	// Recursively walk through the nodes to extract structured plain text
	const getPlainText = (element) => {
		let text = ''
		element.childNodes.forEach((node) => {
			if (node.nodeType === Node.TEXT_NODE) {
				text += node.textContent
			} else if (node.nodeType === Node.ELEMENT_NODE) {
				if (node.tagName === 'LI') {
					text += '- ' + getPlainText(node) + '\n' // Add dash for list items
				} else if (node.tagName === 'P' || node.tagName === 'DIV' || node.tagName === 'BR') {
					text += getPlainText(node) + '\n' // Add new line for paragraphs, divs, and breaks
				} else {
					text += getPlainText(node) // Recursively process other elements
				}
			}
		})
		return text
	}

	// Extract plain text with formatting preserved
	const plainText = getPlainText(tempDiv)

	// Copy the plain text to the clipboard
	navigator.clipboard
		.writeText(plainText)
		.then(() => {
			toast.success('Note copied successfully!')
		})
		.catch(() => {
			toast.error('Failed to copy note.')
		})
}
const ContentAccordion = ({ data, defaultExpanded, handleModal, setEditData, handleDelete }) => {
	const { data: noteData, isLoading } = useGetNoteByIDQuery(data?._id)
	const handleIconClick = (event) => {
		event.stopPropagation() // Prevents the Accordion from toggling
		setEditData(data)
		handleModal()
	}

	const handleIconDelete = (event) => {
		event.stopPropagation() // Prevents the Accordion from toggling
		handleDelete(data?._id)
	}
	return (
		<Accordion sx={{ border: 'none', boxShadow: 'none', width: '100%' }} defaultExpanded={defaultExpanded}>
			<AccordionSummary aria-controls="panel1-content" id="panel1-header">
				<Box sx={{ width: '100%' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Title
							</Typography>
							<Typography variant="body2 ">{data?.title}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Note Type
							</Typography>
							<Typography variant="body2">{data?.noteType}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Date
							</Typography>
							<Typography variant="body2">{moment(data?.date).format('YYYY-MM-DD')}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Source
							</Typography>
							<Typography variant="body2 ">{getArrayStatus(data?.metadata)}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Status
							</Typography>
							{tagStatus(data?.status)}
						</Grid>

						<Grid item xs={12} sm={2}>
							<IconButton onClick={handleIconClick} fontSize="small">
								<BorderColorIcon fontSize="small" />
							</IconButton>
							<IconButton onClick={handleIconDelete} fontSize="small">
								<DeleteIcon fontSize="small" />
							</IconButton>
						</Grid>
					</Grid>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Box>
					<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="p" sx={{ fontSize: '20px', fontWeight: '600' }}>
								Note Detail
							</Typography>
							<div
								style={{ marginTop: '10px', marginLeft: '10px', cursor: 'pointer', color: 'grey' }}
								onClick={() => copyText(data?.description?.replace(/^\`\`\`|`\`\`$/g, ''))}
							>
								<Tooltip title="Copy Generated Note" placement="right">
									<ContentCopy />
								</Tooltip>
							</div>
						</Box>
					</Stack>
					<Box
						component="form"
						sx={{
							'& .MuiTextField-root': { width: '100%' },
						}}
						noValidate
						autoComplete="off"
					>
						<Grid container spacing={2}>
							{/* <Grid item md={12} xs={12}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
										// borderTop:"1px solid #979797",
										// borderBottom:"1px solid #979797",
									}}
								>
									<Box
										sx={{
											marginTop: '20px',
											marginBottom: '20px',
											marginRight: '10px',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'start',
												alignItems: 'center',
											}}
										>
											<Typography variant="h2" sx={{ fontSize: '15px', marginRight: '2px' }}>
												Date :
											</Typography>
											<Typography>{moment(data?.date).format('MM-DD-YYYY')}</Typography>
										</Box>
									</Box>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'start',
											alignItems: 'center',
											marginLeft: '5px',
										}}
									>
										<Typography variant="h2" sx={{ fontSize: '15px', marginRight: '2px' }}>
											Time :
										</Typography>
										<Typography>{moment(data?.date).format('LT')}</Typography>
									</Box>
								</Box>
							</Grid> */}
							{(data?.metadata?.forms?.length !== 0 || data?.metadata?.chats?.length !== 0 || data?.metadata?.chats?.length !== 0) && (
								<Grid item md={12} xs={12}>
									<Typography variant="h2" sx={{ fontSize: '15px', marginRight: '2px' }}>
										Sources Used
									</Typography>

									{data?.metadata?.forms?.length !== 0 && (
										<NoteAccordion
											title={'Form'}
											data={noteData?.data?.metadata?.forms}
											isLoading={isLoading}
											startDate={data?.startDate ? data?.startDate : null}
											endDate={data?.endDate ? data?.endDate : null}
										/>
									)}

									{data?.metadata?.chats?.length !== 0 && (
										<NoteAccordion
											title={'SMS'}
											data={noteData?.data?.metadata?.chats}
											isLoading={isLoading}
											startDate={data?.startDate ? data?.startDate : null}
											endDate={data?.endDate ? data?.endDate : null}
										/>
									)}

									{data?.metadata?.calls?.length !== 0 && (
										<NoteAccordion
											title={'Call Transcript'}
											data={noteData?.data?.metadata?.calls}
											isLoading={isLoading}
											startDate={data?.startDate ? data?.startDate : null}
											endDate={data?.endDate ? data?.endDate : null}
										/>
									)}
								</Grid>
							)}
							<Grid item md={12} xs={12}>
								{/* <Typography
                variant="h2"
                sx={{ fontSize: "15px", marginRight: "2px" }}
              >
                Description
              </Typography> */}

								<Typography>
									<ReactMarkdown
										remarkPlugins={[remarkGfm]}
										components={{
											a: (props) => (
												<a href={props.href} style={{ color: '#000' }} target="blank">
													{props.children}
												</a>
											),
										}}
									>
										{data?.description?.replace(/^\`\`\`|`\`\`$/g, '')}
									</ReactMarkdown>
									{/* <div
                style={{
                  // textOverflow: 'ellipsis',
                  // display: '-webkit-box',
                  // WebkitBoxOrient: 'vertical',
                  // WebkitLineClamp: 2,
                  lineHeight: "1.5em",
                }}
                dangerouslySetInnerHTML={{
                  __html: handleMarkdownChange(data?.description)
                    ?.split("\n")
                    ?.join("\n"),
                }}
              /> */}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</AccordionDetails>
		</Accordion>
	)
}

const customContent = (dummyCallData, handleModal, setEditData, handleDelete) => {
	return dummyCallData.map((data, index) => (
		<Box key={index} sx={{ width: '-webkit-fill-available' }}>
			<ContentAccordion data={data} defaultExpanded={index === 0} handleModal={handleModal} setEditData={setEditData} handleDelete={handleDelete} />
		</Box>
	))
}

const CustomTitle = ({ dateTime }) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography variant="subtitle" color="textSecondary" fontSize={'14px'}>
				{moment(dateTime).format('DD-MMM-YY')}
			</Typography>
			<Typography variant="body2" color="textSecondary" fontSize={'12px'}>
				{moment(dateTime).format('h:mm A')}
			</Typography>
		</Box>
	)
}

const NoteTab = ({ user, id, status }) => {
	const [open, setOpen] = useState(false)
	const [editData, setEditData] = useState({})
	const [timelineItems, setTimelineItems] = useState([])
	const [limit, setLimit] = useState(10);
	const [currentPage, setCurrentPage] = useState(1)
	const { isLoading, isSuccess, isError, error, data, refetch } = useGetPatientNotesQuery({
		userID: user.id,
		page: 1,
		limit,
		patientId: id,
		title: '',
		description: '',
	})

	const [deleteNote] = useDeleteNoteMutation()

	const handleDelete = async (id) => {
		await deleteNote(id)
			.unwrap()
			.then((res) => {
				refetch()
				toast.success('Note is deleted')
			})
			.catch((error) => {
				toast.error('Something went wrong')
			})
	}

	const handleModal = () => {
		setOpen(true)
	}
	const handleViewMore = () => {
		setCurrentPage((prevPage) => prevPage + 1)
		setLimit((prevLimit) => prevLimit + 10);
		refetch();
	}

	const fetchAgain = () => {
		refetch()
	}


	console.log(data, 'DATA')

	useEffect(() => {
		if (data?.data) {
			const timelineDataReturn = data?.data?.map((item) => {
				return { title: <CustomTitle dateTime={item?.createdAt} /> }
			})
			setTimelineItems(timelineDataReturn)

		}
	}, [data, limit])

	if (isLoading) return <CircularLoader />


	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography variant="h5">Notes</Typography>
				<Button variant="contained" startIcon={<AddIcon />} onClick={() => handleModal()}>
					Create New
				</Button>
			</Box>
			<Box
				sx={{
					height: '67vh',
					overflowY: 'scroll',
					overflowX: 'hidden',
				}}
				className="DialogContent"
			>
				{data?.data?.length !== 0 ? (
					<>
						<Box>
							<Chrono
								key={timelineItems?.length}
								items={timelineItems}
								hideControls
								disableClickOnCircle
								mode="VERTICAL"
								disableAutoScrollOnClick={true}
								scrollable={false}
								cardHeight={100}
								theme={{ primary: '#0049C6', secondary: '#f5f5f5' }}
								cardPosition="top"
							>
								{customContent(data?.data ? data?.data : [], handleModal, setEditData, handleDelete)}
								<Box className="chrono-icons">
									{timelineItems.map((item, index) => (
										<CircleIcon key={index} sx={{ color: '#0049C6', fontSize: '20px' }} />
									))}
								</Box>
							</Chrono>
							{currentPage < data?.totalPages && (
								<Box sx={{ display: 'flex', justifyContent: 'flexStart', marginTop: '16px', marginLeft: '20%' }}>
									<Button variant="contained" onClick={handleViewMore}>
										View Old Notes
									</Button>
								</Box>
							)}
						</Box>

					</>
				) : (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '60vh',
						}}
					>
						<Typography variant="h6">No Note Found</Typography>
					</Box>
				)}
			</Box>
			<NoteModal {...{ open, setOpen, fetchAgain, setEditData, editData, status, patientId: id }} />
		</Box>
	)
}

export default NoteTab
