import { useDebouncedValue } from "@mantine/hooks";
import { createContext, useContext, useState } from "react";

export const ChildNode = createContext();

export const ChildNodeProvider = ({ children }) => {
  const [isChildNodeAdded, setIsChildNodeAdded] = useState(false);
  const [editMenu, setEditMenu] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [refreshworkflow, setRefreshworkflow] = useState(false);
  const [forceRefreshChildNode, setForceRefreshChildNode] = useState(false);
  const [procedureReferesh, setProcedureReferesh] = useState(0);
  const [appointmentReferesh, setAppointmentReferesh] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <ChildNode.Provider
      value={{
        isChildNodeAdded,
        setIsChildNodeAdded,
        editMenu,
        setEditMenu,
        refreshworkflow,
        procedureReferesh,
        setProcedureReferesh,
        appointmentReferesh,
        setAppointmentReferesh,
        setRefreshworkflow,
        forceRefreshChildNode,
        setForceRefreshChildNode,
        searchTerm,
        setSearchTerm,
        debouncedValue,
        openDrawer,
        setOpenDrawer,
        openMenu,
        setOpenMenu,
      }}
    >
      {children}
    </ChildNode.Provider>
  );
};

export function useChildNode() {
  return useContext(ChildNode);
}
