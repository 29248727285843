import React from 'react'

export function Appointment({color}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				d="M4.8001 2.00001C4.8001 1.89392 4.75795 1.79218 4.68294 1.71716C4.60793 1.64215 4.50618 1.60001 4.4001 1.60001C4.29401 1.60001 4.19227 1.64215 4.11725 1.71716C4.04224 1.79218 4.0001 1.89392 4.0001 2.00001V2.40001H3.2001C2.77575 2.40001 2.36878 2.56858 2.06873 2.86864C1.76867 3.16869 1.6001 3.57566 1.6001 4.00001L1.6001 4.80001H14.4001V4.00001C14.4001 3.57566 14.2315 3.16869 13.9315 2.86864C13.6314 2.56858 13.2244 2.40001 12.8001 2.40001H12.0001V2.00001C12.0001 1.89392 11.958 1.79218 11.8829 1.71716C11.8079 1.64215 11.7062 1.60001 11.6001 1.60001C11.494 1.60001 11.3923 1.64215 11.3173 1.71716C11.2422 1.79218 11.2001 1.89392 11.2001 2.00001V2.40001H4.8001V2.00001ZM14.4001 12.8V5.60001H1.6001V12.8C1.6001 13.2244 1.76867 13.6313 2.06873 13.9314C2.36878 14.2314 2.77575 14.4 3.2001 14.4H12.8001C13.2244 14.4 13.6314 14.2314 13.9315 13.9314C14.2315 13.6313 14.4001 13.2244 14.4001 12.8ZM8.4001 8.40001V9.60001H9.6001C9.70618 9.60001 9.80793 9.64215 9.88294 9.71716C9.95795 9.79218 10.0001 9.89392 10.0001 10C10.0001 10.1061 9.95795 10.2078 9.88294 10.2828C9.80793 10.3579 9.70618 10.4 9.6001 10.4H8.4001V11.6C8.4001 11.7061 8.35795 11.8078 8.28294 11.8828C8.20793 11.9579 8.10618 12 8.0001 12C7.89401 12 7.79227 11.9579 7.71725 11.8828C7.64224 11.8078 7.6001 11.7061 7.6001 11.6V10.4H6.4001C6.29401 10.4 6.19227 10.3579 6.11725 10.2828C6.04224 10.2078 6.0001 10.1061 6.0001 10C6.0001 9.89392 6.04224 9.79218 6.11725 9.71716C6.19227 9.64215 6.29401 9.60001 6.4001 9.60001H7.6001V8.40001C7.6001 8.29392 7.64224 8.19218 7.71725 8.11716C7.79227 8.04215 7.89401 8.00001 8.0001 8.00001C8.10618 8.00001 8.20793 8.04215 8.28294 8.11716C8.35795 8.19218 8.4001 8.29392 8.4001 8.40001Z"
				fill={color}
			/>
		</svg>
	)
}
