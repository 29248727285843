import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientNotes: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, patientId, ...rest } = query;
        const finalQuery = { page, limit, ...rest };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/notes/patient/${patientId}?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Notes", id: "LIST" },
          ...data.map((p) => ({ type: "Notes", id: p._id })),
        ];
      },
    }),
    getNoteByID: builder.query({
      query: (id) => `/notes/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Notes", id: "LIST" },
          { type: "Notes", id: data._id || arg },
        ];
      },
    }),
    getNoteTypes: builder.query({
      query: () => `/notes/getNoteTypes`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Notes", id: "LIST" },
          { type: "Notes", id: data._id || arg },
        ];
      },
    }),
    addNewNote: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/notes",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Note", id: "LIST" }],
    }),
    editNote: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/notes/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "Notes", id: "LIST" },
          { type: "Notes", id: data.id || id },
        ];
      },
    }),
    deleteNote: builder.mutation({
      query: (id) => {
        return {
          url: `/notes/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Notes", id: "LIST" }],
    }),
    generateNote: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `/notes/patient/${id}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: [{ type: "Note", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPatientNotesQuery,
  useGetNoteByIDQuery,
  useGetNoteTypesQuery,
  useAddNewNoteMutation,
  useEditNoteMutation,
  useDeleteNoteMutation,
  useGenerateNoteMutation,
} = extendedApiSlice;
