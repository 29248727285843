import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CircularLoader } from "../../Components";
import {
  useGetSocialCareFilterQuery,
  useGetSocialCareQuery,
} from "../../store/socialCaresSlice";
import { PatientTable as Table } from "../Patient/PatientTable/PatientTable";
import FilterDropdown from "./FilterDropdown";
import FilterMultiSelect from "./FilterMultiSelect";
import { PieChart } from "./PieChart";
import PopoverUI from "./PopoverUI";
import {
  convertObjectToArray,
  convertSocialNeedsToOptionData,
} from "./Utils/FilterDropdownData";

export function SocialNeedsUI() {
  const pageName = "social";
  const [allMetric, setAllMetric] = useState(
    localStorage.getItem("allMetric")
      ? JSON.parse(localStorage.getItem("allMetric"))
      : [],
  );
  const [selectedValue, setSelectedValue] = useState("pro");
  const [socialPage, setSocialPage] = useState(
    localStorage.getItem("socialPage")
      ? Number.parseInt(localStorage.getItem("socialPage"))
      : 1,
  );
  const [queries, setQueries] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [chiNotesReady, setChiNotesReady] = useState(false);
  const [socialNeedValue, setSocialNeedValue] = useState([]);
  const [socialCareValue, setSocialCareValue] = useState("");
  const [connectionValue, setConnectionValue] = useState("");
  const [socialCareConsentValue, setSocialCareConsentValue] = useState("");
  const [socialNeedsOptionData, setSocialNeedsOptionData] = useState([]);
  const [closedLoopData, setClosedLoopData] = useState([]);
  const [connectionStatusData, setConnectionStatusData] = useState([]);
  const [socialCareConsentData, setSocialCareConsentData] = useState([]);

  // For Popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Calculate today's date and the date two months ago
  const today = new Date();
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(today.getMonth() - 2);

  const [startDate, setStartDate] = useState(twoMonthsAgo);
  const [endDate, setEndDate] = useState(today);

  const { data, refetch, isLoading, isError, isSuccess } =
    useGetSocialCareQuery({ ...queries, page: socialPage, limit: 10 });
  const { data: filterData } = useGetSocialCareFilterQuery();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeValue = (event, newAlignment) => {
    setSelectedValue(newAlignment);
  };

  const refetchProsAgain = () => {
    refetch();
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function isObjectEmpty(obj) {
    return (
      obj !== null &&
      typeof obj === "object" &&
      !Array.isArray(obj) &&
      Object.keys(obj).length === 0
    );
  }

  function handleApplyChanges() {
    if (startDate && endDate && selectedValue === "pro") {
      if (allMetric?.some((obj) => obj.metric === "Pro Assigned Date")) {
        const newArray = allMetric.filter(
          (item) => item?.metric !== "Pro Assigned Date",
        );
        const newArray2 = [
          ...newArray,
          {
            metric: "Pro Assigned Date",
            key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            query: "pro",
          },
        ];
        setAllMetric(newArray2);
        setAnchorEl(null);
        localStorage.setItem("allMetric", JSON.stringify(newArray2));
      } else {
        const newArray = [
          ...allMetric,
          {
            metric: "Pro Assigned Date",
            key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            query: "pro",
          },
        ];
        setAllMetric(newArray);
        setAnchorEl(null);
        localStorage.setItem("allMetric", JSON.stringify(newArray));
      }
    }

    if (startDate && endDate && selectedValue === "procedure") {
      if (allMetric?.some((obj) => obj.metric === "Procedure Date")) {
        const newArray = allMetric.filter(
          (item) => item?.metric !== "Procedure Date",
        );
        const newArray2 = [
          ...newArray,
          {
            metric: "Procedure Date",
            key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            query: "procedure",
          },
        ];
        setAllMetric(newArray2);
        setAnchorEl(null);
        localStorage.setItem("allMetric", JSON.stringify(newArray2));
      } else {
        const newArray = [
          ...allMetric,
          {
            metric: "Procedure Date",
            key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            query: "procedure",
          },
        ];
        setAllMetric(newArray);
        setAnchorEl(null);
        localStorage.setItem("allMetric", JSON.stringify(newArray));
      }
    }

    if (startDate && endDate && selectedValue === "dueDate") {
      if (allMetric?.some((obj) => obj.metric === "PRO Due Date")) {
        const newArray = allMetric.filter(
          (item) => item?.metric !== "PRO Due Date",
        );
        const newArray2 = [
          ...newArray,
          {
            metric: "PRO Due Date",
            key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            query: "dueDate",
          },
        ];
        setAllMetric(newArray2);
        setAnchorEl(null);
        localStorage.setItem("allMetric", JSON.stringify(newArray2));
      } else {
        const newArray = [
          ...allMetric,
          {
            metric: "PRO Due Date",
            key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
            query: "dueDate",
          },
        ];
        setAllMetric(newArray);
        setAnchorEl(null);
        localStorage.setItem("allMetric", JSON.stringify(newArray));
      }
    }
  }

  const handleCheckboxChange = (event) => {
    setChiNotesReady(event.target.checked);
  };

  const clearFilters = () => {
    // Reset all filter states
    setSocialPage(1);
    localStorage.removeItem("allMetric");
    localStorage.removeItem("prosPage");

    // Reset filter states
    setChiNotesReady(false);
    setSocialNeedValue([]);
    setSocialCareValue("");
    setConnectionValue("");
    setSocialCareConsentValue("");

    // Update the queries state to ensure it has defaults and triggers the query
    const defaultQueries = {
      page: 1,
      limit: 10,
    };

    setQueries(defaultQueries); // Update the queries with defaults

    // Manually trigger a refetch after resetting filters
    setTimeout(() => {
      refetch();
    }, 300);
  };

  useEffect(() => {
    if (filterData?.data) {
      if (!isObjectEmpty(filterData?.data?.connectionStatus)) {
        setConnectionStatusData(
          convertObjectToArray(filterData?.data?.connectionStatus),
        );
      }

      if (!isObjectEmpty(filterData?.data?.closedLoop)) {
        setClosedLoopData(convertObjectToArray(filterData?.data?.closedLoop));
      }

      if (!isObjectEmpty(filterData?.data?.socialCareConsent)) {
        setSocialCareConsentData(
          convertObjectToArray(filterData?.data?.socialCareConsent),
        );
      }

      if (!isObjectEmpty(filterData?.data?.socialNeeds)) {
        setSocialNeedsOptionData(
          convertSocialNeedsToOptionData(filterData?.data?.socialNeeds),
        );
      }
    }
  }, [filterData]);

  useEffect(() => {
    const updatedObj = {
      ...(chiNotesReady !== false && { chiNotesReady }), // Add if not false
      ...(socialNeedValue.length !== 0 && { socialNeeds: socialNeedValue }), // Add if not empty
      ...(socialCareValue && { closedLoop: socialCareValue }), // Add if not empty
      ...(connectionValue && { connectionStatus: connectionValue }), // Add if not empty
      ...(socialCareConsentValue && {
        socialCareConsent: socialCareConsentValue,
      }), // Add if not empty
    };
    // Update the queries state or pass newQueries where needed
    setQueries(updatedObj);
    setSocialPage(1);
    localStorage.setItem("socialPage", 1);
  }, [
    chiNotesReady,
    socialNeedValue,
    socialCareValue,
    connectionValue,
    socialCareConsentValue,
  ]);

  // This useEffect will use to execute refetch of SocialCare data after every 10 seconds
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //       refetch()
  //   }, 10000);

  //   return () => clearInterval(intervalId);
  // }, []);

  if (isLoading) return <CircularLoader />;
  return (
    <>
      <Box sx={{ marginTop: "45px", marginLeft: "20px" }}>
        <Grid container={true} padding={"20px"} justifyContent="space-between">
          <Grid item={true} xs={3}>
            <PieChart
              {...{
                heading: "Social Needs Connections",
                query: "proTypes",
                setAllMetric,
                allMetric,
                labels: data?.data?.metrics?.connections
                  ? data?.data?.metrics?.connections?.map((item) => item.label)
                  : [],
                colors: [
                  "#4A5568", // Completed (dark grey)
                  "#FF5E5E", // Not Completed (red)
                ],
                formData: data?.data?.metrics?.connections
                  ? data?.data?.metrics?.connections?.map((item) => item.count)
                  : [],
                backendData: data?.data?.metrics?.connections,
              }}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <PieChart
              {...{
                heading: "Social Needs Closed Loops",
                query: "proStatus",
                setAllMetric,
                allMetric,
                labels: data?.data?.metrics?.closedLoop
                  ? data?.data?.metrics?.closedLoop?.map((item) => item.label)
                  : [],
                colors: [
                  "#4A5568", // Closed (dark grey)
                  "#FF5E5E", // Got Help (red)
                  "#ACACAC", // No Capacity (light grey)
                  "#FF9C9C", // In Progress (light red/pink)
                ],
                formData: data?.data?.metrics?.closedLoop
                  ? data?.data?.metrics?.closedLoop?.map((item) => item.count)
                  : [],
                backendData: data?.data?.metrics?.closedLoop,
              }}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <PieChart
              {...{
                heading: "Social Needs Distribution",
                query: "procedurePhase",
                setAllMetric,
                allMetric,
                labels: data?.data?.metrics?.distribution
                  ? data?.data?.metrics?.distribution?.map(
                      (item) => item?.label,
                    )
                  : [],
                colors: [
                  "#FF5E5E", // Financial (red)
                  "#F57F17", // Food (orange)
                  "#64B5F6", // Transportation (light blue)
                  "#C5CAE9", // Housing (light grey-blue)
                  "#81C784", // Utilities (green)
                  "#BDBDBD", // Safety (grey)
                  "#FFAB91", // Tobacco (light orange)
                ],
                formData: data?.data?.metrics?.distribution
                  ? data?.data?.metrics?.distribution?.map(
                      (item) => item?.count,
                    )
                  : [],
                backendData: data?.data?.metrics?.distribution,
              }}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <PieChart
              {...{
                heading: "Social Needs Screening",
                query: "proStatus",
                setAllMetric,
                allMetric,
                labels: data?.data?.metrics?.screening
                  ? data?.data?.metrics?.screening?.map((item) => item.label)
                  : [],

                colors: [
                  "#4A5568", // Screened (dark grey)
                  "#FF5E5E", // Needs (red)
                  "#ACACAC", // No Needs (light grey)
                  "#FF9C9C", // Not Screened (light red/pink)
                ],
                formData: data?.data?.metrics?.screening
                  ? data?.data?.metrics?.screening?.map((item) => item.count)
                  : [],
                backendData: data?.data?.metrics?.screening,
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Paper
        sx={{
          border: "1px ",
          width: "95%",
          borderRadius: "10px",
          // marginTop: '20px',
          padding: "20px",
          margin: "45px",
        }}
      >
        <Typography sx={{ fontSize: "20px", marginBottom: "14px" }}>
          <b>Search Filter</b>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <FilterMultiSelect
              label={"Filter by Social Needs"}
              options={socialNeedsOptionData}
              selectedValues={socialNeedValue}
              setSelectedValues={setSocialNeedValue}
            />
            <FilterDropdown
              label={"Loop Closing Status"}
              data={closedLoopData}
              selectedValue={socialCareValue}
              setSelectedValue={setSocialCareValue}
            />
            <FilterDropdown
              label={"Connection Status"}
              data={connectionStatusData}
              selectedValue={connectionValue}
              setSelectedValue={setConnectionValue}
            />
            <FilterDropdown
              label={"Social Care Consent"}
              data={socialCareConsentData}
              selectedValue={socialCareConsentValue}
              setSelectedValue={setSocialCareConsentValue}
            />
          </Box>
          {/* <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chiNotesReady}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="CHI Notes Ready"
            />
          </Box> */}
        </Box>
        {(chiNotesReady ||
          socialNeedValue.length !== 0 ||
          socialCareValue !== "" ||
          connectionValue !== "" ||
          socialCareConsentValue !== "") && (
          <Box
            sx={{ marginLeft: "8px", marginBottom: "5px", marginTop: "10px" }}
          >
            <Typography
              fontSize={14}
              fontWeight={600}
              sx={{
                borderBottom: "1px solid red",
                display: "initial",
                color: "red",
                borderRadius: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                clearFilters();
              }}
            >
              Clear Filters
            </Typography>
          </Box>
        )}
      </Paper>

      <Box
        sx={{
          width: "95%",
          margin: "45px",
        }}
      >
        <Table
          {...{
            page: socialPage,
            setPage: setSocialPage,
            data,
            isLoading,
            isError,
            isSuccess,
            refetchPatientAgain: refetchProsAgain,
            pageName,
          }}
        />
      </Box>
      <PopoverUI
        {...{
          id,
          open,
          anchorEl,
          handleClose,
          selectedValue,
          handleChangeValue,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          handleApplyChanges,
        }}
      />
    </>
  );
}
