import {
  FormControl,
  InputLabel,
  MenuItem,
  Portal,
  Select,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGetCallAgentsQuery } from "../../store/callSlice";

const CallPatientForm = ({
  agent,
  setAgent,
  agentShow,
  setAgentShow,
  label,
}) => {
  const container = useRef(null);
  const [formattedData, setFormattedData] = useState([]);
  const { data } = useGetCallAgentsQuery();

  const handleChange = (e) => {
    const findNMethodObject = formattedData?.find(
      (item) => item.title === e.target.value,
    );
    setAgent(findNMethodObject?.id);
  };

  useEffect(() => {
    if (agent) {
      setAgentShow(formattedData.find((item) => item.id === agent));
    } else {
      setAgentShow(
        formattedData.find(
          (item) =>
            item.title?.toLowerCase() ===
            label?.replace("Call from ", "")?.toLowerCase(),
        ),
      );
    }
  }, [agent, formattedData]);

  useEffect(() => {
    if (data) {
      const formatData = (data) => {
        return Object.entries(data).map(([key, value]) => {
          const formattedKey = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
          return { id: value, title: formattedKey };
        });
      };

      const formatted = formatData(data);

      setFormattedData(formatted);
    }
  }, [data]);
  return (
    <>
      <FormControl
        fullWidth
        ref={container}
        sx={{
          textAlign: "left",
        }}
      >
        <InputLabel id="demo-simple-form-label">Select Agent</InputLabel>
        <Portal container={() => container.current}>
          <Select
            fullWidth
            labelId="demo-simple-form-label"
            id="demo-simple-form"
            value={agentShow?.title}
            label="Select Agent"
            onChange={handleChange}
          >
            {formattedData?.map((item) => (
              <MenuItem
                key={item.id}
                value={item.title}
                sx={{
                  "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "100%",
                  },
                  width: "590px",
                }}
              >
                {item?.title}
              </MenuItem>
            ))}
          </Select>
        </Portal>
      </FormControl>
      {/* <Grid item md={12} xs={12} mt={2}>
    <TextField
          id="outlined-number"
          label="Calls Attempts"
          fullWidth
          placeholder="Number of attempts until they pick up phone"
          type="number"
          value={attempt}
          onChange={(e)=>setAttempt(e.target.value)}
          inputProps={{
            step: 1, 
            min: 1, 
          }}
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              display: "none",
            },
           "& input[type=number]": {
              MozAppearance: "textfield",
            },
           }}
        />
    </Grid> */}
    </>
  );
};

export default CallPatientForm;
