import { Box, Modal, Typography } from "@mui/material";
import moment from "moment";
import OutlinedButton from "../OutlinedButton";

export default function ImageModal({
  open,
  setOpen,
  title,
  image,
  date,
  uploadBy,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 680,
          bgcolor: "#F5F5F5",
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
          }}
        >
          <div>
            <Typography fontWeight={600} fontSize={"16px"}>
              Upload By
            </Typography>
            <Typography fontSize={"14px"}>
              {uploadBy === "patient" ? "Patient" : "Care Ally"}
            </Typography>
          </div>
          <div>
            <Typography fontWeight={600} fontSize={"16px"}>
              Upload Date
            </Typography>
            <Typography fontSize={"14px"}>
              {moment(date).format("MM-DD-YYYY")}
            </Typography>
          </div>
          <div>
            <Typography fontWeight={600} fontSize={"16px"}>
              Upload Time
            </Typography>
            <Typography fontSize={"14px"}>
              {moment(date).format("hh:mm a")}
            </Typography>
          </div>
        </Box>
        <img
          src={`${image}`}
          width={610}
          height={360}
          style={{ objectFit: "contain" }}
        />
        <Typography fontSize={"18px"} fontWeight={400}>
          <b>Reason:</b> {title}
        </Typography>
        <Box sx={{ margin: "5px", textAlign: "right" }}>
          <OutlinedButton label={"Cancel"} handleClick={handleClose} />
        </Box>
      </Box>
    </Modal>
  );
}
