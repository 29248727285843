import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { CircularLoader } from "../../../Components";

function NoteAccordion({ title, data, isLoading, endDate, startDate }) {
  const formatDate = (date) => {
    if (date) {
      const convertedDate = new Date(date);
      const year = convertedDate?.getFullYear();
      const month = String(convertedDate?.getMonth() + 1)?.padStart(2, "0"); // Month is zero-indexed
      const day = String(convertedDate?.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  return (
    <Accordion sx={{ marginTop: "10px", marginBottom: "10px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel1-${title}`}
        id={`panel1-${title}`}
        sx={{ background: "#f1f3f4", color: "grey", fontWeight: "600" }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails sx={{ background: "#f1f3f4" }}>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <>
            {title === "Form" ? (
              <ol>
                {data?.map((item, index) => (
                  <li key={index}>
                    <b>{item?.title}</b> , whose score is{" "}
                    <b>{item?.score ? item?.score : "N/A"}</b>
                  </li>
                ))}
              </ol>
            ) : title === "SMS" ? (
              <p>
                This note is generated using the last{" "}
                <b>{data?.length} messages</b> from the{" "}
                {startDate && endDate ? (
                  <>
                    patient, ranging from <b>{formatDate(startDate)}</b> to{" "}
                    <b>{formatDate(endDate)}</b>.
                  </>
                ) : (
                  "patient."
                )}
              </p>
            ) : title === "Call Transcript" ? (
              <p>
                This note is generated using the last{" "}
                <b>{data?.length} call transcripts</b> from the{" "}
                {startDate && endDate ? (
                  <>
                    patient, ranging from <b>{formatDate(startDate)}</b> to{" "}
                    <b>{formatDate(endDate)}</b>.
                  </>
                ) : (
                  "patient."
                )}
              </p>
            ) : (
              <p>N/A</p>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default NoteAccordion;
