import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CircularLoader,
  DeleteModal,
  PaginationBar,
} from "../../../Components";
import { DeleteSvg, EditSvg } from "../../../assets/svgs";
import { useDeleteTeamMutation } from "../../../store/teamsSlice";
import { hasScope } from "../../../utils";
import { EditTeamDrawer, ViewTeamDetailDrawer } from "./index";

// const tags = [{name:"urgent"},{name:"normal"},{name:"quick"}]

const columns = [
  // { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const createRows = (
  teams,
  handleOpenModal,
  handleDelete,
  openModal,
  setOpenModal,
  datas,
  handleDetail,
  handleEdit,
  scopes,
) => {
  const rows = teams.map(({ _id, name, members, description }, index) => {
    return {
      // Srno: (
      //   <Typography
      //     variant="p"
      //     sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
      //   >
      //     {index + 1}
      //   </Typography>
      // ),
      name: (
        <>
          <Typography
            variant="p"
            sx={{ fontSize: 16, fontWeight: 400, color: "black" }}
          >
            {name}
          </Typography>
        </>
      ),
      description: (
        <>
          <Typography
            variant="p"
            sx={{ fontSize: 14, fontWeight: 400, color: "black" }}
          >
            {description?.length <= 50
              ? description
              : description.slice(0, 50) + "....."}
          </Typography>
        </>
      ),
      actions: (
        <>
          <Stack
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {hasScope(scopes, "settings/teams:edit") && (
              <div
                onClick={(event) => handleEdit(event, _id)}
                style={{ cursor: "pointer" }}
              >
                <EditSvg />
              </div>
            )}

            {hasScope(scopes, "settings/teams:delete") && (
              <div
                style={{ cursor: "pointer" }}
                onClick={(event) => handleOpenModal(event, name, _id)}
              >
                <DeleteSvg />
              </div>
            )}
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => handleDetail(e, _id)}
            >
              <RemoveRedEyeIcon />
            </div>
          </Stack>
          <DeleteModal
            open={openModal}
            setOpen={setOpenModal}
            id={datas}
            handle={handleDelete}
          />
        </>
      ),
    };
  });

  return rows;
};

export function TeamsTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  scopes,
}) {
  const [openModal, setOpenModal] = React.useState(false);
  const [datas, setData] = React.useState("");
  const [detailState, setDetailState] = useState({ detail: false, teamID: "" });

  const handleOpenModal = (event, tag, id) => {
    setData(id);
    setOpenModal(true);
  };

  const [deleteTeam, {}] = useDeleteTeamMutation();
  const { user } = useSelector((state) => state.user);

  // States for CreateTagModal
  const [editState, setEditState] = useState({ edit: false, teamID: "" });

  const handleEdit = (e, id) => {
    toggleEditDrawer(true)(e, id);
  };
  const toggleEditDrawer = (edit) => (event, teamID) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setEditState({
      edit,
      teamID: teamID?.startsWith("back") ? "645cd291722a18db5c5b44c3" : teamID,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const toggleDetailDrawer = (detail) => (event, teamID) => {
    if (
      event.type === "click" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDetailState({
      detail,
      teamID: teamID?.startsWith("back") ? "645cd291722a18db5c5b44c3" : teamID,
    });
  };

  const handleDetail = (e, _id) => {
    toggleDetailDrawer(true)(e, _id);
  };
  const handleDelete = async (id) => {
    deleteTeam(id)
      .unwrap()
      .then(() => {
        toast.success("Deleted successfully!");
      })
      .catch((error) => {
        toast.error("some error occured!");
        console.error(error);
      });
  };

  const teams = data ? data.data : [];

  const rows = createRows(
    teams,
    handleOpenModal,
    handleDelete,
    openModal,
    setOpenModal,
    datas,
    handleDetail,
    handleEdit,
    scopes,
  );

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return (
      <Typography sx={{ margin: "10px 20px" }}>No Teams found.</Typography>
    );
  }
  return (
    <>
      <TableContainer>
        <Table stickyHeader={true} aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align={"center"}
                  sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow hover={true} role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data.totalPages, handleChange: handleChangePage }}
      />
      {editState.edit && (
        <EditTeamDrawer toggleDrawer={toggleEditDrawer} state={editState} />
      )}

      {detailState.detail && (
        <ViewTeamDetailDrawer
          toggleDrawer={toggleDetailDrawer}
          state={detailState}
        />
      )}
    </>
  );
}

// import React, { useState } from "react";
// import {
//   Stack,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from "@mui/material";
// import { toast } from "react-toastify";

// import { ActionButtons, PaginationBar } from "../../../Components";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import { useSelector } from "react-redux";
// import { useNotAllowedRole } from "../../../hooks";
// import { EditTeamDrawer } from "./index";
// import { ViewTeamDetailDrawer } from "./ViewTeamDetailDrawer";
// import { useDeleteTeamMutation } from "../../../store/teamsSlice";

// const createColumns = (isNotAllowed) => [
//   { id: "Srno", label: "Index", minWidth: 170 },
//   {
//     id: "name",
//     label: "Name",
//     minWidth: 170,
//     align: "right",
//     // format: (value) => value.toLocaleString("en-US"),
//   },
//   // {
//   //   id: "description",
//   //   label: "Description",
//   //   minWidth: 170,
//   //   align: "right",
//   // },
//   {
//     id: "duedate",
//     label: "Due Date",
//     minWidth: 170,
//     align: "right",
//   },
//   {
//     id: "status",
//     label: "Status",
//     minWidth: 170,
//     align: "right",
//   },
//   {
//     id: "actions",
//     label: "Actions",
//     minWidth: 170,
//     align: "right",
//   },
// ];

// const createRows = ({
//   teams,
//   handleEdit,
//   handleDelete,
//   handleDetail,
//   isNotAllowed,
// }) => {
//   const rows = teams.map(
//     ({ _id: id, name, description, date: duedate, role, members }, index) => {
//       return {
//         Srno: (
//           <Typography
//             variant="p"
//             sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
//           >
//             {index + 1}
//           </Typography>
//         ),
//         name,
//         description: description.slice(0, 28) + ".....",
//         duedate: new Date(duedate)?.toDateString(),

//         actions: (
//           <Stack
//             spacing={3}
//             direction="row"
//             justifyContent="center"
//             alignItems="center"
//           >
//             {!isNotAllowed && (
//               <div>
//                 <ActionButtons
//                   id={id}
//                   handleEdit={handleEdit}
//                   handleDelete={handleDelete}
//                 />
//               </div>
//             )}
//             <div
//               style={{ cursor: "pointer", marginLeft: "10px" }}
//               onClick={(e) => handleDetail(e, id)}
//             >
//               <RemoveRedEyeIcon />
//             </div>
//           </Stack>
//         ),
//       };
//     }
//   );

//   return rows;
// };

// export function TeamsTable({ page, setPage, data }) {
//   const { user } = useSelector((state) => state.user);
//   const [detailState, setDetailState] = useState({ detail: false, teamID: "" });
//   const [editState, setEditState] = useState({ edit: false, teamID: "" });

//   const isNotAllowed = useNotAllowedRole();
//   const [deleteTeam, {}] = useDeleteTeamMutation();

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const toggleEditDrawer = (edit) => (event, teamID) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }

//     setEditState({
//       edit,
//       teamID: teamID?.startsWith("back") ? "645cd291722a18db5c5b44c3" : teamID,
//     });
//   };

//   const toggleDetailDrawer = (detail) => (event, teamID) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setDetailState({
//       detail,
//       teamID: teamID?.startsWith("back") ? "645cd291722a18db5c5b44c3" : teamID,
//     });
//   };

//   const handleDetail = (e, id) => {
//     toggleDetailDrawer(true)(e, id);
//   };

//   const handleEdit = (e, id) => {
//     toggleEditDrawer(true)(e, id);
//   };

//   const handleDelete = async (id) => {
//     deleteTeam({ userID: user.id, teamID: id })
//       .unwrap()
//       .then(() => {
//         toast.success("Deleted successfully!");
//       })
//       .catch((error) => {
//         toast.error("some error occured!");
//         console.error(error);
//       });
//   };

//   const columns = createColumns(isNotAllowed);
//   const rows = createRows({
//     teams: data,
//     handleEdit,
//     handleDelete,
//     handleDetail,
//     isNotAllowed,
//   });
//   return (
//     <>
//       <TableContainer>
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               {columns.map((column) => {
//                 if (column.hide) return null;
//                 return (
//                   <TableCell
//                     variant="head"
//                     key={column.id}
//                     align={"center"}
//                     sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
//                   >
//                     {column.label}
//                   </TableCell>
//                 );
//               })}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {rows.map((row, i) => {
//               if (row.hide) return null;
//               return (
//                 <TableRow hover role="checkbox" tabIndex={-1} key={i}>
//                   {columns.map((column) => {
//                     const value = row[column.id];
//                     return (
//                       <TableCell
//                         key={column.id}
//                         align="center"
//                         sx={{ color: "text.secondary", border: "none" }}
//                       >
//                         {value}
//                       </TableCell>
//                     );
//                   })}
//                 </TableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <PaginationBar
//         {...{ page, count: data.totalPages, handleChange: handleChangePage }}
//       />

//       {editState.edit && (
//         <EditTeamDrawer toggleDrawer={toggleEditDrawer} state={editState} />
//       )}
//       {detailState.detail && (
//         <ViewTeamDetailDrawer
//           toggleDrawer={toggleDetailDrawer}
//           state={detailState}
//         />
//       )}
//     </>
//   );
// }
