import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";

export function SimpleSearchField({ searchTerm, setSearchTerm }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        border: "1px solid #dad4d4",
        borderRadius: "5px",
        height: "2.5em",
      }}
    >
      <ManageSearchIcon
        sx={{ fontSize: 28, paddingLeft: "5px", color: "#979797" }}
      />
      <Box sx={{ margin: "5px" }}>
        <TextField
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search"
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </Box>
    </Box>
  );
}

export default SimpleSearchField;
