import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Chrono } from 'react-chrono'
import CircleIcon from '@mui/icons-material/Circle'
import { CircularLoader } from '../Loader'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { useGetPatientAppointmentsQuery } from '../../store/appointmentsSlice'
import { useDeleteTaskMutation, useGetTasksQuery } from '../../store/tasksSlice'
import AssignTaskModal from '../../Pages/Patient/PatientProfile/ProfileModals/TasksModal'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'
import { toast } from 'react-toastify'

const customContent = (dummyCallData, handleModal, setEditData, handleDelete) => {
	const handleIconClick = (event, data) => {
		event.stopPropagation() // Prevents the Accordion from toggling
		setEditData(data)
		handleModal()
	}

	const handleIconDelete = (event, data) => {
		event.stopPropagation() // Prevents the Accordion from toggling
		handleDelete(data?._id)
	}

	return dummyCallData.map((data, index) => (
		<Box key={index} sx={{ width: '-webkit-fill-available' }}>
			<Grid container spacing={2} mt={0}>
				<Grid item xs={12} sm={2}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Title
					</Typography>
					<Typography variant="body2 ">{data?.title}</Typography>
				</Grid>

				<Grid item xs={12} sm={3}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Description
					</Typography>
					<Typography variant="body2">{data?.taskId?.description}</Typography>
				</Grid>

				<Grid item xs={12} sm={2}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Due Date
					</Typography>
					<Typography variant="body2">{moment(data?.date)?.format('MMMM DD, YYYY')}</Typography>
				</Grid>

				<Grid item xs={12} sm={3}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Status
					</Typography>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<span
							style={{
								display: 'inline-block', // Ensures the dot behaves like an element, not text
								width: '10px', // Set the diameter of the circle
								height: '10px', // Keep width and height equal
								borderRadius: '50%', // Makes the shape circular
								backgroundColor: data?.status === 'completed' ? '#63A02C' : '#FFB400', // Use the current font color from getColorStyles
							}}
						></span>

						<Typography fontSize={14} color="black" marginLeft={'6px'}>
							{data?.status?.charAt(0).toUpperCase() + data?.status?.slice(1)}
						</Typography>
					</Box>
				</Grid>

				<Grid item xs={12} sm={2}>
					<IconButton onClick={(e) => handleIconClick(e, data)} fontSize="small">
						<BorderColorIcon fontSize="small" />
					</IconButton>
					<IconButton onClick={(e) => handleIconDelete(e, data)} fontSize="small">
						<DeleteIcon fontSize="small" />
					</IconButton>
				</Grid>
			</Grid>
		</Box>
	))
}

const CustomTitle = ({ dateTime }) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography variant="subtitle" color="textSecondary" fontSize={'14px'}>
				{moment(dateTime).format('DD-MMM-YY')}
			</Typography>
			<Typography variant="body2" color="textSecondary" fontSize={'12px'}>
				{moment(dateTime).format('h:mm A')}
			</Typography>
		</Box>
	)
}

const TaskTab = ({ user, id, status }) => {
	const [open, setOpen] = useState(false)
	const [editData, setEditData] = useState({})
	const [timelineItems, setTimelineItems] = useState([])
	const [limit, setLimit] = useState(10);
	const [deleteTask] = useDeleteTaskMutation()
	const [currentPage, setCurrentPage] = useState(1)
	const { isLoading, isSuccess, isError, error, data, refetch } = useGetTasksQuery(
		{
			userID: user.id,
			page: 1,
			limit,
			patientId: id,
			title: '',
			description: '',
		},
		{
			refetchOnMountOrArgChange: true,
		}
	)

	console.log(data, 'DATA')

	const handleModal = () => {
		setOpen(true)
	}
	const handleViewMore = () => {
		setCurrentPage((prevPage) => prevPage + 1)
		setLimit((prevLimit) => prevLimit + 10);
		refetch();
	}
	const fetchAgain = () => {
		refetch()
	}

	const handleDelete = async (id) => {
		await deleteTask({ userID: user.id, taskID: id })
			.unwrap()
			.then((res) => {
				fetchAgain()
				toast.success('Task is deleted')
			})
			.catch((error) => {
				toast.error('Something went wrong')
			})
	}

	useEffect(() => {
		if (data?.data) {
			const timelineDataReturn = data?.data?.map((item) => {
				return { title: <CustomTitle dateTime={item?.createdAt} /> }
			})
			setTimelineItems(timelineDataReturn)
		}
	}, [data])

	if (isLoading) return <CircularLoader />
	const isViewMore = data?.data?.length < limit;

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography variant="h5">Tasks</Typography>
				<Button variant="contained" startIcon={<AddIcon />} onClick={() => handleModal()}>
					Assign
				</Button>
			</Box>
			<Box
				sx={{
					height: '67vh',
					overflowY: 'scroll',
					overflowX: 'hidden',
				}}
				className="DialogContent"
			>
				{data?.data?.length !== 0 ? (
					<>
						<Box>
							<Chrono
								key={timelineItems?.length}
								items={timelineItems}
								hideControls
								disableClickOnCircle
								mode="VERTICAL"
								disableAutoScrollOnClick={true}
								scrollable={false}
								cardHeight={100}
								theme={{ primary: '#0049C6', secondary: '#f5f5f5' }}
								cardPosition="top"
							>
								{customContent(data?.data ? data?.data : [], handleModal, setEditData, handleDelete)}

								<Box className="chrono-icons">
									{timelineItems.map((item, index) => (
										<CircleIcon key={index} sx={{ color: '#0049C6', fontSize: '20px' }} />
									))}
								</Box>

							</Chrono>
						</Box>
						{currentPage < data?.totalPages && (
							<Box sx={{ display: 'flex', justifyContent: 'flexStart', marginTop: '16px', marginLeft: '20%' }}>
								<Button variant="contained" onClick={handleViewMore}>
									View Old Tasks
								</Button>
							</Box>
						)}
					</>
				) : (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '60vh',
						}}
					>
						<Typography variant="h6">No Task Found</Typography>
					</Box>
				)}
			</Box>
			<AssignTaskModal {...{ open, setOpen, fetchAgain, setEditData, editData, status, patientId: id }} />
		</Box>
	)
}

export default TaskTab
