import { Box, Typography } from "@mui/material";
import cx from "classnames";
import React, { memo, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Handle, Position, useStoreApi } from "reactflow";
import { useReactFlow } from "reactflow";
import {
  AddButtonFlowV2,
  DeleteModal,
  NodeAccordionV2,
  NodeMenuStartJourney,
} from "../../../../Components";
import { PermissionModal } from "../../../../Components/React-Flow-Form-V2/PermissionModal";
import { useChildNode } from "../../../../contexts/addChildNode";
import { useDeleteJourneyNodeMutation } from "../../../../store/journeysSlice";
import { EditDelayModal, EditDrawer } from "../../Drawers";
import { uuid } from "../utils";
import styles from "./NodeTypes.module.css";

const WorkflowNode = ({ id, data }) => {
  const { setNodes, getNode, getNodes, getEdges, setEdges } = useReactFlow();

  // Desctructring useParams()
  const { patientId, id: jid, assignId } = useParams();
  const location = useLocation();
  const title = location.pathname.split("/")[1];
  const {
    isChildNodeAdded,
    editMenu,
    setEditMenu,
    setIsChildNodeAdded,
    setRefreshworkflow,
    refreshworkflow,
    forceRefreshChildNode,
  } = useChildNode();

  // States
  const [journeyID, setJourneyID] = useState("");
  const [nodeID, setNodeID] = useState("");
  const [parentID, setParentID] = useState("");
  const [showToPatient, setShowToPatient] = useState(false);
  const [hasChildNode, setHasChildNode] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDelayModal, setOpenDelayModal] = React.useState(false);
  const [forceRenderNode, setForceRenderNode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [addChildNode, setAddChildNode] = useState(false);
  const [childNodeId, setChildNodeId] = useState();
  const [childDelayNodeId, setChildDelayNodeId] = useState(uuid());
  const [positiveChildNodeId, setPositiveChildNodeId] = useState(uuid());
  const [negativeChildNodeId, setNegativeChildNodeId] = useState(uuid());
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [permissionValues, setPermissionValues] = useState(null);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);

  const handleOpenDelayModal = (nodeData) => {
    // const nextNode = nodeInternals.get(nodeData?.nextNodeId)
    // if(nextNode?.data?.isFilled){
    setOpenDelayModal(assignId ? false : true);
    // }else {
    //   toast.warning("Please save next node first")
    // } ;
  };

  // Destructring the getState to get state values as React flow use zustand state management tool
  const store = useStoreApi();
  const { nodeInternals } = store.getState();

  // Destructuring Mutation
  const [deleteJourneyNode] = useDeleteJourneyNodeMutation();

  // for delete modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);

  const [expanded, setExpanded] = React.useState(false);

  // State and function for Time Delay
  const [openEdit, setOpenEdit] = useState({ id: null, state: false });

  const toggleEditDrawer = (openEdit) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAnchorEl(null);
    setOpenEdit({ id: openEdit.id, state: openEdit.state });
  };

  const getNextNodeId = (id) => {
    const allEdges = getEdges();
    const filteredEdges = allEdges.find((item) => item?.source === id);
    return filteredEdges?.target;
  };

  const getGrandPreviousNodeId = (id) => {
    const allEdges = getEdges();
    const filteredEdges = allEdges.find((item) => item?.target === id);
    return filteredEdges?.source;
  };

  function checkParentNodeId(objectToCheck) {
    const allNodes = getNodes();

    if (
      !objectToCheck?.data?.positiveChildNodeId ||
      !objectToCheck?.data?.negativeChildNodeId
    ) {
      return false;
    }

    return allNodes.some(
      (item) =>
        item.data.parentNodeId === objectToCheck?.data?.positiveChildNodeId ||
        item.data.parentNodeId === objectToCheck?.data?.negativeChildNodeId,
    );
  }

  const getDescendantNodeIds = (
    idToFind,
    otherIdToDelete,
    value,
    previousNodeId,
  ) => {
    const allNodes = getNodes(); // Assuming getNodes returns the current list of nodes
    const otherIdNodeData = getNode(otherIdToDelete);
    const previousNodeData = getNode(previousNodeId);
    const nextNodeId = getNextNodeId(otherIdToDelete);
    const grandPreviousNodeId = getGrandPreviousNodeId(previousNodeData?.id);

    // Helper function to recursively collect all descendants
    const collectDescendants = (parentId) => {
      let descendants = [];

      for (const node of allNodes) {
        if (node?.data?.parentNodeId === parentId) {
          descendants?.push(node.id); // Add child node to descendants
          descendants = descendants?.concat(collectDescendants(node.id)); // Recursively collect descendants of this child node
        }
      }
      return descendants;
    };

    const newEdge = {
      id: `${grandPreviousNodeId}=>${nextNodeId}`,
      source: grandPreviousNodeId,
      target: nextNodeId,
      type: "workflow",
    };

    // const filteredEdges = getEdges().filter((item) => item.source !== otherIdToDelete).concat(newEdge)
    //   const updatedEdges = filteredEdges.filter((workflow, index, self) =>
    //     index === self.findIndex((w) => (
    //         w.source === workflow.source && w.target === workflow.target
    //     ))
    // )

    // Collect all descendants of the node with idToFind (excluding the idToFind itself)
    const descendants = collectDescendants(idToFind);
    // Combine all IDs to be removed in one step
    const idsToRemove = [
      idToFind,
      otherIdToDelete,
      otherIdNodeData.data.parentNodeId,
      previousNodeId,
    ]?.concat(descendants);

    const filteredEdges = getEdges()?.filter(
      (edge) => !idsToRemove.includes(edge?.source),
    );

    const targetEdges = filteredEdges.filter(
      (edge) => !idsToRemove.includes(edge?.target),
    );

    const updatedEdges = targetEdges.concat(newEdge);

    setEdges(updatedEdges);

    // Filter out the nodes to remove from the allNodes array
    const updatedNodes = allNodes?.filter(
      (node) => !idsToRemove?.includes(node.id),
    );

    // Update the nodes (excluding the descendants)
    setNodes(updatedNodes);
  };

  const deleteNode = (nodeId) => {
    const currentNode = getNode(nodeId);
    if (!currentNode) return false;

    const previousNode = getNode(currentNode.data.parentNodeId);
    if (!previousNode) return false;

    const grandPreviousNode = getNode(previousNode.data.parentNodeId);
    const getCurrentNodeEdge = getNextNodeId(currentNode?.id);
    const grandPreviousNodeId = getGrandPreviousNodeId(previousNode.id);

    let nodesToDelete = [currentNode.id, previousNode.id];

    const filteredEdges = getEdges().filter(
      (edge) => !nodesToDelete.includes(edge.source),
    );
    const updatedEdges = filteredEdges?.filter(
      (edge) => !nodesToDelete.includes(edge.target),
    );

    if (
      (currentNode?.data?.positiveChildNodeId ||
        currentNode?.data?.negativeChildNodeId) &&
      !checkParentNodeId(currentNode)
    ) {
      nodesToDelete = nodesToDelete.concat([
        currentNode?.data?.positiveChildNodeId,
        currentNode?.data?.negativeChildNodeId,
      ]);
    }

    // If there's a grandPreviousNode, connect it to the node after the currentNode
    const nextNodeId = getNextNodeId(nodeId);

    if (
      (grandPreviousNode || true) &&
      nextNodeId &&
      !currentNode?.data?.checkSubmission &&
      !currentNode?.data?.waitUntilReminderAreSend
    ) {
      updatedEdges.push({
        id: `${grandPreviousNode ? grandPreviousNode.id : grandPreviousNodeId}=>${getCurrentNodeEdge}`,
        source: grandPreviousNode ? grandPreviousNode.id : grandPreviousNodeId,
        // target: currentNode?.data?.nextNodeId,
        target: getCurrentNodeEdge,
        type: "workflow",
      });
    }

    if (
      ((currentNode?.data?.checkSubmission &&
        currentNode?.data?.waitUntilReminderAreSend) ||
        currentNode?.data?.method === "call") &&
      checkParentNodeId(currentNode)
    ) {
      getDescendantNodeIds(
        permissionValues?.id,
        permissionValues?.otherId,
        permissionValues?.value,
        previousNode.id,
      );

      setAnchorEl(null);
      return true;
    }

    if (
      isChildNodeAdded &&
      currentNode?.data?.method === "form" &&
      !currentNode?.data?.isFilled
    ) {
      updatedEdges.push({
        id: `${grandPreviousNodeId}=>${getCurrentNodeEdge}`,
        source: grandPreviousNodeId,
        target: getCurrentNodeEdge,
      });
    }

    const updatedNodes = getNodes().filter(
      (node) => !nodesToDelete.includes(node.id),
    );

    setEdges(updatedEdges);
    setNodes(updatedNodes);
    setAnchorEl(null);

    return true;
  };

  const handleOpenPermissionModal = () => {
    setPermissionValues(null);
    setOpenPermissionModal(true);
  };

  const checkNodeIsConditional = (nodeId) => {
    const nodeIdData = getNode(nodeId);
    const method = nodeIdData?.data?.method;
    let hasChildNodes = false;
    if (method === "call" || method === "form") {
      hasChildNodes =
        getNextNodeId(nodeIdData?.data?.positiveChildNodeId) ||
        getNextNodeId(nodeIdData?.data?.negativeChildNodeId);
    }

    console.log("Delete Node Children:", hasChildNodes);
    if (method === "form" && hasChildNodes) {
      return true;
    } else if (method === "call" && hasChildNodes) {
      return true;
    } else {
      return false;
    }

    // return ['form', 'call'].includes(method);
  };

  // Function for deleting specific node
  const handleDelete = async () => {
    const nodeData = nodeInternals.get(nodeID);

    const body = {
      journeyID,
      nodeID,
      parentID: nodeData?.data?.parentNodeId,
    };

    if (
      (nodeData?.data?.checkSubmission &&
        nodeData?.data?.waitUntilReminderAreSend) ||
      nodeData?.data?.method === "call"
    ) {
      body.keepBranch = permissionValues?.value;
    }

    if (nodeData?.data?.isFilled) {
      await deleteJourneyNode(body)
        .unwrap()
        .then(() => {
          // refetch()
          // findIndex(nodeID)
          deleteNode(nodeID);
          toast.success("Node has been deleted");
        })
        .catch((error) => {
          toast.error("some error occured!");
        });
    } else {
      const isDeleted = deleteNode(nodeID);
      if (isDeleted) {
        setIsChildNodeAdded(false);
        toast.success("Node deleted successfully!");
      } else {
        toast.warning("Please save the node first!");
      }
    }
  };

  const isParentNodeHaveTitle = (dataParentNodeId) => {
    const parent = getNode(dataParentNodeId);
    return parent?.data?.label === "Start Journey"
      ? "Start Journey"
      : parent?.data?.title
        ? true
        : false;
  };

  // Class variable for node
  const tagMethodStyle = {
    background: "#816179",
    textTransform: "uppercase",
    fontSize: "10px",
    display: "inline-block",
    padding: "4px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: "white",
  };

  const nodeStyleForDelay = {
    border:
      data?.timeRelation === "before" &&
      (data?.delayFor === "appointment" || data?.delayFor === "surgery")
        ? "1px solid red"
        : data?.timeRelation === "after"
          ? "1px solid green"
          : "1px solid black",
    borderRadius: "25px",
    backgroundColor: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
    padding: "7px",
    width: "100px",
    textAlign: "center",
    fontWeight: "bold",
    color: "#0984e3",
    cursor: "pointer",
    lineHeight: "0.2",
    transform:
      isParentNodeHaveTitle(data?.parentNodeId) === "Start Journey"
        ? "translateX(185%)"
        : isParentNodeHaveTitle(data?.parentNodeId)
          ? "translate(185%, 25px)"
          : "translate(185%, 20px)",
  };

  const convertTo12HourFormat = (hour) => {
    const hourIn12HrFormat = hour % 12 || 12;
    return `${hourIn12HrFormat}`;
  };

  const convertTo12HourFormatWithMinute = ({ hour, minute }) => {
    let hours = Number.parseInt(hour, 10);
    const minutes = Number.parseInt(minute, 10);
    const period = hours >= 12 ? "PM" : "AM";

    if (hours === 0) {
      hours = 12; // Midnight case
    } else if (hours > 12) {
      hours -= 12; // Convert 24-hour format to 12-hour format
    }

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedHours = hours;

    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  const delayTitle = (
    delayFor,
    timeRelation,
    runAtSpecificHour,
    hourToRunAt,
  ) => {
    if (delayFor === "appointment" && timeRelation === "after") {
      return `${data?.value} ${data?.metric} ${data?.timeRelation} the ${data?.delayFor} ${
        runAtSpecificHour
          ? `at ${convertTo12HourFormatWithMinute(hourToRunAt)}`
          : ""
      }`;
    } else if (delayFor === "appointment" && timeRelation === "before") {
      return `${data?.value} ${data?.metric} ${data?.timeRelation} ${data?.delayFor} ${
        runAtSpecificHour
          ? `at ${convertTo12HourFormatWithMinute(hourToRunAt)}`
          : ""
      }`;
    } else if (delayFor === "surgery" && timeRelation !== "on") {
      return `${data?.value} ${data?.metric} ${data?.timeRelation} procedure date ${
        runAtSpecificHour
          ? `at ${convertTo12HourFormatWithMinute(hourToRunAt)}`
          : ""
      }`;
    } else if (timeRelation === "on" && delayFor) {
      return `${data?.timeRelation[0].toUpperCase() + data?.timeRelation?.slice(1)} ${
        data?.delayFor === "surgery"
          ? "Procedure"
          : data?.delayFor[0].toUpperCase() + data?.delayFor?.slice(1)
      } ${runAtSpecificHour ? `at ${convertTo12HourFormatWithMinute(hourToRunAt)}` : "Date"}`;
    }
  };

  // UseEffect for updating the id states
  useEffect(() => {
    //  Shoaib : Error finding : data is coming but dont have parentNodeId at initial
    if (
      (!data?.positiveChildNodeId && !data?.negativeChildNodeId) ||
      data?.method === "form" ||
      data?.method === "call"
    ) {
      if (!jid && data && id) {
        const journeyIdString = JSON.parse(localStorage.getItem("journeyId"));
        setJourneyID(journeyIdString);
        setNodeID(id);
        setParentID(data?.parentNodeId);
      }
      if (jid && data && id) {
        setJourneyID(jid);
        setNodeID(id);
        setParentID(data?.parentNodeId);
      }
    }
  }, [id]);

  const isfilled = () => {
    let lastKey = null;

    for (const [key] of nodeInternals.entries()) {
      // if (value.data?.nextNodeId === '1') {
      //   console.log('return false')
      //   return false;
      // }

      lastKey = key;
    }

    // Return false if it matches any of the specified values
    const nodeOfLastKey = nodeInternals.get(lastKey);

    if (nodeOfLastKey?.data?.label === "Start Journey") {
      return true;
    }

    if (isChildNodeAdded) {
      return false;
    }

    return nodeOfLastKey?.data?.isFilled || false;
  };

  // This function is return true or false to show the More Icon in Start Journey Node when child node is filled or not
  const isNextNodefilled = () => {
    const keysArray = Array.from(nodeInternals.keys());
    const thirdNodeId = keysArray[2];
    const thirdNode = getNode(thirdNodeId);

    return thirdNode?.data?.isFilled || false;
  };

  const hasParentWithYesNoLabel = (id) => {
    const nodeData = nodeInternals.get(id);
    for (const [, value] of nodeInternals.entries()) {
      if (
        value.data?.parentNodeId === id &&
        nodeData.data?.positiveChildNodeId &&
        (nodeData.data?.label === "yes" ||
          nodeData.data?.label === "no" ||
          nodeData.data?.label === "attended" ||
          nodeData.data?.label === "not attended")
      ) {
        return false;
      }
    }

    return true;
  };

  const isConditionLastNode = () => {
    const nextNode = getNextNodeId(id);

    return nextNode ? false : true;
  };

  useEffect(() => {
    if (title !== "viewjourneysflow") {
      // Check if the current node has a child node
      const hasChild = (id) => {
        const allKeys = [...nodeInternals.keys()];

        if (allKeys.length < 2) {
          // If there are less than 2 keys, return true or handle as needed
          return true;
        }

        const lastKey = allKeys[allKeys.length - 1];

        return lastKey === id;
      };

      const hasChildNode = hasChild(id);
      setHasChildNode(hasChildNode);
    }
  }, [nodeInternals, id]);

  useEffect(() => {
    setAnchorEl(null);
  }, [nodeInternals]);
  //   useEffect(() => {
  //     const allKeys = [...nodeInternals.keys()];
  //     const negativeNodes = [];
  //     const positiveNodes = [];

  //     // Iterate through nodeInternals to find nodes with parentNegativeChildNodeId or parentPositiveChildNodeId
  //     for (let i = 0; i < allKeys.length; i++) {
  //         const currentNode = nodeInternals.get(allKeys[i]);

  //         if (currentNode.data.parentNegativeChildNodeId && currentNode.data.parentNegativeChildNodeId!== null) {
  //             negativeNodes.push(i);
  //         } else if (currentNode.data.parentPositiveChildNodeId && currentNode.data.parentPositiveChildNodeId !== null) {
  //             positiveNodes.push(i);
  //         }
  //     }

  // }, [nodeInternals]);

  //     const horizontalSpacing = 500; // Adjust this value as needed
  //     // Update position of second child node
  //     secondChildNode.position.x = firstChildNode.position.x + horizontalSpacing;
  //     secondChildNode.positionAbsolute.x = firstChildNode.positionAbsolute.x + horizontalSpacing;

  useEffect(() => {
    if (title === "viewjourneysflow") {
      setShowToPatient(false);
    }
  }, [title]);

  useEffect(() => {
    setForceRenderNode(false);
  }, [forceRenderNode]);

  const handleChange = (panel) => (event, isExpanded) => {
    if ((!openModal && !isFilled) || isEdit) {
      setExpanded(isExpanded ? panel : false);
      setIsEdit(false);
      setEditMenu(null);
      if (data?.positiveChildNodeId) {
        setRefreshworkflow(!refreshworkflow);
      }
    } else {
      setExpanded(false);
    }
  };

  useEffect(() => {
    const nodeData = nodeInternals.get(nodeID);
    setIsFilled(nodeData?.data?.isFilled);
  }, [nodeInternals, forceRenderNode, isEdit]);

  useEffect(() => {
    if (editMenu) {
      setExpanded(`panel-${editMenu}`);
    } else {
      setExpanded(false);
    }
  }, [editMenu]);

  useEffect(() => {
    setIsEdit(false);
    setEditMenu(null);
    setExpanded(false);

    // This is the solution to bug When we click on "Add Child Node" and then go back without making any changes, upon returning, the plus button disappears , but it causes Add Child Node parallel bug.
  }, []);

  useEffect(() => {
    if (forceRefreshChildNode) {
      setIsChildNodeAdded(false);
      setAddChildNode(false);
    }
  }, []);

  return (
    <>
      {data?.method === "delay" ? (
        data?.delayFor !== "N/A" ? (
          <div style={nodeStyleForDelay}>
            <Typography
              variant="body2"
              sx={{ fontSize: "10px", color: "black" }}
              onClick={() => handleOpenDelayModal(data)}
            >
              {delayTitle(
                data?.delayFor,
                data?.timeRelation,
                data?.runAtSpecificHour,
                data?.hourToRunAt,
              )}
            </Typography>

            <Handle
              className={styles.handle}
              type="target"
              position={Position.Top}
              isConnectable={false}
            />
            <Handle
              className={styles.handle}
              type="source"
              position={Position.Bottom}
              isConnectable={false}
            />
            <EditDelayModal
              open={openDelayModal}
              setOpen={setOpenDelayModal}
              data={data}
              id={id}
              nodeInternals={nodeInternals}
              jid={jid}
            />
          </div>
        ) : (
          <div style={nodeStyleForDelay}>
            <Typography
              variant="body2"
              sx={{ fontSize: "10px", color: "black" }}
              onClick={() => handleOpenDelayModal(data)}
            >
              Delay : {data?.value} {data?.metric}
              {data?.runAtSpecificHour && (
                <>
                  <br />
                  {`Time: ${convertTo12HourFormatWithMinute(data?.hourToRunAt)}`}
                </>
              )}
            </Typography>

            <Handle
              className={styles.handle}
              type="target"
              position={Position.Top}
              isConnectable={false}
            />
            <Handle
              className={styles.handle}
              type="source"
              position={Position.Bottom}
              isConnectable={false}
            />
            <EditDelayModal
              open={openDelayModal}
              setOpen={setOpenDelayModal}
              data={data}
              id={id}
              nodeInternals={nodeInternals}
              jid={jid}
            />
          </div>
        )
      ) : data?.positiveChildNodeId &&
        (data?.label === "yes" ||
          data?.label === "no" ||
          data?.label === "attended" ||
          data?.label === "not attended") ? (
        <div style={nodeStyleForDelay}>
          <Typography variant="body2" sx={{ fontSize: "10px", color: "black" }}>
            {data.label.charAt(0).toUpperCase() + data.label.slice(1)}
          </Typography>
          {/* hasParentWithYesNoLabel(id) */}
          {(showToPatient || (isfilled() && !getNextNodeId(id))) &&
            !patientId && (
              <AddButtonFlowV2
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                label={data?.label}
                method={data?.method}
                id={id}
                childNodeId={childNodeId}
                setChildNodeId={setChildNodeId}
                childDelayNodeId={childDelayNodeId}
                setChildDelayNodeId={setChildDelayNodeId}
                positiveChildNodeId={positiveChildNodeId}
                setPositiveChildNodeId={setPositiveChildNodeId}
                negativeChildNodeId={negativeChildNodeId}
                setNegativeChildNodeId={setNegativeChildNodeId}
                setAddChildNode={setAddChildNode}
                addChildNode={addChildNode}
                nodeInternals={nodeInternals}
                data={data}
              />
            )}

          <Handle
            className={styles.handle}
            type="target"
            position={Position.Top}
            isConnectable={false}
          />
          <Handle
            className={styles.handle}
            type="source"
            position={Position.Bottom}
            isConnectable={false}
          />
        </div>
      ) : (
        <div
          className={cx(styles.node)}
          style={{
            width: data?.label === "Start Journey" && "160px",
            transform: data?.label === "Start Journey" && "translateX(97%)",
          }}
        >
          {/* HTML for Method Tag on the top of node */}
          {data?.label !== "Start Journey" && (
            <div style={{ marginTop: "-42px" }}>
              <p style={tagMethodStyle}>{data?.method?.replace(/_/g, " ")}</p>
            </div>
          )}

          {/* HTML for Node */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgourndColor: "green",
              // border: data?.label !== 'Start Journey' && "1px solid black",
              // borderRadius: data?.label !== 'Start Journey' && "5px"
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: `${
                  !data.positiveChildNodeId &&
                  data.label !== "yes" &&
                  data.label !== "no" &&
                  data?.label === "attended" &&
                  data?.label === "not attended"
                    ? "space-between"
                    : "center"
                }`,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Commenting previous way to show methods in node -  */}
                {/* {data.label !== "Start Journey" && data.label !== "yes" && data.label !== "no" &&
                (data.method !== "delay" ? (
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "uppercase",
                      fontSize: "8px",
                      backgroundColor: "red",
                      borderRadius: "5px",
                      color: "white",
                      padding: "4px",
                    }}
                  >
                    {data.method?.replace(/_/g, ' ')}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "10px", color: "red" }}
                  >
                    {data?.value} {data?.metric}
                  </Typography>
                ))} */}

                {data?.label !== "Start Journey" ? (
                  <NodeAccordionV2
                    {...{
                      data,
                      id,
                      journeyID,
                      openModal,
                      nodeID,
                      parentID,
                      handleOpenModal: checkNodeIsConditional(nodeID)
                        ? handleOpenPermissionModal
                        : handleOpenModal,
                      nodeInternals,
                      handleChange,
                      expanded,
                      setExpanded,
                      setForceRenderNode,
                      isEdit,
                      setIsEdit,
                      childNodeId,
                      setChildNodeId,
                      childDelayNodeId,
                      setChildDelayNodeId,
                      positiveChildNodeId,
                      setPositiveChildNodeId,
                      negativeChildNodeId,
                      setNegativeChildNodeId,
                      isfilled,
                      hasChildNode,
                      setAddChildNode,
                      addChildNode,
                      setAnchorEl,
                      isNextNodefilled,
                      assignId,
                      isConditionLastNode,
                      handleDelete,
                    }}
                  />
                ) : (
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "center",
                      marginLeft:
                        data.label !== "Start Journey"
                          ? data.method === "sms" && data.label.length <= 2
                            ? "40px"
                            : data.label?.length > 17
                              ? "2px"
                              : "8px"
                          : "35px",
                      padding: data.label?.length >= 17 && "3px",
                    }}
                  >
                    {data.method === "delay"
                      ? "Time Delay"
                      : data.method === "sms" && data.label.length >= 15
                        ? data.label.slice(0, 14) + "..."
                        : data.label}
                  </Typography>
                )}
              </Box>

              {data.label !== "Start Journey" &&
                !patientId &&
                !data.positiveChildNodeId &&
                data.label !== "yes" &&
                data.label !== "no" &&
                data?.label === "attended" &&
                data?.label === "not attended" && (
                  <Box sx={{ marginTop: "4px" }}>
                    {/* <NodeMenu handleOpenModal={handleOpenModal} toggleEditDrawer={toggleEditDrawer({ id, state: true })} anchorEl={anchorEl} setAnchorEl={setAnchorEl} /> */}
                    {/* <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={toggleEditDrawer({ id, state: true })}
                >
                  <EditSvg />
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  // onClick={()=>{handleDelete()}}
                  onClick={handleOpenModal}
                >
                  <DeleteSvg />
                </Box> */}
                  </Box>
                )}

              {data.label === "Start Journey" &&
                isNextNodefilled() &&
                isfilled() &&
                !patientId && (
                  <Box sx={{ color: "black" }}>
                    <NodeMenuStartJourney
                      anchorEl={anchorEl1}
                      setAnchorEl={setAnchorEl1}
                      setAddChildNode={setAddChildNode}
                      setAnchorElButton={setAnchorEl}
                    />
                  </Box>
                )}
            </Box>

            {(showToPatient ||
              (isfilled() && hasChildNode && !isEdit && !addChildNode) ||
              (isConditionLastNode() && data?.isFilled) ||
              addChildNode) &&
              !patientId && (
                <AddButtonFlowV2
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  label={data?.label}
                  method={data?.method}
                  id={id}
                  childNodeId={childNodeId}
                  setChildNodeId={setChildNodeId}
                  childDelayNodeId={childDelayNodeId}
                  setChildDelayNodeId={setChildDelayNodeId}
                  positiveChildNodeId={positiveChildNodeId}
                  setPositiveChildNodeId={setPositiveChildNodeId}
                  negativeChildNodeId={negativeChildNodeId}
                  setNegativeChildNodeId={setNegativeChildNodeId}
                  setAddChildNode={setAddChildNode}
                  addChildNode={addChildNode}
                  nodeInternals={nodeInternals}
                  data={data}
                />
              )}
          </Box>

          <Handle
            className={styles.handle}
            type="target"
            position={Position.Top}
            isConnectable={false}
          />
          <Handle
            className={styles.handle}
            type="source"
            position={Position.Bottom}
            isConnectable={false}
          />
        </div>
      )}

      {/* Drawers and Modal */}
      {/* <NodeAccordion {...{data, id, journeyID,openModal, nodeID, parentID, handleOpenModal,nodeInternals,handleChange,expanded,setExpanded}} /> */}

      {/* <AddDrawer
        // toggleDrawer={toggleJourenyDrawer}
        click={click}
        setClick={setClick}
        id={id}
        state={openJourney}
        title={data.label}
        data={data}
      /> */}
      <EditDrawer
        toggleDrawer={toggleEditDrawer}
        state={openEdit.state}
        title={data.label}
        id={openEdit.id}
        data={data}
      />
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        handle={handleDelete}
        journeyID={journeyID}
        nodeID={id}
        parentID={parentID}
        deleteText={
          data.checkCondition
            ? `Are you sure you want to delete this node? This will result in the deletion of all it's child nodes?`
            : ``
        }
      />

      <PermissionModal
        open={openPermissionModal}
        setOpen={setOpenPermissionModal}
        permissionValues={permissionValues}
        setPermissionValues={setPermissionValues}
        handleSave={handleOpenModal}
        nodeData={nodeInternals.get(nodeID)}
      />
    </>
  );
};

export default memo(WorkflowNode);
