import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../contexts/isMobile";
import { SeachField, SolidButton } from "./../../Components";
import { SideBar } from "./SideBar";

export function TableHead({
  searchTerm,
  setSearchTerm,
  options,
  selectedOptions,
  handleOptionSelect,
}) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const width = isMobile ? "100%" : "200px";
  const direction = isMobile ? "column" : "row";
  const stackStyle = {
    paddingTop: isMobile ? "2rem" : "3rem",
    paddingBottom: isMobile ? "2rem" : "3rem",
    paddingRight: "1rem",
    paddingLeft: isMobile && "1rem",
  };
  return (
    <>
      <Box sx={{ padding: isMobile ? "6px" : 3 }}>
        <Grid
          container={true}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item={true} xs={12} sm={12}>
            <Grid
              container={true}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item={true} xs={12} sm={3} textAlign="left">
                <Box
                  sx={[
                    { height: 40, borderColor: "#979797" },
                    {
                      "&": { width },
                    },
                  ]}
                >
                  <SeachField
                    {...{
                      searchTerm,
                      setSearchTerm,
                      options,
                      selectedOptions,
                      handleOptionSelect,
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item={true}
                xs={12}
                sm={9}
                textAlign="right"
                mt={isMobile && 2}
              >
                <SolidButton label="Create" onClick={toggleDrawer(true)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <SideBar toggleDrawer={toggleDrawer} state={open} />
    </>
  );
}
