import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { CircularLoader } from "../../../../Components";
import { useIsMobile } from "../../../../contexts/isMobile";

export function DoughnutChart({ chartData, isLoading, isError }) {
  // For Responsiveness
  const isMobile = useIsMobile();

  // States for Chart
  const [complete, setComplete] = useState(0);
  const [pending, setPending] = useState(0);
  const [noForms, setNoForms] = useState(100);

  useEffect(() => {
    if (chartData?.completeRatio > 0 || chartData?.pendingRatio > 0) {
      console.log("in the useEffect", chartData);
      setComplete(chartData?.completeRatio);
      setPending(chartData?.pendingRatio);
      setNoForms(null);
    }
  }, [chartData, complete, pending, noForms]);

  // Data for doughnet
  const data = {
    labels: ["Complete", "Pending", "No Forms"],
    datasets: [
      {
        label: "Form Submission",
        data: [complete, pending, noForms],
        borderColor: ["rgba(255,206,86,0.2)"],
        backgroundColor: ["#816179", "#D0EAE1", "#09db08"],
        pointBackgroundColor: "rgba(255,206,86,0.2)",
      },
    ],
  };

  // Options for doughnut
  const options = {
    plugins: {
      title: {
        display: false,
        text: "Doughnut Chart",
        color: "blue",
        font: {
          size: 34,
        },
        padding: {
          top: 30,
          bottom: 30,
        },
        responsive: true,
        animation: {
          animateScale: true,
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        // Add the datalabels configuration
        color: "white", // Set the font color for the labels
        formatter: (value, ctx) => {
          // Calculate the percentage value
          const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
          const total = dataset.data.reduce((sum, current) => sum + current, 0);
          const percentage = ((value * 100) / total).toFixed(2);
          if (percentage > 0) {
            return `${percentage}%`;
          } else {
            return ""; // Empty string if percentage is not greater than 0
          }
        },
        font: {
          size: "14",
          weight: "bold",
        },
        display: true, // Set the display property to true
      },
    },
  };

  if (isLoading)
    return (
      <div>
        {" "}
        <CircularLoader />{" "}
      </div>
    );
  if (isError) return <div>some error</div>;
  return (
    <div
      style={{
        width: isMobile ? "100%" : "300px",
        height: "100%",
        margin: "10px",
      }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
}
