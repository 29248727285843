import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import {
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CircularLoader,
  CloneJourneyModalForHospital,
  PaginationBar,
} from "../../../Components";
import { CloneJourneyModal } from "../../../Components";
import { ConfirmationModal } from "../../../Components/ConfirmationModal";
import { DeleteSvg, EditSvg } from "../../../assets/svgs";
import {
  useCloneJourneyForHospitalMutation,
  useCloneJourneyMutation,
  useDeleteJourneyMutation,
  usePublishJourneyMutation,
} from "../../../store/journeysSlice";
import { DeleteJourneyModal } from "./DeleteJourneyModal";

const columns = [
  { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "right",
  },
  {
    id: "createdon",
    label: "Created On",
    minWidth: 170,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const createRows = (
  data,
  handleOpenModal,
  navigateJounreyFlow,
  openModal,
  setOpenModal,
  handleDelete,
  navigateEditJounreyFlow,
  handleCloneModal,
  setCloneModal,
  cloneModal,
  handleClone,
  handlePublishJourney,
  isLoadingPublishJourney,
  openConfirmationModal,
  setOpenConfirmationModal,
  handleOpenConfirmationModal,
  handleCloneModalForHospital,
  cloneModalForHospital,
  setCloneModalForHospital,
  handleCloneForHospital,
  user,
) => {
  const rows = data.map(
    ({ _id, title, status, description, createdAt }, index) => {
      const getStatus = (status) => {
        if (status === "published") {
          return (
            <Chip
              label={status?.charAt(0)?.toUpperCase() + status?.slice(1)}
              size="small"
              color="success"
            />
          );
        } else if (status === "draft") {
          return (
            <Chip
              label={status?.charAt(0)?.toUpperCase() + status?.slice(1)}
              size="small"
              color="warning"
            />
          );
        } else {
          return "N/A";
        }
      };
      return {
        Srno: (
          <Typography
            variant="p"
            sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
          >
            {index + 1}
          </Typography>
        ),
        title,
        // description,
        description:
          description?.length <= 50
            ? description
            : description.slice(0, 50) + ".....",
        createdon: format(new Date(createdAt), "MM-dd-yyyy"),
        status: getStatus(status),
        actions: (
          <>
            <Stack spacing={3} direction="row" justifyContent="left">
              {user?.role === 1526 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleCloneModalForHospital(_id)}
                >
                  <Tooltip
                    title="Clone Journey to a Different Site"
                    placement="top"
                  >
                    <FileCopyOutlinedIcon
                      sx={{ fontSize: "large", fontWeight: "1em" }}
                    />
                  </Tooltip>
                </div>
              )}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleCloneModal(_id)}
              >
                <Tooltip title="Clone Journey" placement="top">
                  <ContentCopyIcon
                    sx={{ fontSize: "large", fontWeight: "1em" }}
                  />
                </Tooltip>
              </div>
              <Tooltip title="Edit Journey" placement="top">
                <div
                  onClick={() => navigateEditJounreyFlow(_id)}
                  style={{ cursor: "pointer" }}
                >
                  <EditSvg />
                </div>
              </Tooltip>
              <Tooltip title="Delete Journey" placement="top">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal(_id)}
                >
                  <DeleteSvg />
                </div>
              </Tooltip>
              {status === "draft" && (
                <div
                  style={{
                    cursor: isLoadingPublishJourney ? "wait" : "pointer",
                  }}
                  onClick={() => handleOpenConfirmationModal(_id)}
                >
                  <Tooltip title="Mark as Published" placement="top">
                    <PublishedWithChangesIcon fontSize="small" />
                  </Tooltip>
                </div>
              )}
            </Stack>
            <DeleteJourneyModal
              open={openModal.open}
              setOpen={setOpenModal}
              handle={handleDelete}
              id={openModal.id}
            />
            <CloneJourneyModalForHospital
              open={cloneModalForHospital.open}
              setOpen={setCloneModalForHospital}
              handle={handleCloneForHospital}
              id={cloneModalForHospital.id}
            />
            <CloneJourneyModal
              open={cloneModal.open}
              setOpen={setCloneModal}
              handle={handleClone}
              id={cloneModal.id}
            />
            <ConfirmationModal
              open={openConfirmationModal.open}
              setOpen={setOpenConfirmationModal}
              handle={handlePublishJourney}
              id={openConfirmationModal.id}
              ConfirmationText={
                "Are you sure you want to publish this journey?"
              }
              isLoading={isLoadingPublishJourney}
            />
          </>
        ),
      };
    },
  );

  return rows;
};

export function JourneyTable({
  page,
  setPage,
  data,
  fetchAgain,
  isLoading,
  isError,
  error,
  isSuccess,
}) {
  const { user } = useSelector((state) => state.user);

  const [openModal, setOpenModal] = React.useState({ id: null, open: false });
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState({
    id: null,
    open: false,
  });
  const [cloneModal, setCloneModal] = React.useState({ id: null, open: false });
  const [cloneModalForHospital, setCloneModalForHospital] = React.useState({
    id: null,
    open: false,
  });
  const handleOpenModal = (id) => setOpenModal({ id: id, open: true });
  const handleOpenConfirmationModal = (id) =>
    setOpenConfirmationModal({ id: id, open: true });
  const handleCloneModal = (id) => setCloneModal({ id: id, open: true });
  const handleCloneModalForHospital = (id) =>
    setCloneModalForHospital({ id: id, open: true });
  const [deleteJourney] = useDeleteJourneyMutation();
  const [cloneJourney] = useCloneJourneyMutation();
  const [cloneJourneyForHospital] = useCloneJourneyForHospitalMutation();
  const [publishJourney, { isLoading: isLoadingPublishJourney }] =
    usePublishJourneyMutation();

  const navigate = useNavigate();

  const navigateJounreyFlow = () => {
    navigate("/journeysflow");
  };
  const navigateEditJounreyFlow = (id) => {
    navigate(`/journeysflow/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    localStorage.setItem("page", newPage);
    setPage(newPage);
  };

  const handlePublishJourney = async (id) => {
    publishJourney(id)
      .unwrap()
      .then(() => {
        fetchAgain();
        toast.success("Journey published successfully!");
        setOpenConfirmationModal({ id: null, open: false });
      })
      .catch((error) => {
        toast.error("some error occured!");
        console.error(error);
        setOpenConfirmationModal({ id: null, open: false });
      });
  };

  const handleDelete = async (id) => {
    deleteJourney(id)
      .unwrap()
      .then(() => {
        fetchAgain();
        toast.success("Deleted successfully!");
      })
      .catch((error) => {
        toast.error("some error occured!");
        console.error(error);
      });
  };

  const handleClone = async (id, body) => {
    cloneJourney({ id, body })
      .unwrap()
      .then(() => {
        fetchAgain();
        toast.success("Cloned successfully!");
      })
      .catch((error) => {
        toast.error("some error occured!");
      });
  };

  const handleCloneForHospital = async (id, body) => {
    cloneJourneyForHospital({ id, body })
      .unwrap()
      .then(() => {
        fetchAgain();
        toast.success(
          "The journey has been successfully cloned to the destination site!",
        );
      })
      .catch((error) => {
        toast.error("some error occured!");
      });
  };

  const rows = createRows(
    data?.data || [],
    handleOpenModal,
    navigateJounreyFlow,
    openModal,
    setOpenModal,
    handleDelete,
    navigateEditJounreyFlow,
    handleCloneModal,
    setCloneModal,
    cloneModal,
    handleClone,
    handlePublishJourney,
    isLoadingPublishJourney,
    openConfirmationModal,
    setOpenConfirmationModal,
    handleOpenConfirmationModal,
    handleCloneModalForHospital,
    cloneModalForHospital,
    setCloneModalForHospital,
    handleCloneForHospital,
    user,
  );

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>{error.error}</div>;

  if (data?.length === 0) {
    return (
      <Typography sx={{ margin: "10px 20px" }}>No Journey found.</Typography>
    );
  }

  return (
    <>
      <Paper
        sx={{
          border: "1px ",
          width: "100%",
          borderRadius: "10px",
        }}
      >
        <TableContainer sx={{ borderRadius: "4px" }}>
          <Table>
            <TableHead style={{ backgroundColor: "rgba(0, 151, 236, 0.12)" }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    key={column.id}
                    align="left"
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#50607A",
                      paddingY: "5px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <TableRow hover={true} role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align="left"
                          sx={{
                            color: "text.secondary",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationBar
          {...{ page, count: data.totalPages, handleChange: handleChangePage }}
        />
      </Paper>
    </>
  );
}
