import { Button, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularLoader } from "../Components/Loader";
import { useGetSurveysQuery } from "../store/suerveySlice";

function SurveyItem({ id }) {
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="p">{"name"}</Typography>
      <Typography variant="h2">{id}</Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          navigate(`/surveys/${id}`);
        }}
      >
        Open Servey
      </Button>
    </>
  );
}

function ViewAllSurveys() {
  const { user } = useSelector((state) => state.user);
  const { isLoading, isSuccess, isError, error, data } = useGetSurveysQuery({
    userID: user.id,
  });

  let content;
  if (isLoading) {
    content = (
      <p>
        <CircularLoader />
      </p>
    );
  } else if (isSuccess) {
    content = data.data.map(({ _id }) => <SurveyItem key={_id} id={_id} />);
  } else if (isError) {
    content = <p>{error}</p>;
  }

  return (
    <>
      <Typography variant="h1">List of all surveys</Typography>
      <section>{content}</section>
    </>
  );
}

export default ViewAllSurveys;
