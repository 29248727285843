import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CompleteSvg, PendingSvg } from "../../../../assets/svgs";
import { useEditTaskMutation } from "../../../../store/tasksSlice";
import { ConfirmStatusModal } from "./ConfirmStatusModal";

export const Status = ({ status, id }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const { user } = useSelector((state) => state.user);
  const [updateTask] = useEditTaskMutation();

  const editStatus = (e) => {
    e.preventDefault();
    updateTask({
      userID: user.id,
      taskID: id,
      body: {
        status: status === "assigned" ? "completed" : "completed",
      },
    })
      .unwrap()
      .then(() => {
        toast.success("Successfully updated the status!");
      })
      .catch((error) => {
        console.log("ON updating status==...>>", error);
        toast.error("some error occured!");
      });
  };

  const handleConfirmation = (e) => {
    setOpenModal(false);
    editStatus(e);
  };

  const pointerEvents = status === "completed" ? "none" : "auto";
  const isCompleted = status === "completed" ? true : false;
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "#50607A",
          background: "rgba(0, 151, 236, 0.12)",
          borderRadius: "30px",
          padding: "5px",
        }}
      >
        {isCompleted ? <CompleteSvg /> : <PendingSvg />}
        <Typography
          sx={{
            marginLeft: "4px",
            color: "#50607A",
            cursor: "pointer",
            pointerEvents,
            fontWeight: "bold",
          }}
          onClick={handleOpenModal}
        >
          {isCompleted ? "Completed" : "Assigned"}
        </Typography>
      </Box>

      <ConfirmStatusModal
        open={openModal}
        setOpen={setOpenModal}
        handle={handleConfirmation}
        id={id}
      />
    </div>
  );
};
