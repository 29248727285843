import { Box, Grid, Typography } from "@mui/material";
import { WeekDropdown } from "../../../../Components";
import { useIsMobile } from "../../../../contexts/isMobile";

export function FormHeader({ week, setWeek }) {
  const isMobile = useIsMobile();

  const gridMargin = {
    margin: isMobile && "10px 5px",
  };

  return (
    <>
      <Box
        sx={{ padding: isMobile ? "6px" : 3, paddingBottom: "0px !important" }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={8} sx={gridMargin}>
            <Typography variant="h5">PROMS Completion</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ padding: !isMobile && "10px" }}>
                <WeekDropdown {...{ week, setWeek }} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
