import { Close as CloseIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

export default function WhatwentwrongDialog({
  open,
  setOpen,
  addReaction,
  queueId,
  originalMessage,
  isAcuitySelected,
  setIsAcuitySelected,
  isChatbotResponse,
  setIsChatbotResponse,
  isTaskCategorySelected,
  setIsTaskCategorySelected,
  proposedChatbotResponse,
  setProposedChatbotResponse,
  chatbotResponseCritique,
  setChatbotResponseCritique,
  selectedAcuity,
  setSelectedAcuity,
  selectedTaskLabel,
  setSelectedTaskLabel,
  chatbotResponse,
}) {
  const options = ["Low", "Medium", "High"];
  const handleDislikeModalClose = () => {
    setOpen(false);
    setSelectedTaskLabel("");
    setSelectedAcuity("");
    setProposedChatbotResponse("");
    setChatbotResponseCritique("");
    setIsChatbotResponse(false);
    setIsAcuitySelected(false);
    setIsTaskCategorySelected(false);
  };

  const submitDislikeReaction = () => {
    addReaction(queueId, "dislike");
  };

  const taskCategoryOptions = [
    "Mental & Emotional Support",
    "Clinical Monitoring & Guidance",
    "Rehabilitation & Physical Therapy Coordination",
    "Urgent/Emergent Clinician Review",
    "Case Management, Community & Social Needs",
    "Medication Guidance & Refills",
    "Appointment Scheduling",
    "Post-Operative Concern",
  ];

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleDislikeModalClose}
        fullWidth={true}
        className="DialogContent"
        sx={{ padding: "12px" }}
      >
        <DialogTitle sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Tell us what went wrong?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={isAcuitySelected}
                onChange={(e) => setIsAcuitySelected(e.target.checked)}
                name="acuityCheckbox"
              />

              <div style={{ width: "100%" }}>
                <Typography fontSize={12}> Acuity </Typography>

                {isAcuitySelected && (
                  <Autocomplete
                    value={selectedAcuity}
                    onChange={(event, newValue) => {
                      setSelectedAcuity(newValue);
                    }}
                    options={options}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={isTaskCategorySelected}
                onChange={(e) => setIsTaskCategorySelected(e.target.checked)}
                name="taskCategoryCheckbox"
              />
              <div style={{ width: "100%" }}>
                <Typography fontSize={12} mt={2}>
                  {" "}
                  Task Category
                </Typography>
                {isTaskCategorySelected && (
                  <Autocomplete
                    value={selectedTaskLabel}
                    onChange={(event, newValue) => {
                      setSelectedTaskLabel(newValue);
                    }}
                    options={taskCategoryOptions}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                )}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "start" }}>
              <Checkbox
                checked={isChatbotResponse}
                onChange={(e) => setIsChatbotResponse(e.target.checked)}
                name="taskCategoryCheckbox"
              />
              <div style={{ width: "100%" }}>
                <Typography fontSize={12} mt={2}>
                  Chatbot message
                </Typography>
                {isChatbotResponse && (
                  <div>
                    <Typography fontSize={12} color={"#979797"} mt={2}>
                      Patient Message
                    </Typography>
                    <TextField
                      value={originalMessage}
                      fullWidth={true}
                      sx={{
                        "& .MuiInput-underline:after": {
                          border: "none",
                          borderBottom: "black",
                        },
                      }}
                    />

                    <Typography fontSize={12} color={"#979797"} mt={2}>
                      Chatbot Reply
                    </Typography>
                    <TextField
                      value={chatbotResponse}
                      fullWidth={true}
                      sx={{
                        "& .MuiInput-underline:after": {
                          border: "none",
                          borderBottom: "black",
                        },
                      }}
                      multiline={true}
                      minRows={3}
                    />

                    <Typography fontSize={12} color={"#979797"} mt={2}>
                      What should be the reply
                    </Typography>
                    <TextField
                      value={proposedChatbotResponse}
                      fullWidth={true}
                      onChange={(e) =>
                        setProposedChatbotResponse(e.target.value)
                      }
                      // InputProps={{
                      //   "& .MuiInput-underline:after": {
                      //     borderBottomColor: "black",
                      //   },
                      // }}
                      multiline={true}
                      minRows={3}
                    />

                    <Typography fontSize={12} color={"#979797"} mt={2}>
                      Provide Feedback
                    </Typography>
                    <TextField
                      value={chatbotResponseCritique}
                      fullWidth={true}
                      onChange={(e) =>
                        setChatbotResponseCritique(e.target.value)
                      }
                      // InputProps={{
                      //   "& .MuiInput-underline:after": {
                      //     borderBottomColor: "black",
                      //   },
                      // }}
                    />
                    <br />
                  </div>
                )}
              </div>
            </div>

            <IconButton
              onClick={handleDislikeModalClose}
              style={{ position: "absolute", top: "8px", right: "10px" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="medium"
            sx={{
              borderRadius: "30px",
              borderWidth: "2px",
              mr: 1,
              color: "#0049C6",
              fontWeight: 600,
              textTransform: "capitalize",
            }}
            onClick={handleDislikeModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              backgroundColor: "#0049C6",
              color: "white",
              borderRadius: "16px",
              textTransform: "capitalize",
            }}
            onClick={submitDislikeReaction}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
