import { Box, Paper } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useIsMobile } from "../../../../contexts/isMobile";
import { useGetFormCompletionRatioQuery } from "../../../../store/dashboardsSlice";
import { DoughnutChart } from "./Doughnut";
import { FormHeader } from "./FormHeader";
import { FormStatus } from "./FormStatus";

export function FormChart({ setFormChartLoader }) {
  const [week, setWeek] = useState("all");
  const isMobile = useIsMobile();

  // Getting the user from state
  const { user } = useSelector((state) => state.user);

  // Integrating the slice
  const { data, isLoading, isError, isSuccess } =
    useGetFormCompletionRatioQuery(
      {
        userID: user.id,
        week,
      },
      { refetchOnMountOrArgChange: true },
    );

  // useEffect(() => {
  //   if(isSuccess){
  //     setFormChartLoader(false);
  //   }else{
  //     setFormChartLoader(true);
  //   }

  // }, [isSuccess, setFormChartLoader]);

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
          border: "1px solid #D0EAE1",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        elevation={0}
      >
        <FormHeader {...{ week, setWeek }} />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <DoughnutChart {...{ chartData: data, isLoading, isError }} />
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <FormStatus />
        </Box>
      </Paper>
    </>
  );
}
