import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query({
      query: (query = {}) => {
        const {
          page = 1,
          limit = 10,
          tags = null,
          userID,
          program,
          ...rest
        } = query;

        // Convert taskCategory, acuity, and program to arrays if they are not already
        const finalQuery = {
          page,
          limit,
          userID,
          tags,
          ...(Array.isArray(program)
            ? { program }
            : { program: [program] || [] }),
          ...rest,
        };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            if (Array.isArray(value)) {
              console.log(value);
              // Handle array values
              value.forEach((item, index) => {
                const formattedKey = encodeURIComponent(key);
                const formattedItem = encodeURIComponent(item);

                params.append(`${formattedKey}[${index}]`, formattedItem);
              });
            } else {
              // Handle non-array values
              params.append(key, value);
            }
          }
        }

        return `/v2/patient/patients/?${params.toString()}`;
      },
      // query: (query = {}) => {
      //   const { page = 1, limit = 10, tags = null,userID, ...rest } = query;
      //   const finalQuery = { page, limit,userID, ...(tags && { tags }), ...rest };

      //   let params = new URLSearchParams();
      //   if (page) {
      //     for (const [key, value] of Object.entries(finalQuery)) {
      //       params.append(key, value);
      //     }
      //   }

      //   console.log({
      //     URL: `/patients/?${params.toString()}`,
      //   });

      //   return `/v2/patient/patients/?${params.toString()}`;
      // },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Patients", id: "LIST" },
          ...data.map((p) => ({ type: "Patient", id: p._id })),
        ];
      },
    }),
    getCareProgramPatients: builder.query({
      query: (query = {}) => {
        const {
          page = 1,
          limit = 10,
          tags = null,
          userID,
          programValue,
          ...rest
        } = query;
        const finalQuery = {
          page,
          limit,
          userID,
          programValue,
          ...(tags && { tags }),
          ...rest,
        };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        console.log({
          URL: `/v2/patient/program/?${params.toString()}`,
        });

        return `/v2/patient/program/?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Patients", id: "LIST" },
          ...data.map((p) => ({ type: "Patient", id: p._id })),
        ];
      },
    }),
    getPatientByID: builder.query({
      query: (id) => `/v2/patient/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Patients", id: "LIST" },
          { type: "Patient", id: data?._id || arg },
        ];
      },
    }),
    getOnePatientDetail: builder.query({
      query: (id) => `/v2/patient/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [{ type: "Patients", id: "LIST" }];
      },
    }),
    getChatGalleryByID: builder.query({
      query: (id) => `/v2/patient/${id}/getChatGallery`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Patients", id: "LIST" },
          { type: "Patient", id: data._id || arg },
        ];
      },
    }),
    addNewPatient: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/v2/patient",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Patients", id: "LIST" }],
    }),
    activatePatient: builder.mutation({
      query: ({ id }) => {
        return { url: `/v2/patient/activate/${id}`, method: "PUT" };
      },
    }),
    deactivatePatient: builder.mutation({
      query: ({ id }) => {
        return { url: `/v2/patient/deactivate/${id}`, method: "PUT" };
      },
    }),
    editPatient: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/v2/patient/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    deletePatient: builder.mutation({
      query: (id) => {
        return {
          url: `/patients/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Patients", id: "LIST" }],
    }),
    uploadProfileImage: builder.mutation({
      query(body) {
        return {
          url: `/v2/patient/profile-image`,
          method: "POST",
          body,
        };
      },
    }),
    uploadGallery: builder.mutation({
      query({ id, body }) {
        return {
          url: `/v2/patient/${id}/uploadImages`,
          method: "POST",
          body,
        };
      },
    }),
    uploadAsset: builder.mutation({
      query(body) {
        return {
          url: `/v2/asset/upload`,
          method: "POST",
          body,
        };
      },
    }),
    sendImageLink: builder.mutation({
      query({ id, uploadBy }) {
        return {
          url: `/v2/patient/${id}/sendImageLink`,
          method: "POST",
          body: { uploadBy: uploadBy },
        };
      },
    }),
    getPatientImages: builder.query({
      query: (id) => `/v2/patient/${id}/patientImages`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [{ type: "Patients", id: "LIST" }];
      },
    }),
    updatePhone: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/v2/patient/updatePhone`,
          method: "PUT",
          body,
        };
      },
    }),
    getPatientsByHospitalId: builder.query({
      query: () => `/v2/patient/getPatientsByHospitalId`,
    }),
    onboardNewPatient: builder.mutation({
      query: (details) => {
        return {
          url: "/v2/patient/selfEnrollment",
          method: "POST",
          body: details,
        };
      },
      invalidatesTags: [{ type: "Patients", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPatientsQuery,
  useGetCareProgramPatientsQuery,
  useGetPatientByIDQuery,
  useAddNewPatientMutation,
  useEditPatientMutation,
  useDeletePatientMutation,
  useUploadProfileImageMutation,
  useActivatePatientMutation,
  useDeactivatePatientMutation,
  useUploadGalleryMutation,
  useUploadAssetMutation,
  useSendImageLinkMutation,
  useGetChatGalleryByIDQuery,
  useGetOnePatientDetailQuery,
  useGetPatientImagesQuery,
  useUpdatePhoneMutation,
  useGetPatientsByHospitalIdQuery,
  useOnboardNewPatientMutation,
} = extendedApiSlice;
