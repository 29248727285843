import {
  Box,
  Button,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import { DateField as DatePicker } from "../../Components";

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButton-root": {
    borderRadius: "0",
    padding: "4px 8px",
    fontSize: "12px",
    minHeight: "auto",
    "&:first-of-type": {
      borderTopLeftRadius: "20px",
      borderBottomLeftRadius: "20px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "20px",
      borderBottomRightRadius: "20px",
    },
    "&.Mui-selected": {
      backgroundColor: "#0049C6",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#003d6a !important",
        color: "white !important",
      },
    },
    "&:not(.Mui-selected)": {
      backgroundColor: "white",
      color: "#0049C6",
      fontWeight: "bold",
      borderColor: "blue",
    },
  },
}));
const ValuesOption = ({ selectedValue, handleChangeValue }) => {
  return (
    <CustomToggleButtonGroup
      color="primary"
      value={selectedValue}
      exclusive
      onChange={handleChangeValue}
      aria-label="Platform"
    >
      <ToggleButton value="pro">Pro Assigned Date</ToggleButton>
      <ToggleButton value="dueDate">PRO Due Date</ToggleButton>
      <ToggleButton value="procedure">Procedure Date</ToggleButton>
    </CustomToggleButtonGroup>
  );
};

export default function PopoverUI({
  id,
  open,
  anchorEl,
  handleClose,
  selectedValue,
  handleChangeValue,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleApplyChanges,
}) {
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2 }}>
          <ValuesOption {...{ selectedValue, handleChangeValue }} />
          <Box sx={{ marginTop: "20px", marginBottom: "5px" }}>
            <DatePicker
              {...{
                date: startDate,
                setDate: setStartDate,
                label: "Start Date",
                isBorder: true,
              }}
            />
          </Box>
          <DatePicker
            {...{
              date: endDate,
              setDate: setEndDate,
              label: "End Date",
              isBorder: true,
            }}
          />
        </Box>
        <Box sx={{ textAlign: "end", p: 2 }}>
          <Button
            variant="contained"
            size="medium"
            sx={{
              background: "#0049C6",
              px: "16px",
              borderRadius: "30px",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            onClick={() => {
              handleApplyChanges();
            }}
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </div>
  );
}
