import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
export default function ThankyouScreen({
  activeStep,
  setActiveStep,
  isSmallScreen,
  setIsEnrolledClicked,
  setPatientCheck,
  hospitalDetails,
}) {
  return (
    <>
      {/* <Header {...{ activeStep, setActiveStep, title:'Thank You' }} /> */}
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            srcSet={require("../../assets/thank-you-doctor.png")}
            src={require("../../assets/thank-you-doctor.png")}
            alt="Thank You doctor"
            loading="lazy"
            height={"220px"}
            width={"220px"}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {hospitalDetails?.thankYouPageTitle}
          </Typography>
          <Typography sx={{ textAlign: "center", marginTop: "12px" }}>
            {hospitalDetails?.thankYouPageDescription}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Card
            sx={{
              backgroundColor: "rgba(27, 77, 255, 0.05)",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            <CardContent>
              <Box
                component="ul"
                sx={{ paddingLeft: "20px", color: "#1B4DFF" }}
              >
                <Typography
                  component="li"
                  sx={{
                    color: "#242424",
                    listStyleType: "disc",
                    marginBottom: "10px",
                  }}
                >
                  Check your phone for a confirmation message.
                </Typography>
                <Typography
                  component="li"
                  sx={{ color: "#242424", listStyleType: "disc" }}
                >
                  Save the number from the text to ensure you receive important
                  care updates.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item>
					<Typography sx={{ textAlign: 'center', marginY: '12px' }}>We’re excited to support your health journey! If you have questions, reach out to us at</Typography>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginY: '12px' }}>
						<CallIcon sx={{ color: '#1B4DFF', fontSize: '24px', mr: 1 }} />
						<Typography variant="h5">410-601-9355</Typography>
					</Box>
				</Grid> */}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", marginY: 3 }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1B4DFF",
              borderRadius: "20px",
              width: isSmallScreen ? "90%" : "initial",
            }}
            onClick={() => {
              setActiveStep(null);
              setIsEnrolledClicked(false);
              setPatientCheck("");
            }}
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
