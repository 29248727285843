export function KIMISvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1518_19677)">
        <path
          d="M23.1057 10.5528L21.9999 10.0002C21.9999 4.4766 17.5224 0 12 0C6.477 0 1.9998 4.4766 1.9998 10.0002L0.8946 10.5528C0.4026 10.7988 0 11.4504 0 12V14.0001C0 14.5497 0.4026 15.2013 0.8946 15.4473L1.9998 15.9999H3.4998V10.0002C3.4998 5.3127 7.3131 1.5 12 1.5C16.6866 1.5 20.4999 5.3127 20.4999 10.0002V18C20.4999 20.4852 18.4854 22.5 15.9999 22.5H12.9999V21H10.9998V24H15.9999C19.3134 24 21.9999 21.3135 21.9999 18V15.9999L23.1057 15.4473C23.5977 15.2013 24 14.5497 24 14.0001V12C24 11.4504 23.5977 10.7988 23.1057 10.5528Z"
          fill="white"
        />
        <path
          d="M16 7.5H12.7501V5.3748C13.3399 5.1738 13.75 4.7478 13.75 4.2498C13.75 3.5595 12.967 3 12.0001 3C11.0332 3 10.2499 3.5595 10.2499 4.2498C10.2499 4.7478 10.66 5.175 11.2501 5.3748V7.5H7.99988C6.34388 7.5 4.99988 8.8437 4.99988 10.5V14.4999C4.99988 17.2596 7.24058 19.5 10.0003 19.5H14.0002C16.7599 19.5 19 17.2596 19 14.4999V10.5C19 8.8437 17.6563 7.5 16 7.5ZM8.50028 12.5001V11.5002C8.50028 11.3689 8.52614 11.2389 8.57639 11.1176C8.62664 10.9962 8.70029 10.886 8.79314 10.7932C8.88599 10.7003 8.99622 10.6267 9.11753 10.5764C9.23885 10.5262 9.36887 10.5003 9.50018 10.5003C9.63149 10.5003 9.76151 10.5262 9.88282 10.5764C10.0041 10.6267 10.1144 10.7003 10.2072 10.7932C10.3001 10.886 10.3737 10.9962 10.424 11.1176C10.4742 11.2389 10.5001 11.3689 10.5001 11.5002V12.5001C10.5002 12.6315 10.4743 12.7615 10.4241 12.8829C10.3739 13.0043 10.3002 13.1145 10.2073 13.2074C10.1144 13.3003 10.0041 13.3739 9.88271 13.4241C9.76132 13.4743 9.63123 13.5001 9.49988 13.5C9.36856 13.5001 9.23852 13.4743 9.11718 13.424C8.99585 13.3738 8.88561 13.3002 8.79277 13.2073C8.69993 13.1144 8.62631 13.0042 8.57612 12.8828C8.52593 12.7615 8.50016 12.6314 8.50028 12.5001ZM14.5 16.5L12.5002 17.0001H11.5003L9.49988 16.5V15.5001H14.5V16.5ZM15.5002 12.5001C15.5003 12.6315 15.4744 12.7615 15.4242 12.8829C15.374 13.0043 15.3003 13.1145 15.2074 13.2074C15.1145 13.3003 15.0042 13.3739 14.8828 13.4241C14.7614 13.4743 14.6313 13.5001 14.5 13.5C14.3686 13.5001 14.2386 13.4743 14.1172 13.4241C13.9958 13.3739 13.8855 13.3003 13.7927 13.2074C13.6998 13.1145 13.6262 13.0043 13.5759 12.8829C13.5257 12.7615 13.5 12.6314 13.5001 12.5001V11.5002C13.5001 11.235 13.6054 10.9806 13.793 10.7931C13.9805 10.6055 14.2349 10.5002 14.5001 10.5002C14.7654 10.5002 15.0197 10.6055 15.2073 10.7931C15.3948 10.9806 15.5002 11.235 15.5002 11.5002V12.5001Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1518_19677">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
