import React from 'react'

export function Journey({color}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2 4C2.0001 3.62595 2.10508 3.25943 2.30304 2.94206C2.50099 2.62469 2.78398 2.36919 3.11986 2.20458C3.45574 2.03998 3.83104 1.97286 4.20316 2.01085C4.57527 2.04885 4.92927 2.19043 5.22495 2.41953C5.52063 2.64862 5.74614 2.95604 5.87586 3.30687C6.00559 3.6577 6.03433 4.03787 5.95883 4.40422C5.88333 4.77057 5.7066 5.1084 5.44873 5.37934C5.19086 5.65029 4.86217 5.84349 4.5 5.937V11.5C4.5 11.8978 4.65804 12.2794 4.93934 12.5607C5.22064 12.842 5.60218 13 6 13C6.39782 13 6.77936 12.842 7.06066 12.5607C7.34196 12.2794 7.5 11.8978 7.5 11.5V4.5C7.5 3.83696 7.76339 3.20107 8.23223 2.73223C8.70107 2.26339 9.33696 2 10 2C10.663 2 11.2989 2.26339 11.7678 2.73223C12.2366 3.20107 12.5 3.83696 12.5 4.5V7.5H11.5V4.5C11.5 4.10218 11.342 3.72064 11.0607 3.43934C10.7794 3.15804 10.3978 3 10 3C9.60218 3 9.22064 3.15804 8.93934 3.43934C8.65804 3.72064 8.5 4.10218 8.5 4.5V11.5C8.5 12.163 8.23661 12.7989 7.76777 13.2678C7.29893 13.7366 6.66304 14 6 14C5.33696 14 4.70107 13.7366 4.23223 13.2678C3.76339 12.7989 3.5 12.163 3.5 11.5V5.937C3.0706 5.82613 2.69024 5.57569 2.41871 5.22505C2.14718 4.87442 1.99989 4.44348 2 4ZM10 10.5C10 9.96957 10.2107 9.46086 10.5858 9.08579C10.9609 8.71071 11.4696 8.5 12 8.5C12.5304 8.5 13.0391 8.71071 13.4142 9.08579C13.7893 9.46086 14 9.96957 14 10.5C14 11.0899 13.8577 11.6712 13.5853 12.1945C13.3128 12.7177 12.9183 13.1676 12.435 13.506L12 13.81L11.565 13.506C11.0817 13.1676 10.6872 12.7177 10.4147 12.1945C10.1423 11.6712 10 11.0899 10 10.5Z"
				fill={color}
			/>
		</svg>
	)
}
