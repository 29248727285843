import React from 'react'

export function Note({color}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				d="M13 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H9.79313C9.92446 14.0002 10.0545 13.9744 10.1759 13.9241C10.2972 13.8738 10.4074 13.8 10.5 13.7069L13.7069 10.5C13.8 10.4074 13.8738 10.2972 13.9241 10.1759C13.9744 10.0545 14.0002 9.92446 14 9.79313V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2ZM6 5.5H10C10.1326 5.5 10.2598 5.55268 10.3536 5.64645C10.4473 5.74021 10.5 5.86739 10.5 6C10.5 6.13261 10.4473 6.25979 10.3536 6.35355C10.2598 6.44732 10.1326 6.5 10 6.5H6C5.86739 6.5 5.74021 6.44732 5.64645 6.35355C5.55268 6.25979 5.5 6.13261 5.5 6C5.5 5.86739 5.55268 5.74021 5.64645 5.64645C5.74021 5.55268 5.86739 5.5 6 5.5ZM8 10.5H6C5.86739 10.5 5.74021 10.4473 5.64645 10.3536C5.55268 10.2598 5.5 10.1326 5.5 10C5.5 9.86739 5.55268 9.74021 5.64645 9.64645C5.74021 9.55268 5.86739 9.5 6 9.5H8C8.13261 9.5 8.25979 9.55268 8.35355 9.64645C8.44732 9.74021 8.5 9.86739 8.5 10C8.5 10.1326 8.44732 10.2598 8.35355 10.3536C8.25979 10.4473 8.13261 10.5 8 10.5ZM6 8.5C5.86739 8.5 5.74021 8.44732 5.64645 8.35355C5.55268 8.25979 5.5 8.13261 5.5 8C5.5 7.86739 5.55268 7.74021 5.64645 7.64645C5.74021 7.55268 5.86739 7.5 6 7.5H10C10.1326 7.5 10.2598 7.55268 10.3536 7.64645C10.4473 7.74021 10.5 7.86739 10.5 8C10.5 8.13261 10.4473 8.25979 10.3536 8.35355C10.2598 8.44732 10.1326 8.5 10 8.5H6ZM10 12.7931V10H12.7937L10 12.7931Z"
				fill={color}
			/>
		</svg>
	)
}
