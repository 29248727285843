import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { CircularLoader, DeleteModal } from "../../../Components";
import { EditSvg } from "../../../assets/svgs";
import { useDeleteHospitalMutation } from "../../../store/hospitalsSlice";
import { hasScope } from "../../../utils";
import { EditHospitalDrawer } from "./EditHospitalDrawer";

const columns = [
  // { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "right",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const createRows = (
  hospitals,
  handleOpenModal,
  toggleDrawer,
  handleDelete,
  openModal,
  setOpenModal,
  openEditModal,
  datas,
  setOpenEditModal,
  refetchAgain,
  scopes,
) => {
  const rows = hospitals.map(({ _id, name, config }, index) => {
    const getConsentMessage = config && config.getConsentMessage;
    const messageAfterConsentDeclined =
      config && config.messageAfterConsentDeclined;
    const consentApprovedMessage = config && config.consentApprovedMessage;
    const message = config && config?.offHourMessage;
    const activeHour = config && config?.activeHours;
    const featureFlags = config && config?.featureFlags;
    const replyToDeactivatedPatients =
      config && config?.replyToDeactivatedPatient;
    const messageToDeactivatedPatients =
      config && config?.messageToDeactivatedPatient;
    const executePastNodess = config && config?.executePastNodes;
    const replyOnlyOnLowAcuitys = config && config?.replyOnLowAcuity;
    const imageUploadNotifierss = config && config?.imageUploadNotifiers;
    const disableDialogues = config && config?.disableDialogue;
    const prescriptionSubscriberss = config && config?.prescriptionSubscribers;

    return {
      // Srno: (
      //   <Typography
      //     variant="p"
      //     sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
      //   >
      //     {index + 1}
      //   </Typography>
      // ),
      name: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              color: "black",
              padding: "7px",
            }}
          >
            {name}
          </Typography>
        </>
      ),
      actions: (
        <>
          <Stack spacing={3} direction="row" justifyContent="center">
            {hasScope(scopes, "settings/sites:edit") && (
              <div
                onClick={toggleDrawer({
                  open: true,
                  name,
                  id: _id,
                  getConsentMessage,
                  messageAfterConsentDeclined,
                  consentApprovedMessage,
                  message,
                  activeHour,
                  featureFlags,
                  replyToDeactivatedPatients,
                  messageToDeactivatedPatients,
                  executePastNodess,
                  replyOnlyOnLowAcuitys,
                  imageUploadNotifierss,
                  disableDialogues,
                  prescriptionSubscriberss,
                })}
                style={{ cursor: "pointer" }}
              >
                <EditSvg />
              </div>
            )}
            {/* <div
                  style={{ cursor: "pointer" }}
                  onClick={(event) => handleOpenModal(event, name, _id)}
                >
                  <DeleteSvg />
                </div> */}
          </Stack>

          <DeleteModal
            open={openModal}
            setOpen={setOpenModal}
            id={datas}
            handle={handleDelete}
          />
          {openEditModal.open && (
            <EditHospitalDrawer
              open={openEditModal.open}
              setOpen={setOpenEditModal}
              names={openEditModal.name}
              consentMessage={openEditModal.getConsentMessage}
              approvedMessage={openEditModal.consentApprovedMessage}
              messageDeclined={openEditModal.messageAfterConsentDeclined}
              id={openEditModal.id}
              activeHour={openEditModal?.activeHour}
              featureFlags={openEditModal?.featureFlags}
              messages={openEditModal?.message}
              refetchAgain={refetchAgain}
              replyToDeactivatedPatients={
                openEditModal?.replyToDeactivatedPatients
              }
              messageToDeactivatedPatients={
                openEditModal?.messageToDeactivatedPatients
              }
              executePastNodess={openEditModal?.executePastNodess}
              replyOnlyOnLowAcuitys={openEditModal?.replyOnlyOnLowAcuitys}
              imageUploadNotifierss={openEditModal?.imageUploadNotifierss}
              disableDialogues={openEditModal?.disableDialogues}
              prescriptionSubscriberss={openEditModal?.prescriptionSubscriberss}
            />
          )}
        </>
      ),
    };
  });

  return rows;
};

export function HospitalsTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  searchTerm,
  refetchAgain,
  scopes,
}) {
  // States
  const [openModal, setOpenModal] = React.useState(false);

  const [datas, setData] = React.useState("");
  const [openEditModal, setOpenEditModal] = React.useState({
    open: false,
    id: null,
    name: "",
    getConsentMessage: "",
    consentApprovedMessage: "",
    messageAfterConsentDeclined: "",
    replyToDeactivatedPatients: "",
    messageToDeactivatedPatients: "",
    executePastNodess: false,
    featureFlags: [],
    replyOnlyOnLowAcuitys: false,
    disableDialogues: false,
    imageUploadNotifierss: [],
    prescriptionSubscriberss: [],
  });

  const toggleDrawer = (open) => (event) => {
    event.stopPropagation();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenEditModal({
      open: open.open,
      name: open.name,
      id: open.id,
      getConsentMessage: open.getConsentMessage,
      messageAfterConsentDeclined: open.messageAfterConsentDeclined,
      consentApprovedMessage: open.consentApprovedMessage,
      message: open.message,
      activeHour: open.activeHour,
      featureFlags: open?.featureFlags,
      replyToDeactivatedPatients: open?.replyToDeactivatedPatients,
      messageToDeactivatedPatients: open?.messageToDeactivatedPatients,
      executePastNodess: open?.executePastNodess,
      replyOnlyOnLowAcuitys: open?.replyOnlyOnLowAcuitys,
      imageUploadNotifierss: open?.imageUploadNotifierss,
      disableDialogues: open?.disableDialogues,
      prescriptionSubscriberss: open?.prescriptionSubscriberss,
    });
  };

  // Mutation:
  const [deleteHospital] = useDeleteHospitalMutation();

  // Functions:
  const handleOpenModal = (event, name, id) => {
    setData(id);
    setOpenModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (id) => {
    await deleteHospital(id).unwrap();
  };

  const hospitals = data ? data.data : [];

  const rows = createRows(
    hospitals,
    handleOpenModal,
    toggleDrawer,
    handleDelete,
    openModal,
    setOpenModal,
    openEditModal,
    datas,
    setOpenEditModal,
    refetchAgain,
    scopes,
  );

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return (
      <Typography sx={{ margin: "10px 20px" }}>No Sites found.</Typography>
    );
  }

  return (
    <>
      <TableContainer sx={{ mb: 3 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align={"center"}
                  sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <PaginationBar
        {...{ page, count: data.totalPages, handleChange: handleChangePage }}
      /> */}
    </>
  );
}
