import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChatCard from "./DefaultCards";

export const Chat = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery("(min-width: 2160px)");
  const ExampleData = [
    {
      value:
        "What should a patient do if they miss a dose of their medication?",
    },
    {
      value: "What could be the possible causes of persistent headaches?",
    },
    { value: "What lifestyle changes can help manage high cholesterol?" },
  ];

  const CapabilitiesData = [
    {
      value:
        "Access to up-to-date and comprehensive medical databases and literature.",
    },
    {
      value:
        "Assist users in understanding potential causes of their symptoms.",
    },
    {
      value:
        "Evaluate and communicate potential health risks based on user input",
    },
  ];

  const LimitationsData = [
    {
      value:
        "As an AI, the chatbot may sometimes provide incorrect or incomplete information",
    },
    { value: "Might create harmful or biased content at times" },
    {
      value:
        "The chatbot provides informational support and is not a substitute for professional medical advice, diagnosis, or treatment.",
    },
  ];
  return (
    <Grid
      container
      spacing={2}
      className="ScrollDesign"
      sx={{
        height: "100%",
        overflowY: "auto",
        backgroundColor: "transparent",
        px: 3,
        pb: 1,
      }}
    >
      {console.log(isLargeScreen)}

      <Grid
        item
        md={12}
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "Column",
          alignItems: "center",
        }}
      >
        <img
          src={require("../../assets/RevelAi-03.png")}
          width={100}
          height={100}
        />
        {/* <Typography color="white" textAlign={"center"} fontWeight={"bold"}>
          RevelAi Health
        </Typography> */}
        <Typography color="#343434" textAlign={"center"} width={"60%"}>
          Explore Deeper Insights, Engage in Meaningful Discussions, and Unlock
          New Possibilities with RevelAi Health.
        </Typography>
      </Grid>
      {/* <Grid item md={4}>
				<ChatCard title={'Example'} data={ExampleData} icon={ReorderIcon} />
			</Grid> */}
      <Grid item md={6}>
        <ChatCard
          title={"Capabilities"}
          data={CapabilitiesData}
          icon={RocketLaunchOutlinedIcon}
        />
      </Grid>
      <Grid item md={6}>
        <ChatCard
          title={"Limitations"}
          data={LimitationsData}
          icon={WarningAmberOutlinedIcon}
        />
      </Grid>
    </Grid>
  );
};
