import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query({
      query: (query = {}) => {
        const { userID, page = 1, limit = 10, patientId, ...rest } = query;
        const finalQuery = { userID, patientId, page, limit, ...rest };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/tasks/?${params.toString()}`;
      },

      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "tasks", id: "LIST" },
          ...data.map((p) => ({ type: "task", id: p._id })),
        ];
      },
    }),
    getTaskByID: builder.query({
      query: ({ userID, taskID }) => {
        const params = new URLSearchParams();
        params.append("userID", userID);

        console.log({
          URLSSSS__taskID: taskID,
        });
        return {
          url: `/tasks/${taskID}/?${params.toString()}`,
          method: "GET",
        };
      },
      providesTags: (data, error, arg) => {
        return [
          { type: "tasks", id: "LIST" },
          { type: "task", id: data?.data?._id || arg || "" },
        ];
      },
    }),
    addNewTask: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/tasks",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "tasks", id: "LIST" }],
    }),
    editTask: builder.mutation({
      query: ({ body, userID, taskID }) => {
        return {
          url: `/tasks/${userID}/${taskID}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "tasks", id: "LIST" },
          { type: "task", id: data.id || id },
        ];
      },
    }),
    deleteTask: builder.mutation({
      query: ({ userID, taskID }) => {
        return {
          url: `/tasks/${userID}/${taskID}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "tasks", id: "LIST" }],
    }),
    getAllTasks: builder.query({
      query: (id) => `/tasks/task/${id}`,
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Tasks", id: "LIST" },
          ...data.map((p) => ({ type: "Tasks", id: p._id })),
        ];
      },
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetAllTasksQuery,
  useGetTaskByIDQuery,
  useAddNewTaskMutation,
  useEditTaskMutation,
  useDeleteTaskMutation,
} = extendedApiSlice;
