import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useIsMobile } from "../../contexts/isMobile";
import { useIsHovered } from "../../contexts/isHovered";
import SelectField from "./FilterUtils/SelectField";
import { useEffect, useState } from "react";
import { useChildNode } from "../../contexts/addChildNode";
import { useLocation } from "react-router-dom";
import { addInParams } from "../../utils";

export function FilterSidebar({
  data,
  queriesValue,
  setQueriesValue,
  isNotDashboard,
  setSelectedValue,
  page,
  setPage,
  setSortTaskCategoryOrder,
  setSortPhysicianNameOrder,
  setSortProcedureTypeOrder,
  setSortQueueTypeOrder,
  setSortSocialNeedsOrder,
  setSortContactTimeOrder,
  setSortField,
  setSortOrder,
  setSortPatientOrder,
}) {
  const isMobile = useIsMobile();
  const { hovered } = useIsHovered();
  const { setSearchTerm } = useChildNode();
  const [surgeonValue, setSurgeonValue] = useState(
    localStorage?.getItem("surgeon")
      ? JSON.parse(localStorage?.getItem("surgeon"))
      : [],
  );
  const [taskCategoryValue, setTaskCategoryValue] = useState(
    localStorage?.getItem("taskCategory")
      ? JSON.parse(localStorage?.getItem("taskCategory"))
      : [],
  );
  const [procedureTypeValue, setProcedureTypeValue] = useState(
    localStorage?.getItem("surgeryType")
      ? JSON.parse(localStorage?.getItem("surgeryType"))
      : [],
  );
  const [careProgramValue, setCareProgramValue] = useState(
    localStorage?.getItem("program")
      ? JSON.parse(localStorage?.getItem("program"))
      : [],
  );
  const [clinicValue, setClinicValue] = useState(
    localStorage?.getItem("clinic")
      ? JSON.parse(localStorage?.getItem("clinic"))
      : [],
  );
  const [assignValue, setAssignValue] = useState(
    localStorage?.getItem("assignee")
      ? JSON.parse(localStorage?.getItem("assignee"))
      : [],
  );

  const [selectedPatientStatus, setSelectedPatientStatus] = useState("pending");
  const [checkboxStates, setCheckboxStates] = useState({});

  // const { data: userData, isLoading } = useGetUserForTaskQuery();
  // const userArr = userData?.data || [];

  const location = useLocation();

  // Create a URLSearchParams object from the location search string
  const queryParams = new URLSearchParams(location.search);

  const handleDelete = (label, value) => {
    let updatedState;
    if (label === "surgeon" || label === "surgeon1") {
      updatedState = surgeonValue.some((item) => item.value === value.value)
        ? surgeonValue.filter((item) => item.value !== value.value) // Remove if value exists
        : surgeonValue;
      setSurgeonValue(updatedState);
      addInParams("surgeon", updatedState.map((item) => item.value).join(","));
      localStorage.removeItem(label);
    } else if (label === "taskCategory" || label === "taskCategory1") {
      updatedState = taskCategoryValue.some(
        (item) => item.value === value.value,
      )
        ? taskCategoryValue.filter((item) => item.value !== value.value) // Remove if value exists
        : taskCategoryValue;
      setTaskCategoryValue(updatedState);
      addInParams(
        "taskCategory",
        updatedState.map((item) => item.value).join(","),
      );
      localStorage.removeItem(label);
    } else if (label === "surgeryType" || label === "surgeryType1") {
      updatedState = procedureTypeValue.some(
        (item) => item.value === value.value,
      )
        ? procedureTypeValue.filter((item) => item.value !== value.value) // Remove if value exists
        : procedureTypeValue;
      setProcedureTypeValue(updatedState);
      addInParams(
        "surgeryType",
        updatedState.map((item) => item.value).join(","),
      );
      localStorage.removeItem(label);
    } else if (label === "program" || label === "program1") {
      updatedState = careProgramValue.some((item) => item.value === value.value)
        ? careProgramValue.filter((item) => item.value !== value.value) // Remove if value exists
        : careProgramValue;
      setCareProgramValue(updatedState);
      addInParams("program", updatedState.map((item) => item.value).join(","));
      localStorage.removeItem(label);
    } else if (label === "clinic" || label === "clinic1") {
      updatedState = clinicValue.some((item) => item.value === value.value)
        ? clinicValue.filter((item) => item.value !== value.value) // Remove if value exists
        : clinicValue;
      setClinicValue(updatedState);
      addInParams("clinic", updatedState.map((item) => item.value).join(","));
      localStorage.removeItem(label);
    } else if (label === "assignee") {
      updatedState = assignValue.some((item) => item.value === value.value)
        ? assignValue.filter((item) => item.value !== value.value) // Remove if value exists
        : assignValue;
      setAssignValue(updatedState);
      addInParams("assignee", updatedState.map((item) => item.value).join(","));
      localStorage.removeItem(label);
    }

    const filteredObject = {
      ...queriesValue,
      [label]: updatedState,
    };
    // Set the queries value using the filtered object
    setQueriesValue(filteredObject);

    localStorage.setItem(
      isNotDashboard ? "queriesValue1" : "queriesValue",
      JSON.stringify(filteredObject),
    );
  };

  const deleteSurgeon = () => {
    setSurgeonValue([]);
  };

  const handleChangPatientStatus = (event, item, key) => {
    const { checked } = event.target;
    const filteredObject = { ...queriesValue };

    filteredObject["status"] = item.value;

    setSelectedPatientStatus(item?.value);
    setQueriesValue(filteredObject);

    // Update the URL with the new status value
    const url = new URL(window.location);
    url.searchParams.set("status", item.value);
    window.history.pushState({}, "", url);
    localStorage.setItem(
      isNotDashboard ? "selectedPatientStatus1" : "selectedPatientStatus",
      item?.value,
    );
    localStorage.setItem(
      isNotDashboard ? "queriesValue1" : "queriesValue",
      JSON.stringify(filteredObject),
    );
  };

  const handleCheckboxChange = (event, item, key) => {
    const { checked } = event.target;
    const filteredObject = { ...queriesValue };

    const updatedCheckboxStates = {
      ...checkboxStates,
      [key]: {
        ...checkboxStates[key],
        [item.title]: checked,
      },
    };

    // All the logic used in setQueriesValue
    if (Array.isArray(filteredObject[key])) {
      if (!checked) {
        let updatedArray;
        if (key === "surgeryType") {
          updatedArray = filteredObject[key].filter((val) => val !== item.id);
        } else {
          updatedArray = filteredObject[key].filter(
            (val) => val !== item.value,
          );
        }

        if (updatedArray.length === 0) {
          delete filteredObject[key];
        } else {
          filteredObject[key] = updatedArray;
        }
        addInParams(key, updatedArray.length !== 0 && updatedArray);
      } else if (key === "surgeryType") {
        filteredObject[key] = [...filteredObject[key], item.id];
      } else {
        filteredObject[key] = [...filteredObject[key], item.value];
        addInParams(
          key,
          [...filteredObject[key]].map((task) => task).join(","),
        );
      }
    } else if (filteredObject[key]) {
      if (checked && key === "surgeryType") {
        filteredObject[key] = [filteredObject[key], item.id];
      } else if (checked) {
        filteredObject[key] = [filteredObject[key], item.value];
      } else if (!checked && key === "surgeryType") {
        filteredObject[key] =
          filteredObject[key] === item.id ? null : filteredObject[key];
      } else {
        filteredObject[key] =
          filteredObject[key] === item.value ? null : filteredObject[key];
      }
    } else if (key === "surgeryType") {
      filteredObject[key] = item.id;
    } else {
      filteredObject[key] = item.value;
      addInParams(key, item.value);
    }

    setCheckboxStates(updatedCheckboxStates);
    // Set the queries value using the filtered object
    setQueriesValue(filteredObject);

    localStorage.setItem(
      isNotDashboard ? "queriesValue1" : "queriesValue",
      JSON.stringify(filteredObject),
    );
    localStorage.setItem(
      isNotDashboard ? "checkboxStates1" : "checkboxStates",
      JSON.stringify(updatedCheckboxStates),
    );
  };

  const handleChange = (event, fieldKey) => {
    const { value } = event.target;
    let updatedState;

    if (fieldKey === "taskCategory" || fieldKey === "taskCategory1") {
      updatedState = taskCategoryValue.some(
        (item) => item.value === value.value,
      )
        ? taskCategoryValue
        : [...taskCategoryValue, value];
      setTaskCategoryValue(updatedState);
      console.log(
        "UpDate State : ********",
        updatedState.map((task) => task.value).join(","),
      );
      addInParams(fieldKey, updatedState.map((task) => task.value).join(","));
    } else if (fieldKey === "surgeryType" || fieldKey === "surgeryType1") {
      updatedState = procedureTypeValue.some(
        (item) => item.value === value.value,
      )
        ? procedureTypeValue
        : [...procedureTypeValue, value];
      setProcedureTypeValue(updatedState);
      addInParams(fieldKey, updatedState.map((task) => task.value).join(","));
    } else if (fieldKey === "program" || fieldKey === "program1") {
      updatedState = careProgramValue.some((item) => item.value === value.value)
        ? careProgramValue
        : [...careProgramValue, value];
      setCareProgramValue(updatedState);
      addInParams(fieldKey, updatedState.map((task) => task.value).join(","));
    } else if (fieldKey === "clinic" || fieldKey === "clinic1") {
      updatedState = clinicValue.some((item) => item.value === value.value)
        ? clinicValue
        : [...clinicValue, value];
      setClinicValue(updatedState);
      addInParams(fieldKey, updatedState.map((task) => task.value).join(","));
    } else if (fieldKey === "surgeon" || fieldKey === "surgeon1") {
      updatedState = surgeonValue.some((item) => item.value === value.value)
        ? surgeonValue
        : [...surgeonValue, value];
      setSurgeonValue(updatedState);
      addInParams(fieldKey, updatedState.map((task) => task.value).join(","));
    } else if (fieldKey === "assignee") {
      updatedState = assignValue.some((item) => item.value === value.value)
        ? assignValue
        : [...assignValue, value];
      setAssignValue(updatedState);
      addInParams(fieldKey, updatedState.map((task) => task.value).join(","));
      console.log(updatedState);
    }

    const filteredObject = {
      ...queriesValue,
      [fieldKey]: updatedState,
    };
    // Set the queries value using the filtered object
    setQueriesValue(filteredObject);

    localStorage.setItem(
      isNotDashboard ? "queriesValue1" : "queriesValue",
      JSON.stringify(filteredObject),
    );
    localStorage.setItem(fieldKey, JSON.stringify(updatedState));
  };

  const handleClear = () => {
    // Reset states to their default values
    setSearchTerm("");
    setSurgeonValue([]);
    setTaskCategoryValue([]);
    setProcedureTypeValue([]);
    setCareProgramValue([]);
    setClinicValue([]);
    setSelectedPatientStatus("pending");
    setCheckboxStates({});
    setQueriesValue({});
    setSelectedValue("all");
    setAssignValue([]);
    setPage(1);
    setSortTaskCategoryOrder({ field: "taskCategory", sort: "asc" });
    setSortPhysicianNameOrder({ field: "surgeonName", sort: "asc" });
    setSortProcedureTypeOrder({ field: "procedureType", sort: "asc" });
    setSortQueueTypeOrder({ field: "queueType", sort: "asc" });
    setSortSocialNeedsOrder({ field: "socialNeeds", sort: "asc" });
    setSortContactTimeOrder({ field: "contactTime", sort: "asc" });
    setSortField("");
    setSortOrder("");
    setSortPatientOrder({ field: "patientName", sort: "asc" });

    const url = new URL(window.location);

    // Clear all existing query parameters
    url.search = ""; // This removes all query parameters

    // Set specific query parameters
    url.searchParams.set("page", "1");
    url.searchParams.set("status", "pending");

    // Update the URL without reloading the page
    window.history.replaceState({}, "", url);

    // Remove the respective items from local storage
    if (isNotDashboard) {
      localStorage.removeItem("taskCategory1");
      localStorage.removeItem("surgeryType1");
      localStorage.removeItem("program1");
      localStorage.removeItem("clinic1");
      localStorage.removeItem("selectedPatientStatus1");
      localStorage.removeItem("checkboxStates1");
      localStorage.removeItem("queriesValue1");
      localStorage.removeItem("selectedValue1");
      localStorage.removeItem("createdAfter1");
      localStorage.removeItem("sortTaskCategoryOrder1");
      localStorage.removeItem("sortPhysicianNameOrder1");
      localStorage.removeItem("sortProcedureTypeOrder1");
      localStorage.removeItem("sortQueueTypeOrder1");
      localStorage.removeItem("sortSocialNeedsOrder1");
      localStorage.removeItem("sortContactTimeOrder1");
      localStorage.removeItem("sortField1");
      localStorage.removeItem("sortOrder1");
      localStorage.removeItem("sortPatientOrder1");
      localStorage.setItem("page", 1);
      localStorage.removeItem("assignee");
    } else {
      localStorage.removeItem("taskCategory");
      localStorage.removeItem("surgeryType");
      localStorage.removeItem("program");
      localStorage.removeItem("clinic");
      localStorage.removeItem("selectedPatientStatus");
      localStorage.removeItem("checkboxStates");
      localStorage.removeItem("queriesValue");
      localStorage.removeItem("selectedValue");
      localStorage.removeItem("createdAfter");
      localStorage.removeItem("sortTaskCategoryOrder");
      localStorage.removeItem("sortPhysicianNameOrder");
      localStorage.removeItem("sortProcedureTypeOrder");
      localStorage.removeItem("sortQueueTypeOrder");
      localStorage.removeItem("sortSocialNeedsOrder");
      localStorage.removeItem("sortContactTimeOrder");
      localStorage.removeItem("sortField");
      localStorage.removeItem("sortOrder");
      localStorage.removeItem("sortPatientOrder");
      localStorage.removeItem("assignee");
      localStorage.setItem("page1", 1);
    }
  };

  // useEffect(() => {
  // 	handleClear()
  // }, [])

  function areObjectsNotEqual(obj1, obj2) {
    // Check if both are the same reference
    if (obj1 === obj2) return false;

    // Check if both are objects and not null
    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 === null ||
      obj2 === null
    ) {
      return true; // If one is an object and the other is not, they are not equal
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if they have the same number of keys
    if (keys1.length !== keys2.length) return true; // Not equal if different number of keys

    // Check each key and value recursively
    for (const key of keys1) {
      if (!keys2.includes(key) || areObjectsNotEqual(obj1[key], obj2[key])) {
        return true; // Not equal if keys don't match or values are not equal
      }
    }

    return false; // If all checks passed, they are equal
  }

  useEffect(() => {
    const labelValue = isNotDashboard ? "queriesValue1" : "queriesValue";
    const typeLocalStorage =
      localStorage.getItem(labelValue) &&
      JSON.parse(localStorage.getItem(labelValue));
    let newObj = {
      taskCategory: taskCategoryValue,
      surgeon: surgeonValue,
      surgeryType: procedureTypeValue,
      program: careProgramValue,
      status: selectedPatientStatus,
      assignee: assignValue, // Add here if needed
    };
    if (
      checkboxStates?.acuity &&
      Object.keys(checkboxStates?.acuity).length !== 0
    ) {
      newObj = {
        ...newObj,
        acuity: data["acuity"]?.value
          ?.filter((item) => checkboxStates?.acuity[item?.title])
          ?.map((item) => item?.value),
      };
    }

    if (typeLocalStorage) {
      newObj = { ...newObj, types: typeLocalStorage?.types };
    }

    setQueriesValue(newObj);
  }, [
    taskCategoryValue,
    surgeonValue,
    procedureTypeValue,
    careProgramValue,
    selectedPatientStatus,
    checkboxStates,
    assignValue,
  ]);

  useEffect(() => {
    const suffix = isNotDashboard ? "1" : "";

    const getItemWithSuffix = (key) => {
      const item = localStorage.getItem(key + suffix);
      return item ? JSON.parse(item) : [];
    };

    const getSimpleItemWithSuffix = (key, defaultValue) => {
      const item = localStorage.getItem(key + suffix);
      return item ? item : defaultValue;
    };

    setSurgeonValue(getItemWithSuffix("surgeon"));
    setTaskCategoryValue(getItemWithSuffix("taskCategory"));
    setProcedureTypeValue(getItemWithSuffix("surgeryType"));
    setCareProgramValue(getItemWithSuffix("program"));
    setClinicValue(getItemWithSuffix("clinic"));
    setAssignValue(getItemWithSuffix("assignee", [])); // Add this line
    setSelectedPatientStatus(
      getSimpleItemWithSuffix("selectedPatientStatus", "pending"),
    );
    setCheckboxStates(getItemWithSuffix("checkboxStates") || {});
  }, [isNotDashboard]);

  useEffect(() => {
    const queryStatus = queryParams.get("status") || "pending";
    const queryAcuity = queryParams?.get("acuity")?.split(",") || [];
    const queryAssignee = queryParams.get("assignee")?.split(",") || [];

    const acuityObject = {
      acuity: {
        High: queryAcuity?.includes("high"),
        Low: queryAcuity?.includes("low"),
        Medium: queryAcuity?.includes("medium"),
        "N/A": queryAcuity?.includes("N/A"),
      },
    };

    console.log(queryAcuity, "queryAcuity");

    setSelectedPatientStatus(queryStatus);
    setCheckboxStates(acuityObject);
    localStorage.setItem("selectedPatientStatus", queryStatus);
    localStorage.setItem(
      isNotDashboard ? "checkboxStates1" : "checkboxStates",
      JSON.stringify(acuityObject),
    );
  }, []);

  useEffect(() => {
    const oldLocalStorage = localStorage.getItem("checkboxStates")
      ? JSON.parse(localStorage.getItem("checkboxStates"))
      : { acuity: {} };

    if (areObjectsNotEqual(oldLocalStorage?.acuity, checkboxStates?.acuity)) {
      setCheckboxStates(oldLocalStorage);
    }
  }, [queriesValue]);

  useEffect(() => {
    setAssignValue(
      localStorage?.getItem("assignee")
        ? JSON.parse(localStorage?.getItem("assignee"))
        : [],
    );
  }, [localStorage?.getItem("assignee")]);

  return (
    <>
      <Stack
        direction="column"
        justifyContent="left"
        sx={{
          height: "100%",
          alignItems: isMobile ? "baseline" : "left",
          position: "fixed",
          bgcolor: "white",
          paddingLeft: !hovered && "12px",
          mt: "1.5rem",
          pl: "1.2rem",
          width: "15%",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for WebKit browsers
          },
          scrollbarWidth: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              textTransform: "uppercase",
              fontWeight: "600",
              mt: "20px",
            }}
          >
            Filters
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              sx={{ mt: "20px", mr: "10px" }}
              size="small"
              onClick={() => handleClear()}
            >
              Clear
            </Button>
            {/* <Button variant="contained" sx={{ mt: "20px" }} size="small">
              Save
            </Button> */}
          </Box>
        </Box>

        {/* Saved Filters */}
        {/* <Box sx={{ padding: "10px", mt: "10px" }}>
          <SavedFilterAccordion />
        </Box> */}

        {/* Acuity */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Acuity</Typography>
          {data["acuity"]?.value?.map((item) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Checkbox
                size="small"
                value={item.title}
                checked={checkboxStates["acuity"]?.[item.title] || false}
                onChange={(event) =>
                  handleCheckboxChange(event, item, "acuity")
                }
              />
              <Typography> {item.title} </Typography>
            </Box>
          ))}
        </Box>

        {/* Status */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Status</Typography>
          {data["status"]?.value?.map((item, index) => (
            <RadioGroup
              aria-label="status"
              name="status"
              value={selectedPatientStatus}
              onChange={(event) =>
                handleChangPatientStatus(event, item, "status")
              }
              sx={{ px: 2 }}
            >
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item?.title}
              />
            </RadioGroup>
          ))}
        </Box>

        {/* Surgeons */}
        {/* <Box sx={{ mt: "10px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography>Surgeons</Typography>
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ cursor: "pointer", mr: "10px" }}
              onClick={()=> deleteSurgeon()}
            />
          </Box>

          <SelectField label="Select" showValue={surgeonValue}  setShowValue={setSurgeonValue} data={data['surgeon']?.value}  onClick={handleChange} fieldKey={"surgeon"} />
            {surgeonValue?.map((item)=>(
          <Chip label={item.title} variant="outlined" onDelete={()=>handleDelete('surgeon', item)} sx={{mr:'3px'}} />
            ))}
        </Box> */}

        {/* Task Category */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Task Category</Typography>

          <SelectField
            label="All"
            showValue={taskCategoryValue}
            setShowValue={setTaskCategoryValue}
            data={data["taskCategory"]?.value}
            onClick={handleChange}
            fieldKey={isNotDashboard ? "taskCategory1" : "taskCategory"}
          />

          {taskCategoryValue?.map((item) => (
            <Chip
              label={item?.title}
              variant="outlined"
              onDelete={() =>
                handleDelete(
                  isNotDashboard ? "taskCategory1" : "taskCategory",
                  item,
                )
              }
              sx={{ mr: "3px" }}
            />
          ))}
        </Box>

        {/*  Procedure Type */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Procedure Type</Typography>

          <SelectField
            label="Select Type"
            showValue={procedureTypeValue}
            setShowValue={setProcedureTypeValue}
            data={data["surgeryType"]?.value}
            onClick={handleChange}
            fieldKey={isNotDashboard ? "surgeryType1" : "surgeryType"}
          />

          {procedureTypeValue?.map((item) => (
            <Chip
              label={item?.title}
              variant="outlined"
              onDelete={() =>
                handleDelete(
                  isNotDashboard ? "surgeryType1" : "surgeryType",
                  item,
                )
              }
              sx={{ mr: "3px" }}
            />
          ))}
        </Box>

        {/*  Care Program */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Care Pathways</Typography>

          <SelectField
            label="Select Type"
            showValue={careProgramValue}
            setShowValue={setCareProgramValue}
            data={data["program"]?.value}
            onClick={handleChange}
            fieldKey={isNotDashboard ? "program1" : "program"}
          />

          {careProgramValue?.map((item) => (
            <Chip
              label={item?.title}
              variant="outlined"
              onDelete={() =>
                handleDelete(isNotDashboard ? "program1" : "program", item)
              }
              sx={{ mr: "3px" }}
            />
          ))}
        </Box>
        {/*  Clinic */}
        {/* <Box sx={{ mt: '10px' }}>
					<Typography>Clinic</Typography>

					<SelectField
						label="Select Clinic"
						showValue={clinicValue}
						setShowValue={setClinicValue}
						data={data['clinic']?.value}
						onClick={handleChange}
						fieldKey={isNotDashboard ? 'clinic1' : 'clinic'}
					/>

					{clinicValue?.map((item) => (
						<Chip label={item?.title} variant="outlined" onDelete={() => handleDelete(isNotDashboard ? 'clinic1' : 'clinic', item)} sx={{ mr: '3px' }} />
					))}
				</Box> */}

        {/*  Surgeon */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Surgeon</Typography>

          <SelectField
            label="Select Type"
            showValue={surgeonValue}
            setShowValue={setSurgeonValue}
            data={data["surgeon"]?.value}
            onClick={handleChange}
            fieldKey={isNotDashboard ? "surgeon1" : "surgeon"}
          />

          {surgeonValue?.map((item) => (
            <Chip
              label={item?.title}
              variant="outlined"
              onDelete={() =>
                handleDelete(isNotDashboard ? "surgeon1" : "surgeon", item)
              }
              sx={{ mr: "3px" }}
            />
          ))}
        </Box>

        <Box sx={{ mt: "10px", mb: "4rem" }}>
          <Typography>Assignee</Typography>
          <SelectField
            label="Select User"
            showValue={assignValue}
            setShowValue={setAssignValue}
            data={data["assignee"]?.value}
            // data={userArr?.map((user) => ({ id: user._id, title: user.name, value:user._id }))}
            onClick={handleChange}
            fieldKey={"assignee"}
          />
          {assignValue?.map((item) => (
            <Chip
              key={item?.id}
              label={item?.title}
              variant="outlined"
              onDelete={() => handleDelete("assignee", item)}
              sx={{ mr: "3px" }}
            />
          ))}
        </Box>
      </Stack>
    </>
  );
}
