import { Box, Button, Modal, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { editNode } from "../Pages/Journeys/Flow/utils";
import { useIsMobile } from "../contexts/isMobile";
import { useEditJourneyNodeMutation } from "../store/journeysSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius: "10px",
};

export const AskModal = ({
  openM,
  setOpenM,
  id,
  journeyID,
  data,
  journeyData,
  toggleDrawer,
  name,
  method,
  metric,
  nodeInternals,
  askText,
}) => {
  const isMobile = useIsMobile();
  const [editJourneyNode] = useEditJourneyNodeMutation();

  const handleClose = () => {
    setOpenM(false);
  };

  const handledelete = async () => {
    // if for Deleting specific node from workflowNode.jsx
    const edit = await editJourneyNode({
      journeyID,
      nodeID: id,
      body: { ...journeyData },
    });

    if (edit) {
      // If the edit has been saved in the backend, then editNode in UI state to show live updates
      editNode(id, name, method, metric, nodeInternals);
      toggleDrawer(false);
      toast.success("Successfully edited!");
      if (journeyData?.form?.checkCondition !== data.checkCondition) {
        window.location = "/journeysflow/" + journeyID;
      }
    } else {
      // Handle the case when the edit was not successful
      toast.error("Edit failed!");
    }
    setOpenM(false);
  };

  return (
    <div>
      <Modal
        open={openM}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            {typeof askText !== "undefined" && askText !== ""
              ? askText
              : "Are you Sure You Want to Delete"}
          </Typography>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Box sx={{ margin: "5px" }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#816179",
                    padding: "1rem 2rem",
                    borderRadius: "0.5rem",
                    fontSize: "14px",
                    width: isMobile ? "100%" : "130px",
                    height: "35px",
                  }}
                  onClick={handledelete}
                >
                  Yes
                </Button>
              </Box>
              <Box sx={{ margin: "5px" }}>
                <Button
                  size="small"
                  sx={{
                    color: "#816179",
                    border: "1px solid #816179",
                    padding: "1rem 2rem",
                    borderRadius: "0.5rem",
                    fontSize: "14px",
                    width: isMobile ? "100%" : "130px",
                    height: "35px",
                    ":hover": {
                      border: "1px solid black",
                      color: "black",
                    },
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
