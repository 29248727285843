import { Badge, Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { AssistantSvg } from "../../assets/svgs/Queue Icons";
import CallIcon from "@mui/icons-material/Call";
import DescriptionIcon from "@mui/icons-material/Description";
import LoopIcon from "@mui/icons-material/Loop";
import RouteIcon from "@mui/icons-material/Route";
import NoteIcon from "@mui/icons-material/Note";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ProcedureTab from "./ProcedureTab";
import { useSelector } from "react-redux";
import JourneyTab from "./JourneyTab";
import NoteTab from "./NoteTab";
import FormTab from "./FormTab";
import CallTab from "./CallTab";
import AppointmentTab from "./AppointmentTab";
import TaskTab from "./TaskTab";
import GalleryTab from "./GalleryTab";
import ConversationTab from "../MessageLayout/ConversationTab";
import AiConversationTab from "../MessageLayout/AiConversationTab";
import { Conversation } from "../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { Call } from "../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Gallery } from "../../assets/svgs/PatientProfileTabsSvgs/Gallery";
import { Form } from "../../assets/svgs/PatientProfileTabsSvgs/Form";
import { Procedure } from "../../assets/svgs/PatientProfileTabsSvgs/Procedure";
import { Journey } from "../../assets/svgs/PatientProfileTabsSvgs/Journey";
import { Note } from "../../assets/svgs/PatientProfileTabsSvgs/Note";
import { Appointment } from "../../assets/svgs/PatientProfileTabsSvgs/Appointment";
import { Task } from "../../assets/svgs/PatientProfileTabsSvgs/Task";
import axios from "axios";
import { addInParams, getTokenFromLocalStroage } from "../../utils";
import CareAllyTab from "../MessageLayout/CareAllyTab";
import ChatBotDropdownButton from "../MessageLayout/ChatBotModeDropdown";
import AiAssistantDropdownButton from "../MessageLayout/AiAssistantDropdownButton";
import { useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Body = ({ patient, isJourney }) => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation()
  // Create a URLSearchParams object from the location search string
  const queryParams = new URLSearchParams(location.search)
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [chatPage, setChatPage] = useState(1)
  const [allyChatPage, setAllyChatPage] = useState(1)
  const [agentChatPage, setAgentChatPage] = useState(1)
  const tabItems = [];
  const [careAllyData1, setCareAllyData1] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isRefetchChat, setIsRefetchChat] = useState(false);
  const [selectedCareAllyValue, setSelectedCareAllyValue] = useState(null);
  const [isRefetchCareAllyChat, setIsRefetchCareAllyChat] = useState(false);
  const [selectedAiValue, setSelectedAiValue] = useState(null);
  const [agentId, setAgentId] = useState(null);

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v2/careAlly/patient/${patient?._id}`,
        config
      );
      setCareAllyData1(response.data?.careAlly);
    } catch (error) {
      setCareAllyData1(null);
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (event, newValue) => {
    addInParams('tab', newValue?.toString())
    setValue(newValue);
  };
  tabItems.push(
    <Tab
      key="conversation"
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: 600,
            textTransform: "capitalize",
            color: value === 0 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Badge
            color="error"
            variant="dot"
            // invisible={!data?.patientImages || data?.patientImages?.length === 0} // Hide the dot if no data
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Conversation color={value === 0 ? "#000" : "rgba(0,0,0,0.60)"} />
              <span style={{ marginLeft: "3px" }}>Messages</span>
            </Box>
          </Badge>
        </div>
      }
      {...a11yProps(0)}
    />
  );
  tabItems.push(
    <Tab
      key="gallery"
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 1 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Call color={value === 1 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "3px" }}>Call</span>
        </Box>
      }
      {...a11yProps(1)}
    />
  );
  tabItems.push(
    <Tab
      key="gallery"
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 2 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Badge
            color="error"
            variant="dot"
            // invisible={!data?.patientImages || data?.patientImages?.length === 0} // Hide the dot if no data
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Gallery color={value === 2 ? "#000" : "rgba(0,0,0,0.60)"} />
              <span style={{ marginLeft: "3px" }}>Gallery</span>
            </Box>
          </Badge>
        </div>
      }
      {...a11yProps(2)}
    />
  );

  tabItems.push(
    <Tab
      key="care-ally"
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 3 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Form color={value === 3 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "3px" }}>Forms</span>
        </Box>
      }
      {...a11yProps(3)}
    />
  );

  tabItems.push(
    <Tab
      key="care-ally"
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 4 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Conversation color={value === 4 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "3px" }}>Care Ally</span>
        </Box>
      }
      {...a11yProps(4)}
    />
  );

  tabItems.push(
    <Tab
      key="assistant"
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 5 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Procedure color={value === 5 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "3px" }}>Procedures</span>
        </Box>
      }
      {...a11yProps(5)}
    />
  );

  tabItems.push(
    <Tab
      key="care-ally"
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 6 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Journey color={value === 6 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "3px" }}>Journeys</span>
        </Box>
      }
      {...a11yProps(6)}
    />
  );

  tabItems.push(
    <Tab
      key="care-ally"
      label={
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 7 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Note color={value === 7 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "3px" }}>Notes</span>
        </Box>
      }
      {...a11yProps(7)}
    />
  );

  tabItems.push(
    <Tab
      key="care-ally"
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 8 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Appointment color={value === 8 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "3px" }}>Appointments</span>
        </Box>
      }
      {...a11yProps(8)}
    />
  );

  tabItems.push(
    <Tab
      key="care-ally"
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 9 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Task color={value === 9 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "3px" }}>Tasks</span>
        </Box>
      }
      {...a11yProps(9)}
    />
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(()=>{
    if(isJourney){
      setValue(6)
    }
  },[isJourney, tabItems])

  useEffect(()=>{
    const newValue = queryParams.get('tab')
    if(newValue){
      console.log(parseInt(newValue), newValue)
      setValue(parseInt(newValue))
    }
  },[])

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          position: "sticky",
          // top:'5.8rem',
          backgroundColor: "white",
          // height: '65%',
          zIndex: "990",
          // padding: 3,
        }}
      >
        {/* Tabs */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              left: `calc(${value} * 15%)`,
              "&.MuiTabs-indicator": {
                backgroundColor: "#000",
              },
            },
          }}
        >
          {tabItems}
        </Tabs>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginLeft: "5px",
          }}
        >
          <IconButton
            aria-label="Ai-agent"
            size="large"
            sx={{
              border: "1px solid #cdcdcd",
              padding: "5px",
              color: value === 10 && "white",
              background: value === 10 && "#1B4DFF",
              "&:hover": {
                backgroundColor: value === 10 && "#fff",
                color: value === 10 && "black",
              },
            }}
            onClick={() => {
              addInParams('tab', 10?.toString())
              setValue(10);
            }}
          >
            <AssistantSvg />
          </IconButton>
        </Box>
      </div>

      {/* Tabs Body */}
      <CustomTabPanel value={value} index={0}>
        <Box sx={{display:'flex', justifyContent:'flex-end', mb:2}}>
        <ChatBotDropdownButton
                chatBotEnabled={
                  patient?.chatMode
                }
                selectedValue={
                  selectedValue
                }
                setSelectedValue={
                  setSelectedValue
                }
                patientId={ 
                  patient?._id
                }
                // refetch={
                //   refetchPatientAgain
                // }
                value={value}
                // pathname={pathname}
                // refetchChat={refetchChat}
                isV2True={true}
                isRefetchChat={isRefetchChat}
                setIsRefetchChat={setIsRefetchChat}
              />
        </Box>
        <ConversationTab
          id={patient?._id}
          consent={patient?.consentResponse}
          isDrawer={false}
          selectedValue={undefined}
          chatBot={patient?.chatBotEnabled}
          page={chatPage}
          setPage={setChatPage}
          status={patient?.status}
          value={0}
          isPatientV2={true}
          // refetchChat={refetchChat}
          // chatData={chatData}
          // isChatLoading={isChatLoading}
          patient={patient}
          isPatientQueueV2={true}
          setIsRefetchChat={setIsRefetchChat}
          isRefetchChat={isRefetchChat}
          height={'250px'}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <CallTab user={user} id={patient?._id} status={patient?.status} receiverId={patient?.phone} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <GalleryTab id={patient?._id} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <FormTab user={user} id={patient?._id} status={patient?.status} patientName={patient ? `${patient?.firstName} ${patient?.lastName}` : 'Patient'} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        {careAllyData1 && (
      <Box sx={{display:'flex', justifyContent:'flex-end', mb:2}}>
        <ChatBotDropdownButton
                chatBotEnabled={
                  careAllyData1?.chatMode
                }
                selectedValue={
                  selectedCareAllyValue
                }
                setSelectedValue={
                  setSelectedCareAllyValue
                }
                patientId={ 
                  careAllyData1?._id
                }
                // refetch={
                //   refetchPatientAgain
                // }
                value={value}
                // pathname={pathname}
                // refetchChat={refetchChat}
                isV2True={true}
                isRefetchChat={isRefetchCareAllyChat}
                setIsRefetchChat={setIsRefetchCareAllyChat}
              />
        </Box>
        )}
        <CareAllyTab
          id={patient?._id}
          consent={careAllyData1?.consentResponse}
          isDrawer={false}
          careAllyData={careAllyData1}
          page={allyChatPage}
          selectedValue={undefined}
          setPage={setAllyChatPage}
          // status={status}
          value={value}
          isPatientQueueV2={true}
          isPatientV2={true}
          isRefetchChat={isRefetchCareAllyChat}
          setIsRefetchChat={setIsRefetchCareAllyChat}
          height={careAllyData1 && '250px'}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
        <ProcedureTab user={user} id={patient?._id} status={patient?.status} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={6}>
        <JourneyTab user={user} id={patient?._id} status={patient?.status} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={7}>
        <NoteTab user={user} id={patient?._id} status={patient?.status} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={8}>
        <AppointmentTab user={user} id={patient?._id} status={patient?.status} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={9}>
        <TaskTab user={user} id={patient?._id} status={patient?.status} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={10}>
        <Box sx={{display:'flex', justifyContent:'flex-end', mb:2}}>
        <AiAssistantDropdownButton
                chatBotEnabled={
                  patient?.chatMode
                }
                selectedValue={selectedAiValue}
                setSelectedValue={setSelectedAiValue}
                patientId={
                  patient?._id
                }
                // refetch={
                //   value === 0 ? refetchPatientAgain : refetchCareAllyAgain
                // }
                value={value}
                // pathname={pathname}
                // refetchChat={refetchChat}
                setAgentId={setAgentId}
                isV2True={true}
              />
        </Box>
        <AiConversationTab
          id={patient?._id}
          AiAssistantValue={0}
          agentId={agentId}
          consent={patient?.consentResponse}
          isDrawer={false}
          selectedValue={undefined}
          chatBot={patient?.chatBotEnabled}
          page={agentChatPage}
          setPage={setAgentChatPage}
          status={patient?.status}
          value={0}
          // refetchChat={refetchChat}
          // chatData={chatData}
          // isChatLoading={isChatLoading}
          patient={patient}
          // isPatientQueueV2={true}
          height={'250px'}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default Body;
