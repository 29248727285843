export function DeleteSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      fill="none"
      viewBox="0 0 12 16"
    >
      <path
        fill="#979797"
        d="M1 13.833A1.666 1.666 0 002.667 15.5h6.666A1.667 1.667 0 0011 13.833v-10H1v10zM2.667 5.5h6.666v8.333H2.666V5.5zm6.25-4.167L8.082.5H3.916l-.833.833H.167V3h11.666V1.333H8.916z"
      ></path>
    </svg>
  );
}
