import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";

const EmailNotificationForm = ({
  subject,
  setSubject,
  messageBody,
  setMessageBody,
}) => {
  const [description, setDescription] = useState("");
  return (
    <>
      <Box sx={{ marginBottom: "20px" }}>
        <TextField
          required
          fullWidth
          className="nopan"
          id="outlined-textarea"
          label="Subject"
          placeholder="Enter Subject"
          name="subject"
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
      </Box>
      <Box className="nopan">
        <Typography
          fontSize={14}
          color={"#343A40"}
          mt={2}
          sx={{ textAlign: "left" }}
        >
          Message Body <span style={{ color: "black" }}>*</span> :
        </Typography>
        <CKEditor
          editor={ClassicEditor}
          data={messageBody}
          className="nopan"
          onReady={(editor) => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                "20px",
                editor.editing.view.document.getRoot(),
              );
            });
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setMessageBody(data);
          }}
          config={{
            toolbar: {
              items: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "|",
                "undo",
                "redo",
              ],
            },
            placeholder: "Enter your message here...",
          }}
        />
      </Box>
    </>
  );
};

export default EmailNotificationForm;
