export const DemographicsDataTitles = [
  { title: "First Name", value: "firstName" },
  { title: "Last Name", value: "lastName" },
  { title: "Date of Birth", value: "birthday" },
  { title: "Gender", value: "gender" },
  { title: "Race", value: "race" },
  { title: "Ethnicity", value: "ethnicity" },
  { title: "Language", value: "language" },
  { title: "MRN", value: "mrn" },
  // { title: "Tags", value: "tags" },
  // { title: "Procedure Date", value: "procedureDate" },
  // { title: "SSN", value: "SSN" },
  { title: "Patient Summary", value: "patientSummary" },
];
export const AddressDataTitles = [
  { title: "Address Line 1", value: "address1" },
  { title: "Address Line 2", value: "address2" },
  { title: "City", value: "city" },
  { title: "State", value: "state" },
  { title: "ZIP Code", value: "zip" },
];

export const ContactDataTitles = [
  { title: "Email", value: "email" },
  { title: "Phone", value: "phone" },
];

export const ProviderColumns = [
  { id: "name", label: "Physician" },
  { id: "speciality", label: "Speciality" },
  { id: "type", label: "Type" },
  { id: "consent", label: "Consent" },
];

export const ProsColumns = [
  { id: "collapsibleIcon", label: "" },
  { id: "name", label: "Name" },
  { id: "date", label: "Date Assigned" },
  { id: "dueDate", label: "Due Date" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

export const SurgeonColummns = [
  { id: "name", label: "Surgeon" },
  { id: "speciality", label: "Speciality" },
  { id: "type", label: "Type" },
  { id: "consent", label: "Consent" },
];

export const CareAllyColumns = [
  { id: "tag", label: "Care Giver" },
  { id: "relationship", label: "Relationship" },
  { id: "consent", label: "Consent" },
];

export const ProgramColumns = [{ id: "name", label: "Name" }];
export const JourneyColumns = [
  { id: "name", label: "Title" },
  { id: "assignedOn", label: "Assigned On" },
  { id: "status", label: "Status" },
];
