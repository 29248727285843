import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useIsAuthenticatedUser } from "../../contexts/isAuthenticatedUser";
import { useIsHovered } from "../../contexts/isHovered";
import { useIsMobile } from "../../contexts/isMobile";
import { CircularLoader } from "../Loader";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { drawerWidth } from "./constant";

export function Layout() {
  const { loading } = useIsAuthenticatedUser();
  const isMobile = useIsMobile();
  const { hovered } = useIsHovered();
  const { user } = useSelector((state) => state.user);
  let width = `calc(100% - ${drawerWidth}px)`;
  let ml = hovered ? `${drawerWidth}px` : "50px";

  if (isMobile) {
    width = "80%";
    ml = "50px";
  }

  return user.email ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Box
        component="main"
        sx={{
          width,
          ml,
          flexGrow: 1,
          bgcolor: "#9797971a",
        }}
      >
        <Header />
        <Outlet />
      </Box>
    </Box>
  ) : (
    <>
      <Box
        component="main"
        sx={{
          width,
          ml,
          flexGrow: 1,
          bgcolor: "background.default",
          height: "90vh",
          padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem",
        }}
      >
        <Box sx={{ marginTop: isMobile ? "150px" : "100px" }}>
          <CircularLoader />
        </Box>
      </Box>
    </>
  );
}
