import {
  Autocomplete,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { languages } from "../utils";

const CreateCareAlly = ({
  language,
  setLanguage,
  form,
  setForm,
  isValidNumber,
  setIsValidNumber,
}) => {
  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const handleChange = (newValue) => {
    matchIsValidTel(newValue); // boolean
    setIsValidNumber(matchIsValidTel(newValue));
  };

  //   useEffect(() => {
  //     if (editData) {

  //   }, [editData]);
  return (
    <>
      <Paper elevation={2} sx={{ borderRadius: "10px", p: 2, mb: 3 }}>
        <Typography fontSize={"18px"} fontWeight={"bolder"}>
          Create Care Ally
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={2}>
              First Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth={true}
              name="firstName"
              value={form.firstName}
              onChange={onChange}
            />
          </Grid>

          <Grid item={true} md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={2}>
              Last Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth={true}
              name="lastName"
              value={form.lastName}
              onChange={onChange}
            />
          </Grid>
          <Grid item={true} md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={2}>
              Relationship <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth={true}
              name="relationship"
              value={form.relationship}
              onChange={onChange}
            />
          </Grid>
          <Grid item={true} md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={1}>
              Phone No <span style={{ color: "red" }}>*</span>
            </Typography>
            <MuiTelInput
              required={true}
              id="standard"
              variant="standard"
              fullWidth={true}
              name="phoneNumber"
              defaultCountry="US"
              onlyCountries={["US"]}
              error={isValidNumber === false}
              helperText={isValidNumber === false && "Incorrect entry."}
              value={form.phoneNumber}
              onChange={(newValue) => {
                const event = {
                  target: { name: "phoneNumber", value: newValue },
                };
                onChange(event);
                handleChange(newValue);
              }}
              sx={{
                padding: "3px",
                "& .MuiInputLabel-root": {
                  padding: "3px",
                },
              }}
            />
          </Grid>
          <Grid item={true} md={6} xs={12}>
            <Typography fontSize={12} mt={2} color="#979797">
              Preferred Language <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              value={language}
              onChange={(event, newValue) => {
                setLanguage(newValue);
              }}
              options={languages?.map((item) => item.label)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CreateCareAlly;
