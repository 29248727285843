import { useSelector } from "react-redux";
import { ROLES } from "../constants/routes";

export { useIsMobile } from "./useIsMobile";

const notAllowedRoles = [ROLES.CareTeamMember];
export const useNotAllowedRole = () => {
  const { user } = useSelector((state) => state.user);
  return notAllowedRoles.includes(user.role);
};
