import Tooltip from "@material-ui/core/Tooltip";
import { DoneAllRounded } from "@mui/icons-material";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Box, IconButton, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import remarkGfm from "remark-gfm";
import WhatwentwrongDialog from "../../Pages/patientQueue/Profile/WhatwentwrongDialog";
import { AssistantSvg } from "../../assets/svgs/Queue Icons";
import { getCurrentSiteId, getTokenFromLocalStroage } from "../../utils";
import MessageInterventionAccordion from "./MessageInterventionAccordion";

export function UnknownDisplayMessageBox({
  data,
  selectedValue,
  careAlly,
  setShowLoader,
  setAiGeneratedMessage,
  setPatientQuestion,
  patient,
}) {
  // const [isModalOpen, setIsModalOpen] = useState(false)
  // const [selectedMediaUrl, setSelectedMediaUrl] = useState([])
  const { user } = useSelector((state) => state.user);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [generatedSuggestions, setGeneratedSuggestions] = useState({});
  const [selectedAcuity, setSelectedAcuity] = useState("");
  const [selectedTaskLabel, setSelectedTaskLabel] = useState("");
  const [chatbotResponseCritique, setChatbotResponseCritique] = useState("");
  const [proposedChatbotResponse, setProposedChatbotResponse] = useState("");
  const [isTaskCategorySelected, setIsTaskCategorySelected] = useState(true);
  const [isChatbotResponse, setIsChatbotResponse] = useState(true);
  const [isAcuitySelected, setIsAcuitySelected] = useState(true);
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [currentItemData, setCurrentItemData] = useState({});

  const setCurrentItem = async (itemId, patientQueue) => {
    setCurrentItemData(patientQueue);
    setOpenDislikeModal(true);
  };

  // const openModal = (mediaUrl) => {
  // 	setSelectedMediaUrl(mediaUrl[0])
  // 	setIsModalOpen(true)
  // }

  const config = {
    headers: {
      Authorization: `Bearer ${getTokenFromLocalStroage()}`,
      hospital: getCurrentSiteId(),
    },
  };

  const getMessageSuggestion = async (chatId) => {
    try {
      setShowLoader(true);
      await axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/chat/generateReplySuggestion/${chatId}`,
          config,
        )
        .then((res) => {
          const data = res.data;
          setShowLoader(false);
          console.log("generatedSuggestions", data);
          setAiGeneratedMessage(data.suggestion);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error(
        "Error:" + error?.response?.data?.message ||
          "Something went wrong, Please try again later!",
      );
    }
  };

  // const closeModal = () => {
  // 	setSelectedMediaUrl('')
  // 	setIsModalOpen(false)
  // }

  const formatDate = (createdAt) => {
    const createdAtMoment = moment(createdAt);
    const today = moment().startOf("day");
    const tomorrow = moment().startOf("day").add(1, "day");

    if (createdAtMoment.isSame(today, "day")) {
      return "Today " + createdAtMoment.format("hh:mm A");
    } else if (createdAtMoment.isSame(tomorrow, "day")) {
      return "Tomorrow " + createdAtMoment.format("hh:mm A");
    } else {
      return createdAtMoment.format("MMMM DD, YYYY hh:mm A");
    }
  };

  const handleCollapse = (index, itemId = null) => {
    setExpandedIndices((prevIndices) => {
      const isExpanded = prevIndices.includes(index);

      if (isExpanded) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });

    if (!expandedIndices.includes(index) && itemId) {
      setShowLoader(true);
      getMessageSuggestion(itemId);
    }
  };

  const copyText = (item) => {
    const textToCopy =
      item?.patientQueue?.chatbotReply || generatedSuggestions[item._id];

    navigator.clipboard.writeText(textToCopy).then(() => {
      toast.success("Text copied successfully!");
    });
  };

  const chatModeChangeMessage = (body) => {
    if (!body) return body;

    const replacements = {
      Careguard: "Care Guard",
      Automatic: "Autopilot",
    };

    let updatedBody = body;

    for (const [key, value] of Object.entries(replacements)) {
      if (updatedBody.includes(key)) {
        updatedBody = updatedBody.replace(key, value);
      }
    }

    return updatedBody;
  };

  const addReaction = async (queueID, status) => {
    if (status === "dislike") {
      if (!selectedAcuity || !selectedTaskLabel || !chatbotResponseCritique) {
        toast.error("Please fill out the form in-order to submit the feedback");
        return;
      }
    }

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/addReaction/${queueID}`,
          {
            status: status,
            userId: user.id,
            correctTaskLabel: selectedTaskLabel,
            correctAcuityLabel: selectedAcuity,
            proposedChatbotResponse: proposedChatbotResponse,
            chatbotResponseCritique: chatbotResponseCritique,
          },
          config,
        )
        .then((res) => {
          toast.success("Feedback Recorded!");
          // setOpen(true);
          // if(patientDrawer){
          // 	setUserFeedback2([res?.data?.feedback])
          // }
        });
    } catch (error) {
      toast.error("Error:" + error);
    }
    setSelectedTaskLabel("");
    setSelectedAcuity("");
    setProposedChatbotResponse("");
    setChatbotResponseCritique("");
    setIsAcuitySelected(false);
    setIsChatbotResponse(false);
    setIsTaskCategorySelected(false);
    setOpenDislikeModal(false);
    setCurrentItemData({});
  };

  function renderFeedbackIcons(itemId, userFeedbackData, patientQueue) {
    const iconSkeleton = {
      color: "#979797",
      cursor: "pointer",
    };

    const dislikeIcon = {
      cursor: "pointer",
      color: "red",
    };

    const feedbackIcons = {
      like: <ThumbUpOffAltIcon fontSize="medium" style={{ color: "green" }} />,
      dislike: (
        <ThumbDownOffAltIcon fontSize="medium" style={{ color: "red" }} />
      ),
    };

    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "start" }}
      >
        <>
          {userFeedbackData?.some((item) => item?.status === "like") &&
            feedbackIcons.like}
          {!userFeedbackData?.some((item) => item?.status === "like") && (
            <ThumbUpOffAltIcon
              fontSize="medium"
              onClick={() => addReaction(itemId, "like")}
              style={iconSkeleton}
            />
          )}
          &ensp;
        </>
        {userFeedbackData?.some((item) => item?.status === "dislike") &&
          feedbackIcons.dislike}
        {!userFeedbackData?.some((item) => item?.status === "dislike") && (
          <ThumbDownOffAltIcon
            fontSize="medium"
            onClick={() => setCurrentItem(itemId, patientQueue)}
            style={iconSkeleton}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {data &&
          data.map((item, index) => (
            <>
              {item?.body?.length > 0 &&
                (item.sentBy === "user" ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        mt: !item?.patientQueue && 1,
                        my: item?.patientQueue && 1,
                        // mr:1,
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#979797",
                          fontSize: "12px",
                          margin: "2px",
                          textAlign: "right",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Sent by {item.sentBy}, {formatDate(item.createdAt)}
                      </Typography>
                      <Box
                        // sx={{
                        //   maxWidth: "50%",
                        //   borderRadius: "10px",
                        //   border: "1px ",
                        //   backgroundColor: "rgba(0,151,236,0.12)",
                        //   color: "#000",
                        //   borderTopRightRadius: "0px",
                        //   p: 2,
                        //   mt: 1,
                        //   textAlign: "left",
                        // }}
                        sx={{
                          maxWidth: "50%",
                          borderRadius: "10px",
                          border: "1px ",
                          backgroundColor: "#F2F2F7",
                          color: "#000",
                          borderTopLeftRadius: "0px",
                          p: 2,
                          mt: 1,
                          textAlign: "left",
                          display: "flex", // Add display flex
                          alignItems: "center", // Center items vertically
                          position: "relative", // Required for positioning the pseudo-element
                          "&::before": {
                            // Create the triangle
                            content: '""',
                            position: "absolute",
                            bottom: 10,
                            left: -10,
                            width: 0,
                            height: 0,
                            borderLeft: "10px solid transparent",
                            borderRight: "10px solid #F2F2F7",
                            borderBottom: "10px solid transparent",
                          },
                        }}
                      >
                        {/* {item?.mediaUrl.length !== 0 && (
												<img
													src={item?.mediaUrl}
													style={{
														cursor: 'pointer',
														width: '4em',
														height: '4em',
														padding: '5px',
														borderRadius: '2px',
														background: '#FFFFFF',
														marginTop: '1em',
													}}
													onClick={() => openModal(item?.mediaUrl)}
												/>
											)} */}
                        <Typography
                          sx={{
                            color: "#000",
                            mt: 1,
                            fontSize: "1.1rem",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                              a: (props) => {
                                return (
                                  <a
                                    href={props.href}
                                    target="blank"
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    {props.children}
                                  </a> // All other links
                                );
                              },
                            }}
                          >
                            {item?.body
                              ?.replace(/\n\s*\n/g, "\n")
                              ?.replace(/^```|```$/g, "")}
                          </ReactMarkdown>
                        </Typography>

                        {selectedValue !== "Manual" && (
                          <IconButton
                            sx={{ mt: 1 }}
                            onClick={() => {
                              getMessageSuggestion(item?._id);
                              setPatientQuestion(item.body);
                            }}
                          >
                            <Tooltip title="Generate AI Response">
                              <AssistantSvg />
                            </Tooltip>
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                    {item?.patientQueue && (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <MessageInterventionAccordion
                          item={item}
                          index={index}
                          queueData={item?.patientQueue}
                          patient={patient}
                        />
                      </Box>
                    )}
                  </>
                ) : item.sentBy === "journey" && item.chatType === "email" ? (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        item?.sentBy === "user" ? "flex-start" : "flex-end",
                      my: 1,
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#979797",
                        fontSize: "12px",
                        margin: "2px",
                        textAlign: "left",
                      }}
                    >
                      {formatDate(item.createdAt)}
                      {", "}
                      Automated Journey Email
                    </Typography>
                    <Box
                      sx={{
                        maxWidth: "50%",
                        borderRadius: "10px",
                        border: "1px ",
                        backgroundColor: "rgba(0,151,236,0.12)",
                        color: "#000",
                        borderTopRightRadius: "0px",
                        p: 2,
                        mt: 1,
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          mt: 1,
                          fontSize: "1.1rem",
                          whiteSpace: "pre-wrap",
                        }}
                        dangerouslySetInnerHTML={{ __html: item?.body }}
                      ></Typography>
                    </Box>
                  </Box>
                ) : item?.senderId === "chatModeSource" ? (
                  <Typography
                    sx={{
                      color: "#979797",
                      fontSize: "12px",
                      margin: "2px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      lineHeight: 5.5,
                    }}
                  >
                    {formatDate(item.createdAt)}
                    {", "}
                    {chatModeChangeMessage(item?.body)}
                  </Typography>
                ) : (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        item?.sentBy === "user" ? "flex-start" : "flex-end",
                      my: 1,
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#979797",
                        fontSize: "12px",
                        margin: "2px",
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {formatDate(item.createdAt)}
                      {", "}
                      {item.chatbot === true && item?.sentBy == "journey"
                        ? "Automated Journey Message"
                        : item.chatbot === true
                          ? "AI Chatbot Message"
                          : "Handcrafted Message"}
                      <DoneAllRounded sx={{ color: "#1B4DFF", ml: "5px" }} />
                    </Typography>
                    <Box
                      sx={{
                        maxWidth: "50%",
                        borderRadius: "10px",
                        border: "1px ",
                        backgroundColor: "rgba(0,151,236,0.12)",
                        color: "#000",
                        borderTopRightRadius: "0px",
                        p: 2,
                        mt: 1,
                        textAlign: "left",
                      }}
                    >
                      {/* {item?.mediaUrl.length !== 0 && (
												<img
													src={item?.mediaUrl}
													style={{
														cursor: 'pointer',
														width: '4em',
														height: '4em',
														padding: '5px',
														borderRadius: '2px',
														background: '#FFFFFF',
														marginTop: '1em',
													}}
													onClick={() => openModal(item?.mediaUrl)}
												/>
											)} */}
                      <Typography
                        sx={{
                          color: "#000",
                          mt: 1,
                          fontSize: "1.1rem",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            a: (props) => {
                              return (
                                <a
                                  href={props.href}
                                  target="blank"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {props.children}
                                </a> // All other links
                              );
                            },
                          }}
                        >
                          {item?.body
                            ?.replace(/\n\s*\n/g, "\n")
                            ?.replace(/^```|```$/g, "")}
                        </ReactMarkdown>
                      </Typography>
                    </Box>
                    {item?.chatbot && item?.patientQueue && (
                      <Box sx={{ marginTop: "5px" }}>
                        {renderFeedbackIcons(
                          item?.patientQueue?._id,
                          item?.patientQueue?.userFeedback,
                          item?.patientQueue,
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
            </>
          ))}
      </Box>
      <WhatwentwrongDialog
        open={openDislikeModal}
        setOpen={setOpenDislikeModal}
        addReaction={addReaction}
        queueId={currentItemData?._id}
        originalMessage={currentItemData?.originalMessage}
        isAcuitySelected={isAcuitySelected}
        setIsAcuitySelected={setIsAcuitySelected}
        isChatbotResponse={isChatbotResponse}
        setIsChatbotResponse={setIsChatbotResponse}
        isTaskCategorySelected={isTaskCategorySelected}
        setIsTaskCategorySelected={setIsTaskCategorySelected}
        proposedChatbotResponse={proposedChatbotResponse}
        setProposedChatbotResponse={setProposedChatbotResponse}
        chatbotResponseCritique={chatbotResponseCritique}
        setChatbotResponseCritique={setChatbotResponseCritique}
        selectedAcuity={selectedAcuity}
        setSelectedAcuity={setSelectedAcuity}
        selectedTaskLabel={selectedTaskLabel}
        setSelectedTaskLabel={setSelectedTaskLabel}
        chatbotResponse={currentItemData?.chatbotReply}
      />
      {/* <Modal open={isModalOpen} onClose={closeModal}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: '#FFF',
						boxShadow: 24,
						p: 2,
					}}
				>
					<img src={selectedMediaUrl} alt="Media" style={{ maxWidth: '100%', maxHeight: '100%' }} />
				</Box>
			</Modal> */}
    </>
  );
}
