import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRiskProfiles: builder.query({
      query: (query = {}) => {
        const { userID, page = 1, limit = 5, week } = query;
        const finalQuery = { userID, page, limit, week };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/riskProfile/?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Dashboards", id: "LIST" },
          ...data.map((p) => ({ type: "Dashboards", id: p._id })),
        ];
      },
    }),
    getProfileByPatientID: builder.query({
      query: (id) => `/riskProfile/${id}`,
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Dashboards", id: "LIST" },
          ...data.map((p) => ({ type: "Dashboards", id: p._id })),
        ];
      },
    }),
  }),
});

export const { useGetRiskProfilesQuery, useGetProfileByPatientIDQuery } =
  extendedApiSlice;
