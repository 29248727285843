import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import TuneIcon from "@mui/icons-material/Tune";
import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const CheckboxMenu = ({ selectedOptions, onOptionSelect, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleOptionSelect = (option) => (event) => {
    onOptionSelect(option, event.target.checked);
  };
  return (
    <>
      <Box
        sx={{ cursor: "pointer", fontSize: 28, color: "#979797" }}
        onClick={handleMenuClick}
      >
        <TuneIcon />
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {options &&
          options.map((option) => (
            <MenuItem key={option}>
              <Checkbox
                checked={selectedOptions.includes(option)}
                onChange={handleOptionSelect(option)}
              />
              {option}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export function SeachField({
  searchTerm,
  setSearchTerm,
  options,
  selectedOptions,
  handleOptionSelect,
}) {
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        border: "1px solid #979797",
        borderRadius: "5px",
        height: "35px",
      }}
    >
      <ManageSearchIcon
        sx={{ fontSize: 28, paddingLeft: "5px", color: "#979797" }}
      />
      <Box sx={{ margin: "5px", marginTop: "8px" }}>
        <TextField
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          placeholder="Search"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <CheckboxMenu
                selectedOptions={selectedOptions}
                onOptionSelect={handleOptionSelect}
                options={options}
              />
            ),
          }}
        />
      </Box>
    </Box>
  );
}

export default SeachField;
