import { CheckCircleRounded } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PendingIcon from "@mui/icons-material/Pending";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import { CircularLoader } from "../../Components";
import { useGetSocialCareDetailQuery } from "../../store/socialCaresSlice";
import NoteModal from "../Patient/PatientProfile/ProfileModals/NotesModal";
import FindHelpModal from "./FindHelpModal";
import GenerateNote from "./GenerateNote";
import { HorizontalBar } from "./MUI";
import ReferModal from "./ResonReferalModal";
import SurveyModal from "./SurvayModal";
import { customContent } from "./Utils/Drawerdata";

const CustomTitle = ({ title, dateTime }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6" fontSize={"16px"} textAlign={"center"}>
        {title}
      </Typography>
      {dateTime && (
        <>
          <Typography
            variant="subtitle"
            color="textSecondary"
            fontSize={"14px"}
          >
            {moment(dateTime).format("DD-MMM-YY")}
          </Typography>
          <Typography variant="body2" color="textSecondary" fontSize={"12px"}>
            {moment(dateTime).format("h:mm A")}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default function RightDrawer({
  open,
  toggleDrawer,
  isCHW,
  patient,
  allNeedsMet,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalData, setModalData] = useState(null);
  const [timelineItems, setTimelineItems] = useState([]);
  // [
  //   { title: <CustomTitle title={"Screening"} /> },
  //   { title: <CustomTitle title={"Results"} /> },
  //   { title: <CustomTitle title={"Consent"} /> },
  //   // { title: <CustomTitle title={'CHW Referral'} /> },  Commenting for V1 and adding below are if chw timeline and removed after V1 i.e. timeline contain Screening to CHW Referal only
  //   { title: <CustomTitle title={"Online Referral Mode"} /> },
  //   { title: <CustomTitle title={"Communication"} /> },
  //   { title: <CustomTitle title={"Loop Closed"} /> },
  //   { title: <CustomTitle title={"Assessment & Billing"} /> },
  // ]
  const [chwTimelineItems, setchwTimelineItems] = useState([
    { title: <CustomTitle title={"Referred In"} /> },
    { title: <CustomTitle title={"Contact Made"} /> },
    { title: <CustomTitle title={"Online Referral Mode"} /> },
    { title: <CustomTitle title={"Communication"} /> },
    { title: <CustomTitle title={"Loop Closed"} /> },
    { title: <CustomTitle title={"Assessment & Billing"} /> },
  ]);
  const [isReferralSubmitted, setIsReferralSubmitted] = useState(false);
  const [fifthItemContent, setFifthItemContent] = useState(false);
  const [isBiling, setIsBiling] = useState(false);
  const [communicationData, setCommunicationData] = useState([]);

  const { data, isLoading, refetch } = useGetSocialCareDetailQuery(
    patient?._id,
  );

  const handleOpenModal = (type, dataToShow) => {
    setModalType(type);
    setModalOpen(true);
    setModalData(dataToShow);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalType("");
    setModalData(null);
  };

  // Function to add a new timeline item
  const handleAddTimelineItem = () => {
    setTimelineItems((prevItems) => [
      ...prevItems,
      { title: <CustomTitle title={"CHW Review"} /> }, // Add new timeline item
    ]);
    setIsReferralSubmitted(true);
  };

  const handleAddTimelineBilling = () => {
    timelineItems?.length <= 5 &&
      setTimelineItems((prevItems) => [
        ...prevItems,
        { title: <CustomTitle title={"Billing"} /> },
      ]);
  };

  function isObjectEmpty(obj, key) {
    // if (key === 'closed_loop') {
    //   return !obj?.allNeedsMet;
    // }

    if (key === "communication") {
      return !Object.values(obj).some(
        (value) => Array.isArray(value) && value.length > 0,
      );
    }

    return (
      obj !== null &&
      typeof obj === "object" &&
      !Array.isArray(obj) &&
      Object.keys(obj).length === 0
    );
  }

  useEffect(() => {
    const keyToTitleMap = {
      screening: "Screening",
      results: "Results",
      consent: "Consent",
      online_referral_mode: "Online Referral Mode",
      communication: "Communication",
      closed_loop: "All Needs Met",
      "assessment_&_billing": "Assessment & Billing",
    };

    if (data?.data) {
      const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

      const getDateTime = (key, dataObject) => {
        if (key === "online_referral_mode") {
          const referrals = dataObject?.referrals || [];
          return referrals.length > 0
            ? referrals[referrals.length - 1]?.createdAt
            : null;
        }
        return dataObject?.dateTime;
      };

      const resultArray = Object.keys(data?.data)
        .filter(
          (key) =>
            key === "online_referral_mode" ||
            !isObjectEmpty(data?.data[key], key),
        ) // Filter out empty objects
        .map((key) => {
          return {
            title: (
              <CustomTitle
                title={keyToTitleMap[key]}
                dateTime={getDateTime(key, data?.data[key])}
              />
            ),
          };
        });

      if (
        data?.data["communication"] &&
        Object?.values(data?.data["communication"])?.some(
          (value) => Array?.isArray(value) && value?.length > 0,
        )
      ) {
        const combinedData = Object.keys(data?.data["communication"]).reduce(
          (acc, key) => {
            // Ensure the value is an array and iterate over it
            if (Array.isArray(data?.data["communication"][key])) {
              const formattedArray = data?.data["communication"][key].map(
                (item) => ({
                  method: capitalize(key), // Use the key as the method and capitalize it
                  date: item.createdAt, // Use createdAt as date if it exists in each item
                  data: item,
                }),
              );
              return [...acc, ...formattedArray]; // Merge with the accumulator
            }
            return acc;
          },
          [],
        );

        setCommunicationData(combinedData);
      }

      const hasCommunication = resultArray?.some(
        (item) => item?.title?.props?.title === "Communication",
      );

      const filteredArray = hasCommunication
        ? resultArray
        : resultArray?.filter(
            (item) => item?.title?.props?.title !== "All Needs Met",
          );

      setTimelineItems(filteredArray);
    }
  }, [data]);

  // This useEffect will use to execute refetch of SocialCareDetail data after every 10 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (open) {
        refetch();
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer({ value: false, data: null, allNeedsMet: null })}
      onOpen={toggleDrawer({ value: true, data: patient, allNeedsMet })}
      elevation={0}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box sx={{ width: 900 }} role="presentation">
        {isLoading ? (
          <CircularLoader />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                borderBottom: "1px solid #ccc",
              }}
            >
              {/* Commenting it due to V1 of Social Care */}
              {/* {isCHW ? <Typography variant="h6">Social Care Details</Typography> : <Typography variant="h6">Reason for Referral</Typography>} */}

              {/* Adding due to V1 */}
              <Typography variant="h6">Social Care Details</Typography>

              <IconButton
                onClick={toggleDrawer({
                  value: false,
                  data: null,
                  allNeedsMet: null,
                })}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <HorizontalBar
              width={900}
              patientDetails={patient}
              allNeedsMet={allNeedsMet}
            />
            <Box sx={{ padding: "10px" }}>
              <Chrono
                key={isCHW ? chwTimelineItems?.length : timelineItems?.length}
                items={isCHW ? chwTimelineItems : timelineItems}
                hideControls
                disableClickOnCircle
                mode="VERTICAL"
                cardHeight={100}
                theme={{ primary: "#0049C6", secondary: "#f5f5f5" }}
                cardPosition="top"
              >
                {/* Commenting due to V1 */}
                {/* {isCHW
							? chwCustomContent(handleOpenModal, isReferralSubmitted, setFifthItemContent, fifthItemContent, handleAddTimelineBilling, setIsBiling, isBiling)
							: customContent(handleOpenModal, isReferralSubmitted, setFifthItemContent, fifthItemContent, handleAddTimelineBilling, setIsBiling, isBiling)} */}

                {/* Adding for V1 */}
                {customContent(
                  handleOpenModal,
                  isReferralSubmitted,
                  setFifthItemContent,
                  fifthItemContent,
                  handleAddTimelineBilling,
                  setIsBiling,
                  isBiling,
                  patient,
                  data?.data,
                  timelineItems,
                  communicationData,
                )}
                <Box className="chrono-icons">
                  {/* Commenting due to V1 */}
                  {/* {isCHW
								? chwTimelineItems.map((item, index) => <CheckCircleRounded key={index} sx={{ color: '#0049C6', fontSize: '20px' }} />)
								: timelineItems.map((item, index) => <CheckCircleRounded key={index} sx={{ color: '#0049C6', fontSize: '20px' }} />)} */}

                  {/* Adding for V1 */}
                  {timelineItems.map((item, index) => {
                    return item?.title?.props?.title === "All Needs Met" &&
                      !data?.data["closed_loop"]?.allNeedsMet ? (
                      <PendingIcon
                        key={index}
                        sx={{ color: "#0049C6", fontSize: "20px" }}
                      />
                    ) : (
                      <CheckCircleRounded
                        key={index}
                        sx={{ color: "#0049C6", fontSize: "20px" }}
                      />
                    );
                  })}
                </Box>
              </Chrono>
            </Box>
          </>
        )}
      </Box>

      {modalType === "survey" && modalOpen && (
        <SurveyModal
          open={modalOpen}
          handleClose={handleCloseModal}
          data={modalData}
        />
      )}
      {modalType === "referToCHW" && (
        <ReferModal
          open={modalOpen}
          handleClose={handleCloseModal}
          onSubmit={handleAddTimelineItem}
        />
      )}
      {modalType === "referToFindHelp" && (
        <FindHelpModal
          open={modalOpen}
          handleClose={handleCloseModal}
          onSubmit={handleAddTimelineItem}
          patientId={patient?._id}
        />
      )}
      {modalType === "notes" && (
        <NoteModal
          open={modalOpen}
          setOpen={setModalOpen}
          editData={{}}
          isDrawer={true}
        />
      )}
      {modalType === "clinicalNotes" && (
        <GenerateNote
          open={modalOpen}
          handleClose={handleCloseModal}
          content="Survey Content"
        />
      )}

      <style jsx={true}>
        {`
          .TimelineItemContentWrapper-sc-d7qjm1-0 {
            height: auto !important;
            min-height: 120px;
          }
        `}
      </style>
    </SwipeableDrawer>
  );
}
