export const uuid = () =>
  new Date().getTime().toString(36) + Math.random().toString(36).slice(2);

const emojis = [
  "🍇 Grapes",
  "🍈 Melon",
  "🍉 Watermelon",
  "🍊 Tangerine",
  "🍋 Lemon",
  "🍌 Banana",
  "🍍 Pineapple",
  "🥭 Mango",
  "🍎 Red Apple",
  "🍏 Green Apple",
  "🍐 Pear",
  "🍑 Peach",
  "🍒 Cherries",
  "🍓 Strawberry",
  "🫐 Blueberries",
  "🥝 Kiwi Fruit",
  "🍅 Tomato",
  "🫒 Olive",
  "🥥 Coconut",
  "🥑 Avocado",
  "🍆 Eggplant",
  "🥔 Potato",
  "🥕 Carrot",
  "🌽 Ear of Corn",
  "🌶️ Hot Pepper",
  "🫑 Bell Pepper",
  "🥒 Cucumber",
  "🥬 Leafy Green",
  "🥦 Broccoli",
  "🧄 Garlic",
  "🧅 Onion",
  "🍄 Mushroom",
  "🥜 Peanuts",
  "🌰 Chestnut",
  "🍞 Bread",
  "🥐 Croissant",
  "🥖 Baguette Bread",
  "🫓 Flatbread",
  "🥨 Pretzel",
  "🥯 Bagel",
  "🥞 Pancakes",
  "🧇 Waffle",
  "🧀 Cheese Wedge",
  "🍖 Meat on Bone",
  "🍗 Poultry Leg",
  "🥩 Cut of Meat",
  "🥓 Bacon",
  "🍔 Hamburger",
  "🍟 French Fries",
  "🍕 Pizza",
  "🌭 Hot Dog",
  "🥪 Sandwich",
  "🌮 Taco",
  "🌯 Burrito",
  "🫔 Tamale",
  "🥙 Stuffed Flatbread",
  "🧆 Falafel",
  "🥚 Egg",
  "🍳 Cooking",
  "🥘 Shallow Pan of Food",
  "🍲 Pot of Food",
  "🫕 Fondue",
  "🥣 Bowl with Spoon",
  "🥗 Green Salad",
  "🍿 Popcorn",
  "🧈 Butter",
  "🧂 Salt",
  "🥫 Canned Food",
  "🍱 Bento Box",
  "🍘 Rice Cracker",
  "🍙 Rice Ball",
  "🍚 Cooked Rice",
  "🍛 Curry Rice",
  "🍜 Steaming Bowl",
  "🍝 Spaghetti",
  "🍠 Roasted Sweet Potato",
  "🍢 Oden",
  "🍣 Sushi",
  "🍤 Fried Shrimp",
  "🍥 Fish Cake with Swirl",
  "🥮 Moon Cake",
  "🍡 Dango",
  "🥟 Dumpling",
  "🥠 Fortune Cookie",
  "🥡 Takeout Box",
  "🦪 Oyster",
  "🍦 Soft Ice Cream",
  "🍧 Shaved Ice",
  "🍨 Ice Cream",
  "🍩 Doughnut",
  "🍪 Cookie",
  "🎂 Birthday Cake",
  "🍰 Shortcake",
  "🧁 Cupcake",
  "🥧 Pie",
  "🍫 Chocolate Bar",
  "🍬 Candy",
  "🍭 Lollipop",
  "🍮 Custard",
  "🍯 Honey Pot",
  "🍼 Baby Bottle",
  "🥛 Glass of Milk",
  "☕ Hot Beverage",
  "🫖 Teapot",
  "🍵 Teacup Without Handle",
  "🍶 Sake",
  "🍾 Bottle with Popping Cork",
  "🍷 Wine Glass",
  "🍸 Cocktail Glass",
  "🍹 Tropical Drink",
  "🍺 Beer Mug",
  "🍻 Clinking Beer Mugs",
  "🥂 Clinking Glasses",
  "🥃 Tumbler Glass",
  "🥤 Cup with Straw",
  "🧋 Bubble Tea",
  "🧃 Beverage Box",
  "🧉 Mate",
  "🧊 Ice",
  "🥢 Chopsticks",
  "🍽️ Fork and Knife with Plate",
  "🍴 Fork and Knife",
  "🥄 Spoon",
];

export const randomLabel = () => {
  return emojis[~~(Math.random() * emojis.length)];
};

// Function for Edit Node on real time will use in FILE PATH: Pages/Journeys/Drawers/EditDrawer.js
export const editNode = (
  childNodeId,
  name,
  method,
  metric,
  nodeInternals,
  delayFor,
  timeRelation,
  checkTimeCondition,
  hourToRunAt,
) => {
  // Retrieve the node value associated with the childNodeId
  const node = nodeInternals.get(childNodeId);

  if (node) {
    const { data } = node;

    // Update the label property with the provided name
    data.label = name;

    if (method === "delay") {
      // If the method is "delay", update method, metric, and value properties
      data.method = method;
      data.metric = metric;
      data.value = name;
      data.delayFor = delayFor;
      data.timeRelation = timeRelation;
      data.runAtSpecificHour = checkTimeCondition;
      data.hourToRunAt = hourToRunAt;
    } else {
      // If the method is not "delay", update only the method property
      data.method = method;
    }

    // Return the modified node
    return node;
  }

  // Return null if the childNodeId is not found
  return null;
};
