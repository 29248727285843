import { Box, Grid, Typography } from "@mui/material";
import { WeekDropdown } from "../../../../Components";
import { useIsMobile } from "../../../../contexts/isMobile";

export function AttentionHeader({ week, setWeek }) {
  const isMobile = useIsMobile();

  const gridMargin = {
    margin: isMobile && "10px 5px",
  };

  return (
    <>
      <Box sx={{ padding: isMobile ? "6px" : 3 }}>
        <Grid
          container={true}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item={true} xs={12} sm={8} sx={gridMargin}>
            <Typography variant="h5">Patient Who Need Attention</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ padding: !isMobile && "10px" }}>
                <WeekDropdown {...{ week, setWeek }} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
