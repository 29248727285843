import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
const calculateAge = (dob, gender) => {
  const genderShortForm =
    gender === "male" ? "M" : gender === "female" ? "F" : "N/A";
  if (dob) {
    // Check if the date is in ISO 8601 format
    const birthDate = dob.includes("T")
      ? new Date(dob)
      : new Date(`${dob}T00:00:00.000Z`);
    const today = new Date();
    let age_now = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return `| ${age_now}Y, ${genderShortForm}`;
  } else {
    return `| ${genderShortForm}`;
  }
};
function formatPhoneNumber(phoneNumber) {
  // Remove any non-digit characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Check if the input is valid (should be 11 digits with country code)
  if (cleaned.length === 11 && cleaned[0] === "1") {
    const country = cleaned[0]; // Country code
    const area = cleaned.slice(1, 4); // Area code
    const local = cleaned.slice(4, 7); // First 3 digits
    const number = cleaned.slice(7, 11); // Last 4 digits

    return `+${country} (${area}) ${local}-${number}`;
  }

  return phoneNumber; // Return original if not valid
}

export function HorizontalBar({ patientDetails, allNeedsMet }) {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        gap: 2,
        m: 3,
        p: 2,
        bgcolor:
          theme.palette.mode === "dark" ? "#101010" : "rgba(0, 151, 236, 0.12)",
        color: theme.palette.mode === "dark" ? "grey.300" : "#50607A",
        border: "1px solid",
        borderColor:
          theme.palette.mode === "dark"
            ? "grey.800"
            : "rgba(0, 151, 236, 0.12)",
        borderRadius: 3,
        fontSize: "14px",
        fontWeight: "600",
      })}
    >
      <Avatar
        sx={{ width: 40, height: 40 }}
        alt="Profile Image"
        //   src={
        //     path
        //       ? `${process.env.REACT_APP_BASE_API_URL}/${path}`
        //       : user?.picture
        //   }
      />

      <Stack direction="column" spacing={0.5}>
        <Typography variant="body2" sx={{ color: "#50607A" }}>
          {`${patientDetails?.firstName} ${patientDetails?.lastName}`}{" "}
          {calculateAge(patientDetails?.birthday, patientDetails?.gender)}
        </Typography>
        {patientDetails?.mrn && (
          <Typography variant="caption" sx={{ color: "#50607A" }}>
            {patientDetails?.mrn} (MRN)
          </Typography>
        )}
      </Stack>

      <Stack direction="column" spacing={0.5}>
        <Typography variant="body2" sx={{ color: "#50607A" }}>
          Phone Number
        </Typography>
        <Typography variant="caption" sx={{ color: "#50607A" }}>
          {formatPhoneNumber(patientDetails?.phone)}
        </Typography>
      </Stack>

      {/* <Stack direction="column" spacing={0.5}>
				<Typography variant="body2" sx={{ color: '#50607A' }}>
					Assignee CHW
				</Typography>
				<Typography variant="caption" sx={{ color: '#50607A' }}>
					N/A
				</Typography>
			</Stack> */}

      <Stack direction="column" spacing={0.5}>
        <Typography variant="body2" sx={{ color: "#50607A" }}>
          All Needs Met
        </Typography>
        <Typography variant="caption" sx={{ color: "#50607A" }}>
          {allNeedsMet ? "Yes" : allNeedsMet === false ? "No" : "N/A"}
        </Typography>
      </Stack>
    </Box>
  );
}
