export function MessageSvg() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2V9C16 10.1 15.1 11 14 11H10V14L6 11H2C0.899 11 0 10.1 0 9V2C0 0.9 0.899 0 2 0H14C15.1 0 16 0.9 16 2Z"
        fill="#979797"
      />
    </svg>
  );
}
