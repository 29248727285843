import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, userID, ...rest } = query;
        const finalQuery = { page, limit, userID, ...rest };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/users/?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          ...data.map((p) => ({ type: "User", id: p._id })),
        ];
      },
    }),
    getAllUser: builder.query({
      query: (id) => `/users/all/${id}`,
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          ...data.map((p) => ({ type: "User", id: p._id })),
        ];
      },
    }),
    getUserByID: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          { type: "User", id: data._id || arg },
        ];
      },
    }),
    getUserForTask: builder.query({
      query: () => `/users/all/1`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Users", id: "LIST" },
          { type: "User", id: data._id || arg },
        ];
      },
    }),
    addNewUser: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/users",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
    editUser: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/users/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "Users", id: "LIST" },
          { type: "User", id: data.id || id },
        ];
      },
    }),
    deleteUser: builder.mutation({
      query: (id) => {
        return {
          url: `/users/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIDQuery,
  useAddNewUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useGetAllUserQuery,
  useGetUserForTaskQuery,
} = extendedApiSlice;
