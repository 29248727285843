import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const dummyWeekData = [
  { _id: 1, title: "All", value: "all" },
  { _id: 2, title: "Week 1", value: "week1" },
  { _id: 3, title: "Week 2", value: "week2" },
  { _id: 4, title: "Week 3", value: "week3" },
  { _id: 5, title: "Week 4", value: "week4" },
];

export function WeekDropdown({ week, setWeek }) {
  const handleChange = (event) => {
    setWeek(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel sx={{ color: "#979797" }} id="demo-simple-select-label">
          This Week
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={week}
          label="This Week"
          onChange={handleChange}
        >
          {dummyWeekData.map((values) => (
            <MenuItem key={values._id} value={values.value}>
              {values.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
