import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function PlaceholderMenu({
  data,
  open,
  anchorEl,
  setAnchorEl,
  handleMenuClick,
  index,
}) {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        sx={{
          backgroundColor: "#816179", // Set your desired background color here
          color: "white", // Set text color
          marginY: 1,
          "&:hover": {
            backgroundColor: "#A69398", // Change background color on hover
          },
        }}
      >
        Insert Tags
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            marginTop: "20px", // Adjust the space from the top
          },
        }}
      >
        {data &&
          Object.entries(data).map(([key, value], index) => (
            <MenuItem key={index} onClick={() => handleMenuClick(value, index)}>
              {key}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
