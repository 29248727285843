import BlockIcon from "@mui/icons-material/Block";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ShieldIcon from "@mui/icons-material/Shield";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EditSvg } from "../../../assets/svgs";
import { useIsMobile } from "../../../contexts/isMobile";

const renderContactInfo = (value, IconComponent) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      marginLeft: "20px",
      alignItems: "center",
      marginBottom: "5px",
    }}
  >
    <IconComponent sx={{ color: "#616161", fontSize: "0.9rem" }} />
    <Typography
      sx={{ fontSize: "0.9rem", marginLeft: "8px", color: "rgba(0,0,0,0.60)" }}
    >
      {value}
    </Typography>
  </Box>
);

export function PatientOverview({ data }) {
  const isMobile = useIsMobile();
  const calculateAge = (dob) => {
    if (dob) {
      // Check if the date is in ISO 8601 format
      const birthDate = dob.includes("T")
        ? new Date(dob)
        : new Date(`${dob}T00:00:00.000Z`);
      const today = new Date();
      let age_now = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return `${age_now}Y`;
    } else {
      return `N/A`;
    }
  };
  const navigate = useNavigate();

  const address =
    (data?.address1 ? data?.address1 + ", " : "") +
    (data?.address2 ? data?.address2 + ", " : "") +
    (data?.city ? data?.city + ", " : "") +
    (data?.state ? data?.state + ", " : "") +
    data?.zip;

  const handleRedirect = () => {
    navigate(`/editProfile/${data?._id}`);
  };

  const getConsentColors = (consent) => {
    if (consent === "yes") {
      return {
        marginRight: "5px",
        color: "#63A02C",
      };
    } else if (consent === "no") {
      return {
        marginRight: "5px",
        color: "#DC362E",
      };
    } else {
      return {
        marginRight: "5px",
        color: "#FFB400",
      };
    }
  };
  const getCarePhase = (surgeryDate) => {
    if (new Date(surgeryDate) > new Date()) {
      return "Post Operative Case";
    } else if (new Date(surgeryDate) < new Date()) {
      return "Pre Operative Case ";
    }
  };

  const iconMap = {
    food: <Chip icon={<RestaurantIcon />} label="Food" />,
    transportation: (
      <Chip icon={<DirectionsCarIcon />} label="Transportation" />
    ),
    housing: <Chip icon={<HouseOutlinedIcon />} label="Housing" />,
    safety: <Chip icon={<ShieldIcon />} label="Safety" />,
    utility: <Chip icon={<TipsAndUpdatesIcon />} label="Utility" />,
    financial: <Chip icon={<PaymentsIcon />} label="Financial" />,
    urgent: <Chip icon={<DirectionsRunIcon />} label="Urgent" />,
  };

  return (
    <div id="overview">
      <Paper elevation={3} sx={{ height: "100%", p: 2, borderRadius: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="p"
            sx={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            Overview
          </Typography>
          <IconButton onClick={handleRedirect}>
            <EditSvg sx={{ color: "#979797" }} />
          </IconButton>
        </div>
        <Grid container={true} spacing={2} m={2}>
          <Grid item={true} md={6.5} xs={12}>
            <div style={{ display: "flex" }}>
              <Avatar
                sx={{ width: "80px", height: "80px" }}
                alt="Profile Image"
                //   src={
                //     path
                //       ? `${process.env.REACT_APP_BASE_API_URL}/${path}`
                //       : user?.picture
                //   }
              />

              <Typography
                variant="p"
                sx={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  marginTop: "10px",
                  ml: 2,
                }}
              >
                {data?.firstName} {data?.lastName} <br />
                {data?.mrn && (
                  <Typography
                    sx={{ color: "rgba(0,0,0,0.60)", fontSize: "small" }}
                  >
                    MRN : {data?.mrn ? data?.mrn : "N/A"}
                  </Typography>
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      mr: 1,
                    }}
                  >
                    {data?.birthday
                      ? `${calculateAge(data?.birthday)}, ${
                          data?.gender?.charAt(0)?.toUpperCase() || "N/A"
                        }`
                      : "N/A"}
                  </Typography>
                  <>
                    <span
                      style={{
                        ...getConsentColors(data?.consentResponse),
                        fontSize: "36px",
                        height: "18px",
                        marginTop: "-36px",
                      }}
                    >
                      •
                    </span>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 500,
                      }}
                    >
                      {data?.consentResponse === "yes"
                        ? "SMS Consent Approved"
                        : data?.consentResponse === "no"
                          ? "SMS Consent Declined"
                          : "SMS Consent Pending"}
                    </Typography>
                  </>
                </div>
              </Typography>
            </div>
            <Box
              sx={{
                marginLeft: "10px",
                background: "#F7F8FB",
                borderRadius: "10px",
                my: "15px",
                width: "90%",
                p: 2,
                mt: "3rem",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                  fontSize: "1.1rem",
                  marginBottom: "10px",
                }}
              >
                Contact Info
              </Typography>
              {renderContactInfo(data?.phone, CallOutlinedIcon)}
              {renderContactInfo(data?.email, EmailOutlinedIcon)}
              {renderContactInfo(address, LocationOnOutlinedIcon)}
            </Box>
          </Grid>

          <Grid
            item
            md={5}
            xs={12}
            sx={{ paddingTop: data?.mrn && "0px !important" }}
          >
            <Typography
              variant="p"
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
              }}
            >
              Other Demographic Information
            </Typography>
            <Grid container={true} spacing={2} mb={"1rem"}>
              <Grid item={true} md={6}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#979797",
                  }}
                >
                  DOB
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    mb: 2,
                  }}
                >
                  {data?.birthday
                    ? moment(data?.birthday).format("MM-DD-YYYY")
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item={true} md={6}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#979797",
                  }}
                >
                  Ethnicity
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    mb: 2,
                  }}
                >
                  {data?.ethnicity ? data?.ethnicity : "N/A"}
                </Typography>
              </Grid>
              <Grid item={true} md={6}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#979797",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Race
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    mb: 2,
                  }}
                >
                  {data?.race ? data?.race : "N/A"}
                </Typography>
              </Grid>
              <Grid item={true} md={6}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#979797",
                  }}
                >
                  Language
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    mb: 2,
                  }}
                >
                  {data?.language ? data?.language : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
              }}
            >
              Recent Procedure Information
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} md={6}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#979797",
                  }}
                >
                  Surgeon Name
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    mb: 2,
                  }}
                >
                  {data?.surgery?.surgeon
                    ? data?.surgery?.surgeon?.name
                    : data?.surgery?.surgeonName
                      ? data?.surgery?.surgeonName
                      : "N/A"}
                </Typography>
              </Grid>
              <Grid item={true} md={6}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#979797",
                  }}
                >
                  Procedure Date
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    mb: 2,
                  }}
                >
                  {data?.surgery?.surgeryDate
                    ? moment(data?.surgery?.surgeryDate).format("MM-DD-YYYY")
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item={true} md={12}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    color: "#979797",
                  }}
                >
                  Procedure Type
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    mb: 2,
                  }}
                >
                  {data?.surgery?.surgeryType
                    ? data?.surgery?.surgeryType
                        ?.replace(/_/g, " ")
                        .split(/\s+/)
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.slice(1),
                        )
                        .join(" ")
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ color: "#979797", width: "95%" }} />
          {(data?.socialNeeds || data?.socialRisks) &&
            (data?.socialNeeds?.length > 0 ||
              data?.socialRisks?.length > 0) && (
              <Grid item md={11} xs={11} sx={{ textAlign: "justify" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  Social Needs
                </Typography>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  {[
                    ...(data?.socialRisks || []),
                    ...(data?.socialNeeds || []),
                  ].map((icon, index) => {
                    const isSocialNeed = data.socialNeeds?.includes(icon);
                    const color = isSocialNeed ? "#ffb400" : "#DC362E";
                    const IconElement = iconMap[icon] || (
                      <Chip
                        icon={<BlockIcon />}
                        label={icon.charAt(0).toUpperCase() + icon.slice(1)}
                      />
                    );

                    return (
                      <div key={index} style={{ margin: "0px 20px 0px 0px" }}>
                        {React.cloneElement(IconElement, {
                          icon: React.cloneElement(IconElement.props.icon, {
                            sx: { "&&": { color } },
                          }),
                        })}
                      </div>
                    );
                  })}
                </Box>
              </Grid>
            )}
          <Grid item md={11} xs={11} sx={{ textAlign: "justify" }}>
            <Typography
              variant="p"
              sx={{
                fontSize: "1.1rem",
                fontWeight: 600,
                mb: 2,
              }}
            >
              Last Note
            </Typography>
            <br />
            <Typography
              variant="p"
              sx={{
                fontSize: "1rem",
                color: "#979797",
                // mb: "2rem",
              }}
            >
              {data?.patientSummary ? data?.patientSummary : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
