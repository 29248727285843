import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../../contexts/isMobile";

export function BoneHealth({ data, value, label, count }) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleRedirect = (e) => {
    navigate(`/patients/${value}`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: !isMobile && "center",
          alignItems: "center",
          margin: isMobile ? "15px 10px" : "15px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            background: "#D0EAE1",
            margin: "20px",
            width: 50,
            height: 50,
            marginLeft: isMobile ? "16px" : 0,
          }}
        >
          <Typography
            color="#816179"
            sx={{
              fontSize: isMobile ? "20px" : "24px",
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {count}
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="p"
            sx={{
              fontSize: "15px",
              color: "text.secondary",
              cursor: "pointer",
              "&:hover": {
                color: "#816179",
              },
            }}
            onClick={(e) => handleRedirect(e)}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
