import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularLoader, SolidButton } from "../../../../Components";
import OutlinedButton from "../../../../Components/OutlinedButton";
import { useIsMobile } from "../../../../contexts/isMobile";
import {
  useAddNewCallMutation,
  useGetCallAgentsQuery,
} from "../../../../store/callSlice";

export const CallModal = ({
  open,
  setOpen,
  pid,
  fetechAgain,
  status,
  receiverId,
}) => {
  const { user } = useSelector((state) => state.user);
  const isMobile = useIsMobile();

  const [data, setData] = useState([]);

  const { data: agentData, isLoading, isError } = useGetCallAgentsQuery();
  const [addNewCall, { isLoading: isLoadingNewCall }] = useAddNewCallMutation();

  const [agentId, setAgentId] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("");

  const resetValues = () => {
    setAgentId("");
    setSelectedAgent("");
  };

  useEffect(() => {
    if (agentData) {
      const formatData = (agentData) => {
        return Object.entries(agentData).map(([key, value]) => {
          const formattedKey = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
          return { id: value, title: formattedKey, value: key };
        });
      };

      const formatted = formatData(agentData);

      setData(formatted);
    }
  }, [agentData]);

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;

  const handleChange = (event, newValue) => {
    const journeyId = data?.find((item) => item?.title === newValue);
    setSelectedAgent(newValue);
    setAgentId(journeyId?.id);
  };

  const handleAssign = () => {
    if (status === "active") {
      if (selectedAgent === "" || agentId === "") {
        toast.error("Please pick an agent from the dropdown menu first.");
        return;
      }

      addNewCall({
        agent: agentId,
        receiverId,
      })
        .unwrap()
        .then(() => {
          toast.success(
            `Our AI agent ${selectedAgent} will be calling the patient. Once the call is complete you can check the Calls under patient profile`,
          );
          setOpen(false);
          resetValues();
          fetechAgain();
        });
    } else {
      toast.error("The patient status is currently inactive.");
      setOpen(false);
    }
  };

  const handleClose = () => {
    resetValues();
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "80%" : 700,
            height: isMobile ? 300 : 400,
            bgcolor: "#F5F5F5",
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Call
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          <Grid
            container={true}
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item={true} md={6} xs={12}>
              <Typography fontSize={12} mt={2}>
                Assign Agent <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedAgent}
                onChange={(event, newValue) => handleChange(event, newValue)}
                options={data?.map((item) => item?.title)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              mt: isMobile ? "2rem" : "12rem",
              justifyContent: "flex-end",
            }}
          >
            <OutlinedButton label="Cancel" handleClick={handleClose} />
            <SolidButton
              label="Call"
              onClick={handleAssign}
              disabled={isLoadingNewCall}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
