import { Paper } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetPatientTaskQuery } from "../../../store/dashboardsSlice";
import { TaskTable } from "./PatientTaskTable";
import { TableHeader } from "./TableHeader";

export function PatientTask({ setPatientTaskLoader }) {
  // UseStates for Table Header and Task Table
  const [page, setPage] = useState(1);
  const [view, setView] = useState("");
  const [week, setWeek] = useState("all");

  // Getting the user from state
  const { user } = useSelector((state) => state.user);

  // Getting the patient tasks from api
  const { data, isLoading, isError, isSuccess } = useGetPatientTaskQuery(
    {
      userID: user.id,
      page,
      view,
      week,
    },
    { refetchOnMountOrArgChange: true },
  );

  // useEffect(() => {
  //   if(isSuccess){
  //     setPatientTaskLoader(false);
  //   }else{
  //     setPatientTaskLoader(true);
  //   }

  // }, [isSuccess, setPatientTaskLoader]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
        border: "1px solid #D0EAE1",
        marginTop: "25px",
      }}
      elevation={0}
    >
      <TableHeader {...{ view, week, setWeek, setView }} />
      <TaskTable
        {...{
          page,
          setPage,
          data,
          isLoading,
          isError,
        }}
      />
    </Paper>
  );
}
