import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useStoreApi } from "reactflow";
import {
  AskModal,
  DateField as DatePicker,
  SolidButton,
} from "../../../Components";
import { ROLES } from "../../../constants/routes";
import { useIsMobile } from "../../../contexts/isMobile";
import { useGetAllContentsQuery } from "../../../store/contentsSlice";
import { useGetAllJourneysQuery } from "../../../store/journeysSlice";
import { useEditJourneyNodeMutation } from "../../../store/journeysSlice";
import { useGetAllSurveysQuery } from "../../../store/suerveySlice";
import {
  useAddNewTaskMutation,
  useGetAllTasksQuery,
} from "../../../store/tasksSlice";
import { useGetAllUserQuery } from "../../../store/userManagmentSlice";
import { uuid } from "../FlowV2/utils";
import { editNode } from "../FlowV2/utils";
import { ContentModal } from "./ContentModal";

export function MethodSelect({
  method,
  setMethod,
  setSelectForm,
  setFormQuestions,
  setCheckCondition,
  setSelectedQuestions,
  setSurveyValues,
}) {
  const handleChange = (event) => {
    setSelectForm({ id: null, form: "" });
    setMethod(event.target.value);
    setFormQuestions([]);
    setCheckCondition(false);
    setSelectedQuestions([]);
    setSurveyValues({});
  };

  return (
    <Box>
      <FormControl fullWidth={true}>
        <InputLabel id="demo-simple-select-label">Select Method</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={method}
          label="Select Method"
          onChange={handleChange}
        >
          {[
            { id: "form", label: "Form" },
            { id: "task", label: "Task" },
            { id: "delay", label: "Time Delay" },
            { id: "sms", label: "SMS" },
            { id: "trigger", label: "Trigger" },
            { id: "assign_journey", label: "Assign Journey" },
          ].map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function FormSelect({
  selectForm,
  setSelectForm,
  setSurveyValues,
  setCheckCondition,
  setConditionOperator,
  setFormQuestions,
  setSelectedQuestions,
  setMetric,
  setDelayValue,
}) {
  const { user } = useSelector((state) => state.user);
  const { data } = useGetAllSurveysQuery(user.id);
  const handleChange = (event) => {
    const selectedFormId = event.target.value;
    const selectedForm = data?.data.find((item) => item._id === selectedFormId);
    const questionsArray = [];
    setSelectForm({
      id: selectedForm._id,
      form: selectedForm.form.title,
      pages: selectedForm.form.pages,
    });

    selectedForm.form.pages.forEach((page) => {
      page.elements.forEach((element) => {
        if (
          ["radiogroup", "checkbox", "boolean", "dropdown"].includes(
            element.type,
          )
        ) {
          questionsArray.push({
            name: element.name,
            title: element.title,
            type: element.type,
            choices: element.choices,
            labelTrue: element.labelTrue,
            labelFalse: element.labelFalse,
          });
        }
      });
    });

    setFormQuestions(questionsArray);
    setCheckCondition(false);
    setMetric("weeks");
    setDelayValue("1");
    setSelectedQuestions([]);
    setSurveyValues({});
    setConditionOperator("and");
  };

  return (
    <Box>
      <FormControl fullWidth={true}>
        <InputLabel id="demo-simple-form-label">Select Form</InputLabel>
        <Select
          labelId="demo-simple-form-label"
          id="demo-simple-form"
          value={selectForm.id || ""}
          label="Select Form"
          onChange={handleChange}
        >
          {data?.data?.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {item?.form?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function DelaySelect({ metric, setMetric, defaultMetric = "" }) {
  const handleChange = (event) => {
    setMetric(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth sx={{ marginBottom: "15px" }}>
        <InputLabel id="demo-simple-delay-label">
          {defaultMetric !== ""
            ? "Wait until user completes the survey"
            : "Select Time Delay"}
        </InputLabel>
        <Select
          labelId="demo-simple-delay-label"
          id="demo-simple-delay"
          value={metric !== "" ? metric : defaultMetric}
          label="Select Time Delay"
          onChange={handleChange}
        >
          {[
            { id: "minutes", label: "Minutes" },
            { id: "hours", label: "Hours" },
            { id: "days", label: "Days" },
            { id: "weeks", label: "Weeks" },
            { id: "months", label: "Months" },
          ].map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function TaskSelect({
  task,
  setTask,
  newTask,
  setNewTask,
  responseData,
}) {
  const { user } = useSelector((state) => state.user);
  const { data, isLoading, isError } = useGetAllTasksQuery(user.id, {
    refetchOnMountOrArgChange: true,
  });

  const handleChange = (event) => {
    const task = event.target.value;

    // Check if the selected task is "Create New Task" and has a null id
    if (task.task === "Create New Task" && task.id === null) {
      setNewTask(true);
    } else {
      setNewTask(false);
      setTask({ id: task._id, task: task.title });
    }
  };

  return (
    <Box>
      <FormControl fullWidth={true}>
        <InputLabel id="demo-simple-task-label">Select Task</InputLabel>
        <Select
          labelId="demo-simple-task-label"
          id="demo-simple-task"
          value={newTask ? responseData.id : task._id}
          label="Select Task"
          onChange={handleChange}
        >
          <MenuItem key={122} value={responseData}>
            Create New Task
          </MenuItem>
          {data?.data?.map((item) => (
            <MenuItem key={item._id} value={item}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function TriggerSelect({ data, trigger, setTrigger, responseData }) {
  const handleChange = (event) => {
    const trigger = event.target.value;
    setTrigger(trigger);
  };
  return (
    <Box>
      <FormControl fullWidth={true}>
        <InputLabel id="demo-simple-trigger-label">Select Trigger</InputLabel>
        <Select
          labelId="demo-simple-task-label"
          id="demo-simple-trigger"
          value={trigger}
          label="Select Trigger"
          onChange={handleChange}
        >
          {data?.data?.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

// For new Task
export function StatusSelect({ status, setStatus }) {
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <Box>
      <FormControl
        sx={{
          padding: "3px 0px",
          "& .MuiInputLabel-root": {
            padding: "3px",
          },
          marginBottom: "5px",
        }}
        fullWidth={true}
      >
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status"
          onChange={handleChange}
        >
          {[
            { id: "assigned", label: "Assigned" },
            { id: "completed", label: "Completed" },
          ].map((item) => (
            <MenuItem value={item.id}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function MembersSelect({ selectedTags, setSelectedTags }) {
  const { data: users, isLoading, isError } = useGetAllUserQuery();

  const data = users?.data
    ?.filter((user) => user.role === ROLES.CareTeamMember)
    ?.map((CareTeamMember) => ({
      _id: CareTeamMember._id,
      tag: CareTeamMember.name,
    }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const selectedTagsText = (selected) =>
    selected?.map((tagID) => {
      return data?.find((tag) => tag._id === tagID).tag;
    });

  if (isLoading || isError) return null;
  return (
    <FormControl
      sx={{
        padding: "3px 0px",
        "& .MuiInputLabel-root": {
          padding: "3px",
        },
        marginBottom: "10px",
        marginTop: "10px",
      }}
      fullWidth={true}
    >
      <InputLabel id="demo-multiple-checkbox-label">
        Care Team Members
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        required={true}
        id="demo-multiple-checkbox"
        multiple={true}
        value={selectedTags || []}
        onChange={handleChange}
        input={
          <OutlinedInput sx={{ height: "100%" }} label="Care Team Members" />
        }
        renderValue={(selected) => selectedTagsText(selected).join(", ")}
        MenuProps={MenuProps}
      >
        {data?.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            <Checkbox checked={selectedTags.indexOf(item._id) > -1} />
            <ListItemText primary={item.tag} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const Content = ({
  toggleDrawer,
  delayValue,
  setDelayValue,
  setNewTask,
  setMetric,
  metric,
  newTask,
  journeyID,
  id,
  data,
  choice,
  title,
  method,
  setMethod,
  delay,
  setDelay,
  task,
  setTask,
  trigger,
  setTrigger,
  triggerData,
  triggerJourney,
  setTriggerJourney,
  selectForm,
  setSelectForm,
  setSms,
  sms,
  conditionOperator,
  setConditionOperator,
  surveyValues,
  setSurveyValues,
  setCheckCondition,
  checkCondition,
  formQuestions,
  setFormQuestions,
  selectedQuestions,
  setSelectedQuestions,
}) => {
  const [editJourneyNode] = useEditJourneyNodeMutation();
  const [addNewTask] = useAddNewTaskMutation();
  const [date, setDate] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [status, setStatus] = useState("assigned");
  const [url, setUrl] = useState("");
  const [responseData, setResponseData] = useState({
    id: null,
    task: "Create New Task",
  });
  const [addQuestion, setAddQuestion] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [taskForms, setTaskForms] = useState({
    title: "",
    description: "",
  });

  // States for CreateContentModal
  const [open, setOpen] = React.useState(false);
  const [openM, setOpenM] = React.useState(false);

  const handleDropdownChange = (selectedQuestion) => {
    const selectedQuestionObject = formQuestions.find(
      (question) => question.name === selectedQuestion,
    );

    setSelectedQuestions([...selectedQuestions, selectedQuestionObject]);
    let defaultValue = "";
    if (selectedQuestionObject.type === "checkbox") {
      defaultValue = [];
    } else if (selectedQuestionObject.type === "boolean") {
      defaultValue = false;
    }

    setSurveyValues({
      [`${selectedQuestionObject.name}`]: defaultValue,
      ...surveyValues,
    });

    setAddQuestion(false);
  };

  const handleRemoveQuestion = (questionName) => {
    const updatedSelectedQuestions = selectedQuestions.filter(
      (question) => question.name !== questionName,
    );
    const updatedSurveyValues = { ...surveyValues };
    delete updatedSurveyValues[questionName];

    setSelectedQuestions(updatedSelectedQuestions);
    setSurveyValues(updatedSurveyValues);
  };

  const handleCheckConditionUpdate = (e) => {
    setCheckCondition((checkCondition) => !checkCondition);
    setSurveyValues({});
    setSelectedQuestions([]);
  };
  const handleOpenCreateModal = () => {
    setOpen(true);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const triggerShowName = (triggerName) => {
    let name = triggerName;
    if (triggerName.length) {
      const newNameArr1 = triggerName.split("_");
      const firstPart = newNameArr1[0];
      const secondPart = newNameArr1[1];
      name =
        capitalizeFirstLetter(firstPart) +
        " " +
        capitalizeFirstLetter(secondPart);
    }
    return name;
  };

  const handleInputChange = (elementName, value, isCheckbox = false) => {
    setSurveyValues((prevSurveyValues) => {
      if (isCheckbox) {
        const currentValues = prevSurveyValues[elementName] || [];
        const updatedValues = currentValues.includes(value)
          ? currentValues.filter((item) => item !== value)
          : [...currentValues, value];

        return {
          ...prevSurveyValues,
          [elementName]: updatedValues,
        };
      } else {
        return {
          ...prevSurveyValues,
          [elementName]: value,
        };
      }
    });
  };

  // Integrating the get all content api
  const { data: contentData } = useGetAllContentsQuery(user.id);
  const { data: journeyListData } = useGetAllJourneysQuery(user.id);

  // Destructring the getState to get state values as React flow use zustand state management tool
  const store = useStoreApi();
  const { nodeInternals } = store.getState();

  let name = null;
  if (method === "form") {
    name = selectForm.form;
  }
  if (method === "task") {
    name = task.task;
  }
  if (method === "trigger") {
    name = triggerShowName(trigger);
  }
  if (method === "sms") {
    name = sms;
  }
  if (method === "delay") {
    name = delay;
  }
  if (method === "assign_journey") {
    name = triggerJourney.name;
  }

  const dropdownOptions = formQuestions.filter(
    (question) =>
      !selectedQuestions.some((selected) => selected.name === question.name),
  );

  const journeyData = {
    method: method,
    patientId: "6479bb531e31431a60ca5875",
  };

  if (method === "sms") {
    journeyData.sms = {
      sms,
      childNodeId: id,
      method,
      parentNodeId: data.parentNodeId,
    };
  } else if (method === "delay") {
    journeyData.delay = {
      delay: delayValue,
      metric,
      value: delay,
      childNodeId: id,
      method,
      parentNodeId: data.parentNodeId,
    };
  } else if (method === "task") {
    journeyData.task = {
      task: task.id,
      childNodeId: id,
      method,
      parentNodeId: data.parentNodeId,
    };
  } else if (method === "trigger") {
    journeyData.trigger = {
      triggerType: trigger,
      childNodeId: id,
      method,
      parentNodeId: data.parentNodeId,
    };
  } else if (method === "assign_journey") {
    journeyData.assign_journey = {
      childNodeId: id,
      method,
      parentNodeId: data.parentNodeId,
      journeyId: triggerJourney.id,
      journeyName: triggerJourney.name,
    };
  } else if (method === "form") {
    journeyData.form = {
      form: selectForm.id,
      childNodeId: id,
      method,
      parentNodeId: data.parentNodeId,
      surveyValues,
      conditionOperator,
      checkCondition,
      metric,
      delay: delayValue,
      delayValue: delay,
    };

    if (checkCondition) {
      journeyData.form.positiveChildNodeId = data.positiveChildNodeId
        ? data.positiveChildNodeId
        : uuid();
      journeyData.form.negativeChildNodeId = data.negativeChildNodeId
        ? data.negativeChildNodeId
        : uuid();
    }
  }

  const onChangeTask = (e) => {
    setTaskForms((taskForms) => ({
      ...taskForms,
      [e.target.name]: e.target.value,
    }));
  };

  const isSurveyComplete = () => {
    for (const key in surveyValues) {
      if (surveyValues.hasOwnProperty(key)) {
        const value = surveyValues[key];

        if (
          value === undefined ||
          (typeof value === "string" && value.trim() === "") ||
          (typeof value === "object" && value.length === 0)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const createJourney = async (e) => {
    e.preventDefault();
    if (method === "form" && checkCondition) {
      if (Object.keys(surveyValues).length === 0) {
        toast.error("Please select any question if you have to add a condtion");
        return;
      }

      if (!isSurveyComplete()) {
        toast.error("Please fill out the conditions for the selected form");
        return;
      }
    }

    try {
      // Editing the journey in backend

      if (
        (data.method === "form" &&
          data.checkCondition &&
          journeyData.method === "form") ||
        (data.method === "form" &&
          data.checkCondition &&
          journeyData.method !== "form")
      ) {
        setOpenM(true);
        return;
      } else {
        const edit = await editJourneyNode({
          journeyID,
          nodeID: id,
          body: { ...journeyData },
        });

        if (edit) {
          // If the edit has been saved in the backend, then editNode in UI state to show live updates
          editNode(id, name, method, metric, nodeInternals);
          toggleDrawer(false);
          toast.success("Successfully edited!");
          if (journeyData?.form?.checkCondition !== data.checkCondition) {
            window.location = "/journeysflow/" + journeyID;
          }
        } else {
          // Handle the case when the edit was not successful
          toast.error("Edit failed!");
        }
      }
    } catch (error) {
      toast.error("Some error occurred!");
    }
  };

  const createTask = async (e) => {
    e.preventDefault();
    try {
      const taskData = await addNewTask({
        ...taskForms,
        date,
        status,
        createdBy: user.id,
        members: [...selectedMembers, user.id],
      });
      if (taskData) {
        setResponseData({
          id: taskData?.data?._id,
          task: taskData?.data?.title,
        });
        setTask({
          id: taskData?.data?.data?._id,
          task: taskData?.data?.data?.title,
        });
        setNewTask(false);
        toast.success("Successfully created!");
      }
    } catch (error) {
      toast.error("Some error occurred!");
    }
  };

  useEffect(() => {
    if (metric === "months") {
      // Convert months to hours
      const monthsToHours = Number.parseInt(delay) * 30 * 24 - 1;
      setDelayValue(Number.parseInt(delay));
    }
    if (metric === "weeks") {
      // Convert weeks to hours
      const weeksToHours = Number.parseInt(delay) * 7 * 24 - 1;
      setDelayValue(Number.parseInt(delay));
    }
    if (metric === "days") {
      // Convert days to hours
      const daysToHours = Number.parseInt(delay) * 24 - 1;
      setDelayValue(Number.parseInt(delay));
    }
    if (metric === "hours") {
      const fixingHours = Number.parseInt(delay) - 1;
      setDelayValue(Number.parseInt(delay));
    }
    if (metric === "minutes") {
      const fixingHours = Number.parseInt(delay) - 1;
      setDelayValue(Number.parseInt(delay));
    }
  }, [delay, metric]);

  return (
    <Box sx={{ padding: "2rem" }}>
      <AskModal
        id={id}
        journeyID={journeyID}
        journeyData={journeyData}
        toggleDrawer={toggleDrawer}
        name={name}
        method={method}
        metric={metric}
        nodeInternals={nodeInternals}
        data={data}
        openM={openM}
        setOpenM={setOpenM}
        askText={`Are you sure you want to edit this node? This may result in the deletion of all it's child nodes?`}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography variant="p" sx={{ fontSize: 30, fontWeight: 400 }}>
          {data.method === "delay"
            ? `Time Delay/${data?.value} ${data?.metric}`
            : data.method === "sms" && title.length > 28
              ? title.slice(0, 28) + "..."
              : title}
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => toggleDrawer(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        noValidate={true}
        autoComplete="off"
      >
        <Grid container={true} spacing={2}>
          <Grid item={true} md={12} xs={12}>
            <MethodSelect
              method={method}
              setMethod={setMethod}
              setSelectForm={setSelectForm}
              setFormQuestions={setFormQuestions}
              setCheckCondition={setCheckCondition}
              setSelectedQuestions={setSelectedQuestions}
              setSurveyValues={setSurveyValues}
            />
          </Grid>
          <Grid item={true} md={12} xs={12}>
            {method === "form" && (
              <FormSelect
                selectForm={selectForm}
                setSelectForm={setSelectForm}
                setSurveyValues={setSurveyValues}
                setConditionOperator={setConditionOperator}
                setFormQuestions={setFormQuestions}
                setCheckCondition={setCheckCondition}
                setSelectedQuestions={setSelectedQuestions}
                setMetric={setMetric}
                setDelayValue={setDelayValue}
              />
            )}
            {selectForm.id && selectForm.pages.length > 0 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkCondition}
                    onChange={(e) => handleCheckConditionUpdate(e)}
                  />
                }
                label="Apply More Conditions"
              />
            )}

            {checkCondition && (
              <div>
                <FormControl fullWidth sx={{ marginTop: "1em" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select Condition
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={conditionOperator}
                    label="Select Condition"
                    onChange={(e) => setConditionOperator(e.target.value)}
                  >
                    {[
                      { id: "and", label: "Check all the answers" },
                      { id: "or", label: "Check any of the answer" },
                    ].map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ padding: "0.5rem" }}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { width: "100%" },
                    }}
                    noValidate={true}
                    autoComplete="off"
                  >
                    <Grid container={true} spacing={2}>
                      <Grid item={true} md={12} xs={12}>
                        <FormControl fullWidth={true}>
                          {selectedQuestions.map((element, index) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    marginTop: "3px",
                                    marginRight: "20px",
                                    padding: "6px",
                                  }}
                                >
                                  <Typography
                                    variant="h2"
                                    sx={{ fontSize: "20px" }}
                                  >
                                    Question
                                  </Typography>
                                </Box>
                                <Box sx={{ width: "70%" }}>
                                  <Typography
                                    variant="p"
                                    sx={{ fontSize: "16px" }}
                                  >
                                    {element.title
                                      ? element.title
                                      : element.name}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{ marginLeft: "auto", cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleRemoveQuestion(element.name)
                                  }
                                >
                                  <CloseIcon />
                                </Box>
                              </Box>
                              <Typography
                                sx={{ marginTop: "10px", fontSize: "14px" }}
                              >
                                <Box
                                  sx={{
                                    padding: "6px",
                                  }}
                                >
                                  {element.type === "radiogroup" && (
                                    <Grid item md={12} xs={12}>
                                      {element.choices.map(
                                        (choice, choiceIndex) => (
                                          <>
                                            <RadioGroup
                                              required
                                              aria-labelledby="demo-error-radios"
                                              name="quiz"
                                              value={choice}
                                              checked={
                                                surveyValues[
                                                  `${element.name}`
                                                ] === choice
                                              }
                                              onChange={() =>
                                                handleInputChange(
                                                  element.name,
                                                  typeof choice === "object"
                                                    ? choice.text
                                                    : choice,
                                                )
                                              }
                                            >
                                              <FormControlLabel
                                                checked={
                                                  surveyValues[
                                                    `${element.name}`
                                                  ] ===
                                                  (typeof choice === "object"
                                                    ? choice.text
                                                    : choice)
                                                }
                                                value={
                                                  typeof choice === "object"
                                                    ? choice.text
                                                    : choice
                                                }
                                                control={<Radio />}
                                                label={
                                                  typeof choice === "object"
                                                    ? choice.text
                                                    : choice
                                                }
                                              />
                                            </RadioGroup>
                                          </>
                                        ),
                                      )}
                                    </Grid>
                                  )}
                                  {element.type === "checkbox" && (
                                    <Grid item md={12} xs={12}>
                                      {element.choices.map(
                                        (choice, choiceIndex) => (
                                          <FormGroup
                                            key={`${element.name}-${choiceIndex}`}
                                          >
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={surveyValues[
                                                    `${element.name}`
                                                  ].includes(
                                                    typeof choice === "object"
                                                      ? choice.text
                                                      : choice,
                                                  )}
                                                  onChange={() =>
                                                    handleInputChange(
                                                      element.name,
                                                      typeof choice === "object"
                                                        ? choice.text
                                                        : choice,
                                                      true,
                                                    )
                                                  }
                                                  name={`${element.name}-${typeof choice === "object" ? choice.text : choice}`}
                                                />
                                              }
                                              label={
                                                typeof choice === "object"
                                                  ? choice.text
                                                  : choice
                                              }
                                            />
                                          </FormGroup>
                                        ),
                                      )}
                                    </Grid>
                                  )}
                                  {element.type === "boolean" && (
                                    <Grid item md={12} xs={12}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={
                                                surveyValues[`${element.name}`]
                                              }
                                              onChange={() =>
                                                handleInputChange(
                                                  element.name,
                                                  !surveyValues[
                                                    `${element.name}`
                                                  ],
                                                )
                                              }
                                            />
                                          }
                                          label={element.labelTrue}
                                        />
                                      </FormGroup>
                                    </Grid>
                                  )}
                                  {element.type === "dropdown" && (
                                    <Grid item md={12} xs={12}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Select
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-form-label"
                                          id="demo-simple-form"
                                          value={
                                            surveyValues[`${element.name}`] ||
                                            ""
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              element.name,
                                              e.target.value,
                                            )
                                          }
                                        >
                                          {element.choices.map(
                                            (choice, choiceIndex) => (
                                              <MenuItem
                                                key={choiceIndex}
                                                value={
                                                  typeof choice === "object"
                                                    ? choice.text
                                                    : choice
                                                }
                                              >
                                                {typeof choice === "object"
                                                  ? choice.text
                                                  : choice}
                                              </MenuItem>
                                            ),
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  )}
                                </Box>
                              </Typography>
                              <Divider sx={{ marginTop: "3px" }} />
                              <br />
                            </>
                          ))}
                          {addQuestion && (
                            <FormControl fullWidth>
                              <InputLabel id="question-select-label">
                                Select Question
                              </InputLabel>
                              <Select
                                labelId="question-select-label"
                                id="question-select"
                                value=""
                                label="Select Question"
                                onChange={(e) =>
                                  handleDropdownChange(e.target.value)
                                }
                              >
                                {dropdownOptions.map((element) => (
                                  <MenuItem
                                    key={element.name}
                                    value={element.name}
                                  >
                                    {element.title
                                      ? element.title
                                      : element.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <br />
                            </FormControl>
                          )}
                          {dropdownOptions.length > 0 && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) =>
                                  setAddQuestion((addQuestion) => !addQuestion)
                                }
                                sx={{ marginBottom: "1em" }}
                              >
                                Add Condition
                              </Button>
                              <br />
                              <br />
                            </>
                          )}
                          <DelaySelect
                            defaultMetric={"weeks"}
                            metric={metric}
                            setMetric={setMetric}
                            sx={{ marginTop: "1em" }}
                          />
                          <Grid item md={12} xs={12}>
                            <TextField
                              required={true}
                              id="outlined-required"
                              label={`${metric}`}
                              placeholder={`Enter ${metric}`}
                              name="title"
                              value={delay}
                              onChange={(e) => {
                                setDelay(e.target.value);
                              }}
                            />
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </div>
            )}

            {method === "delay" && (
              <>
                <DelaySelect metric={metric} setMetric={setMetric} />
                {metric !== "" && (
                  <Grid item={true} md={12} xs={12}>
                    <TextField
                      required={true}
                      id="outlined-required"
                      label={`${metric}`}
                      placeholder={`Enter ${metric}`}
                      name="title"
                      value={delay}
                      onChange={(e) => {
                        setDelay(e.target.value);
                      }}
                    />
                  </Grid>
                )}
              </>
            )}

            {method === "task" && (
              <TaskSelect
                task={task}
                setTask={setTask}
                newTask={newTask}
                setNewTask={setNewTask}
                responseData={responseData}
              />
            )}

            {method === "trigger" && (
              <TriggerSelect
                data={triggerData}
                trigger={trigger}
                setTrigger={setTrigger}
                responseData={responseData}
              />
            )}

            {method === "assign_journey" && (
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-journey-label">
                    Select Journey to Assign
                  </InputLabel>
                  <Select
                    labelId="demo-simple-journey-label"
                    label="Select Journey to Assign"
                    value={triggerJourney.id}
                    onChange={(e) =>
                      setTriggerJourney({
                        id: e.target.value,
                        name: journeyListData?.data?.find(
                          (item) => item._id === e.target.value,
                        )?.title,
                      })
                    }
                  >
                    {journeyListData?.data
                      ?.filter((item) => item._id !== journeyID)
                      ?.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item?.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {method === "sms" && (
              <>
                <TextField
                  required={true}
                  multiline={true}
                  rows={4}
                  id="outlined-textarea"
                  label="SMS"
                  placeholder="Enter SMS"
                  name="sms"
                  value={sms}
                  onChange={(e) => {
                    setSms(e.target.value);
                  }}
                />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ padding: "15px 10px", marginTop: "7px" }}>
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleOpenCreateModal()}
                    >
                      <AddCircleOutlineIcon />
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      padding: "15px 0px",
                      fontSize: 15,
                      fontWeight: "bolder",
                    }}
                  >
                    Add Content in sms
                  </Typography>
                </Box>
              </>
            )}

            {newTask === true && (
              <>
                <Grid item={true} md={12} xs={12}>
                  <TextField
                    required={true}
                    id="outlined-required"
                    label="Task Title"
                    placeholder="Enter Title"
                    name="title"
                    value={taskForms.title}
                    onChange={onChangeTask}
                    sx={{
                      padding: "3px 0px",
                      "& .MuiInputLabel-root": {
                        padding: "3px",
                      },
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                  />
                </Grid>
                <Grid
                  item={true}
                  md={12}
                  xs={12}
                  sx={{
                    "& .MuiStack-root": { marginBottom: "10px" },
                  }}
                >
                  <DatePicker {...{ date, setDate, label: "Due Date" }} />
                </Grid>
                <Grid item={true} md={12} xs={12}>
                  <StatusSelect status={status} setStatus={setStatus} />
                </Grid>
                {/* <Grid item md={12} xs={12}>
            <Box>
              <MembersSelect
                selectedTags={selectedMembers}
                setSelectedTags={setSelectedMembers}
              />
            </Box>
          </Grid> */}
                <Grid item={true} md={12} xs={12}>
                  <TextField
                    multiline={true}
                    rows={4}
                    required={true}
                    id="outlined-required"
                    label="Task Description"
                    placeholder="Enter Description"
                    name="description"
                    value={taskForms.description}
                    onChange={onChangeTask}
                    sx={{
                      padding: "3px 0px",
                      "& .MuiInputLabel-root": {
                        padding: "3px",
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          sx={{ marginTop: "1rem" }}
        >
          {newTask ? (
            <SolidButton label="Create Task" onClick={createTask} />
          ) : (
            <SolidButton label="Save" onClick={createJourney} />
          )}
        </Stack>
      </Box>
      {/* Modal Button */}
      <ContentModal
        open={open}
        setOpen={setOpen}
        setUrl={setUrl}
        url={url}
        message={sms}
        setMessage={setSms}
        title={"Choose Content"}
        data={contentData?.data || []}
      />
    </Box>
  );
};

export function EditDrawer({ state, data, toggleDrawer, title, id, choice }) {
  const { id: paramsId } = useParams();
  const [method, setMethod] = useState(data.method);
  const [delay, setDelay] = useState("1");
  const [metric, setMetric] = useState("");
  const [newTask, setNewTask] = useState(false);
  const [task, setTask] = useState({ id: null, task: "" });
  const [trigger, setTrigger] = useState({ id: null, trigger: "" });
  const [delayValue, setDelayValue] = useState("");
  const [checkCondition, setCheckCondition] = useState(false);
  const [triggerJourney, setTriggerJourney] = useState({ id: null, name: "" });
  const [selectForm, setSelectForm] = useState({ id: null, form: "" });
  const [conditionOperator, setConditionOperator] = useState("and");
  const [surveyValues, setSurveyValues] = useState({});
  const [formQuestions, setFormQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [sms, setSms] = useState("");
  const [journeyID, setJourneyID] = useState("");
  const isMobile = useIsMobile();
  const triggerData = {
    data: [
      { _id: "pre_surgery", title: "Pre Procedure" },
      { _id: "post_surgery", title: "Post Procedure" },
      { _id: "pre_appointment", title: "Pre Appointment" },
      { _id: "post_appointment", title: "Post Appointment" },
      { _id: "assign_journey", title: "Assign Journey" },
    ],
  };

  const lowerFirstLetter = (string) => {
    return string.toLowerCase();
  };

  const triggerActualName = (triggerName) => {
    let name = triggerName;
    if (triggerName.length) {
      const newNameArr1 = triggerName.split(" ");
      const firstPart = newNameArr1[0];
      const secondPart = newNameArr1[1];
      name = lowerFirstLetter(firstPart) + "_" + lowerFirstLetter(secondPart);
    }
    return name;
  };

  useEffect(() => {
    async function updateData() {
      if (data.method === "delay") {
        setDelayValue(data.label);
        setMetric(data?.metric);
        setDelay(data?.value);
      }
      if (data.method === "task") {
        setTask({ id: data.extra, task: data.label });
      }
      if (data.method === "trigger") {
        console.log("data in use", data);
        setTrigger(triggerActualName(data.label));
      }
      if (data.method === "assign_journey") {
        console.log("dsdata.journeyName", data.journeyName);
        setTriggerJourney({ id: data.journeyId, name: data.journeyName });
      }
      if (data.method === "form") {
        const questionsArray = [];
        setSelectForm({ id: data.extra, form: data.label, pages: data.pages });
        setSurveyValues(data.surveyValues);
        setConditionOperator(data.conditionOperator);
        setCheckCondition(data.checkCondition);
        data.pages.forEach((page) => {
          page.elements.forEach((element) => {
            if (
              ["radiogroup", "checkbox", "boolean", "dropdown"].includes(
                element.type,
              )
            ) {
              questionsArray.push({
                name: element.name,
                title: element.title,
                type: element.type,
                choices: element.choices,
                labelTrue: element.labelTrue,
                labelFalse: element.labelFalse,
              });
            }
          });
        });

        setMetric(data.metric);
        setDelay(data.value);
        setFormQuestions(questionsArray);
        let selectedPrevQuestions = [];

        const keys = Object.keys(data.surveyValues);
        keys.forEach((key) => {
          const selectedQuestionObject = questionsArray.find(
            (question) => question.name === key,
          );
          selectedPrevQuestions = [
            ...selectedPrevQuestions,
            selectedQuestionObject,
          ];
        });

        setSelectedQuestions(selectedPrevQuestions);
      }
      if (data.method === "sms") {
        setSms(data.label);
      }
    }
    updateData();
  }, []);

  useEffect(() => {
    if (paramsId) {
      setJourneyID(paramsId);
    } else {
      const journeyIdString = JSON.parse(localStorage.getItem("journeyId"));
      setJourneyID(journeyIdString);
    }
  });

  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: isMobile ? "80%" : "40%",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Content
          toggleDrawer={toggleDrawer(false)}
          setDelayValue={setDelayValue}
          delayValue={delayValue}
          setMetric={setMetric}
          metric={metric}
          journeyID={journeyID}
          setNewTask={setNewTask}
          newTask={newTask}
          data={data}
          choice={choice}
          id={id}
          title={title}
          method={method}
          setMethod={setMethod}
          delay={delay}
          setDelay={setDelay}
          task={task}
          setTask={setTask}
          trigger={trigger}
          setTrigger={setTrigger}
          triggerData={triggerData}
          triggerJourney={triggerJourney}
          setTriggerJourney={setTriggerJourney}
          selectForm={selectForm}
          setSelectForm={setSelectForm}
          setSms={setSms}
          sms={sms}
          conditionOperator={conditionOperator}
          setConditionOperator={setConditionOperator}
          surveyValues={surveyValues}
          setSurveyValues={setSurveyValues}
          setCheckCondition={setCheckCondition}
          checkCondition={checkCondition}
          formQuestions={formQuestions}
          setFormQuestions={setFormQuestions}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      </Drawer>
    </React.Fragment>
  );
}
