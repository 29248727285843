import { useDebouncedValue } from "@mantine/hooks";
import { Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { CircularLoader } from "../../../Components";
import OutlinedButton from "../../../Components/OutlinedButton";
import { useGetCallsByPatientIDQuery } from "../../../store/callSlice";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import { CallModal } from "./ProfileModals/CallModal";
import { callData } from "./ProfileUtils";

export default function CallTable({ id, user, status, receiverId }) {
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["name"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
  const [open, setOpen] = useState(false);
  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};
  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }

  const { data, isLoading, isError, refetch, error } =
    useGetCallsByPatientIDQuery({ id, page });

  const handleModal = () => {
    setOpen(true);
  };

  const fetechAgain = () => {
    refetch();
  };

  if (isLoading) return <CircularLoader />;
  if (isError) return <div>{error}</div>;

  return (
    <div id="call">
      <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Call
        </Typography>
        <Grid
          container={true}
          justifyContent={"space-between"}
          sx={{ paddingY: "12px" }}
        >
          {/* <Grid item md={4} xs={6}>
          <SearchField
            {...{
              searchTerm,
              setSearchTerm,
              options: searchAbleFields,
              selectedOptions: selectedFields,
              handleOptionSelect: handleFieldSelect,
            }}
          />
          </Grid> */}
          <Grid item={true} md={12} xs={12} textAlign={"right"}>
            <OutlinedButton label={"Create Call"} handleClick={handleModal} />
          </Grid>
        </Grid>
        <ProviderInfo
          data={data?.calls || []}
          columns={callData}
          tableType="call"
          count={data?.totalPages}
          {...{
            page,
            setPage,
            setEditData,
            handleModalEdit: handleModal,
            fetchAgain: fetechAgain,
          }}
        />
      </Paper>
      <CallModal
        {...{
          open,
          setOpen,
          fetechAgain,
          setEditData,
          editData,
          status,
          receiverId,
        }}
      />
    </div>
  );
}
