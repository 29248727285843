export default function filterByDate(selectedValue) {
  const today = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

  let filteredDate;

  switch (selectedValue) {
    case "all":
      filteredDate = "";
      break;
    case "today":
      today.setHours(0, 0, 0, 0);
      filteredDate = today.toISOString();
      break;
    case "yesterday":
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      filteredDate = yesterday.toISOString();
      break;
    case "7":
      const sevenDaysAgo = new Date(today.getTime() - 7 * oneDay);
      sevenDaysAgo.setHours(0, 0, 0, 0);
      filteredDate = sevenDaysAgo.toISOString();
      break;
    case "30":
      const thirtyDaysAgo = new Date(today.getTime() - 30 * oneDay);
      thirtyDaysAgo.setHours(0, 0, 0, 0);
      filteredDate = thirtyDaysAgo.toISOString();
      break;
    default:
      filteredDate = "";
  }

  return filteredDate;
}
