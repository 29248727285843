import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Portal,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { DateField } from "../../../Components";
import { patientQueueFilterData } from "../../../FilterUtils";
import { useGetUserForTaskQuery } from "../../../store/userManagmentSlice";

export function CreateTask({
  taskData: data,
  task,
  setTask,
  newTask,
  setNewTask,
  responseData,
  date,
  setDate,
  status,
  setStatus,
  taskForms,
  setTaskForms,
  data: nodeData,
  userId,
  setUserId,
  taskCategory,
  setTaskCategory,
  acuity,
  setAcuity,
}) {
  const container = useRef(null);
  const containerStatus = useRef(null);
  const containerStatus1 = useRef(null);
  const containerStatus2 = useRef(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { data: userData } = useGetUserForTaskQuery();

  const [forceUpdate2, setForceUpdate2] = useState(false);

  const handleChange = (event) => {
    const task = event.target.value;

    // Check if the selected task is "Create New Task" and has a null id
    if (task.task === "Create New Task" && task.id === null) {
      setNewTask(true);
    } else {
      setNewTask(false);
      const selectedTask = data.data.find((item) => item._id === task);
      if (selectedTask) {
        setTask({ id: selectedTask._id, task: selectedTask.title });
      }
    }
  };

  const handleChangeUser = (event) => {
    setUserId(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setTaskCategory(event.target.value);
  };

  const handleChangeAcuity = (event) => {
    setAcuity(event.target.value);
  };

  const onChangeTask = (e) => {
    setTaskForms((taskForms) => ({
      ...taskForms,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (newTask) {
      setForceUpdate(true);
    } else {
      setForceUpdate(false);
    }
  }, [newTask]);

  useEffect(() => {
    if (nodeData?.extra) {
      const filteredTaskData = data?.data?.find(
        (item) => item._id === nodeData?.extra,
      );
      setTask({ id: filteredTaskData?._id, task: filteredTaskData?.title });
    }
  }, [nodeData]);

  useEffect(() => {
    setForceUpdate2(true);
  }, []);

  return (
    <>
      <FormControl
        fullWidth
        ref={containerStatus}
        sx={{
          textAlign: "left",
        }}
      >
        <InputLabel id="demo-simple-form-task">Select Task</InputLabel>
        {forceUpdate2 && (
          <Portal container={() => containerStatus.current}>
            <Select
              fullWidth
              labelId="demo-simple-form-task"
              id="demo-simple-task"
              label="Select Task"
              onChange={handleChange}
              value={newTask ? responseData : task.id}
            >
              <MenuItem
                key="default"
                value={responseData}
                sx={{
                  "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "100%",
                  },
                  width: "500px",
                }}
              >
                Create New Task
              </MenuItem>
              {data?.data?.map((item) => (
                <MenuItem
                  key={item._id}
                  value={item?._id}
                  sx={{
                    "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                      width: "100%",
                    },
                    width: "500px",
                  }}
                >
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </Portal>
        )}
      </FormControl>

      {newTask === true && (
        <>
          <Grid item md={12} xs={12}>
            <TextField
              required
              fullWidth
              id="outlined-required"
              label="Task Title"
              placeholder="Enter Title"
              name="title"
              className="nopan"
              value={taskForms.title}
              onChange={onChangeTask}
              sx={{
                padding: "3px 0px",
                "& .MuiInputLabel-root": {
                  padding: "3px",
                },
                marginBottom: "5px",
                marginTop: "5px",
              }}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              "& .MuiStack-root": { marginBottom: "10px" },
            }}
          >
            <DateField {...{ date, setDate, label: "Due Date*" }} />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl
              fullWidth
              ref={container}
              sx={{
                textAlign: "left",
                my: 2,
              }}
            >
              <InputLabel id="demo-simple-form-status">Status</InputLabel>
              {forceUpdate && (
                <Portal container={() => container.current}>
                  <Select
                    fullWidth
                    labelId="demo-simple-form-status"
                    id="demo-simple-status"
                    value={status}
                    label="Status"
                    onChange={handleChangeStatus}
                  >
                    {[
                      { id: "assigned", label: "Assigned" },
                      { id: "completed", label: "Completed" },
                    ].map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        sx={{
                          "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                            width: "100%",
                          },
                          width: "500px",
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Portal>
              )}
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              required
              id="outlined-required"
              label="Task Description"
              placeholder="Enter Description"
              name="description"
              className="nopan"
              value={taskForms.description}
              onChange={onChangeTask}
              sx={{
                padding: "3px 0px",
                "& .MuiInputLabel-root": {
                  padding: "3px",
                },
              }}
            />
          </Grid>
        </>
      )}

      <FormControl
        fullWidth
        ref={containerStatus1}
        sx={{
          textAlign: "left",
          marginTop: "15px",
        }}
      >
        <InputLabel id="demo-simple-form-task">Select User</InputLabel>
        <Portal container={() => containerStatus1.current}>
          <Select
            fullWidth
            labelId="demo-simple-form-task"
            id="demo-simple-task"
            label="Select User"
            onChange={handleChangeUser}
            value={userId}
          >
            {userData?.data?.map((item) => (
              <MenuItem
                key={item._id}
                value={item?._id}
                sx={{
                  "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "100%",
                  },
                  width: "500px",
                }}
              >
                {item?.name}
              </MenuItem>
            ))}
          </Select>
        </Portal>
      </FormControl>

      <FormControl
        fullWidth
        ref={containerStatus2}
        sx={{
          textAlign: "left",
          marginTop: "15px",
        }}
      >
        <InputLabel id="demo-simple-form-task">Select Task Category</InputLabel>
        <Portal container={() => containerStatus2.current}>
          <Select
            fullWidth
            labelId="demo-simple-form-task"
            id="demo-simple-task"
            label="Select Task Category"
            onChange={handleChangeCategory}
            value={taskCategory}
          >
            {patientQueueFilterData?.taskCategory?.value.map((item) => (
              <MenuItem
                key={item.id}
                value={item?.value}
                sx={{
                  "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "100%",
                  },
                  width: "500px",
                }}
              >
                {item?.title}
              </MenuItem>
            ))}
          </Select>
        </Portal>
      </FormControl>

      <FormControl sx={{ marginTop: "15px" }}>
        <FormLabel id="demo-radio-buttons-group-label">Select Acuity</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="N/A"
          name="radio-buttons-group"
          value={acuity}
          onChange={handleChangeAcuity}
        >
          <FormControlLabel value="high" control={<Radio />} label="High" />
          <FormControlLabel value="medium" control={<Radio />} label="Medium" />
          <FormControlLabel value="low" control={<Radio />} label="Low" />
          <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
        </RadioGroup>
      </FormControl>
    </>
  );
}
