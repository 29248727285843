import { Grid } from '@mui/material'
import { LeftPart } from './LeftPart'
import { RightSide } from './RightSide'

export function UpperAnalytics({data, backendData, queriesValue, setQueriesValue}) {
	return (
		<Grid container spacing={0.5} sx={{ mt: '1rem', display: 'flex' }}>
			{/* <Grid item xs={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
				<LeftPart style={{ flex: 1 }} data={data} />
			</Grid> */}
			<Grid item xs={12} sx={{ display: 'flex', alignItems: 'stretch' }}>
				<RightSide style={{ flex: 1 }} data={data} backendData={backendData} queriesValue={queriesValue} setQueriesValue={setQueriesValue} /> {/* Allow RightSide to stretch */}
			</Grid>
		</Grid>
	)
}
