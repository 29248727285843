export const journeyData = [
  { id: "title", label: "Title" },
  { id: "description", label: "Description" },
  { id: "createdAt", label: "Assigned On" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

export const formsData = [
  { id: "title", label: "Title" },
  { id: "createdAt", label: "Assigned Date" },
  { id: "updatedAt", label: "Date Filled" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

export const SurgeryData = [
  { id: "surgeryDate", label: "Procedure Date" },
  { id: "surgeonName", label: "Surgeon Name" },
  { id: "surgeryType", label: "Procedure Type" },
  { id: "surgeryStatus", label: "Procedure Status" },
  { id: "action", label: "Action" },
];

export const NoteData = [
  { id: "title", label: "Title" },
  { id: "noteType", label: "Note Type" },
  { id: "date", label: "Date" },
  // {id: 'generated', label: 'Generated By'},
  // {id: 'edit', label : 'Edited By'},
  { id: "metadata", label: "Sources" },
  { id: "status", label: "Status" },
  // {id:"description",label:"Description"},
  { id: "action", label: "Action" },
];

export const careAllyData = [
  { id: "name", label: "Name" },
  { id: "relationship", label: "Relationship" },
  { id: "phoneNumber", label: "Contact Number" },
  { id: "consentResponse", label: "SMS Consent" },
  { id: "action", label: "Action" },
];

export const tasksData = [
  { id: "title", label: "Title" },
  { id: "description", label: "Description" },
  { id: "dueDate", label: "Due Date" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

export const appointmentData = [
  { id: "dateTime", label: "Appointment Time" },
  { id: "location", label: "Location" },
  { id: "reasonForVisit", label: "Reason For Visit" },
  { id: "providerDetails", label: "Provider Details" },
  { id: "completed", label: "Status" },
  { id: "action", label: "Action" },
];

export const callData = [
  { id: "agentId", label: "Agent Name" },
  { id: "createdAt", label: "Date" },
  { id: "call_status", label: "Call Picked-up" },
  { id: "action", label: "Action" },
  // {id: 'transcript', label: 'Transcript'},
  // {id: 'summary', label: 'Summary'},
  // {id: 'note', label: 'Note'},
  // {id: 'recording', label: 'Recording'},
];
