import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useIsAuthenticatedUser } from "../contexts/isAuthenticatedUser";
import { isAllowed } from "../utils";

export function Protected({ children, allowedRoles = [] }) {
  const { isAuthenticatedUser: isAuth } = useIsAuthenticatedUser();
  const { user } = useSelector((state) => state.user);
  if (!isAuth) return <Navigate to="/" replace={true} />;

  const isNotAllowed = !isAllowed(allowedRoles, user.role);
  if (isNotAllowed) return <Navigate to="/unauthorized" replace={true} />;

  return children;
}

// export function Protected({ children, allowedRoles = [] }) {
//   return (
//     <IsAuthenticatedUserProvider>
//       <ProtectedCheck allowedRoles={allowedRoles}>{children}</ProtectedCheck>
//     </IsAuthenticatedUserProvider>
//   )

// }
