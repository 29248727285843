import { Box, Stack, Typography } from "@mui/material";

export function Thankyou({ heading, paragraph }) {
  return (
    <Stack direction="row" justifyContent="center" sx={{ height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h1" sx={{ marginBottom: "5px" }}>
          {heading ? heading : `Thank you for your participation`}
        </Typography>
        <Typography variant="body1">
          {paragraph
            ? paragraph
            : "We appreciate your time and valuable feedback!"}
        </Typography>
      </Box>
    </Stack>
  );
}
