import { Paper } from "@mui/material";
import { SlideCount } from "./SlideCount";

export function CareCount({ data, isError }) {
  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
        border: "1px solid #D0EAE1",
        marginTop: "25px",
      }}
      elevation={0}
    >
      <SlideCount {...{ data, isError }} />
    </Paper>
  );
}
