import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJourneys: builder.query({
      // query: () => "journeys",

      query: (query = {}) => {
        const { page = 1, limit = 10, userID, ...rest } = query;
        const finalQuery = { page, limit, userID, ...rest };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/journeys/?${params.toString()}`;
      },
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Journeys", id: "LIST" },
          ...data.map((p) => ({ type: "Journeys", id: p._id })),
        ];
      },
    }),
    getAllJourneys: builder.query({
      query: ({ id, params, procedureId }) => {
        const paramsObj = new URLSearchParams(params); // Convert the string into URLSearchParams object

        if (procedureId) {
          // Add procedureId to paramsObj
          paramsObj.append("procedureId", procedureId);
        }

        return `/journeys/getAllJourneys/${id}?${paramsObj.toString()}`;
      },
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Journeys", id: "LIST" },
          ...data.map((p) => ({ type: "Journeys", id: p._id })),
        ];
      },
    }),
    getJourneyByID: builder.query({
      query: (id) => `/journeys/${id}`,
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Journeys", id: "LIST" },
          ...data.map((p) => ({ type: "Journeys", id: p._id })),
        ];
      },
    }),
    addNewJourney: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/journeys",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesJourneys: [{ type: "Journeys", id: "LIST" }],
    }),
    cloneJourney: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/journeys/cloneJourney/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesJourneys: [{ type: "Journeys", id: "LIST" }],
    }),
    cloneJourneyForHospital: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/journeys/cloneJourneyToHospital/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesJourneys: [{ type: "Journeys", id: "LIST" }],
    }),
    editJourney: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/journeys/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesJourneys: ({ data }, error, { id }) => {
        return [
          { type: "Journeys", id: "LIST" },
          { type: "Journey", id: data.id || id },
        ];
      },
    }),
    editJourneyDetail: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/journeys/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesJourneys: ({ data }, error, { id }) => {
        return [
          { type: "Journeys", id: "LIST" },
          { type: "Journey", id: data.id || id },
        ];
      },
    }),
    publishJourney: builder.mutation({
      query: (id) => {
        return {
          url: `/journeys/publishJourney/${id}`,
          method: "PUT",
        };
      },
    }),
    addJourneyNode: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/journeys/addJourneyNode/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesJourneys: ({ data }, error, { id }) => {
        return [
          { type: "Journeys", id: "LIST" },
          { type: "Journey", id: data.id || id },
        ];
      },
    }),
    editJourneyNode: builder.mutation({
      query: ({ journeyID, nodeID, body }) => {
        return {
          url: `/journeys/${journeyID}/${nodeID}`,
          method: "PUT",
          body,
        };
      },
      invalidatesJourneys: ({ data }, error, { id }) => {
        return [
          { type: "Journeys", id: "LIST" },
          { type: "Journey", id: data.id || id },
        ];
      },
    }),
    deleteJourney: builder.mutation({
      query: (id) => {
        return {
          url: `/journeys/${id}`,
          method: "DELETE",
        };
      },
      invalidatesJourneys: [{ type: "Journeys", id: "LIST" }],
    }),
    deleteJourneyNode: builder.mutation({
      query: ({ journeyID, nodeID, parentID, keepBranch }) => {
        let url;
        if (keepBranch) {
          url = `/journeys/${journeyID}/${nodeID}/${parentID}/?keepBranch=${keepBranch}`;
        } else {
          url = `/journeys/${journeyID}/${nodeID}/${parentID}`;
        }
        return {
          url,
          method: "DELETE",
        };
      },
      invalidatesJourneys: [{ type: "Journeys", id: "LIST" }],
    }),
  }),
});

export const {
  useGetJourneysQuery,
  useGetAllJourneysQuery,
  useGetJourneyByIDQuery,
  useAddNewJourneyMutation,
  useCloneJourneyMutation,
  useCloneJourneyForHospitalMutation,
  useEditJourneyMutation,
  useAddJourneyNodeMutation,
  useEditJourneyNodeMutation,
  useDeleteJourneyMutation,
  useDeleteJourneyNodeMutation,
  useEditJourneyDetailMutation,
  usePublishJourneyMutation,
} = extendedApiSlice;
