import { Badge, Box, Drawer, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { UnknownDrawerHeader } from "../../Pages/Dashboard/PatientQueue/UnknownDrawerHeader";
import { Call } from "../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Conversation } from "../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { useIsMobile } from "../../contexts/isMobile";
import UnknownCallTab from "./UnknownCallTab";
import UnknownConversationTab from "./UnknownConversationTab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export function UnknownCallerMessageDrawer({
  state,
  toggleDrawer,
  data,
  setOpenUnknownCallerChat,
  refetchQueueAgain,
}) {
  const isMobile = useIsMobile();
  const tabItems = [];

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    // const objectData = {
    //   message: 0,
    //   call: 1,
    //   new_media: 2,
    //   form_submission: 3,
    //   care_ally_message: careAllyData && 4,
    //   dialogue:
    //     (selectedPatientStatus === "resolved"
    //       ? queueData?.type === "dialogue"
    //       : queueData?._id?.type === "dialogue") && dialogueAssessmentValue,
    //   assistant: AiAssistantValue,
    //   task: 0,
    // };

    // const getKeyByValue = (obj, value) => {
    //   return Object.entries(obj).find(([key, val]) => val === value)?.[0];
    // };

    setValue(newValue);
    // addInParams("formType", getKeyByValue(objectData, newValue));
  };

  tabItems.push(
    <Tab
      key="messages"
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: 600,
            textTransform: "capitalize",
            color: value === 0 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Badge
            color="error"
            variant="dot"
            invisible={true}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Conversation color={value === 0 ? "#000" : "rgba(0,0,0,0.60)"} />
            <span style={{ marginLeft: "5px" }}>Messages</span>
          </Badge>
        </div>
      }
      {...a11yProps(0)}
    />,
  );
  tabItems.push(
    <Tab
      key="calls"
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: 600,
            textTransform: "capitalize",
            color: value === 1 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Call color={value === 1 ? "#000" : "rgba(0,0,0,0.60)"} />
          <span style={{ marginLeft: "5px" }}>Calls</span>
        </div>
      }
      {...a11yProps(1)}
    />,
  );

  console.log(state, "STATE");

  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflow: "hidden",
            width: isMobile ? "90%" : "60%",
          },
        }}
        anchor="right"
        open={state?.value}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 990,
          }}
        >
          <UnknownDrawerHeader
            data={state}
            setOpenUnknownCallerChat={setOpenUnknownCallerChat}
            refetchQueueAgain={refetchQueueAgain}
          />
        </Box>

        <Box sx={{ padding: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
              position: "sticky",
              top: "5.8rem",
              backgroundColor: "white",
              //   height: "6%",
              zIndex: "990",
              padding: 3,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                sx: {
                  left: `calc(${value} * 15%)`,
                  "&.MuiTabs-indicator": {
                    backgroundColor: "#000",
                  },
                },
              }}
            >
              {tabItems}
            </Tabs>
          </div>
          <CustomTabPanel value={value} index={0}>
            <UnknownConversationTab
              {...{
                phone: state?.results?.phone_number,
                consentResponse: state?.results?.consentResponse,
              }}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <UnknownCallTab
              {...{
                queueId: state.data,
                taskCategory: state.taskCategory,
                data,
              }}
            />
          </CustomTabPanel>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
