import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Chrono } from 'react-chrono'
import CircleIcon from '@mui/icons-material/Circle'
import { useDeleteSurgeryMutation, useGetPatientSurgeriesQuery } from '../../store/surgeriesSlice'
import { CircularLoader } from '../Loader'
import AddIcon from '@mui/icons-material/Add'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'
import { SurgeryModal } from '../../Pages/Patient/PatientProfile/ProfileModals/SurgeryModal'
import { useDeleteAppointmentMutation } from '../../store/appointmentsSlice'
import { toast } from 'react-toastify'

const ContentAccordion = ({ data, defaultExpanded, handleModal, setEditData, handleDelete }) => {
	const handleIconClick = (event) => {
		event.stopPropagation() // Prevents the Accordion from toggling
		setEditData(data)
		handleModal()
	}

	const handleIconDelete = (event) => {
		event.stopPropagation() // Prevents the Accordion from toggling
		handleDelete(data?._id)
	}

	return (
		<Accordion sx={{ border: 'none', boxShadow: 'none', width: '100%' }} defaultExpanded={defaultExpanded}>
			<AccordionSummary aria-controls="panel1-content" id="panel1-header">
				<Box sx={{ width: '100%' }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Surgeon
							</Typography>
							<Typography variant="body2">{data?.surgeon?.name}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Specialty
							</Typography>
							<Typography variant="body2">{data?.surgeon?.speciality}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Type
							</Typography>
							<Typography variant="body2">{data?.surgeryType}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Status
							</Typography>
							<Typography variant="body2">{data?.surgeryStatus}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<Typography variant="subtitle2" sx={{ mb: 1 }}>
								Consent
							</Typography>
							<Typography variant="body2">{data?.surgeon?.consent}</Typography>
						</Grid>

						<Grid item xs={12} sm={2}>
							<IconButton onClick={handleIconClick} size="small">
								<BorderColorIcon fontSize="small" />
							</IconButton>
							<IconButton onClick={handleIconDelete} size="small">
								<DeleteIcon fontSize="small" />
							</IconButton>
						</Grid>
					</Grid>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Typography variant="subtitle2">Selected Journeys:</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Typography variant="body2">
							{data?.assignJourneyDetails?.length > 0 ? data.assignJourneyDetails.map((journey) => journey.title).join(', ') : 'N/A'}{' '}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Typography variant="subtitle2">Location:</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Typography variant="body2">{data?.location}</Typography>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Typography variant="subtitle2">Description:</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Typography variant="body2">{data?.description}</Typography>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	)
}

const customContent = (dummyCallData, handleModal, setEditData, handleDelete) => {
	return dummyCallData.map((data, index) => (
		<Box key={index} sx={{ width: '-webkit-fill-available' }}>
			<ContentAccordion data={data} defaultExpanded={index === 0} handleModal={handleModal} setEditData={setEditData} handleDelete={handleDelete} />
		</Box>
	))
}

const CustomTitle = ({ dateTime }) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography variant="subtitle" color="textSecondary" fontSize={'14px'}>
				{moment(dateTime).format('DD-MMM-YY')}
			</Typography>
			<Typography variant="body2" color="textSecondary" fontSize={'12px'}>
				{moment(dateTime).format('h:mm A')}
			</Typography>
		</Box>
	)
}

const ProcedureTab = ({ user, id, status }) => {
	const [open, setOpen] = useState(false)
	const [editData, setEditData] = useState({})
	const [timelineItems, setTimelineItems] = useState([{ title: <CustomTitle dateTime={'2024-11-25T15:30:45Z'} /> }])
	const [limit, setLimit] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [deleteSurgery] = useDeleteSurgeryMutation()

	const { isLoading, isSuccess, isError, error, data, refetch } = useGetPatientSurgeriesQuery({
		userID: user.id,
		page: 1,
		patientId: id,
		limit,
		location: '',
		description: '',
	})

	const handleDelete = async (id) => {
		await deleteSurgery(id)
			.unwrap()
			.then((res) => {
				refetch()
				toast.success('Procedure is deleted')
				// setOpenModal(false);
			})
			.catch((error) => {
				toast.error('Something went wrong')
			})
	}

	console.log(data, 'DATA')

	const handleModal = () => {
		setOpen(true)
	}
	const handleViewMore = () => {
		setCurrentPage((prevPage) => prevPage + 1)
		setLimit((prevLimit) => prevLimit + 10)
		refetch()
	}

	const fetechAgain = () => {
		refetch()
	}

	useEffect(() => {
		if (data?.data) {
			const timelineDataReturn = data?.data?.map((item) => {
				return { title: <CustomTitle dateTime={item?.createdAt} /> }
			})
			setTimelineItems(timelineDataReturn)
		}
	}, [data])

	if (isLoading) return <CircularLoader />

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography variant="h5">Procedures</Typography>
				<Button variant="contained" startIcon={<AddIcon />} onClick={() => handleModal()}>
					Create New
				</Button>
			</Box>
			<Box
				sx={{
					height: '67vh',
					overflowY: 'scroll',
					overflowX: 'hidden',
				}}
				className="DialogContent"
			>
				{data?.data?.length !== 0 ? (
					<>
						<Box>
							<Chrono
								key={timelineItems?.length}
								items={timelineItems}
								hideControls
								disableClickOnCircle
								mode="VERTICAL"
								disableAutoScrollOnClick={true}
								scrollable={false}
								cardHeight={100}
								theme={{ primary: '#0049C6', secondary: '#f5f5f5' }}
								cardPosition="top"
							>
								{customContent(data?.data ? data?.data : [], handleModal, setEditData, handleDelete)}

								<Box className="chrono-icons">
									{timelineItems.map((item, index) => (
										<CircleIcon key={index} sx={{ color: '#0049C6', fontSize: '20px' }} />
									))}
								</Box>
							</Chrono>
						</Box>
						{currentPage < data?.totalPages && (
							<Box sx={{ display: 'flex', justifyContent: 'flexStart', marginTop: '16px', marginLeft: '20%' }}>
								<Button variant="contained" onClick={handleViewMore}>
									View Old Procedure
								</Button>
							</Box>
						)}
					</>
				) : (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '60vh',
						}}
					>
						<Typography variant="h6">No Procedure Found</Typography>
					</Box>
				)}
			</Box>
			<SurgeryModal {...{ open, setOpen, fetechAgain, setEditData, editData, status, patientId: id }} />
		</Box>
	)
}

export default ProcedureTab
