import { Box } from "@mui/material";
import { InactivityCheck } from "../../../Components/InactivityCheck";
import { useIsMobile } from "../../../contexts/isMobile";
import { SurveyCreatorWidget } from "./SurveyCreatorWidget";

export function CreatorPage() {
  const isMobile = useIsMobile();
  return (
    <Box
      display="flex"
      alignItems="center"
      padding={isMobile ? "1.5rem 1rem" : "1.5rem 2rem"}
      bgcolor={"background.default"}
    >
      <SurveyCreatorWidget />
      <InactivityCheck />
    </Box>
  );
}
