import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFindHelp: builder.query({
      query: (id) => {
        return `/findhelp/${id}`;
      },
    }),
  }),
});

export const { useGetFindHelpQuery } = extendedApiSlice;
