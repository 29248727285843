import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export function SingleSelectDropdown({
  data,
  setData,
  placeholder,
  options,
  filterData,
  setKeyData,
  setKey,
  allMetric,
  setConsent,
  setProcedurePhase,
  setProType,
}) {
  function getKeysForMetric(metric) {
    return allMetric
      .filter((item) => item.metric === metric)
      .map((item) => item.key);
  }

  function getSingleKeyForMetric(metric) {
    const resData = allMetric?.find((item) => item.metric === metric);
    if (resData) {
      return { key: resData?.key, value: resData?.values };
    } else {
      return null;
    }
  }

  const handleChange = (event) => {
    setData(event.target.value);

    const key = convertTitleCaseToSnakeCase(event.target.value);

    const selectedData = filterData[key];

    if (typeof selectedData === "object" && !Array.isArray(selectedData)) {
      const optionsArray = Object.entries(selectedData).map(([k, v]) => ({
        [k]: v,
      }));
      setKeyData(optionsArray);
    } else if (Array.isArray(selectedData)) {
      const optionsArray = selectedData.map((item) => ({ [item]: item }));
      setKeyData(optionsArray);
    } else {
      setKeyData([]);
    }

    setKey(getKeysForMetric(event.target.value));
    setConsent(getSingleKeyForMetric(event.target.value));
    setProcedurePhase(getSingleKeyForMetric(event.target.value));
    setProType(getSingleKeyForMetric(event.target.value));
  };

  function convertSnakeCaseToTitleCase(key) {
    if (!key.includes("_")) {
      return key?.charAt(0)?.toUpperCase() + key?.slice(1); // Capitalize the first letter if no underscores
    }
    return key
      .split("_") // Split the string by underscores
      .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back with a space
  }

  function convertTitleCaseToSnakeCase(key) {
    if (!key.includes(" ")) {
      return key?.replace(/([a-z])([A-Z])/g, "$1_$2")?.toLowerCase();
    }
    return key
      ?.split(" ") // Split the string by spaces
      ?.map((word) => word?.toLowerCase()) // Convert each word to lowercase
      ?.join("_"); // Join the words back with an underscore
  }

  return (
    <Box sx={{ m: 1, minWidth: 300 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data}
          label={placeholder}
          onChange={handleChange}
        >
          {options?.length !== 0 &&
            options.map((item, index) => (
              <MenuItem key={index} value={convertSnakeCaseToTitleCase(item)}>
                {convertSnakeCaseToTitleCase(item)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
