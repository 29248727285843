import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useIsMobile } from "../../../contexts/isMobile";
import { useNotAllowedRole } from "../../../hooks";
import { SeachField, SolidButton } from "./../../../Components";
import { AddTaskDrawer } from "./AddTaskDrawer";

export function TableHeader({
  searchTerm,
  setSearchTerm,
  options,
  selectedOptions,
  handleOptionSelect,
}) {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const isNotAllowed = useNotAllowedRole();

  const width = isMobile ? "100%" : "200px";
  const direction = isMobile ? "column" : "row";
  const stackStyle = {
    paddingTop: isMobile ? "1rem" : "3rem",
    paddingBottom: isMobile ? "1rem" : "3rem",
    paddingRight: isMobile ? "0.5rem" : "1rem",
    paddingLeft: isMobile && "0.5rem",
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <Stack
      spacing={2}
      direction={direction}
      justifyContent="end"
      sx={stackStyle}
    >
      {!isNotAllowed && (
        <SolidButton label="Create" onClick={toggleDrawer(true)} />
      )}
      <Box
        sx={[
          { height: 40, borderColor: "#979797" },
          {
            "&": { width },
          },
        ]}
      >
        <SeachField
          {...{
            searchTerm,
            setSearchTerm,
            options,
            selectedOptions,
            handleOptionSelect,
          }}
        />
      </Box>
      <AddTaskDrawer toggleDrawer={toggleDrawer} state={open} />
    </Stack>
  );
}
