import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import {
  CircularLoader,
  DeleteModal,
  PaginationBar,
} from "../../../Components";
import { DeleteSvg, EditSvg } from "../../../assets/svgs";
import { useDeleteCarePathwayMutation } from "../../../store/carepathwaysSlice";
import { hasScope } from "../../../utils";
import { CarePathwayModal } from "./CarePathwayModal";

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "right",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "right",
  },
  {
    id: "journeyIds",
    label: "Journeys",
    minWidth: 170,
    align: "right",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const createRows = (
  contents,
  handleOpenModal,
  handleDelete,
  openModal,
  setOpenModal,
  datas,
  handleCopyClick,
  scopes,
  openEditModal,
  setOpenEditModal,
  refetchAgain,
) => {
  const rows =
    contents &&
    contents?.map(
      ({ _id, name, description, journeyIds, procedureType = null }, index) => {
        return {
          name: (
            <>
              <Typography
                variant="p"
                sx={{
                  fontSize: 14,
                  color: "black",
                  padding: "7px",
                }}
              >
                {name}
              </Typography>
            </>
          ),
          description: (
            <>
              <Typography
                variant="p"
                sx={{
                  fontSize: 14,
                  color: "black",
                  padding: "7px",
                }}
              >
                {description}
              </Typography>
            </>
          ),
          journeyIds: (
            <>
              <Typography
                variant="p"
                sx={{
                  fontSize: 14,
                  color: "black",
                  padding: "7px",
                }}
              >
                {journeyIds?.length !== 0
                  ? journeyIds?.map((item) => item?.title)?.join(", ")
                  : "N/A"}
              </Typography>
            </>
          ),
          actions: (
            <>
              <Stack
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <div
                  onClick={(event) =>
                    handleCopyClick(
                      _id,
                      name,
                      description,
                      journeyIds?.map((item) => item?._id),
                      procedureType,
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <EditSvg />
                </div>

                {hasScope(scopes, "settings/contentManagers:delete") && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(event) => handleOpenModal(event, name, _id)}
                  >
                    <DeleteSvg />
                  </div>
                )}
              </Stack>

              <DeleteModal
                open={openModal}
                setOpen={setOpenModal}
                id={datas}
                handle={handleDelete}
              />
              <CarePathwayModal
                open={openEditModal.open}
                setOpen={setOpenEditModal}
                title="Edit Care Pathway"
                refetchAgain={refetchAgain}
                data={{
                  id: openEditModal.id,
                  name: openEditModal.name,
                  description: openEditModal.description,
                  journeyIds: openEditModal.journeyIds,
                  procedureType: openEditModal?.procedureType,
                }}
              />
            </>
          ),
        };
      },
    );

  return rows;
};

export function CarePathwayTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  searchTerm,
  scopes,
  refetchAgain,
}) {
  // States
  const [openModal, setOpenModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState({
    open: false,
    id: null,
    name: "",
    description: "",
    journeyIds: [],
    procedureType: "",
  });
  const [datas, setData] = React.useState("");

  // Mutation:
  const [deleteCarePathway] = useDeleteCarePathwayMutation();

  // Functions:
  const handleOpenModal = (event, name, id) => {
    setData(id);
    setOpenModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (id) => {
    await deleteCarePathway(id)
      .unwrap()
      .then((res) => {
        toast.success("Care Pathway deleted successfully!");
        refetchAgain();
      })
      .catch((e) => {
        toast.error(e?.data?.error || e?.message || "some error occured!");
      });
  };

  const handleCopyClick = (
    id,
    name,
    description,
    journeyIds,
    procedureType,
  ) => {
    setOpenEditModal({
      open: true,
      id,
      name,
      description,
      journeyIds,
      procedureType,
    });
  };

  const ProcedureType = data ? data?.data?.data : [];
  const rows = createRows(
    ProcedureType,
    handleOpenModal,
    handleDelete,
    openModal,
    setOpenModal,
    datas,
    handleCopyClick,
    scopes,
    openEditModal,
    setOpenEditModal,
    refetchAgain,
  );

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return (
      <Typography sx={{ margin: "10px 20px" }}>
        No Care Pathway found.
      </Typography>
    );
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader={true} aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align={"center"}
                  sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => {
              return (
                <TableRow hover={true} role="checkbox" tabIndex={-1} key={i}>
                  {columns?.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data?.totalPages, handleChange: handleChangePage }}
      />
    </>
  );
}
