import { Box, Stack, Typography } from "@mui/material";
import { useIsMobile } from "../../../contexts/isMobile";

export function ErrorMsg() {
  const isMobile = useIsMobile();
  return (
    <Stack direction="row" justifyContent="center" sx={{ height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: isMobile ? "80%" : "100%",
        }}
      >
        <Typography variant="h1" sx={{ marginBottom: "5px" }}>
          Journey Not Found!
        </Typography>
        <Typography variant="body1">
          Oops! It seems like the journey you are looking for doesn't exist or
          has been deleted.
        </Typography>
      </Box>
    </Stack>
  );
}
