import {
  Box,
  Checkbox,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ROLES } from "../../../constants/routes";
import { useAddNewTaskMutation } from "../../../store/tasksSlice";
import { useGetAllUserQuery } from "../../../store/userManagmentSlice";
import { DateField as DatePicker, SolidButton } from "./../../../Components";

export function StatusSelect({ status, setStatus }) {
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <Box>
      <FormControl sx={{ padding: "3px" }} fullWidth>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status"
          onChange={handleChange}
        >
          {[
            { id: "assigned", label: "Assigned" },
            { id: "completed", label: "Completed" },
          ].map((item) => (
            <MenuItem value={item.id}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function MembersSelect({ selectedTags, setSelectedTags }) {
  const {
    data: users,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useGetAllUserQuery();

  const data = users?.data
    ?.filter((user) => user.role === ROLES.CareTeamMember)
    ?.map((CareTeamMember) => ({
      _id: CareTeamMember._id,
      tag: CareTeamMember.name,
    }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const selectedTagsText = (selected) =>
    selected?.map((tagID) => {
      return data?.find((tag) => tag._id === tagID).tag;
    });

  if (isLoading || isError) return null;
  return (
    <FormControl sx={{ padding: "3px" }} fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">
        Care Team Members
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        required={true}
        id="demo-multiple-checkbox"
        multiple={true}
        value={selectedTags || []}
        onChange={handleChange}
        input={
          <OutlinedInput sx={{ height: "100%" }} label="Care Team Members" />
        }
        renderValue={(selected) => selectedTagsText(selected).join(", ")}
        MenuProps={MenuProps}
      >
        {data?.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            <Checkbox checked={selectedTags.indexOf(item._id) > -1} />
            <ListItemText primary={item.tag} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const Content = ({ toggleDrawer }) => {
  const { user } = useSelector((state) => state.user);
  const [date, setDate] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [status, setStatus] = useState("assigned");
  const [form, setForm] = useState({
    title: "",
    description: "",
  });

  const [addNewTask, { isLoading, isError }] = useAddNewTaskMutation();

  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const createTask = (e) => {
    e.preventDefault();
    addNewTask({
      ...form,
      date,
      status,
      members: selectedMembers,
    })
      .unwrap()
      .then(() => {
        toggleDrawer(false);
        toast.success("Successfully created!");
      })
      .catch((error) => {
        toast.error(error.data);
      });
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography variant="p" sx={{ fontSize: 30, fontWeight: 400 }}>
          Create Task 1
        </Typography>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        noValidate={true}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              required={true}
              id="outlined-required"
              label="Title"
              placeholder="Enter Title"
              name="title"
              value={form.title}
              onChange={onChange}
              sx={{ padding: "3px" }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required={true}
              id="outlined-required"
              label="Description"
              placeholder="Enter Description"
              name="description"
              value={form.description}
              onChange={onChange}
              sx={{ padding: "3px" }}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              "& .MuiStack-root": { padding: "3px" },
            }}
          >
            <DatePicker {...{ date, setDate, label: "Due Date" }} />
          </Grid>
          <Grid item md={6} xs={12}>
            <StatusSelect status={status} setStatus={setStatus} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box>
              <MembersSelect
                selectedTags={selectedMembers}
                setSelectedTags={setSelectedMembers}
              />
            </Box>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          sx={{ marginTop: "3rem" }}
        >
          <SolidButton label="Save" onClick={createTask} />
        </Stack>
      </Box>
    </Box>
  );
};

export function AddTaskDrawer({ state, toggleDrawer }) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "80%",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Content toggleDrawer={toggleDrawer(false)} />
      </Drawer>
    </React.Fragment>
  );
}
