import { useDebouncedValue } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InactivityCheck } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useGetJourneysQuery } from "../../../store/journeysSlice";
import { JourneyTable } from "./JourneyTable";
import { TableHeader } from "./TableHeader";

export function JourneysTableV2() {
  const isMobile = useIsMobile();
  const { user } = useSelector((state) => state.user);
  const [page, setPage] = useState(
    localStorage.getItem("page")
      ? Number.parseInt(localStorage.getItem("page"))
      : 1,
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["title", "description"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};

  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }

  const { isLoading, isSuccess, isError, error, data, refetch } =
    useGetJourneysQuery({
      userID: user.id,
      page,
      ...queries,
    });

  const fetchAgain = () => {
    refetch();
  };

  useEffect(() => {
    refetch(); // Trigger the initial query fetch
  }, [refetch]);

  // If Token is expire it navigate to Login page

  return (
    <div style={{ padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem" }}>
      <TableHeader
        {...{
          searchTerm,
          setSearchTerm,
          options: searchAbleFields,
          selectedOptions: selectedFields,
          handleOptionSelect: handleFieldSelect,
        }}
      />
      <JourneyTable
        data={data || {}}
        {...{ page, setPage, isSuccess, isError, error, isLoading }}
        fetchAgain={fetchAgain}
      />
      <InactivityCheck />
    </div>
  );
}
