import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Grid,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SolidButton } from "../../Components";
import OutlinedButton from "../../Components/OutlinedButton";
import { useIsMobile } from "../../contexts/isMobile";
import { useAddReminderPatientsSurveyMutation } from "../../store/patientsSurveySlice";

export const SmsNotificationModal = ({
  open,
  setOpen,
  patientName,
  surveyId,
  setSurveyId,
}) => {
  const isMobile = useIsMobile();

  const [initialRender, setInitialRender] = useState(true);
  const [smsOnFormAssignment, setSmsOnFormAssignment] = useState("");

  const [addReminderPatientsSurvey, { isLoading }] =
    useAddReminderPatientsSurveyMutation();

  const resetValues = () => {
    setSurveyId(null);
    setSmsOnFormAssignment(
      `Dear ${patientName},\nWe hope this message finds you well. This is a friendly reminder to complete your survey at your earliest convenience.\nPlease click the link below to access the survey:\n{{ surveyLink }}\nThank you for your time and cooperation.`,
    );
  };

  const handleValidation = () => {
    if (
      smsOnFormAssignment === "" ||
      smsOnFormAssignment === "{{ surveyLink }}"
    ) {
      toast.error("SMS Notification Message is required");
    }
  };

  const handleClose = () => {
    resetValues();
    setOpen(false);
  };

  const handleAssign = () => {
    if (
      smsOnFormAssignment === "" ||
      smsOnFormAssignment === "{{ surveyLink }}"
    ) {
      handleValidation();
      return;
    }

    const body = { message: smsOnFormAssignment };
    addReminderPatientsSurvey({
      body,
      id: surveyId,
    })
      .unwrap()
      .then(() => {
        toast.success("Reminder Message send Successfully!");
        resetValues();
        setOpen(false);
      })

      .catch((error) => {
        toast.error(error?.data?.error);
        console.log(error, "error in Sms Notification Modal");
        setOpen(true);
      });
    handleClose();
  };

  const handleSmsChange = (e) => {
    const newValue = e.target.value;
    if (!newValue.includes("{{ surveyLink }}")) {
      toast.error(
        "You must include {{ surveyLink }} in the SMS Notification Message.",
      );
      return;
    }
    setSmsOnFormAssignment(newValue);
  };

  useEffect(() => {
    if (smsOnFormAssignment === "" && initialRender) {
      setSmsOnFormAssignment(
        `Dear ${patientName},\nWe hope this message finds you well. This is a friendly reminder to complete your survey at your earliest convenience.\nPlease click the link below to access the survey:\n{{ surveyLink }}\nThank you for your time and cooperation.`,
      );
      setInitialRender(false);
    }
  }, [smsOnFormAssignment]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "80%" : 700,
            height: 400,
            bgcolor: "#F5F5F5",
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              SMS Reminder
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          <Grid
            container={true}
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item={true} md={12} xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize={12} mr={1}>
                  SMS Notification Message{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Tooltip title="This message will be sent as a reminder to the patient. The placeholder {{ surveyLink }} will be replaced with the actual survey link when the message is delivered.">
                  <Box
                    sx={{ color: "grey", paddingTop: "3px", cursor: "pointer" }}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Box>
                </Tooltip>
              </Box>
              <TextField
                variant="standard"
                fullWidth={true}
                name="address1"
                multiline={true}
                rows={7}
                value={smsOnFormAssignment}
                onChange={(e) => handleSmsChange(e)}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
            }}
          >
            <OutlinedButton label="Cancel" handleClick={handleClose} />
            <SolidButton
              label="Send"
              onClick={handleAssign}
              disabled={isLoading}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
