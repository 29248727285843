import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
// biome-ignore lint/nursery/useImportRestrictions: <explanation>
import { useChildNode } from "../contexts/addChildNode.js";
import { SmsForm } from "./React-Flow-Form-V2/index.js";

const Content = ({
  menuMethod,
  setOpenDrawer,
  data,
  id,
  nodeID,
  nodeInternals,
  setForceRenderNode,
  isEdit,
  setIsEdit,
  setExpanded,
  childNodeId,
  setChildNodeId,
  childDelayNodeId,
  setChildDelayNodeId,
  positiveChildNodeId,
  setPositiveChildNodeId,
  negativeChildNodeId,
  setNegativeChildNodeId,
  addChildNode,
  setAnchorEl,
  checkConditionRender,
  setCheckConditionRender,
  selectedQuestionsRender,
  setSelectedQuestionsRender,
  selectedRemindersRender,
  setSelectedRemindersRender,
  isfilled,
  handleDelete,
}) => {
  return (
    <Box sx={{ padding: "2rem" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography variant="p" sx={{ fontSize: 30, fontWeight: 400 }}>
          {data?.method?.replace("_", " ")?.toUpperCase()}
        </Typography>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (!isfilled()) {
              handleDelete();
            }
            setOpenDrawer(false);
          }}
        >
          <CloseIcon />
        </Box>
      </Stack>
      <SmsForm
        {...{
          method: data?.method,
          data,
          id,
          nodeID,
          nodeInternals,
          setForceRenderNode,
          isEdit,
          setIsEdit,
          setExpanded,
          childNodeId,
          setChildNodeId,
          childDelayNodeId,
          setChildDelayNodeId,
          positiveChildNodeId,
          setPositiveChildNodeId,
          negativeChildNodeId,
          setNegativeChildNodeId,
          addChildNode,
          setAnchorEl,
          checkConditionRender,
          setCheckConditionRender,
          selectedQuestionsRender,
          setSelectedQuestionsRender,
          selectedRemindersRender,
          setSelectedRemindersRender,
        }}
      />
    </Box>
  );
};

export function AddDrawerV2({
  state,
  toggleDrawer,
  menuMethod,
  method,
  data,
  id,
  nodeID,
  nodeInternals,
  setForceRenderNode,
  isEdit,
  setIsEdit,
  setExpanded,
  childNodeId,
  setChildNodeId,
  childDelayNodeId,
  setChildDelayNodeId,
  positiveChildNodeId,
  setPositiveChildNodeId,
  negativeChildNodeId,
  setNegativeChildNodeId,
  addChildNode,
  setAnchorEl,
  checkConditionRender,
  setCheckConditionRender,
  selectedQuestionsRender,
  setSelectedQuestionsRender,
  selectedRemindersRender,
  setSelectedRemindersRender,
  handleDelete,
  isfilled,
}) {
  const { setOpenDrawer, openDrawer } = useChildNode();

  const handleClose = () => {
    if (!data.isFilled) {
      handleDelete();
    }
    setOpenDrawer(false);
  };

  return (
    <React.Fragment key="anchor">
      {console.log(data, "STATE")}
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
          },
        }}
        anchor="right"
        open={state}
        onClose={() => {
          handleClose();
        }}
      >
        <Content
          {...{
            menuMethod,
            setOpenDrawer,
            handleDelete,
            method,
            data,
            id,
            nodeID,
            nodeInternals,
            setForceRenderNode,
            isEdit,
            setIsEdit,
            setExpanded,
            childNodeId,
            setChildNodeId,
            childDelayNodeId,
            setChildDelayNodeId,
            positiveChildNodeId,
            setPositiveChildNodeId,
            negativeChildNodeId,
            setNegativeChildNodeId,
            addChildNode,
            setAnchorEl,
            checkConditionRender,
            setCheckConditionRender,
            selectedQuestionsRender,
            setSelectedQuestionsRender,
            selectedRemindersRender,
            setSelectedRemindersRender,
            isfilled,
          }}
        />
      </Drawer>
    </React.Fragment>
  );
}
