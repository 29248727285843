import { Box, Modal, Typography } from "@mui/material";
import { useIsMobile } from "../contexts/isMobile";
import OutlinedButton from "./OutlinedButton";
import { SolidButton } from "./SolidButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius: "10px",
};

export const LinkCarePathwayModal = ({
  open,
  setOpen,
  handle,
  setAssignPathwayJourney,
}) => {
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  const handleYesClick = () => {
    setOpen(false);
    handle(true);
  };

  const handleNoClick = () => {
    setOpen(false);
    handle(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Do you want to assign the journeys linked to the new care pathway
            you selected to the patient?
          </Typography>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Box sx={{ margin: "5px" }}>
                <SolidButton
                  label={"Yes"}
                  onClick={handleYesClick}
                  btnWidth={isMobile ? "100%" : "90px"}
                />
              </Box>
              <Box sx={{ margin: "5px" }}>
                <OutlinedButton label={"No"} handleClick={handleNoClick} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
