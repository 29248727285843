import { Box, Typography } from "@mui/material";
import { SolidButton } from "../Components";

function ErrorPage() {
  const returnBack = () => {
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 50);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      <img
        src={require("../assets/404.jpg")}
        style={{ width: "500px" }}
        alt="404"
      />
      <Typography
        sx={{ fontWeight: "bold", fontSize: "36px", color: "#0049C6" }}
      >
        Whoops!
      </Typography>
      <Typography
        sx={{ fontWeight: 500, fontSize: "24px", color: "#588add", mb: 1 }}
      >
        Something went wrong!
      </Typography>
      <SolidButton label="Return Back" onClick={() => returnBack()} />
    </Box>
  );
}

export default ErrorPage;
