import { useDebouncedValue } from "@mantine/hooks";
import { Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { CircularLoader } from "../../../Components";
import OutlinedButton from "../../../Components/OutlinedButton";
import SearchField from "../../../Components/SearchField";
import { useGetTasksQuery } from "../../../store/tasksSlice";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import AssignTaskModal from "./ProfileModals/TasksModal";
import { tasksData } from "./ProfileUtils";

const TasksDetailsData = [
  {
    name: "N/A",
    description: "N/A",
    dueDate: "N/A",
    status: "N/A",
    action: "N/A",
  },
];
export default function TaskTable({ id: patientId, user, status }) {
  const [editData, setEditData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const [page, setPage] = useState(1);
  const searchAbleFields = ["title", "description"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
  const [open, setOpen] = useState(false);
  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};

  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }

  const handleModal = () => {
    setOpen(true);
  };

  const { isLoading, isSuccess, isError, error, data, refetch } =
    useGetTasksQuery({
      userID: user.id,
      page,
      patientId,
      ...queries,
    });

  const fetchAgain = () => {
    refetch();
  };

  if (isLoading) return <CircularLoader />;
  if (isError) return <div>{error}</div>;
  return (
    <div id="tasks">
      <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Tasks
        </Typography>
        <Grid
          container
          justifyContent={"space-between"}
          sx={{ paddingY: "12px" }}
        >
          <Grid item md={4} xs={6}>
            <SearchField
              {...{
                searchTerm,
                setSearchTerm,
                options: searchAbleFields,
                selectedOptions: selectedFields,
                handleOptionSelect: handleFieldSelect,
              }}
            />
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <OutlinedButton label={"Assign"} handleClick={handleModal} />
          </Grid>
        </Grid>
        <ProviderInfo
          data={data?.data || []}
          tableType={"task"}
          columns={tasksData}
          count={data?.totalPages}
          {...{
            page,
            setPage,
            setEditData,
            handleModalEdit: handleModal,
            fetchAgain,
          }}
        />
      </Paper>
      <AssignTaskModal
        {...{ open, setOpen, fetchAgain, setEditData, editData, status }}
      />
    </div>
  );
}
