import moment from "moment";
import { Chrono } from "react-chrono";

const Timeline = ({ events }) => {
  function formatString(input) {
    // Insert a space before each uppercase letter, except for the first character
    const withSpaces = input?.replace(/([A-Z])/g, " $1");

    // Capitalize the first letter of the string
    const capitalized =
      withSpaces?.charAt(0)?.toUpperCase() + withSpaces?.slice(1);

    return capitalized;
  }

  const chronoItems = events.map((event) => ({
    title: moment(event?.date).format("MM-DD-YYYY"),
    cardTitle: formatString(event?.eventType),
    cardDetailedText: event.description,
  }));

  return (
    <div>
      <Chrono
        items={chronoItems}
        mode="VERTICAL_ALTERNATING"
        hideControls={true}
        cardHeight={100}
        theme={{ primary: "#0049C6", secondary: "#f5f5f5" }}
      />
    </div>
  );
};

export default Timeline;
