export const messagesColumn = [
	{ id: 'collapsibleIcon', label: '' },
	{
		id: 'patientInfo',
		label: 'Patient Information',
		maxWidth:110
	},
	{
		id: 'taskCategory',
		label: 'Task Category',
		maxWidth:70
	},
	{
		id: 'createdAt',
		label: 'Contact Time',
		maxWidth:90
	},
	{
		id: 'surgeonName',
		label: 'Physician Name',
		maxWidth:80
	},
	{
		id: 'surgeryType',
		label: 'Procedure Type',
		maxWidth:60
	},
	// {
	// 	id: 'clinic',
	// 	label: 'Clinic',
	// 	maxWidth:90
	// },
	// {
	// 	id: 'surgeryDate',
	// 	label: 'Procedure Date',
	// },
	{
		id:'queueType',
		label:'Queue Type',
		maxWidth:80
	},
	{
		id: 'socialNeeds',
		label: 'Social Needs',
		maxWidth:80
	},
	{
		id: 'acuity',
		label: 'Acuity',
		maxWidth:100
	},
	{
		id: 'assignee',
		label: ' Assignee',
		maxWidth:20
	},
	// {
	// 	id: 'ChatbotMode',
	// 	label: 'Chatbot Mode',
	// },

  {
    id: "action",
    label: "Action",
    maxWidth: 80,
  },
];

export const dialoguesColumn = [
  { id: "collapsibleIcon", label: "" },
  {
    id: "patientInfo",
    label: "Patient Information",
  },
  {
    id: "dialogueIntent",
    label: "Dialogue Intent",
  },
  {
    id: "createdAt",
    label: "Message Time",
  },
  {
    id: "surgeonName",
    label: "Surgeon Name",
  },
  {
    id: "surgeryType",
    label: "Procedure Type",
  },
  {
    id: "surgeryDate",
    label: "Procedure Date",
  },
  {
    id: "socialNeeds",
    label: "Social Needs",
  },
  {
    id: "acuity",
    label: "Acuity",
  },
  {
    id: "ChatbotMode",
    label: "Chatbot Mode",
  },

  {
    id: "action",
    label: "Action",
  },
];

export const CareAllyColumn = [
  {
    id: "patientInfo",
    label: "Patient Information",
  },
  {
    id: "taskCategory",
    label: "Task Category/Conversation",
  },
  {
    id: "createdAt",
    label: "Message Time",
  },
  {
    id: "surgeonName",
    label: "Surgeon Name",
  },
  {
    id: "surgeryType",
    label: "Procedure Type",
  },
  {
    id: "surgeryDate",
    label: "Procedure Date",
  },
  {
    id: "socialNeeds",
    label: "Social Needs",
  },
  {
    id: "acuity",
    label: "Acuity",
  },

  {
    id: "action",
    label: "Action",
  },
];

export const PhotosColumn = [
  {
    id: "patientInfo",
    label: "Patient Information",
  },
  {
    id: "taskCategory",
    label: "Task Category",
  },
  {
    id: "createdAt",
    label: "Creation Time",
  },
  {
    id: "socialNeeds",
    label: "Social Needs",
  },
  {
    id: "acuity",
    label: "Acuity",
  },
  {
    id: "action",
    label: "Action",
  },
];

export const FormsColumn = [
  {
    id: "patientInfo",
    label: "Patient Information",
  },
  {
    id: "taskCategory",
    label: "Task Category",
  },
  {
    id: "createdAt",
    label: "Creation Time",
  },
  {
    id: "socialNeeds",
    label: "Social Needs",
  },
  {
    id: "acuity",
    label: "Acuity",
  },
  {
    id: "action",
    label: "Action",
  },
];
