import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";

export const reasonData = [
  { _id: 1, title: "Wound Condition", value: "woundCondition" },
  { _id: 2, title: "Swelling", value: "swelling" },
  { _id: 3, title: "Dressing", value: "dressing" },
  {
    _id: 4,
    title: "Mobility and Range of Motion",
    value: "mobilityAndRangeOfMotion",
  },
  { _id: 5, title: "Equipment Issues", value: "equipmentIssues" },
  { _id: 6, title: "Skin Findings", value: "skinFindings" },
  { _id: 7, title: "Medication Reactions", value: "medicationReactions" },
  { _id: 8, title: "Other", value: "other" },
];

export function ReasonDropdown({
  reason,
  setReason,
  inputReason,
  setInputReason,
  reasonBool,
  setReasonBool,
}) {
  const [error, setError] = useState("");
  const handleChange = (event) => {
    setReason(event.target.value);
    setReasonBool(!reasonBool);
  };

  const handleInputChnage = (event) => {
    const { value } = event.target;

    if (value.length <= 50) {
      setInputReason(value);
      setError("");
    } else {
      // setInputReason(value.slice(0, 50));
      setError("Cannot enter more than 50 characters");
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 120, my: 2 }}>
        <FormControl required fullWidth>
          <InputLabel
            sx={{ color: "#979797" }}
            id="demo-simple-select-required-label"
          >
            {" "}
            Reason
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={reason}
            label="Reason"
            name="reason"
            onChange={handleChange}
          >
            {reasonData.map((values) => (
              <MenuItem key={values._id} value={values.title}>
                {values.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {reason === "Other" && (
        <TextField
          variant="standard"
          fullWidth={true}
          required={true}
          id="outlined-required"
          label="Reason"
          name="reason"
          value={inputReason}
          onChange={handleInputChnage}
          error={error !== ""}
          helperText={error}
        />
      )}
    </>
  );
}
