import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularLoader } from "../../Components";
import { InactivityCheck } from "../../Components/InactivityCheck";
import { patientQueueFilterData } from "../../FilterUtils";
import { useGetCarePathwayQuery } from "../../store/carepathwaysSlice";
import {
  useGetPatientQueueFilterQuery,
  useGetPatientQueuesQuery,
} from "../../store/patientQueuesSlice";
import { useGetPhysiciansOptionsQuery } from "../../store/physiciansSlice";
import { useGetProceduresOptionsQuery } from "../../store/proceduresSlice";
import { toSnakeCase } from "../../utils";
import { FilterSidebar } from "../Dashboard/FilterSidebar";
import { PatientQueue } from "../Dashboard/PatientQueue";
import filterByDate from "./DateFilterFunction";

export default function QueueIndex() {
  const { user } = useSelector((state) => state.user);

  const [page, setPage] = useState(
    localStorage.getItem("page1")
      ? Number.parseInt(localStorage.getItem("page1"))
      : 1,
  );
  const [filterData, setFilterData] = useState({});
  const [queriesValue, setQueriesValue] = useState(
    localStorage.getItem("queriesValue1")
      ? JSON.parse(localStorage.getItem("queriesValue1"))
      : {},
  );
  const [selectedPatientIds, setSelectedPatientIds] = useState([]);

  // Sorting states
  const [sortCreatedAtOrder, setSortCreatedAtOrder] = useState(
    localStorage.getItem("sortCreatedAtOrder1")
      ? JSON.parse(localStorage.getItem("sortCreatedAtOrder1"))
      : { field: "createdAt", sort: "desc" },
  );
  const [sortAcuityOrder, setSortAcuityOrder] = useState(
    localStorage.getItem("sortAcuityOrder1")
      ? JSON.parse(localStorage.getItem("sortAcuityOrder1"))
      : { field: "acuity", sort: "asc" },
  );
  const [sortPatientOrder, setSortPatientOrder] = useState(
    localStorage.getItem("sortPatientOrder1")
      ? JSON.parse(localStorage.getItem("sortPatientOrder1"))
      : { field: "patientName", sort: "asc" },
  );
  const [sortField, setSortField] = useState(
    localStorage.getItem("sortField1")
      ? localStorage.getItem("sortField1")
      : "",
  );
  const [sortSurgeonName, setSortSurgeonName] = useState(
    localStorage.getItem("sortSurgeonName1")
      ? JSON.parse(localStorage.getItem("sortSurgeonName1"))
      : { field: "surgeonName", sort: "asc" },
  );
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem("sortOrder1")
      ? localStorage.getItem("sortOrder1")
      : "",
  );
  const [createdAfter, setCreatedAfter] = useState(
    localStorage.getItem("createdAfter1")
      ? localStorage.getItem("createdAfter1")
      : "",
  );
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue1")
      ? localStorage.getItem("selectedValue1")
      : "all",
  );

  const [sortTaskCategoryOrder, setSortTaskCategoryOrder] = useState(
    localStorage.getItem("sortTaskCategoryOrder1")
      ? JSON.parse(localStorage.getItem("sortTaskCategoryOrder1"))
      : { field: "taskCategory", sort: "asc" },
  );

  const [sortPhysicianNameOrder, setSortPhysicianNameOrder] = useState(
    localStorage.getItem("sortPhysicianNameOrder1")
      ? JSON.parse(localStorage.getItem("sortPhysicianNameOrder1"))
      : { field: "surgeonName", sort: "asc" },
  );

  const [sortProcedureTypeOrder, setSortProcedureTypeOrder] = useState(
    localStorage.getItem("sortProcedureTypeOrder1")
      ? JSON.parse(localStorage.getItem("sortProcedureTypeOrder1"))
      : { field: "surgeryType", sort: "asc" },
  );

  const [sortQueueTypeOrder, setSortQueueTypeOrder] = useState(
    localStorage.getItem("sortQueueTypeOrder1")
      ? JSON.parse(localStorage.getItem("sortQueueTypeOrder1"))
      : { field: "queueType", sort: "asc" },
  );

  const [sortSocialNeedsOrder, setSortSocialNeedsOrder] = useState(
    localStorage.getItem("sortSocialNeedsOrder1")
      ? JSON.parse(localStorage.getItem("sortSocialNeedsOrder1"))
      : { field: "socialNeeds", sort: "asc" },
  );

  const [sortContactTimeOrder, setSortContactTimeOrder] = useState(
    localStorage.getItem("sortContactTimeOrder1")
      ? JSON.parse(localStorage.getItem("sortContactTimeOrder1"))
      : { field: "contactTime", sort: "asc" },
  );

  console.log("Value:", selectedValue);

  const queries = {};

  queries["acuity"] = queriesValue?.acuity || [];
  queries["status"] = queriesValue?.status || "pending";
  queries["taskCategory"] =
    queriesValue?.taskCategory?.map((item) => item.value) || [];
  queries["carePathways"] =
    queriesValue?.program?.map((item) => item.value) || [];
  queries["clinic"] = queriesValue?.clinic?.map((item) => item.value) || [];
  queries["surgeryType"] =
    queriesValue?.surgeryType?.map((item) => item.id) || [];
  queries["surgeons"] = queriesValue?.surgeon?.map((item) => item.id) || [];
  queries["types"] = queriesValue?.types || [];
  queries["assignees"] = queriesValue?.assignee?.map((item) => item.id) || [];

  const { data: procedureTypeData, isLoading } = useGetProceduresOptionsQuery();
  const { data: physicianOptions, isLoading: isPhysicianLoading } =
    useGetPhysiciansOptionsQuery();
  const { data: filteredData } = useGetPatientQueueFilterQuery();
  const { data: carePathwayData } = useGetCarePathwayQuery();

  const {
    data,
    refetch,
    isLoading: isQueueLoading,
    isSuccess,
  } = useGetPatientQueuesQuery(
    {
      page,
      userId: user.id,
      sortField,
      sortOrder,
      createdAfter,
      ...queries,
    },
    { refetchOnMountOrArgChange: true },
  );

  const refetchQueueAgain = () => {
    refetch();
  };

  const handleSorting = (field, isCreatedAt) => {
    if (field === sortCreatedAtOrder.field) {
      setSortCreatedAtOrder(
        sortCreatedAtOrder.sort === "asc"
          ? { field: "createdAt", sort: "desc" }
          : { field: "createdAt", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortCreatedAtOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortCreatedAtOrder",
        JSON.stringify(
          sortCreatedAtOrder.sort === "asc"
            ? { field: "createdAt", sort: "desc" }
            : { field: "createdAt", sort: "asc" },
        ),
      );
    } else if (field === sortAcuityOrder.field) {
      setSortAcuityOrder(
        sortAcuityOrder.sort === "asc"
          ? { field: "acuity", sort: "desc" }
          : { field: "acuity", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortAcuityOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortAcuityOrder",
        JSON.stringify(
          sortAcuityOrder.sort === "asc"
            ? { field: "acuity", sort: "desc" }
            : { field: "acuity", sort: "asc" },
        ),
      );
    } else if (field === sortPhysicianNameOrder.field) {
      setSortPhysicianNameOrder(
        sortPhysicianNameOrder.sort === "asc"
          ? { field: "surgeonName", sort: "desc" }
          : { field: "surgeonName", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortPhysicianNameOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortPhysicianNameOrder",
        JSON.stringify(
          sortPhysicianNameOrder.sort === "asc"
            ? { field: "surgeonName", sort: "desc" }
            : { field: "surgeonName", sort: "asc" },
        ),
      );
    } else if (field === sortTaskCategoryOrder.field) {
      setSortTaskCategoryOrder(
        sortTaskCategoryOrder.sort === "asc"
          ? { field: "taskCategory", sort: "desc" }
          : { field: "taskCategory", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortTaskCategoryOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortTaskCategoryOrder",
        JSON.stringify(
          sortTaskCategoryOrder.sort === "asc"
            ? { field: "taskCategory", sort: "desc" }
            : { field: "taskCategory", sort: "asc" },
        ),
      );
    } else if (
      sortProcedureTypeOrder.field === "procedureType" &&
      field === "surgeryType"
    ) {
      setSortProcedureTypeOrder(
        sortProcedureTypeOrder.sort === "asc"
          ? { field: "procedureType", sort: "desc" }
          : { field: "procedureType", sort: "asc" },
      );
      setSortField("procedureType");
      setSortOrder(sortProcedureTypeOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortProcedureTypeOrder",
        JSON.stringify(
          sortProcedureTypeOrder.sort === "asc"
            ? { field: "procedureType", sort: "desc" }
            : { field: "procedureType", sort: "asc" },
        ),
      );
    } else if (field === sortQueueTypeOrder.field) {
      setSortQueueTypeOrder(
        sortQueueTypeOrder.sort === "asc"
          ? { field: "queueType", sort: "desc" }
          : { field: "queueType", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortQueueTypeOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortQueueTypeOrder",
        JSON.stringify(
          sortQueueTypeOrder.sort === "asc"
            ? { field: "queueType", sort: "desc" }
            : { field: "queueType", sort: "asc" },
        ),
      );
    } else if (field === sortSocialNeedsOrder.field) {
      setSortSocialNeedsOrder(
        sortSocialNeedsOrder.sort === "asc"
          ? { field: "socialNeeds", sort: "desc" }
          : { field: "socialNeeds", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortSocialNeedsOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortSocialNeedsOrder",
        JSON.stringify(
          sortSocialNeedsOrder.sort === "asc"
            ? { field: "socialNeeds", sort: "desc" }
            : { field: "socialNeeds", sort: "asc" },
        ),
      );
    }

    if (sortPatientOrder.field === "patientName" && field === "patientInfo") {
      setSortPatientOrder(
        sortSocialNeedsOrder.sort === "asc"
          ? { field: "patientName", sort: "desc" }
          : { field: "patientName", sort: "asc" },
      );
      setSortField("patientName");
      setSortOrder(sortSocialNeedsOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortPatientOrder",
        JSON.stringify(
          sortSocialNeedsOrder.sort === "asc"
            ? { field: "patientName", sort: "desc" }
            : { field: "patientName", sort: "asc" },
        ),
      );
    }

    if (isCreatedAt) {
      setSortContactTimeOrder(
        sortContactTimeOrder.sort === "asc"
          ? { field: "contactTime", sort: "desc" }
          : { field: "contactTime", sort: "asc" },
      );
      setSortField("contactTime");
      setSortOrder(sortContactTimeOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortContactTimeOrder",
        JSON.stringify(
          sortContactTimeOrder.sort === "asc"
            ? { field: "contactTime", sort: "desc" }
            : { field: "contactTime", sort: "asc" },
        ),
      );
    }

    localStorage.setItem(
      "sortField",
      isCreatedAt
        ? "contactTime"
        : field === "patientInfo"
          ? "patientName"
          : field,
    );
    localStorage.setItem("sortOrder", sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    if (procedureTypeData?.data) {
      const surgeryType = {
        value: procedureTypeData?.data?.map((item) => ({
          id: item._id,
          title: item.name,
          value: toSnakeCase(item.name),
        })),
      };

      const surgeon = {
        value: physicianOptions?.map((item) => ({
          id: item._id,
          title: item.name,
          value: toSnakeCase(item.name),
        })),
      };
      const clinic = {
        value: filteredData?.data?.clinic
          ? Object.entries(filteredData.data.clinic).map(([key, value]) => ({
              id: value,
              title: key,
              value: value,
            }))
          : [],
      };

      const program = {
        value: carePathwayData?.data
          ? Object.entries(carePathwayData.data).map(([key, value]) => ({
              id: value,
              title: key,
              value: value,
            }))
          : [],
      };
      const newPatientQueueFilterData = {
        ...patientQueueFilterData,
        surgeryType,
        surgeon,
        clinic,
        program,
      };

      setFilterData(newPatientQueueFilterData);
    } else {
      setFilterData(patientQueueFilterData);
    }
  }, [procedureTypeData, physicianOptions, filteredData, carePathwayData]);

  // This useEffect set the selectedIds null when filter change
  useEffect(() => {
    if (queriesValue?.status) {
      setSelectedPatientIds([]);
    }
  }, [queriesValue]);

  useEffect(() => {
    if (selectedValue) {
      setPage(1);
      const filteredDate = filterByDate(selectedValue);
      localStorage.setItem("createdAfter1", filteredDate);
      setCreatedAfter(filteredDate);
    }
  }, [selectedValue]);

  // Loader for both data
  if (isLoading || isQueueLoading || isPhysicianLoading)
    return (
      <>
        <CircularLoader />
      </>
    );

  return (
    <>
      <Grid container spacing={2} sx={{ background: "#fff" }}>
        <Grid
          item
          xs={2}
          sx={{
            borderRight: "1px solid #DFE1E6",
            // boxShadow: "1px 5px 1px 1px rgba(52, 58, 64, 0.2)",
          }}
        >
          <FilterSidebar
            data={filterData}
            queriesValue={queriesValue}
            setQueriesValue={setQueriesValue}
            isNotDashboard={true}
            setSelectedValue={setSelectedValue}
            page={page}
            setPage={setPage}
            setSortTaskCategoryOrder={setSortTaskCategoryOrder}
            setSortPhysicianNameOrder={setSortPhysicianNameOrder}
            setSortProcedureTypeOrder={setSortProcedureTypeOrder}
            setSortQueueTypeOrder={setSortQueueTypeOrder}
            setSortSocialNeedsOrder={setSortSocialNeedsOrder}
            setSortContactTimeOrder={setSortContactTimeOrder}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSortPatientOrder={setSortPatientOrder}
          />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: "1.5rem 1.5rem 1.5rem 0.5rem",
            }}
          >
            <PatientQueue
              queueData={data || null}
              queries={queriesValue}
              setQueries={setQueriesValue}
              selectedPatientIds={selectedPatientIds}
              setSelectedPatientIds={setSelectedPatientIds}
              refetchQueueAgain={refetchQueueAgain}
              page={page}
              setPage={setPage}
              handleSorting={handleSorting}
              sortPatientOrder={sortPatientOrder}
              sortAcuityOrder={sortAcuityOrder}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              isNotDashboard={true}
              sortTaskCategoryOrder={sortTaskCategoryOrder}
              sortPhysicianNameOrder={sortPhysicianNameOrder}
              sortProcedureTypeOrder={sortProcedureTypeOrder}
              sortQueueTypeOrder={sortQueueTypeOrder}
              sortSocialNeedsOrder={sortSocialNeedsOrder}
              sortContactTimeOrder={sortContactTimeOrder}
            />
            <InactivityCheck />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
