import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the datalabels plugin
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { IsMobileProvider } from "./contexts/isMobile";
import { persistor, store } from "./store";

// Define your custom plugin
const noDataPlugin = {
  id: "noDataPlugin",
  beforeDraw: (chart) => {
    if (chart.data.datasets[0].data.every((item) => item === 0)) {
      const ctx = chart.ctx;
      const { width, height } = chart;

      chart.clear();
      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = "16px Arial"; // Customize the font style
      ctx.fillStyle = "#000"; // Customize text color

      // Retrieve the chart title from options
      const heading = chart.options.plugins.title.text || "No Data Available";
      ctx.fillText(`No data to display`, width / 2, height / 2);

      ctx.restore();
    }
  },
};

Chart.register(...registerables);
// Register the plugin to all charts:
Chart.register(ChartDataLabels, noDataPlugin);

const theme = createTheme({
  customeScondary: "#979797",
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
    text: {
      main: "#979797",
      secondary: "#979797",
    },
    custom: {
      main: "#48f95e",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Roboto, sans-serif",
      color: "#343A40",
      fontWeight: {
        primary: {
          main: 400,
        },
        secondary: {
          main: 600,
        },
      },
    },
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 30,
    },
  },
});
const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      scope="read:azraauth"
      responseType="code"
      audience={process.env.REACT_APP_BASE_API_URL}
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      }}
    >
      <IsMobileProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </IsMobileProvider>
    </Auth0Provider>
  </React.StrictMode>,
  container,
);
