import { useSelector } from "react-redux";
import { CircularLoader, InactivityCheck } from "../../Components/index.js";
import { useIsMobile } from "../../contexts/isMobile.js";
import {
  useGetPatientMapByZipCodeQuery,
  useGetPatientMapQuery,
} from "../../store/analyticsSlice.js";
import {
  useGetIcd10CountQuery,
  useGetProgramCountQuery,
} from "../../store/dashboardsSlice.js";
import { AttentionAndForm } from "../Dashboard/AttentionAndForm/index.js";
import { CareCount } from "../Dashboard/CareBoardCount/index.js";
import { PatientCount } from "../Dashboard/PatientCount/index.js";
import { PatientTask } from "../Dashboard/PatientTask/index.js";
import { CareProgramChart } from "./CareProgramChart/index.js";
import { ICD10Chart } from "./ICD10DataChart/index.js";
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import { Map } from "./Map/index.js";

export function Analytics() {
  const isMobile = useIsMobile();

  // Extracting the user from state
  const { user } = useSelector((state) => state.user);

  // Integration of Porgram Count Query api
  const {
    data: programData,
    isLoading: programDataLoading,
    isError: programDataError,
  } = useGetProgramCountQuery(user.id);

  // Integration of Patient Map Count Api
  const { data, isLoading, isError } = useGetPatientMapQuery(user.id);

  // Integrating the Program Count Api
  const {
    data: chartData,
    isLoading: chartDataLoading,
    isError: chartDataError,
  } = useGetProgramCountQuery(user.id);

  const {
    data: icd10Data,
    isLoading: icd10Loading,
    isError: icd10Error,
  } = useGetIcd10CountQuery(user.id);

  // Integrating the api for getting top 5 zip code map
  const {
    data: zipCodeData,
    isLoading: zipCodeLoading,
    isError: zipCodeError,
  } = useGetPatientMapByZipCodeQuery(user.id);

  // Loader for both data
  if (isLoading && chartDataLoading && zipCodeLoading && programDataLoading) {
    return (
      <>
        <CircularLoader />
      </>
    );
  }

  // If Token is expire it navigate to Login page
  return (
    <div style={{ padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem" }}>
      <CareCount {...{ data: programData || [], isError: programDataError }} />
      <PatientTask />
      <AttentionAndForm />
      <PatientCount />
      <CareProgramChart {...{ chartData, chartDataError }} />
      <ICD10Chart
        {...{ chartData: icd10Data || [], chartDataError: icd10Error }}
      />
      <Map
        {...{ zipCodeData: zipCodeData?.data || [], isError: zipCodeError }}
      />
      <InactivityCheck />
      {/* <AdiMap {...{ allState: data?.data, isError }} /> */}
    </div>
  );
}
