export function PatientsSvg({ fill }) {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50016 7.33366C9.34183 7.33366 10.8335 5.84199 10.8335 4.00033C10.8335 2.15866 9.34183 0.666992 7.50016 0.666992C5.6585 0.666992 4.16683 2.15866 4.16683 4.00033C4.16683 5.84199 5.6585 7.33366 7.50016 7.33366ZM7.50016 2.33366C8.41683 2.33366 9.16683 3.08366 9.16683 4.00033C9.16683 4.91699 8.41683 5.66699 7.50016 5.66699C6.5835 5.66699 5.8335 4.91699 5.8335 4.00033C5.8335 3.08366 6.5835 2.33366 7.50016 2.33366ZM12.8252 9.46699C11.4252 8.75033 9.6085 8.16699 7.50016 8.16699C5.39183 8.16699 3.57516 8.75033 2.17516 9.46699C1.34183 9.89199 0.833496 10.7503 0.833496 11.6837V17.3337H2.50016V11.6837C2.50016 11.367 2.66683 11.0837 2.9335 10.9503C3.92516 10.442 5.52516 9.83366 7.50016 9.83366C8.1335 9.83366 8.72516 9.89199 9.27516 10.0003L7.9835 12.7503H5.62516C4.3585 12.7503 3.3335 13.7753 3.3335 15.042C3.3335 16.3087 4.3585 17.3337 5.62516 17.3337H12.5002C13.4168 17.3337 14.1668 16.5837 14.1668 15.667V11.6837C14.1668 10.7503 13.6585 9.89199 12.8252 9.46699ZM6.61683 15.667H5.62516C5.2835 15.667 5.00016 15.3837 5.00016 15.042C5.00016 14.7003 5.2835 14.417 5.62516 14.417H7.20016L6.61683 15.667ZM12.5002 15.667H8.4585L10.9085 10.442C11.3585 10.6087 11.7502 10.7837 12.0752 10.9503C12.3335 11.0837 12.5002 11.367 12.5002 11.6837V15.667Z"
        fill={fill}
      />
    </svg>
  );
}
