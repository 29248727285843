import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";

export const ContentModal = ({
  open,
  setOpen,
  setMessage,
  message,
  title,
  data,
  url,
  setUrl,
}) => {
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
    setUrl(""); // Clear the tag input field
  };

  const onSave = async () => {
    const newMessage = message + " " + url;
    setMessage(newMessage);
    handleClose();
  };

  const handleChange = (event) => {
    setUrl(event.target.value);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 400,
    bgcolor: "#F5F5F5",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <FormControl
              sx={{
                padding: "3px",
                "& .MuiInputLabel-root": {
                  padding: "3px",
                },
              }}
              fullWidth={true}
            >
              <InputLabel id="demo-simple-select-label">Content</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={url}
                label="Content"
                onChange={handleChange}
              >
                {data?.map((item) => (
                  <MenuItem key={item._id} value={item.url}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Box sx={{ ml: "auto" }}>
                <SolidButton label="Save" onClick={onSave} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
