import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProsFilters: builder.query({
      query: ({ proOnly }) => `/pros/filters?proOnly=${proOnly}`,
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Pros", id: "LIST" },
          ...data.map((p) => ({ type: "Pros" })),
        ];
      },
    }),
    getPros: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, ...rest } = query;

        // Convert taskCategory, acuity, and program to arrays if they are not already
        const finalQuery = {
          page,
          limit,
          // ...(Array.isArray(program) ? { program } : { program: [program] || [] }),
          ...rest,
        };

        return {
          url: `/pros/patient`,
          method: "POST",
          body: finalQuery,
        };
      },
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Pros", id: "LIST" },
          ...data.map((p) => ({ type: "Pros" })),
        ];
      },
    }),
  }),
});

export const { useGetProsFiltersQuery, useGetProsQuery } = extendedApiSlice;
