import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useOnboardNewPatientMutation } from "../../store/patientsSlice";
import { useGetSelfEnrolledPhysiciansOptionsQuery } from "../../store/physiciansSlice";
import { formatPhone } from "../../utils";
import Header from "./Header";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PatientDetailScreen({
  activeStep,
  setActiveStep,
  patientCheck,
  isSmallScreen,
  termsAndConditions,
  hospitalId,
  isPatientScreen,
  setIsEnrolledClicked,
  showCarePathway,
}) {
  const { id } = useParams();
  const [onboardNewPatient, { isLoading: isPatientLoading }] =
    useOnboardNewPatientMutation();
  const [isValidNumber, setIsValidNumber] = useState(null);
  const [isValidConfirmNumber, setIsValidConfirmNumber] = useState(null);
  const [birthday, setBirthday] = useState("");
  const [selectedPhysician, setSelectedPhysician] = useState(null);
  const [physician, setPhysician] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [selectCarePathway, setSelectCarePathway] = useState([]);
  const [carePathways, setCarePathway] = useState([]);
  const [procedureDate, setProcedureDate] = useState("");
  const [CarePathwaysList, setCarePathwaysList] = useState([]);
  const { data: physicianOptions, isLoading: isPhysicianLoading } =
    useGetSelfEnrolledPhysiciansOptionsQuery(hospitalId);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    confirmPhone: "",
  });
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isValidDate = (dateString) => {
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (!datePattern.test(dateString)) return false;

    const [month, day, year] = dateString.split("/").map(Number);

    // Check for valid day/month combinations, including leap years
    const isValidDayMonth =
      (month === 2 && day <= (isLeapYear(year) ? 29 : 28)) ||
      ([4, 6, 9, 11].includes(month) && day <= 30) ||
      ([1, 3, 5, 7, 8, 10, 12].includes(month) && day <= 31);

    return isValidDayMonth;
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const handlePhysicianChange = (event, newValue) => {
    setSelectedPhysician(newValue);
    setPhysician(newValue?._id);
  };
  const handleCarePathwayChange = (event, newValue) => {
    // Extract values from newValue array of objects
    const values = newValue.map((item) => item._id);

    setSelectCarePathway(
      // On autofill we get a stringified value.
      typeof newValue === "string" ? newValue?.split(",") : newValue,
    );

    setCarePathway(values);
  };
  const validateForm = () => {
    const requiredFields = ["firstName", "lastName", "phone", "confirmPhone"];
    const fieldMessages = {
      firstName: patientCheck === "no" ? "Patient First Name" : "First Name",
      lastName: patientCheck === "no" ? "Patient Last Name" : "Last Name",
      phone: "Mobile Phone",
      confirmPhone: "Confirm Mobile Phone",
      birthday: patientCheck === "no" ? "Patient DOB" : "Date of Birth",
      physician: patientCheck === "no" ? "Patient Provider" : "Provider",
    };

    let isValid = true;

    // Check text fields
    requiredFields.forEach((field) => {
      if (!form[field]) {
        toast.error(`${fieldMessages[field]} is required!`);
        isValid = false;
      }
    });
    // Check Email
    if (form.email && !isValidEmail(form.email)) {
      toast.error("Email format is incorrect!");
      isValid = false;
    }
    // Check Date of Birth
    if (!birthday) {
      toast.error(
        `${patientCheck === "no" ? "Patient DOB" : "Date of Birth"} is required!`,
      );
      isValid = false;
    } else if (!isValidDate(birthday)) {
      toast.error(
        `${patientCheck === "no" ? "Patient DOB" : "Date of Birth"} format is incorrect. Please use MM/DD/YYYY.`,
      );
      isValid = false;
    }

    // Check Date of Procedure
    if (procedureDate && !isValidDate(procedureDate)) {
      toast.error("Procedure Date is incorrect. Please use MM/DD/YYYY.");
      isValid = false;
    }

    // Check Select Provider
    if (!selectedPhysician) {
      toast.error(
        `${patientCheck === "no" ? "Patient Provider" : "Provider"} is required!`,
      );
      isValid = false;
    }

    // Check if phone number is valid
    if (isValidNumber === false) {
      toast.error("Phone number is not valid!");
      isValid = false;
    }

    // Check if phone number is valid
    if (isValidConfirmNumber === false) {
      toast.error("Confirm Phone number is not valid!");
      isValid = false;
    }
    // Check if phone and confirm phone match
    if (form.phone !== form.confirmPhone) {
      toast.error("Mobile numbers do not match!");
      isValid = false;
    }

    return isValid; // If no errors, the form is valid
  };
  function convertToISOString(dateString) {
    const date = new Date(dateString);
    return date.toISOString(); // returns in 'YYYY-MM-DDTHH:mm:ss.sssZ' format
  }
  const handleCreate = () => {
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    // Formatting phone number by replacing spaces and adding + sign for the number validator
    const formattedPhone = formatPhone(form?.phone);

    onboardNewPatient({
      ...form,
      phone: formattedPhone,
      birthday: convertToISOString(birthday),
      physician,
      ...(showCarePathway && {
        carePathways,
        ...(procedureDate && {
          procedureDate: convertToISOString(procedureDate),
        }),
      }),
      areYouPatientResponse: patientCheck,
      hospital: hospitalId,
    })
      .unwrap()
      .then(() => {
        setActiveStep(2);
      })
      .catch((error) => {
        if (
          error.data ===
          'Cast to ObjectId failed for value "" (type string) at path "team" because of "BSONError"'
        ) {
          toast.error("Team is required");
        } else {
          toast.error(error.data?.message);
        }
      });
  };
  const formatDate = (value) => {
    const numbers = value.replace(/\D/g, "");
    const formattedValue = [];

    for (let i = 0; i < numbers.length; i++) {
      if (i === 2 || i === 4) {
        formattedValue.push("/");
      }
      formattedValue.push(numbers[i]);
    }

    return formattedValue.join("");
  };
  const handleDOBChange = (e) => {
    const rawValue = e.target.value;
    const formattedValue = formatDate(rawValue);
    setBirthday(formattedValue);
  };

  const handleProcedureDateChange = (e) => {
    const rawValue = e.target.value;
    const formattedValue = formatDate(rawValue);
    setProcedureDate(formattedValue);
  };

  const handleChange = (newValue) => {
    matchIsValidTel(newValue); // boolean
    setIsValidNumber(matchIsValidTel(newValue));
  };
  const handleConfirmPhoneChange = (newValue) => {
    setIsValidConfirmNumber(matchIsValidTel(newValue));
  };
  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const fetchCarePathway = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/carePathway/${id}`,
        );
        if (response.data.data) {
          const newData = Object?.entries(response.data.data)?.map(
            ([key, value]) => {
              return {
                _id: value,
                title: key,
              };
            },
          );

          setCarePathwaysList(newData);
        }
      } catch (error) {
        console.error("Error fetching care pathway:", error);
      }
    };

    fetchCarePathway();
  }, [id]);

  return (
    <>
      <Header
        {...{
          activeStep,
          setActiveStep,
          isPatientScreen,
          setIsEnrolledClicked,
          title: patientCheck !== "no" && "Patient Details",
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography variant="h4">
            Please provide your information here!
          </Typography>
        </Grid>

        <Grid item md={4} xs={6}>
          <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
            {patientCheck === "no" ? "Patient First Name" : "First Name"}{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            name="firstName"
            value={form.firstName}
            onChange={onChange}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
            {patientCheck === "no" ? "Patient Last Name" : "Last Name"}{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            name="lastName"
            value={form.lastName}
            onChange={onChange}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
            Email
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            name="email"
            value={form.email}
            onChange={onChange}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
            {patientCheck === "no" ? "Patient DOB" : "Date of Birth"}{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            fullWidth
            value={birthday}
            onChange={handleDOBChange}
            placeholder="MM/DD/YYYY"
            InputProps={{
              inputProps: {
                pattern: "(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/[0-9]{4}", // input pattern for MM/DD/YYYY
              },
            }}
            variant="outlined"
          />{" "}
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
            Mobile Phone <span style={{ color: "red" }}>*</span>
          </Typography>
          <MuiTelInput
            required
            id="standard"
            variant="outlined"
            fullWidth
            name="phone"
            disableDropdown
            forceCallingCode
            defaultCountry="US"
            onlyCountries={["US"]}
            error={isValidNumber === false}
            helperText={
              isValidNumber === false && "Please enter a valid 10-digit number."
            }
            value={form.phone}
            onChange={(newValue) => {
              const event = { target: { name: "phone", value: newValue } };
              onChange(event);
              handleChange(newValue);
            }}
            sx={{
              padding: "3px",
              "& .MuiInputLabel-root": {
                padding: "3px",
              },
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
            Confirm Mobile Phone <span style={{ color: "red" }}>*</span>
          </Typography>
          <MuiTelInput
            required
            id="standard"
            variant="outlined"
            fullWidth
            name="phone"
            defaultCountry="US"
            disableDropdown
            forceCallingCode
            onlyCountries={["US"]}
            error={isValidConfirmNumber === false}
            helperText={
              isValidConfirmNumber === false &&
              "Please enter a valid 10-digit number."
            }
            value={form.confirmPhone}
            onChange={(newValue) => {
              const event = {
                target: { name: "confirmPhone", value: newValue },
              };
              onChange(event);
              handleConfirmPhoneChange(newValue);
            }}
            sx={{
              padding: "3px",
              "& .MuiInputLabel-root": {
                padding: "3px",
              },
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
            {patientCheck === "no"
              ? "Select Patient Provider"
              : "Select Provider"}{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Autocomplete
            value={selectedPhysician}
            onChange={(e, newValue) => handlePhysicianChange(e, newValue)}
            options={
              !isPhysicianLoading && Array.isArray(physicianOptions)
                ? physicianOptions
                : []
            }
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Grid>
        {showCarePathway && (
          <Grid item md={12} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              What procedure are you having?
            </Typography>
            <Autocomplete
              multiple
              value={selectCarePathway}
              onChange={(e, newValue) => handleCarePathwayChange(e, newValue)}
              options={CarePathwaysList}
              getOptionLabel={(option) => option?.title}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        )}
        {showCarePathway && (
          <Grid item md={12} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Procedure Date
            </Typography>
            <TextField
              fullWidth
              value={procedureDate}
              onChange={handleProcedureDateChange}
              placeholder="MM/DD/YYYY"
              InputProps={{
                inputProps: {
                  pattern: "(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/[0-9]{4}", // input pattern for MM/DD/YYYY
                },
              }}
              variant="outlined"
            />{" "}
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              size="small"
              checked={isTermsAccepted}
              onChange={(e) => setIsTermsAccepted(e.target.checked)}
            />
            <Typography>
              I agree to the{" "}
              <Link
                href={termsAndConditions}
                target="_blank"
                sx={{ color: "#1B4DFF", textDecoration: "none" }}
              >
                Terms and Conditions
              </Link>{" "}
              of using this text service.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mb: 3 }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor:
                isTermsAccepted || !isPatientLoading ? "#1B4DFF" : "gray",
              borderRadius: "20px",
              width: isSmallScreen ? "90%" : "initial",
            }}
            onClick={handleCreate}
            disabled={isPatientLoading || !isTermsAccepted}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
