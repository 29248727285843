import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientsSurvey: builder.query({
      query: ({ userID, id, page, limit: limit1 }) => {
        const limit = limit1 ? limit1 : 10;
        return `/patientsSurvey/${userID}/${id}/${page}/${limit}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "PatientsSurvey", id: "LIST" },
          ...data.map((p) => ({ type: "PatientsSurvey", id: p._id })),
        ];
      },
    }),

    getPatientsSurveyByID: builder.query({
      query: ({ id, title }) => {
        const url =
          title !== "patientSurveys"
            ? `/patientsSurvey/getStatus/${id}?dashboard=false`
            : `/patientsSurvey/getStatus/${id}`;
        return url;
      },
    }),

    getPatientsSurveyResultByID: builder.query({
      query: (id) => `/patientsSurvey/results/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "PatientsSurvey", id: "LIST" },
          { type: "PatientsSurvey", id: data._id || arg },
        ];
      },
    }),
    addNewPatientsSurvey: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/patientsSurvey",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "PatientsSurvey", id: "LIST" }],
    }),
    addReminderPatientsSurvey: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `/patientsSurvey/reminderSms/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "PatientsSurvey", id: "LIST" }],
    }),
    updatePatientsSurvey: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/patientsSurvey/" + initialPost.id,
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "PatientsSurvey", id: "LIST" }],
    }),
    getPatientsByPatientsSurveyID: builder.query({
      query: (surveyid) => `/patientsSurvey/getpatient/${surveyid}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "PatientsSurvey", id: "LIST" },
          { type: "PatientsSurvey", id: data._id || arg },
        ];
      },
    }),
  }),
});

export const {
  useGetPatientsSurveyQuery,
  useGetPatientsSurveyByIDQuery,
  useAddNewPatientsSurveyMutation,
  useAddReminderPatientsSurveyMutation,
  useUpdatePatientsSurveyMutation,
  useGetPatientsSurveyResultByIDQuery,
  useGetPatientsByPatientsSurveyIDQuery,
} = extendedApiSlice;
