import { Box, Typography } from "@mui/material";
import { useIsMobile } from "../../../contexts/isMobile";

export function CountStatus() {
  const isMobile = useIsMobile();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: !isMobile && "20px",
            marginLeft: isMobile && "20px",
          }}
        >
          <Box
            sx={{
              background: "#816179",
              width: "13px",
              height: "13px",
              borderRadius: "7px",
              marginRight: "10px",
            }}
          />
          <Typography>Male</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: isMobile && "20px",
          }}
        >
          <Box
            sx={{
              background: "#D0EAE1",
              width: "13px",
              height: "13px",
              borderRadius: "7px",
              marginRight: "10px",
            }}
          />
          <Typography>Female</Typography>
        </Box>
      </Box>
    </>
  );
}
