import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import { useIsMobile } from "../../../contexts/isMobile";
import { useGetOnePatientDetailQuery } from "../../../store/patientsSlice";
import {
  AddressDataTitles,
  ContactDataTitles,
  DemographicsDataTitles,
  JourneyColumns,
  ProgramColumns,
  ProviderColumns,
} from "./DrawerComponents/DataTitlesUtils";
import DemographicsData from "./DrawerComponents/DmographicsData";
import ProviderInfo from "./DrawerComponents/ProviderInfo";

const Content = ({ toggleDrawer, data }) => {
  const isMobile = useIsMobile();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={"#F7F8FB"}
        p={2}
        width={isMobile ? "80%" : "40%"}
        position="fixed"
        top={0}
        right={0}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Preview of ‘{data.firstName} {data.lastName}’
        </Typography>
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
            justifyContent: "right",
            color: "#536878",
            width: "30%",
          }}
          onClick={() => toggleDrawer(false)}
        >
          {/* <PrintOutlinedIcon />
          <FileDownloadOutlinedIcon /> */}
          <CloseIcon />
        </Box>
      </Stack>
      <Box sx={{ p: 2, mt: 7 }}>
        <ProviderInfo
          data={data?.physician ? [data?.physician] : []}
          title="Provider Information"
          tableType={"physician"}
          columns={ProviderColumns}
        />
        <DemographicsData
          data={data}
          TitleData={DemographicsDataTitles}
          title="Demographics"
        />
        <DemographicsData
          data={data}
          TitleData={AddressDataTitles}
          title="Address Details"
        />
        <DemographicsData
          data={data}
          TitleData={ContactDataTitles}
          title="Contact Information"
        />

        <ProviderInfo
          data={data?.program || []}
          title="Care Program"
          columns={ProgramColumns}
          tableType={"program"}
        />
        {/* <ProviderInfo data={careAllyData} title="Care Ally" columns={CareAllyColumns} /> */}

        <ProviderInfo
          data={data?.journeyDetails || []}
          title="Journeys"
          tableType={"journey"}
          columns={JourneyColumns}
        />
      </Box>
    </>
  );
};

export default function PatientProfileDrawer({ open, toggleDrawer, id }) {
  const { data, isLoading } = useGetOnePatientDetailQuery(id);
  const isMobile = useIsMobile();

  if (isLoading) {
    return <>{console.log("Loading")}</>;
  }
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: isMobile ? "80%" : "40%",
          },
        }}
        className="DialogContent"
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Content toggleDrawer={toggleDrawer(false)} data={data} />
      </Drawer>
    </React.Fragment>
  );
}
