import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Alert, Snackbar, Typography } from "@mui/material";

export default function SnackbarMsg({ msg, open, width, handleClose, msg2 }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      sx={{ width: width }}
      onClose={handleClose}
      autoHideDuration={2000}
    >
      <Alert
        sx={{
          width: "100%",
          border: "2px solid #33691E",
          color: "#33691E",
          p: 1,
        }}
        icon={<CheckCircleOutlineIcon />}
      >
        <Typography
          sx={{ fontWeight: "bold", color: "#1E4620", fontSize: "16px" }}
        >
          {msg}
        </Typography>
        <Typography sx={{ color: "#1E4620" }}>{msg2}</Typography>
      </Alert>
    </Snackbar>
  );
}
