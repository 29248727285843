import { useDebouncedValue } from "@mantine/hooks";
import { Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { CircularLoader } from "../../../Components/Loader";
import OutlinedButton from "../../../Components/OutlinedButton";
import { getTokenFromLocalStroage } from "../../../utils";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import CareAllyModal from "./ProfileModals/CareAllyModal";
import { careAllyData } from "./ProfileUtils";

export default function CareAllyTable({ id, user, status }) {
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["title", "description"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
  const [open, setOpen] = useState(false);
  const [careAllyData1, setCareAllyData1] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v2/careAlly/patient/${id}`,
        config,
      );
      setCareAllyData1(response.data);
    } catch (error) {
      setCareAllyData1(null);
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [id, debouncedValue]);

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const handleModal = () => {
    setOpen(true);
  };

  const fetchAgain = () => {
    fetchData();
  };

  if (isLoading) return <CircularLoader />;

  return (
    <div id="careAlly">
      <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Care Ally
        </Typography>
        <Grid
          container
          justifyContent={"space-between"}
          sx={{ paddingY: "12px" }}
        >
          {/* <Grid item md={4} xs={6} sx={{ display: 'none' }}>
            <SearchField
              {...{
                searchTerm,
                setSearchTerm,
                options: searchAbleFields,
                selectedOptions: selectedFields,
                handleOptionSelect: handleFieldSelect,
              }}
            />
          </Grid> */}
          {!careAllyData1?.careAlly && (
            <Grid item={true} xs={12} md={12} textAlign={"right"}>
              <OutlinedButton label={"Create"} handleClick={handleModal} />
            </Grid>
          )}
        </Grid>
        <ProviderInfo
          data={careAllyData1?.careAlly ? [careAllyData1?.careAlly] : []}
          tableType={"careAlly"}
          columns={careAllyData}
          count={1}
          {...{
            page,
            setPage,
            setEditData,
            handleModalEdit: handleModal,
            fetchAgain,
          }}
        />
      </Paper>
      <CareAllyModal
        {...{ open, setOpen, fetchAgain, setEditData, editData, status }}
      />
    </div>
  );
}
