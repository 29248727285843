import { Box, Modal, Typography } from "@mui/material";
import { useIsMobile } from "../contexts/isMobile";
import OutlinedButton from "./OutlinedButton";
import { SolidButton } from "./SolidButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius: "10px",
};

export const ConfirmationModal = ({
  open,
  setOpen,
  handle,
  id,
  ConfirmationText,
  isLoading,
}) => {
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    handle(id);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={!isLoading && handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            {ConfirmationText}
          </Typography>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Box sx={{ margin: "5px" }}>
                <SolidButton
                  label={"Yes"}
                  onClick={handleUpdate}
                  disabled={isLoading}
                  btnWidth={isMobile ? "100%" : "90px"}
                />
              </Box>
              <Box sx={{ margin: "5px" }}>
                <OutlinedButton
                  label={"Cancel"}
                  handleClick={
                    isLoading
                      ? () => {
                          console.log("loading");
                        }
                      : handleClose
                  }
                  disabled={isLoading}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
