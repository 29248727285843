export function EditSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#979797"
        d="M2.167 17.75c-.459 0-.851-.163-1.178-.49A1.601 1.601 0 01.5 16.084V4.417c0-.459.163-.851.49-1.178.327-.327.719-.49 1.177-.49h7.437L7.938 4.418H2.167v11.666h11.666v-5.791L15.5 8.625v7.458c0 .459-.163.851-.49 1.178-.327.326-.719.49-1.177.489H2.167zm9.312-14.52l1.188 1.166-5.5 5.5v1.187h1.166l5.521-5.52 1.188 1.166-6 6.02H5.5V9.21l5.98-5.98zm3.563 3.5l-3.563-3.5 2.084-2.084c.333-.334.732-.5 1.198-.5.465 0 .858.166 1.177.5l1.166 1.187c.32.32.48.709.48 1.167 0 .458-.16.847-.48 1.167l-2.062 2.062z"
      ></path>
    </svg>
  );
}
