import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import {
  CircularLoader,
  DeleteModal,
  PaginationBar,
} from "../../../Components";
import { DeleteSvg } from "../../../assets/svgs";
import { useDeleteContentMutation } from "../../../store/contentsSlice";
import { hasScope } from "../../../utils";

const columns = [
  // { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "right",
  },
  {
    id: "type",
    label: "Type",
    minWidth: 170,
    align: "right",
  },
  {
    id: "url",
    label: "Url",
    minWidth: 170,
    align: "right",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const createRows = (
  contents,
  handleOpenModal,
  handleDelete,
  openModal,
  setOpenModal,
  datas,
  handleCopyClick,
  handleOpenNewTab,
  scopes,
) => {
  const rows = contents.map(({ _id, name, type, url }, index) => {
    return {
      // Srno: (
      //   <Typography
      //     variant="p"
      //     sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
      //   >
      //     {index + 1}
      //   </Typography>
      // ),
      name: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              color: "black",
              padding: "7px",
            }}
          >
            {name}
          </Typography>
        </>
      ),
      type: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              color: "black",
              padding: "7px",
            }}
          >
            {type?.toUpperCase()}
          </Typography>
        </>
      ),
      url: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              color: "black",
              padding: "7px",
            }}
          >
            {url}
          </Typography>
        </>
      ),
      actions: (
        <>
          <Stack
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div
              onClick={(event) => handleCopyClick(event, url, _id)}
              style={{ cursor: "pointer" }}
            >
              <ContentCopyIcon />
            </div>

            {hasScope(scopes, "settings/contentManagers:delete") && (
              <div
                style={{ cursor: "pointer" }}
                onClick={(event) => handleOpenModal(event, name, _id)}
              >
                <DeleteSvg />
              </div>
            )}

            <div
              style={{ color: "grey", cursor: "pointer" }}
              onClick={(event) => handleOpenNewTab(event, url, _id)}
            >
              <VisibilityIcon />
            </div>
          </Stack>

          <DeleteModal
            open={openModal}
            setOpen={setOpenModal}
            id={datas}
            handle={handleDelete}
          />
        </>
      ),
    };
  });

  return rows;
};

export function ContentsTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  searchTerm,
  scopes,
  isAIAssistant,
}) {
  // States
  const [openModal, setOpenModal] = React.useState(false);
  const [datas, setData] = React.useState("");

  // Mutation:
  const [deleteContent] = useDeleteContentMutation();

  // Functions:
  const handleOpenModal = (event, name, id) => {
    setData(id);
    setOpenModal(true);
  };

  const handleOpenNewTab = (event, url, id) => {
    window.open(url, "_blank");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (id) => {
    await deleteContent(id).unwrap();
  };

  const handleCopyClick = async (event, url, id) => {
    try {
      await navigator.clipboard.writeText(url);
      toast.success("Url Copied!");
    } catch (error) {
      toast.error("Failed to copy link. Please try again.");
    }
  };

  const contents = data ? data.data : [];

  const rows = createRows(
    contents,
    handleOpenModal,
    handleDelete,
    openModal,
    setOpenModal,
    datas,
    handleCopyClick,
    handleOpenNewTab,
    scopes,
  );

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return (
      <Typography sx={{ margin: "10px 20px" }}>No Content found.</Typography>
    );
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader={true} aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns
                .filter((column) => !(isAIAssistant && column.id === "url"))
                .map((column) => (
                  <TableCell
                    variant="head"
                    key={column.id}
                    align={"center"}
                    sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns
                    .filter((column) => !(isAIAssistant && column.id === "url"))
                    .map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ color: "text.secondary", border: "none" }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data.totalPages, handleChange: handleChangePage }}
      />
    </>
  );
}
