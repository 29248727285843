import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

const steps = [
  "Create Patient",
  "Create Care Ally",
  "Assign Procedure",
  "Assign Journey",
];

export function CreatePatientStepper({ activeStep, isStepSkipped, children }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography
                  variant="body1"
                  fontWeight={index === activeStep ? "600" : "normal"}
                >
                  {label}
                </Typography>
              </StepLabel>
              <StepContent>{index === activeStep && children}</StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
