import { ManageSearch } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PatientBreadcrumb } from "../../Pages/Patient/index";
import { KIMISvg } from "../../assets/svgs/KIMI";
import { ROLES } from "../../constants/routes";
import { useChildNode } from "../../contexts/addChildNode";
import { useAssistantChatValues } from "../../contexts/assistantChatValue";
import { useChatValues } from "../../contexts/chatValue";
import { useIsHovered } from "../../contexts/isHovered";
import { socket } from "../../socket";
import {
  useGetNotificationsQuery,
  useViewNotificationMutation,
} from "../../store/notificationsSlice";
import { useGetPatientByIDQuery } from "../../store/patientsSlice";
import { addInParams } from "../../utils";
import { ExternalAIAssistant } from "../ExternalAIAssistant/ExternalAIAssistantDrawer";
import { MessageDrawer } from "../MessageLayout/MessageDrawer";
import { NotificationDrawer } from "../NotificationDrawer";
import OutlinedButton from "../OutlinedButton";
import { Breadcrumb } from "../index";
import { drawerWidth } from "./constant";

// import { Button } from '@material-ui/core'
export const Header = () => {
  const location = useLocation();

  // Create a URLSearchParams object from the location search string
  const queryParams = new URLSearchParams(location.search);
  const { sid, id } = useParams();
  const { user } = useSelector((state) => state.user);
  const { searchTerm, setSearchTerm } = useChildNode();
  const { setChatMessages, chatMessages } = useChatValues();
  // States for notification
  const [count, setCount] = useState(0);
  const [notification, setNotification] = useState([]);
  const { hovered } = useIsHovered();
  const pl = hovered ? `${drawerWidth + 10}px` : "60px";
  const [page, setPage] = useState(1);
  const { data, refetch } = useGetNotificationsQuery(user.id);
  const [viewNotification] = useViewNotificationMutation();
  const { setAssistantChatMessages } = useAssistantChatValues();

  const refetchAgain = () => {
    refetch();
  };
  const [arrow, setArrow] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [prevTitle, setPrevTitle] = useState("");
  const [backArrow, setBackArrow] = useState(false);
  const [openAIAssistant, setOpenAIAssistant] = useState(false);
  const [curPageTitle, setCurPageTitle] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentPageTitle = pathSegments[1];
    setCurPageTitle(currentPageTitle);

    // Set the previous page's title when the location changes
    setPrevTitle(pageTitle);
    if (currentPageTitle === "queue") {
      // Set pageTitle based on currentPageTitle
      setPageTitle("Patient Queue");
    } else if (
      (currentPageTitle === "queueprofile" && prevTitle !== "dashboard") ||
      currentPageTitle === "createProfile"
    ) {
      setBackArrow(true);
      setPageTitle(prevTitle || "Patient Queue Profile");
    } else if (
      currentPageTitle === "queueprofile" &&
      prevTitle === "dashboard"
    ) {
      setBackArrow(true);
      setPageTitle("Patient Queue Profile");
    } else if (currentPageTitle === "patients") {
      setBackArrow(false);
      setPageTitle("Patient Management");
    } else if (currentPageTitle === "pros") {
      setBackArrow(false);
      setPageTitle("Patient Reported Outcome (PRO) Insights");
    } else if (currentPageTitle === "social-care-orchestration") {
      setBackArrow(false);
      setPageTitle("social Care Orchestration");
    } else if (currentPageTitle === "social-care-queue") {
      setBackArrow(false);
      setPageTitle("social Care Queue");
    } else {
      setBackArrow(false);
      setPageTitle(currentPageTitle);
    }
  }, [location.pathname, pageTitle]);

  const pid = location.pathname.split("/").reverse()[0];

  // varibles for arrow button
  const formEdit = location.pathname.split("/")[2];
  const formCreate = location.pathname;
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  // States and Functions for Notification Drawer
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    viewNotification().then(() => {
      refetch();
    });
    setOpen(open);
  };

  function getRoleName(roleId) {
    for (const [roleName, id] of Object.entries(ROLES)) {
      if (id === roleId) {
        const formattedRoleName = roleName.replace(/([a-z])([A-Z])/g, "$1 $2");
        return formattedRoleName;
      }
    }
    return "Role not found";
  }

  useEffect(() => {
    if (formEdit === "edit" || formCreate === "/forms/create") {
      setArrow(true);
    } else {
      setArrow(false);
    }
  }, [formEdit, formCreate]);

  // useEffect for notification
  useEffect(() => {
    if (data) {
      setNotification(data.data);
    }
  }, [data]);

  useEffect(() => {
    socket.on("Notification", (notificationData) => {
      setNotification((prevNotification) => [
        ...prevNotification,
        notificationData,
      ]);
    });
  }, []); // Only run once on mount

  useEffect(() => {
    const filteredCount = notification.filter((item) => !item?.isCount);
    const countLength = filteredCount.length;
    setCount(countLength);
  }, [notification, count]);

  const [openMsg, setOpenMsg] = useState(false);
  const {
    data: patient,
    isLoading,
    refetch: refetchPatient,
  } = useGetPatientByIDQuery(id, { refetchOnMountOrArgChange: true });

  const toggleMsgDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenMsg(open);
    setChatMessages([]);
  };
  const toggleAIDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setAssistantChatMessages([]);
    setOpenAIAssistant(open);
  };
  const refetchPatientAgain = () => {
    refetchPatient();
  };

  useEffect(() => {
    if (queryParams.get("name")) {
      setSearchTerm(queryParams.get("name"));
    }
  }, []);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={arrow && "center"}
        sx={{
          py: "10px",
          px: "40px",
          bgcolor: "white",
          position: "fixed",
          top: 0,
          zIndex: 900,
          left: "2rem",
          width: "100%",
          borderBottom: "1px solid #9797971a",
          pl,
        }}
      >
        <Box
          sx={{
            display: (arrow || backArrow) && "flex",
            alignItems: (arrow || backArrow) && "center",
          }}
        >
          {(arrow || backArrow) && (
            <ArrowBackIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={handleGoBack}
            />
          )}
          <Typography
            variant="h1"
            sx={{
              justifyContent: "center",
              textTransform: "capitalize",
              fontSize: "2em",
            }}
          >
            {pageTitle !== "patientSurveys" &&
            pageTitle !== "profile" &&
            pageTitle !== "editProfile" ? (
              pageTitle
            ) : pageTitle === "profile" || pageTitle === "editProfile" ? (
              <PatientBreadcrumb id={id} />
            ) : (
              <Breadcrumb surveyid={id} />
            )}
          </Typography>
        </Box>
        {(curPageTitle === "dashboard" ||
          curPageTitle === "patients" ||
          curPageTitle === "queue") && (
          <Box>
            <TextField
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                pageTitle === "dashboard" &&
                  addInParams("name", e.target.value);
              }}
              placeholder="Search Patient"
              variant="outlined"
              endIcon={<ManageSearch />}
              InputProps={{
                sx: {
                  height: 35, // Outer height
                  borderRadius: "20px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <ManageSearch sx={{ color: "#979797" }} /> {/* End icon */}
                  </InputAdornment>
                ),
              }}
              sx={{ width: "600px" }}
            />
          </Box>
        )}
        {/* Commenting out the Icons part in navbar */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "auto",
              marginRight:
                (pageTitle === "profile" || pageTitle === "EditProfile") &&
                "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginRight: "5px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "end",
                  color: "grey",
                  fontSize: "12px",
                  fontWeight: 800,
                }}
              >
                {user.email}
              </Typography>
              <Typography
                sx={{ textAlign: "end", color: "grey", fontSize: "12px" }}
              >
                {getRoleName(user.role)}
              </Typography>
            </Box>

            <AccountCircleIcon sx={{ color: "#979797", fontSize: 40 }} />
          </Box>
          {(pageTitle === "profile" || pageTitle === "EditProfile") && (
            <OutlinedButton
              label={"Message"}
              handleClick={toggleMsgDrawer(true)}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            startIcon={<KIMISvg />}
            onClick={toggleAIDrawer(true)}
          >
            Ask KIMI
          </Button>
          {/* <IconButton aria-label={'more than 99 notifications'} onClick={toggleDrawer(true)}>
						{count === 0 ? (
							<NotificationsNoneIcon sx={{ color: '#979797' }} />
						) : (
							<Badge badgeContent={count} color="secondary">
								<NotificationsNoneIcon />
							</Badge>
						)}
					</IconButton> */}
        </Box>
        <NotificationDrawer
          toggleDrawer={toggleDrawer}
          state={open}
          data={notification}
          refetchAgain={refetchAgain}
        />
        <ExternalAIAssistant
          toggleDrawer={toggleAIDrawer}
          state={openAIAssistant}
        />
        <MessageDrawer
          toggleDrawer={toggleMsgDrawer}
          state={openMsg}
          id={id}
          name={patient?.firstName}
          user={user}
          path={patient?.path}
          patient={patient}
          title={pageTitle}
          consent={patient?.consentResponse}
          page={page}
          setPage={setPage}
          refetchPatientAgain={refetchPatientAgain}
        />
      </Stack>
      <Box sx={{ height: "2rem" }} />{" "}
    </>
  );
};
