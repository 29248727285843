import {
  FormControl,
  InputLabel,
  MenuItem,
  Portal,
  Select,
} from "@mui/material";
import { useEffect, useRef } from "react";

function CreateAssignJourney({
  triggerJourney,
  setTriggerJourney,
  journeyListData,
  user,
  currentJourneyId,
  data,
}) {
  const container = useRef(null);

  useEffect(() => {
    const filteredJourneyData = journeyListData?.data?.find(
      (item) => item._id === data?.journeyId,
    );
    setTriggerJourney({
      id: filteredJourneyData?._id,
      name: filteredJourneyData?.title,
    });
  }, [data]);

  return (
    <FormControl
      fullWidth={true}
      ref={container}
      sx={{
        textAlign: "left",
      }}
    >
      <InputLabel id="demo-simple-form-label">
        Select Journey to Assign
      </InputLabel>
      <Portal container={() => container.current}>
        <Select
          fullWidth={true}
          labelId="demo-simple-form-label"
          id="demo-simple-form"
          label="Select Journey to Assign"
          value={triggerJourney.id}
          onChange={(e) =>
            setTriggerJourney({
              id: e.target.value,
              name: journeyListData?.data?.find(
                (item) => item._id === e.target.value,
              )?.title,
            })
          }
        >
          {journeyListData?.data
            ?.filter((item) => item._id !== currentJourneyId)
            ?.map((item) => (
              <MenuItem
                key={item._id}
                value={item._id}
                sx={{
                  "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "100%",
                  },
                  width: "590px",
                }}
              >
                {item?.title}
              </MenuItem>
            ))}
        </Select>
      </Portal>
    </FormControl>
  );
}

export default CreateAssignJourney;
