import { CircularProgress, useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";

export function CircularLoader({ message, modalHeight, height }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: height
          ? height
          : isSmallScreen
            ? "40vh"
            : modalHeight || "60vh",
        margin: "0 auto",
      }}
    >
      <div>
        <CircularProgress color="inherit" size={isSmallScreen ? 40 : 80} />
      </div>
      {message && <Typography variant="h6">{message}</Typography>}
    </div>
  );
}
