import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Drawer, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { parse } from "marked";
import moment from "moment";
import React from "react";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import remarkGfm from "remark-gfm";
import { SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useGetNoteByIDQuery } from "../../../store/notesSlice";
import NoteAccordion from "./NoteAccordion";

const Content = ({ toggleDrawer, data }) => {
  const isMobile = useIsMobile();
  const { data: noteData, isLoading } = useGetNoteByIDQuery(data?._id);

  const handleMarkdownChange = (value) => {
    if (value) {
      return parse(value);
    }
  };

  const copyText = (description) => {
    // Create a temporary container to hold the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = parse(description); // Convert markdown/HTML to actual DOM structure

    // Recursively walk through the nodes to extract structured plain text
    const getPlainText = (element) => {
      let text = "";
      element.childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          text += node.textContent;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "LI") {
            text += "- " + getPlainText(node) + "\n"; // Add dash for list items
          } else if (
            node.tagName === "P" ||
            node.tagName === "DIV" ||
            node.tagName === "BR"
          ) {
            text += getPlainText(node) + "\n"; // Add new line for paragraphs, divs, and breaks
          } else {
            text += getPlainText(node); // Recursively process other elements
          }
        }
      });
      return text;
    };

    // Extract plain text with formatting preserved
    const plainText = getPlainText(tempDiv);

    // Copy the plain text to the clipboard
    navigator.clipboard
      .writeText(plainText)
      .then(() => {
        toast.success("Note copied successfully!");
      })
      .catch(() => {
        toast.error("Failed to copy note.");
      });
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="p" sx={{ fontSize: "28px", fontWeight: "400" }}>
            Note Detail
          </Typography>
          <div
            style={{
              marginTop: "10px",
              marginLeft: "10px",
              cursor: "pointer",
              color: "grey",
            }}
            onClick={() =>
              copyText(data?.description?.replace(/^```|```$/g, ""))
            }
          >
            <Tooltip title="Copy Generated Note" placement="right">
              <ContentCopyIcon />
            </Tooltip>
          </div>
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        noValidate={true}
        autoComplete="off"
      >
        <Grid container={true} spacing={2}>
          <Grid item={true} md={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: isMobile ? "end" : "center",
                // borderTop:"1px solid #979797",
                // borderBottom:"1px solid #979797",
              }}
            >
              <Box
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginRight: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{ fontSize: "15px", marginRight: "2px" }}
                  >
                    Date :
                  </Typography>
                  <Typography>
                    {moment(data?.date).format("MM-DD-YYYY")}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginLeft: "5px",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{ fontSize: "15px", marginRight: "2px" }}
                >
                  Time :
                </Typography>
                <Typography>{moment(data?.date).format("LT")}</Typography>
              </Box>
            </Box>
          </Grid>
          {(data?.metadata?.forms?.length !== 0 ||
            data?.metadata?.chats?.length !== 0 ||
            data?.metadata?.chats?.length !== 0) && (
            <Grid item={true} md={12} xs={12}>
              <Typography
                variant="h2"
                sx={{ fontSize: "15px", marginRight: "2px" }}
              >
                Sources Used
              </Typography>

              {data?.metadata?.forms?.length !== 0 && (
                <NoteAccordion
                  title={"Form"}
                  data={noteData?.data?.metadata?.forms}
                  isLoading={isLoading}
                  startDate={data?.startDate ? data?.startDate : null}
                  endDate={data?.endDate ? data?.endDate : null}
                />
              )}

              {data?.metadata?.chats?.length !== 0 && (
                <NoteAccordion
                  title={"SMS"}
                  data={noteData?.data?.metadata?.chats}
                  isLoading={isLoading}
                  startDate={data?.startDate ? data?.startDate : null}
                  endDate={data?.endDate ? data?.endDate : null}
                />
              )}

              {data?.metadata?.calls?.length !== 0 && (
                <NoteAccordion
                  title={"Call Transcript"}
                  data={noteData?.data?.metadata?.calls}
                  isLoading={isLoading}
                  startDate={data?.startDate ? data?.startDate : null}
                  endDate={data?.endDate ? data?.endDate : null}
                />
              )}
            </Grid>
          )}
          <Grid item={true} md={12} xs={12}>
            {/* <Typography
                variant="h2"
                sx={{ fontSize: "15px", marginRight: "2px" }}
              >
                Description
              </Typography> */}

            <Typography>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: (props) => (
                    <a
                      href={props.href}
                      style={{ color: "#000" }}
                      target="blank"
                    >
                      {props.children}
                    </a>
                  ),
                }}
              >
                {data?.description?.replace(/^```|```$/g, "")}
              </ReactMarkdown>
              {/* <div
                style={{
                  // textOverflow: 'ellipsis',
                  // display: '-webkit-box',
                  // WebkitBoxOrient: 'vertical',
                  // WebkitLineClamp: 2,
                  lineHeight: "1.5em",
                }}
                dangerouslySetInnerHTML={{
                  __html: handleMarkdownChange(data?.description)
                    ?.split("\n")
                    ?.join("\n"),
                }}
              /> */}
            </Typography>
          </Grid>

          <Stack
            direction="row"
            justifyContent={"flex-end"}
            sx={{
              marginTop: "1rem",
              mr: "1.7rem",
              position: "fixed",
              width: "650px",
              bottom: "1rem",
              right: 0,
            }}
          >
            <SolidButton
              label="Close"
              onClick={(e) => {
                toggleDrawer(false);
              }}
            />
          </Stack>
        </Grid>
      </Box>
    </Box>
  );
};

export function NoteViewDrawer({ state, toggleDrawer, data }) {
  const isMobile = useIsMobile();
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: isMobile ? "80%" : "45%",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer({ value: false, data: null })}
      >
        <Content
          toggleDrawer={toggleDrawer({ value: false, data: null })}
          data={data}
        />
      </Drawer>
    </React.Fragment>
  );
}
