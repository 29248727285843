import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ROLES } from "../../../constants/routes";
import { useAddNewTeamMutation } from "../../../store/teamsSlice";
import { useGetAllUserQuery } from "../../../store/userManagmentSlice";
import { SolidButton } from "./../../../Components";

export function MembersSelect({
  selectedTags,
  setSelectedTags,
  selectName,
  setSelectedName,
}) {
  const { user } = useSelector((state) => state.user);
  const { data: users, isLoading, isError } = useGetAllUserQuery(user.id);

  const data = users?.data
    ?.filter((user) => user.role === ROLES.CareTeamMember)
    ?.map((CareTeamMember) => ({
      _id: CareTeamMember._id,
      tag: CareTeamMember.name,
      name: CareTeamMember.name,
    }));

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Splitting the stringified value into an array if it's a string
    const selectedIds = typeof value === "string" ? value.split(",") : value;

    // Finding the corresponding name for each id and creating an object with id and name
    const selectedNames = selectedIds.map((id) => {
      const tag = data?.find((tag) => tag._id === id);
      const existingMember = selectName.find((member) => member.id === id);

      return {
        id,
        name: tag?.tag || "",
        role: existingMember ? existingMember.role : "",
      };
    });

    setSelectedTags(selectedIds);
    setSelectedName(selectedNames);
  };

  const selectedTagsText = (selected) =>
    selected?.map((tagID) => {
      return data?.find((tag) => tag._id === tagID).tag;
    });

  if (isLoading || isError) return null;
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="demo-multiple-checkbox-label" sx={{ mt: "20px" }}>
        Care Team Members
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        required={true}
        id="demo-multiple-checkbox"
        multiple={true}
        value={selectedTags || []}
        onChange={handleChange}
        input={
          <OutlinedInput
            sx={{ height: "100%", mt: 2, padding: "3px" }}
            label="Care Team Members"
          />
        }
        renderValue={(selected) => selectedTagsText(selected).join(", ")}
        MenuProps={MenuProps}
      >
        {data?.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            <Checkbox checked={selectedTags.indexOf(item._id) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const Content = ({ toggleDrawer }) => {
  const { user } = useSelector((state) => state.user);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedMembersName, setSelectedMembersName] = useState([]);

  const [team, setTeam] = useState({
    name: "",
    description: "",
  });

  const [addNewTeam, { isLoading, isError }] = useAddNewTeamMutation();

  const onChange = (e) => {
    setTeam((team) => ({ ...team, [e.target.name]: e.target.value }));
  };

  function handleRoleChange(id, e) {
    const selectedMembers = [...selectedMembersName];
    const selectedMemberIndex = selectedMembers.findIndex(
      (member) => member.id === id,
    );
    selectedMembers[selectedMemberIndex].role = e.target.value;
    setSelectedMembersName(selectedMembers);
  }

  const createTeam = (e) => {
    e.preventDefault();

    addNewTeam({
      ...team,
      members: selectedMembersName,
      creator: user.id,
    })
      .unwrap()
      .then(() => {
        toggleDrawer(false);
        toast.success("Successfully created!");
      })
      .catch((error) => {
        toast.error(error.data.error);
      });
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography variant="p" sx={{ fontSize: 30, fontWeight: 400 }}>
          Create Team
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => toggleDrawer(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        noValidate={true}
        autoComplete="off"
      >
        <Grid container={true} spacing={2}>
          <Grid item={true} md={12} xs={12}>
            <TextField
              required={true}
              id="outlined-required"
              label="Title"
              placeholder="Enter Team Name"
              name="name"
              value={team.name}
              onChange={onChange}
              sx={{ padding: "3px" }}
            />
          </Grid>
          <Grid item={true} md={12} xs={12}>
            <TextField
              required={true}
              multiline={true}
              rows={4}
              id="outlined-required"
              label="Description"
              placeholder="Enter Team Description"
              name="description"
              value={team.description}
              onChange={onChange}
              sx={{ padding: "3px" }}
            />
          </Grid>
          <Box sx={{ width: "97%", ml: 2.2 }}>
            <MembersSelect
              selectedTags={selectedMembers}
              setSelectedTags={setSelectedMembers}
              selectName={selectedMembersName}
              setSelectedName={setSelectedMembersName}
            />

            {selectedMembers.length > 0 &&
              selectedMembersName.map((item) => (
                <TextField
                  required={true}
                  key={item.id}
                  id={`outlined-required-${item.id}`}
                  label={item.name}
                  placeholder={`Enter ${item.name} Role`}
                  name={item.name}
                  value={item.role}
                  onChange={(e) => handleRoleChange(item.id, e)}
                  sx={{ padding: "3px", mt: 2 }}
                />
              ))}
          </Box>
        </Grid>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          sx={{ marginTop: "3rem" }}
        >
          <SolidButton label="Save" onClick={createTeam} disabled={isLoading} />
        </Stack>
      </Box>
    </Box>
  );
};

export function AddTeamDrawer({ state, toggleDrawer }) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Content toggleDrawer={toggleDrawer(false)} />
      </Drawer>
    </React.Fragment>
  );
}
