import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addInParams } from "../utils";

const timeFrameData = [
  {
    title: "Newest",
    value: "desc",
  },
  {
    title: "Oldest",
    value: "asc",
  }
];

export default function PatientSort({ selectedValue, setSelectedValue, sortField, setSortField, sortOrder, setSortOrder }) {
  const navigate = useNavigate();

  const handleChangeDropdown = (event) => {
    localStorage.setItem("sortField3", 'createdAt');
    localStorage.setItem("sortOrder3", event.target.value)
    setSortField('createdAt');
    setSortOrder(event.target.value)
    addInParams('sortField', 'createdAt');
    addInParams('sortOrder', event.target.value);
    // setSelectedValue(event.target.value);
  };

  const handleRedirect = () => {
    localStorage.removeItem("patientForm");
    localStorage.removeItem("assignJourneyForm");
    localStorage.removeItem("assignSurgeryForm");
    localStorage.removeItem("stepperValue");
    navigate("/createProfile/");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          ml: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginRight: "8px" }} color={"#B3B7BA"}>
            Sort by:
          </Typography>
          <FormControl sx={{ minWidth: 80 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={"desc"}
              sx={{ height: 35, borderRadius: "8px" }} // Set the height here
              value={sortField === "createdAt" ? sortOrder : 'desc'}
              onChange={handleChangeDropdown}
            >
              {timeFrameData?.map((values, index) => (
                <MenuItem key={values._id} value={values.value}>
                  {values.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          size="small"
          sx={{
            cursor: "pointer",
            ml: "10px",
            paddingX: "12px", paddingY: '8px'
          }}
          onClick={() => handleRedirect()}
        >
          <Typography
            variant="caption"
            sx={{ color: "white", textWrap: "nowrap"}}
          >
            Create New Patient
          </Typography>
        </Button>
      </Box>
    </>
  );
}
