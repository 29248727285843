import { Paper } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useIsMobile } from "../../../../contexts/isMobile";
import { useGetRiskProfilesQuery } from "../../../../store/riskProfilesSlice";
import { AttentionHeader } from "./AttentionHeader";
import { AttentionTable } from "./AttentionTable";

export function PatientAttention() {
  const [page, setPage] = useState(1);
  const [week, setWeek] = useState("all");
  const isMobile = useIsMobile();

  // Getting the user from state
  const { user } = useSelector((state) => state.user);

  // Getting the patient tasks from api
  const { data, isLoading, isError, isSuccess } = useGetRiskProfilesQuery(
    {
      userID: user.id,
      page,
      week,
    },
    { refetchOnMountOrArgChange: true },
  );

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
          border: "1px solid #D0EAE1",
          height: "100%",
        }}
        elevation={0}
      >
        <AttentionHeader {...{ week, setWeek }} />
        <AttentionTable {...{ page, setPage, data, isLoading, isError }} />
      </Paper>
    </>
  );
}
