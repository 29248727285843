import "./App.css";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import { ChildNodeProvider } from "./contexts/addChildNode";
import { AssistantChatProvider } from "./contexts/assistantChatValue";
import { ChatProvider } from "./contexts/chatValue";
import { HoveredProvider } from "./contexts/isHovered";
import { RoutersProvider } from "./router";
import { socket } from "./socket";

function App() {
  const [socketio, setSocket] = useState(socket.connected);

  useEffect(() => {
    function onConnect() {
      setSocket(true);
    }

    function onDisconnect() {
      setSocket(false);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  return (
    <>
      {/* <Container sx={{ minWidth: "100%" }} maxWidth={false}> */}
      <ThemeProvider theme={theme}>
        <HoveredProvider>
          <ChildNodeProvider>
            <ChatProvider>
              <AssistantChatProvider>
                <RoutersProvider />
                <ToastContainer />
              </AssistantChatProvider>
            </ChatProvider>
          </ChildNodeProvider>
        </HoveredProvider>
      </ThemeProvider>
      {/* </Container> */}
    </>
  );
}

export default App;
