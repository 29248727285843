import { getBezierPath } from "reactflow";

export default function PlaceholderEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
}) {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return;
  // return <path id={id} style={style} className={styles.placeholderPath} d={edgePath} markerEnd={markerEnd} />;
}
