import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { toast } from "react-toastify";
import LinkQueueModal from "../../../Components/LinkQueueModal";
import { ConfirmationModal } from "../../../Components/MessageLayout/ConfirmationModal";
import { useResendUnknownUserConsentMutation } from "../../../store/chatSlice";

function formatPhoneNumber(phoneNumber) {
  // Remove any non-digit characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Check if the input is valid (should be 11 digits with country code)
  if (cleaned.length === 11 && cleaned[0] === "1") {
    const country = cleaned[0]; // Country code
    const area = cleaned.slice(1, 4); // Area code
    const local = cleaned.slice(4, 7); // First 3 digits
    const number = cleaned.slice(7, 11); // Last 4 digits

    return `+${country} (${area}) ${local}-${number}`;
  }

  return phoneNumber; // Return original if not valid
}

const getConsentColors = (consent) => {
  if (consent === "yes") {
    return {
      marginRight: "5px",
      color: "#63A02C",
    };
  } else if (consent === "no") {
    return {
      marginRight: "5px",
      color: "#DC362E",
    };
  } else if (consent === "NO_RESPONSE") {
    return {
      marginRight: "5px",
      color: "#FFB400",
    };
  } else {
    return {
      marginRight: "5px",
      color: "black",
    };
  }
};

const getSmsConsent = (data) => {
  return data?.consentResponse === "NO_RESPONSE"
    ? "No Response"
    : data?.consentResponse?.charAt(0).toUpperCase() +
        data?.consentResponse?.slice(1);
};

export function UnknownDrawerHeader({
  data,
  setOpenUnknownCallerChat,
  refetchQueueAgain,
}) {
  const [linkQueueModalOpen, setLinkQueueModalOpen] = React.useState({
    value: false,
    data: null,
    results: null,
    taskCategory: null,
    callerIdentification: null,
  });

  const [openResend, setOpenResend] = React.useState(false);

  const [resendUnknownUserConsent] = useResendUnknownUserConsentMutation();

  const handleResend = () => {
    setOpenResend(true);
  };

  function validatePhoneNumber(number) {
    // Remove all spaces
    const cleanedNumber = number.replace(/\s+/g, "");

    // Check if it starts with "+1" and has exactly 12 characters
    if (!cleanedNumber.startsWith("+1") && cleanedNumber.length !== 12) {
      return true;
    } else {
      return false;
    }
  }

  const handleDataResend = async () => {
    if (data?.results?.phone_number === "N/A") {
      toast.error("User phone number does not exist!");
    } else if (validatePhoneNumber(data?.results?.phone_number)) {
      toast.error("Phone Number not valid!");
    } else {
      const body = {
        phone: data?.results?.phone_number.replace(/\n/g, "\r\n"),
      };
      const response = await resendUnknownUserConsent(body);
      if (response?.data?.message) {
        toast.success("The SMS consent has been resent.");
        refetchQueueAgain();
        setOpenResend(false);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        gap: 2,
        m: "0px 16px",
        mt: "10px",
        p: 1,
        bgcolor: "rgba(0,151,236,0.12)",
        color: "#50607A",
        border: "1px solid #DC362E",
        borderRadius: 3,
        fontSize: "10px",
        fontWeight: "600",
      })}
    >
      <Avatar
        sx={{ width: 40, height: 40 }}
        alt="Profile Image"
        //   src={
        //     path
        //       ? `${process.env.REACT_APP_BASE_API_URL}/${path}`
        //       : user?.picture
        //   }
      />

      <Stack direction="column" spacing={0.5}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="customHeading">
            {" "}
            {data?.results?.name || "N/A"}
          </Typography>
          <Tooltip title="Link Patient">
            <IconButton
              sx={{ ml: "3px" }}
              size="small"
              onClick={() => {
                setLinkQueueModalOpen({
                  value: true,
                  data: data?.data,
                  results: data?.results,
                  taskCategory: data?.taskCategory,
                  callerIdentification: data?.callerIdentification,
                });
              }}
            >
              <PersonSearchIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Chip
          label={"Not Registered"}
          size="small"
          sx={{
            backgroundColor: "#B71C1C",
            color: "white",
            fontWeight: 600,
          }}
        />
      </Stack>

      <Stack direction="column" spacing={0.5}>
        <Typography variant="customHeading">Date of Birth</Typography>
        <Typography variant="customContent">
          {data?.results?.dob || "N/A"}
        </Typography>
      </Stack>

      <Stack direction="column" spacing={0.5}>
        <Typography variant="customHeading">Phone</Typography>
        <Typography variant="customContent">
          {formatPhoneNumber(data?.results?.phone_number) || "N/A"}
        </Typography>
      </Stack>

      <Stack direction="column" spacing={0.5}>
        <Typography variant="customHeading">Caller Identification</Typography>
        <Typography variant="customContent">
          {data?.callerIdentification || "N/A"}
        </Typography>
      </Stack>

      <Stack direction="column" spacing={0.5}>
        <Typography variant="customHeading">
          SMS Consent
          {(data?.results?.consentResponse !== "yes" ||
            data?.results?.phone_number !== "N/A") &&
            data?.results?.phone_number && (
              <Tooltip title="Resend SMS Consent">
                <IconButton onClick={handleResend}>
                  <ReplayIcon sx={{ width: "20px", height: "20px" }} />
                </IconButton>
              </Tooltip>
            )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              ...getConsentColors(data?.results?.consentResponse),
              display: "inline-block", // Ensures the dot behaves like an element, not text
              width: "8px", // Set the diameter of the circle
              height: "8px", // Keep width and height equal
              borderRadius: "50%", // Makes the shape circular
              backgroundColor: "currentColor", // Use the current font color from getColorStyles
            }}
          ></span>
          <Typography variant="customContent">
            {getSmsConsent(data?.results) || "N/A"}
          </Typography>
        </Box>
      </Stack>

      <LinkQueueModal
        open={linkQueueModalOpen?.value}
        queueId={linkQueueModalOpen?.data}
        metadata={linkQueueModalOpen?.results}
        callresults={linkQueueModalOpen?.callresults}
        taskCategory={linkQueueModalOpen?.taskCategory}
        callerIdentification={linkQueueModalOpen?.callerIdentification}
        setOpenUnknownCallerChat={setOpenUnknownCallerChat}
        onClose={() =>
          setLinkQueueModalOpen({
            value: false,
            data: null,
            results: null,
            taskCategory: null,
            callerIdentification: null,
          })
        }
        refetchQueueAgain={refetchQueueAgain}
      />

      <ConfirmationModal
        open={openResend}
        setOpen={setOpenResend}
        txt="Are you sure you want to resend the SMS consent?"
        handle={handleDataResend}
      />
    </Box>
  );
}
