import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { toast } from "react-toastify";
import { useChildNode } from "../contexts/addChildNode";

export function NodeMenuV2({
  handleOpenModal,
  toggleEditDrawer,
  anchorEl,
  setAnchorEl,
  setIsEdit,
  setExpanded,
  isfilled,
  hasChildNode,
  setAddChildNode,
  id,
  checkCondition,
  setAnchorElButton,
  isNextNodefilled,
  isEdit,
  title,
  nodeInternals,
  isConditionLastNode,
}) {
  const open = Boolean(anchorEl);
  const {
    setIsChildNodeAdded,
    setEditMenu,
    setForceRefreshChildNode,
    setOpenDrawer,
  } = useChildNode();

  const [permissionValues, setPermissionValues] = useState(null);

  const checkIsFilledNode = () => {
    for (const [key, value] of nodeInternals.entries()) {
      if (
        !value.data?.isFilled &&
        value?.data?.label !== "Start Journey" &&
        value?.data?.method !== "delay"
      ) {
        return key;
      }
    }

    return null;
  };

  const handleClick = (event) => {
    if (checkIsFilledNode() !== id && checkIsFilledNode() !== null) {
      toast.warning(
        "Please save or delete the unsaved node to continue. The unsaved node has a red flashing sign.",
      );
    } else {
      setExpanded(false);
      setEditMenu(null);
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
    }
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
    setAnchorElButton(null);
  };

  const handleAdChild = (event) => {
    event?.stopPropagation();
    setAddChildNode(true);
    setIsChildNodeAdded(true);
    setForceRefreshChildNode(true);
    setAnchorEl(null);
    setAnchorElButton(null);
    setOpenDrawer(true);
  };

  return (
    <div style={{ marginTop: title && "5px" }}>
      <div
        sx={{ cursor: "pointer" }}
        id="basic-button"
        aria-controls={open ? "basic-menu1" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </div>
      <Menu
        id="basic-menu1"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!hasChildNode &&
          !checkCondition &&
          isNextNodefilled() &&
          !isConditionLastNode() &&
          isfilled() && (
            <MenuItem onClick={handleAdChild}>Add Child Node</MenuItem>
          )}
        {isfilled() && (
          <MenuItem
            onClick={(event) => {
              event?.stopPropagation();
              setIsEdit(true);
              setEditMenu(id);
              setOpenDrawer(true);
              setAnchorEl(null);
            }}
          >
            Edit
          </MenuItem>
        )}
        <MenuItem
          onClick={(event) => {
            event?.stopPropagation();
            handleClose();
            handleOpenModal();
            setAnchorEl(null);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
