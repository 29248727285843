import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CircularLoader,
  DeleteModal,
  PaginationBar,
} from "../../../Components";
import { DeleteSvg, EditSvg } from "../../../assets/svgs";
import { useDeleteTagMutation } from "../../../store/tagsSlice";
import { hasScope } from "../../../utils";
import { EditTagModal } from "./EditTagModal";

// const tags = [{name:"urgent"},{name:"normal"},{name:"quick"}]

const columns = [
  // { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const createRows = (
  tags,
  handleOpenModal,
  handleOpenEditModal,
  handleDelete,
  openModal,
  setOpenModal,
  openEditModal,
  datas,
  setOpenEditModal,
  scopes,
) => {
  const rows = tags.map(({ _id, tag, color }, index) => {
    return {
      // Srno: (
      //   <Typography
      //     variant="p"
      //     sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
      //   >
      //     {index + 1}
      //   </Typography>
      // ),
      name: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              color: "#FFFFFF",
              backgroundColor: color ? `#${color.hex}` : "red",
              border: "1px solid #979797",
              borderRadius: "5px",
              padding: "7px",
            }}
          >
            {tag}
          </Typography>
        </>
      ),
      actions: (
        <>
          <Stack spacing={3} direction="row" justifyContent="center">
            {hasScope(scopes, "settings/tags:edit") && (
              <div
                onClick={(event) => handleOpenEditModal(event, tag, color, _id)}
                style={{ cursor: "pointer" }}
              >
                <EditSvg />
              </div>
            )}

            {hasScope(scopes, "settings/tags:delete") && (
              <div
                style={{ cursor: "pointer" }}
                onClick={(event) => handleOpenModal(event, tag, _id)}
              >
                <DeleteSvg />
              </div>
            )}
          </Stack>
          <DeleteModal
            open={openModal}
            setOpen={setOpenModal}
            id={datas}
            handle={handleDelete}
          />
          <EditTagModal
            open={openEditModal.open}
            setOpen={setOpenEditModal}
            tags={openEditModal.tag}
            hospitals={openEditModal?.hospital}
            creators={openEditModal.creator}
            color={openEditModal.color}
            id={openEditModal.id}
            title={"Edit Tag"}
          />
        </>
      ),
    };
  });

  return rows;
};

export function TagsTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  searchTerm,
  scopes,
}) {
  const [openModal, setOpenModal] = React.useState(false);
  const [datas, setData] = React.useState("");
  const handleOpenModal = (event, tag, id) => {
    console.log(tag, 111);
    setData(id);
    setOpenModal(true);
  };
  const [deleteTag, {}] = useDeleteTagMutation();
  // States for CreateTagModal
  const [openEditModal, setOpenEditModal] = React.useState({
    open: false,
    id: null,
    tag: "",
    color: {},
  });
  const handleOpenEditModal = (event, tag, color, id) => {
    event.stopPropagation();
    setOpenEditModal({ open: true, tag, color, id });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleDelete = async (id) => {
    console.log(id, "awais");
    await deleteTag(id).unwrap();
  };

  const tags = data ? data.data : [];

  const rows = createRows(
    tags,
    handleOpenModal,
    handleOpenEditModal,
    handleDelete,
    openModal,
    setOpenModal,
    openEditModal,
    datas,
    setOpenEditModal,
    scopes,
  );

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return <Typography sx={{ margin: "10px 20px" }}>No Tags found.</Typography>;
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader={true} aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align={"center"}
                  sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow hover={true} role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data.totalPages, handleChange: handleChangePage }}
      />
    </>
  );
}
