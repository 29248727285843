import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Chrono } from 'react-chrono'
import CircleIcon from '@mui/icons-material/Circle'
import { useGetPatientSurgeriesQuery } from '../../store/surgeriesSlice'
import { CircularLoader } from '../Loader'
import AddIcon from '@mui/icons-material/Add'
import { useDeleteAssignJourneyMutation, useGetAssignJourneyQuery } from '../../store/assignJourneySlice'
import { useNavigate } from 'react-router-dom'
import { JourneyAccordianModal } from '../../Pages/Patient/Profile/Journey'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { toast } from 'react-toastify'

const customContent = (dummyCallData, id, handleNavigate, handleDelete) => {
	return dummyCallData.map((data, index) => (
		<Box key={index} sx={{ width: '-webkit-fill-available' }}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={2}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Title
					</Typography>
					<Typography variant="body2 ">{data?.title}</Typography>
				</Grid>

				<Grid item xs={12} sm={2}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Description
					</Typography>
					<Typography variant="body2">{data?.description}</Typography>
				</Grid>

				<Grid item xs={12} sm={2}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Created on
					</Typography>
					<Typography variant="body2">{moment(data?.journeyId?.createdAt).format('DD-MMM-YY')}</Typography>
				</Grid>

				<Grid item xs={12} sm={2}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Assigned on
					</Typography>
					<Typography variant="body2">{moment(data?.createdAt).format('DD-MMM-YY')}</Typography>
				</Grid>

				<Grid item xs={12} sm={2}>
					<Typography variant="subtitle2" sx={{ mb: 1 }}>
						Status
					</Typography>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<span
							style={{
								display: 'inline-block', // Ensures the dot behaves like an element, not text
								width: '10px', // Set the diameter of the circle
								height: '10px', // Keep width and height equal
								borderRadius: '50%', // Makes the shape circular
								backgroundColor: data?.currentNodeCompleted ? '#63A02C' : '#FFB400', // Use the current font color from getColorStyles
							}}
						></span>

						<Typography fontSize={14} color="black" marginLeft={'6px'}>
							{data?.currentNodeCompleted ? 'Completed' : 'In Progress'}{' '}
						</Typography>
					</Box>
				</Grid>

				<Grid item xs={12} sm={2}>
					<IconButton onClick={() => handleNavigate(data?.journeyId?._id, id, data?._id)} fontSize="small">
						<VisibilityIcon fontSize="small" />
					</IconButton>
					<IconButton onClick={() => handleDelete(data?._id)} fontSize="small">
						<DeleteIcon fontSize="small" />
					</IconButton>
				</Grid>
			</Grid>
		</Box>
	))
}

const CustomTitle = ({ dateTime }) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography variant="subtitle" color="textSecondary" fontSize={'14px'}>
				{moment(dateTime).format('DD-MMM-YY')}
			</Typography>
			<Typography variant="body2" color="textSecondary" fontSize={'12px'}>
				{moment(dateTime).format('h:mm A')}
			</Typography>
		</Box>
	)
}

const JourneyTab = ({ user, id, status }) => {
	const [open, setOpen] = useState(false)
	const navigate = useNavigate()
	const [timelineItems, setTimelineItems] = useState([])
	const [limit, setLimit] = useState(10);
	const [deleteAssignJourney] = useDeleteAssignJourneyMutation()
	const [currentPage, setCurrentPage] = useState(1)
	const { isLoading, isSuccess, isError, error, data, refetch } = useGetAssignJourneyQuery(
		{ userID: user.id, page: 1, limit, patientId: id, title: '', description: '' },
		{
			refetchOnMountOrArgChange: true,
		}
	)

	const handleNavigate = (journeyId, id, dataId) => {
		navigate(`/viewjourneysflow/${journeyId}/${id}/${dataId}`)
	}
	const handleViewMore = () => {
		setCurrentPage((prevPage) => prevPage + 1)
		setLimit((prevLimit) => prevLimit + 10);
		refetch();
	}

	const fetchAgain = () => {
		refetch()
	}

	const handleModal = () => {
		setOpen(true)
	}

	const handleDelete = async (id) => {
		deleteAssignJourney(id)
			.unwrap()
			.then(() => {
				fetchAgain()
				toast.success('Deleted successfully!')
			})
			.catch((error) => {
				toast.error('some error occured!')
				console.error(error)
			})
	}

	console.log(data, 'DATA')

	useEffect(() => {
		if (data?.data) {
			const timelineDataReturn = data?.data?.map((item) => {
				return { title: <CustomTitle dateTime={item?.createdAt} /> }
			})
			setTimelineItems(timelineDataReturn)
		}
	}, [data])

	if (isLoading) return <CircularLoader />
	const isViewMore = data?.data?.length < limit;

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography variant="h5">Journeys</Typography>
				<Button variant="contained" startIcon={<AddIcon />} onClick={() => handleModal()}>
					Assign
				</Button>
			</Box>
			<Box
				sx={{
					height: '67vh',
					overflowY: 'scroll',
					overflowX: 'hidden',
				}}
				className="DialogContent"
			>
				{data?.data?.length !== 0 ? (
					<>
						<Box>
							<Chrono
								key={timelineItems?.length}
								items={timelineItems}
								hideControls
								disableClickOnCircle
								mode="VERTICAL"
								disableAutoScrollOnClick={true}
								scrollable={false}
								cardHeight={100}
								theme={{ primary: '#0049C6', secondary: '#f5f5f5' }}
								cardPosition="top"
							>
								{customContent(data?.data ? data?.data : [], id, handleNavigate, handleDelete)}

								<Box className="chrono-icons">
									{timelineItems.map((item, index) => (
										<CircleIcon key={index} sx={{ color: '#0049C6', fontSize: '20px' }} />
									))}
								</Box>
							</Chrono>
						</Box>
						{currentPage < data?.totalPages && (
								<Box sx={{ display: 'flex', justifyContent: 'flexStart', marginTop: '16px', marginLeft: '20%' }}>
									<Button variant="contained" onClick={handleViewMore}>
										View Old journeys
									</Button>
								</Box>
						)}
					</>

				) : (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '60vh',
						}}
					>
						<Typography variant="h6">No Journey Found</Typography>
					</Box>
				)}
			</Box>
			<JourneyAccordianModal {...{ open, setOpen, pid: id, fetchAgain, status }} />
		</Box>
	)
}

export default JourneyTab
