import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularLoader } from "../../../Components/Loader";
import { PATHS } from "../../../constants/routes";
import {
  useEditSurveyMutation,
  useGetSurveysByIDQuery,
} from "../../../store/suerveySlice";
import uploadSurveyLogo from "../HelperFunction";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: false,
  haveCommercialLicense: true,
};

export function SurveyEditorWidget() {
  const navigate = useNavigate();
  const location = useLocation();

  const id = location.state.id;
  const [editPost, {}] = useEditSurveyMutation();
  const { data, isLoading, isSuccess, isError, error } =
    useGetSurveysByIDQuery(id);

  // let form = {};
  // if (isSuccess) {
  //   form = data.data;

  // }

  const user = useSelector((state) => state.user);

  async function saveSurveyJson(url, json, saveNo, callback) {
    let imageUrl = null;

    if (json?.logo?.startsWith("data")) {
      imageUrl = await uploadSurveyLogo(json.logo);
      json.logo = imageUrl;
    }

    const payload = {
      saveNo,
      form: json,
      createdBy: user?.email,
    };

    try {
      const response = await editPost({
        id: location.state.id,
        body: payload,
      }).unwrap();
      navigate(PATHS.formsIndexPage);
      callback(saveNo, true);
    } catch (e) {
      console.log(e);
      callback(saveNo, false);
    }
  }

  if (isLoading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <CircularLoader />
      </div>
    );

  if (isSuccess) {
    const creator = new SurveyCreator(creatorOptions);
    const { form } = data;
    creator.text = JSON.stringify(form.form);
    creator.saveSurveyFunc = (saveNo, callback, user) => {
      callback(saveNo, true);
      saveSurveyJson(
        `${process.env.REACT_APP_BASE_API_URL}/surveys/`,
        creator.JSON,
        saveNo,
        callback,
        user,
      );
    };
    return <SurveyCreatorComponent creator={creator} />;
  }
}
