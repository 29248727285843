import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, Typography } from "@mui/material";

export default function Header({
  activeStep,
  setActiveStep,
  title,
  isPatientScreen,
  setIsEnrolledClicked,
}) {
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Box
            sx={{
              border: "1px solid #959595",
              borderRadius: "15px",
              display: "inline-flex",
              padding: "8px",
            }}
          >
            <ArrowBackIcon
              fontSize="small"
              onClick={() => {
                if (isPatientScreen) {
                  setActiveStep(activeStep - 1);
                } else {
                  setActiveStep(null);
                  setIsEnrolledClicked(false);
                }
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={10} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={1} /> {/* Empty grid item to balance the layout */}
      </Grid>
    </>
  );
}
