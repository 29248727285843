import React from 'react'

export function Gallery({color, size}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size || '16'} height={size || '16'} viewBox="0 0 16 16" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M6.51883 14H9.4815C11.5622 14 12.6028 14 13.3502 13.51C13.6727 13.2988 13.9505 13.026 14.1675 12.7073C14.6668 11.974 14.6668 10.952 14.6668 8.90933C14.6668 6.86667 14.6668 5.84467 14.1675 5.11133C13.9505 4.79266 13.6727 4.51991 13.3502 4.30867C12.8702 3.99333 12.2688 3.88067 11.3482 3.84067C10.9088 3.84067 10.5308 3.514 10.4448 3.09067C10.3791 2.78058 10.2083 2.50269 9.96139 2.30397C9.71445 2.10524 9.40646 1.99787 9.0895 2H6.91083C6.25216 2 5.68483 2.45667 5.5555 3.09067C5.4695 3.514 5.0915 3.84067 4.65216 3.84067C3.73216 3.88067 3.13083 3.994 2.65016 4.30867C2.32785 4.51997 2.05035 4.79273 1.8335 5.11133C1.3335 5.84467 1.3335 6.866 1.3335 8.90933C1.3335 10.9527 1.3335 11.9733 1.83283 12.7073C2.04883 13.0247 2.32616 13.2973 2.65016 13.51C3.3975 14 4.43816 14 6.51883 14ZM8.00016 6.182C6.46616 6.182 5.22216 7.40267 5.22216 8.90867C5.22216 10.4147 6.46683 11.6373 8.00016 11.6373C9.5335 11.6373 10.7782 10.416 10.7782 8.91C10.7782 7.404 9.5335 6.182 8.00016 6.182ZM8.00016 7.27267C7.08016 7.27267 6.3335 8.00533 6.3335 8.90933C6.3335 9.81267 7.08016 10.5453 8.00016 10.5453C8.92016 10.5453 9.66683 9.81267 9.66683 8.90933C9.66683 8.006 8.92016 7.27267 8.00016 7.27267ZM11.1482 6.72733C11.1482 6.426 11.3968 6.182 11.7042 6.182H12.4442C12.7508 6.182 13.0002 6.426 13.0002 6.72733C12.9988 6.87327 12.9395 7.01267 12.8353 7.11492C12.7312 7.21717 12.5908 7.27391 12.4448 7.27267H11.7042C11.6318 7.27337 11.5601 7.25982 11.493 7.2328C11.4259 7.20577 11.3648 7.1658 11.3132 7.11516C11.2616 7.06452 11.2204 7.00421 11.1921 6.93766C11.1638 6.87112 11.1489 6.79965 11.1482 6.72733Z"
				fill={color}
			/>
		</svg>
	)
}
