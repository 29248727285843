import { Box, Grid, Typography } from "@mui/material";
import { useIsMobile } from "../../../contexts/isMobile";

export function ChartHeader({ week, setWeek }) {
  const isMobile = useIsMobile();

  const gridMargin = {
    margin: isMobile && "10px 5px",
  };

  return (
    <>
      <Box
        sx={{
          padding: isMobile ? "6px" : 3,
          paddingBottom: "0px !important",
          marginBottom: "10px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item xs={12} sm={8} sx={gridMargin}>
            <Typography variant="h5">
              Social Needs Distribution by ICD -10 Code
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
