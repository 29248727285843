import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SolidButton } from "../../../../Components";
import { DateField } from "../../../../Components";
import OutlinedButton from "../../../../Components/OutlinedButton";
import { useIsMobile } from "../../../../contexts/isMobile";
import { useAddNewPatientsSurveyMutation } from "../../../../store/patientsSurveySlice";
import { useGetAllSurveysQuery } from "../../../../store/suerveySlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 400,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius: "10px",
};

export const AssignFormsModal = ({
  open,
  setOpen,
  pid,
  fetchAgain,
  status,
  patientName,
}) => {
  const isMobile = useIsMobile();
  const { user } = useSelector((state) => state.user);

  const [initialRender, setInitialRender] = useState(true);
  const [smsOnFormAssignment, setSmsOnFormAssignment] = useState("");
  const [assign, setAssign] = useState("");
  const [selectedAssign, setSelectedAssign] = useState("");
  const [date, setDate] = useState();

  const { isLoading, isSuccess, isError, error, data } = useGetAllSurveysQuery({
    userID: user.id,
  });
  const [addNewPatientsSurvey, { isLoading: isAddSurveyLoading }] =
    useAddNewPatientsSurveyMutation();

  const resetValues = () => {
    setSmsOnFormAssignment(
      `Dear ${patientName},\nWe value you and would appreciate it if you could take a moment to complete a short survey.\nPlease click on the following link to access the survey:\n{{ surveyLink }}`,
    );
    setAssign("");
    setSelectedAssign("");
    setDate();
    setInitialRender(true);
  };

  const handleValidation = () => {
    if (
      smsOnFormAssignment === "" ||
      smsOnFormAssignment === "{{ surveyLink }}"
    ) {
      toast.error("SMS Notification Message is required");
    } else if (selectedAssign === "") {
      toast.error("Assign Form is required");
    }
  };

  const handleAssign = () => {
    if (status === "active") {
      if (
        smsOnFormAssignment === "" ||
        smsOnFormAssignment === "{{ surveyLink }}" ||
        selectedAssign === ""
      ) {
        handleValidation();
        return;
      }

      addNewPatientsSurvey({
        smsOnFormAssignment,
        formId: assign,
        patientId: pid,
        status: "pending",
        hospital: user?.hospital || null,
        dueDate: date,
      })
        .unwrap()
        .then(() => {
          fetchAgain();
          resetValues();
          setOpen(false);
          toast.success("Successfully assigned!");
        })

        .catch((error) => {
          toast.error(error?.data?.error);
          console.log(error, "error in addnewPatientSurvey");
          setOpen(true);
        });
    } else {
      toast.error("The patient status is currently inactive.");
      setOpen(false);
    }
  };

  const handleChange = (event, value) => {
    const formId = data?.data?.find((item) => item?.form?.title === value);
    setSelectedAssign(value);
    setAssign(formId?._id);
  };

  const handleClose = () => {
    resetValues();
    setOpen(false);
  };

  const handleSmsChange = (e) => {
    const newValue = e.target.value;
    if (!newValue.includes("{{ surveyLink }}")) {
      toast.error(
        "You must include {{ surveyLink }} in the SMS Notification Message.",
      );
      return;
    }
    setSmsOnFormAssignment(newValue);
  };

  useEffect(() => {
    if (smsOnFormAssignment === "" && initialRender) {
      setSmsOnFormAssignment(
        `Dear ${patientName},\nWe value you and would appreciate it if you could take a moment to complete a short survey.\nPlease click on the following link to access the survey:\n{{ surveyLink }}`,
      );
      setInitialRender(false);
    }
  }, [smsOnFormAssignment]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "80%" : 700,
            height: 400,
            bgcolor: "#F5F5F5",
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Forms
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          <Grid
            container={true}
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item={true} md={6} xs={12}>
              <Typography fontSize={12} mt={2}>
                Assign Form <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                sx={{ marginTop: "32px" }}
                value={selectedAssign}
                onChange={(event, newValue) => {
                  handleChange(event, newValue);
                }}
                options={
                  !isLoading && data?.data?.map((item) => item?.form?.title)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography fontSize={12} mt={2}>
                Due Date
              </Typography>
              <DateField {...{ date, setDate }} />
            </Grid>
            <Grid item={true} md={12} xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize={12} mr={1}>
                  SMS Notification Message{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Tooltip title="This message will be sent when a form is assigned to the patient. The placeholder {{ surveyLink }} will be replaced with the actual survey link when the message is delivered.">
                  <Box
                    sx={{ color: "grey", paddingTop: "3px", cursor: "pointer" }}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Box>
                </Tooltip>
              </Box>
              <TextField
                variant="standard"
                fullWidth={true}
                name="address1"
                multiline={true}
                rows={5}
                value={smsOnFormAssignment}
                onChange={(e) => handleSmsChange(e)}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
            }}
          >
            <OutlinedButton label="Cancel" handleClick={handleClose} />
            <SolidButton
              label="Assign"
              onClick={handleAssign}
              disabled={isAddSurveyLoading}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
