import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChat: builder.query({
      query: (test) => {
        return `/chat/${test?.id}/${test?.sid}?limit=10&&page=${test?.page}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Chat", id: "LIST" },
          ...data.map((p) => ({ type: "Chat", id: p._id })),
        ];
      },
    }),
    getMessagePlaceholders: builder.query({
      query: () => {
        return `/chat/getMessagePlaceholders`;
      },
    }),
    toggleChat: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/patients/toggleChatBot",
          method: "POST",
          body: initialPost,
        };
      },
      // invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),
    messageSeen: builder.mutation({
      query: (id) => {
        return {
          url: `/chat/${id}`,
          method: "POST",
          body: "",
        };
      },
      // invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),

    addChat: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/chat",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),

    resendConsent: builder.mutation({
      query: (id) => {
        return {
          url: `/chat/${id}/sendConsentMessage`,
          method: "POST",
        };
      },
    }),

    resendUnknownUserConsent: builder.mutation({
      query: (body) => {
        return {
          url: `/chat/sendConsentMessageToUnknownUser`,
          method: "POST",
          body,
        };
      },
    }),

    generateSuggestion: builder.query({
      query: (id) => {
        return {
          url: `/chat/generateReplySuggestion/${id}`,
          method: "GET",
          body: "",
        };
      },
    }),
  }),
});

export const {
  useGenerateSuggestionQuery,
  useGetChatQuery,
  useGetMessagePlaceholdersQuery,
  useAddChatMutation,
  useResendConsentMutation,
  useToggleChatMutation,
  useMessageSeenMutation,
  useResendUnknownUserConsentMutation,
} = extendedApiSlice;
