import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DateField, SolidButton } from "../../../../Components";
import { useIsMobile } from "../../../../contexts/isMobile";
import {
  useAddNewTaskMutation,
  useEditTaskMutation,
} from "../../../../store/tasksSlice";

const statusData = [
  { id: "assigned", label: "Assigned" },
  { id: "completed", label: "Completed" },
];
export function StatusSelect({ selectedStatus, setSelectedStatus, setStatus }) {
  const handleChange = (event, newValue) => {
    const statusId = statusData?.find((item) => item.label === newValue);
    setStatus(statusId?.id);
    setSelectedStatus(newValue);
  };

  return (
    <Box>
      <Typography fontSize={12} mt={2}>
        Select Status
      </Typography>
      <Autocomplete
        value={selectedStatus}
        onChange={(event, newValue) => handleChange(event, newValue)}
        options={statusData.map((item) => item.label)}
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    </Box>
  );
}

export default function AssignTaskModal({
  open,
  setOpen,
  fetchAgain,
  setEditData,
  editData,
  status: patientStatus,
  patientId,
}) {
  const [date, setDate] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [status, setStatus] = useState("assigned");
  const [selectedStatus, setSelectedStatus] = useState("assigned");
  const [team, setTeam] = useState("");
  const { user } = useSelector((state) => state.user);
  const [form, setForm] = useState({
    title: "",
    description: "",
  });
  const [addNewTask, { isLoading: isAddTaskLoading }] = useAddNewTaskMutation();
  const [editTask, { isLoading: isEditTaskLoading }] = useEditTaskMutation();
  const isMobile = useIsMobile();
  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const resetAllValues = () => {
    setDate("");
    setSelectedMembers([]);
    setStatus("assigned");
    setSelectedStatus("assigned");
    setTeam("");
    setForm({
      title: "",
      description: "",
    });
    setEditData({});
  };

  const { id } = useParams();
  const createTask = async (e) => {
    if (patientStatus === "active") {
      if (editData._id) {
        const body = {
          ...form,
          date,
          status,
        };

        await editTask({
          userID: user.id,
          taskID: editData?._id,
          body,
        })
          .unwrap()
          .then(() => {
            fetchAgain();
            resetAllValues();
            setOpen(false);
            toast.success("Successfully created!");
          })
          .catch((error) => {
            toast.error(error.data || error?.data?.error);
          });
      } else {
        await addNewTask({
          ...form,
          date,
          status,
          flag: "true",
          patientId: patientId ? patientId : id,
          createdBy: user.id,
          members: [...selectedMembers, user.id],
        })
          .unwrap()
          .then(() => {
            fetchAgain();
            resetAllValues();
            setOpen(false);
            toast.success("Successfully created!");
          })
          .catch((error) => {
            toast.error(error.data || error?.data?.error);
          });
      }
    } else {
      toast.error("The patient status is currently inactive.");
      resetAllValues();
      setOpen(false);
    }
  };

  const handleClose = () => {
    resetAllValues();
    setOpen(false);
  };

  useEffect(() => {
    if (editData) {
      const statusLabel = statusData?.find(
        (item) => item.id === editData?.status,
      );
      setForm({
        title: editData?.taskId?.title || "",
        description: editData?.taskId?.description || "",
      });

      setSelectedStatus(statusLabel);
      setStatus(editData?.status);
      setDate(new Date(editData?.date));
    }
  }, [editData]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "80%" : 700,
          height: isMobile ? 435 : 400,
          bgcolor: "#F5F5F5",
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Task
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={2}>
              Title
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="title"
              value={form.title}
              onChange={onChange}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <DateField {...{ date, setDate, label: "Due Date" }} />
          </Grid>
          <Grid item md={6} xs={12}>
            <StatusSelect
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              setStatus={setStatus}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={2}>
              Description
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="description"
              value={form.description}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
        >
          <SolidButton
            label="Assign"
            onClick={createTask}
            disabled={isAddTaskLoading || isEditTaskLoading}
          />
        </Stack>
      </Box>
    </Modal>
  );
}
